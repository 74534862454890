import styled from 'styled-components';

export const Container = styled.div`
  z-index: 98;
  height: 74px;

  .card-alert {
    width: 100%;
    position: fixed;
    padding-top: 74px;
    padding-bottom: 12px;
    background-color: var(--color-primary);
  }

  .convite-display {
    display: flex;
  }

  .btn-preview.ant-btn-background-ghost.ant-btn-warning {
    background-color: var(--color-white) !important;
  }

  .font-highlight {
    font-size: 16px;
    font-weight: 600;
  }

  .convite-card {
    display: flex;
    align-items: center;
    width: 60%;
  }

  .convite-msg-card {
    width: 75%;
    padding-right: 1rem;
  }

  .convite-botao-card {
    width: 25%;
  }

  @media (min-width: 768px) {
    .convite-msg {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @media (max-width: 767px) {
    .convite-display {
      display: block;
    }

    .convite-msg-card {
      width: 100%;
    }

    .convite-botao-card {
      width: 100%;
    }

    .convite-botao {
      width: 100%;
    }
  }
`;
