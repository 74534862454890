import gql from 'graphql-tag';

export const LISTAR_FORNECEDORES_RECENTES = gql`
  query ListarFornecedoresRecentes($limit: Int, $apenasProdutos: Boolean) {
    listarFornecedoresRecentes(limit: $limit, apenasProdutos: $apenasProdutos) {
      id
      nome
      fotoUrl
      fotoUrlResponsive {
        url
        urlXs
        urlMd
        urlXl
        urlXxl
      }
      ocupacao
      mediaAvaliacao
      slug
    }
  }
`;
