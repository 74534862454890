import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  @media (min-width: 576px) {
    margin-top: 50px;
  }

  @media (min-width: 768px) {
    .container-card:nth-child(1) .card,
    .container-card:nth-child(2) .card {
      background-image: unset;
    }
  }

  @media (min-width: 992px) {
    .container-card .card {
      background-image: unset;
    }
  }
`;
