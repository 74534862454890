const fontFamily = `'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`;

export const editorRenderStyles = {
  header: {
    textAlign: 'left',
    fontFamily: fontFamily
  },
  paragraph: {
    fontFamily: fontFamily,
    fontSize: 14,
    fontWeight: 500
  },
  list: {
    fontFamily: fontFamily,
    paddingLeft: 16
  },

  delimiter: {
    container: {},
    svg: { width: '10px', height: '10px', margin: '5px' },
    path: {
      fill: '#231F20'
    }
  }
};
