import { createGlobalStyle } from 'styled-components';
import './helpers.css';
import './ui-kit.css';
import Cookies from '../images/svgs/cookie.svg';

export default createGlobalStyle`
/*Esse trecho de css é utilizado para ter um padding para o botão do chat não fica em cima da modal  */
.ant-modal-content {
    margin-bottom: 56px;
  }

@media(min-width: 768px) {
  .ant-modal-content {
    margin-bottom: 24px; 
  }
} 


/*Esse trecho de css é utilizado para fazer popover customizados  */
.popover-transparent {
  z-index: 1 !important;
  border-color: transparent;
  box-shadow: 0 0px 0px transparent;

  .ant-popover-inner {
    background: transparent;
    border-color: transparent;
    box-shadow: 0 0px 0px transparent;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }
}

.icon-loading svg {
    animation: loadingCircle 1s infinite linear;
    display: block;
  }

  @-webkit-keyframes loadingCircle {
    100% {
      /* stylelint-disable-next-line */
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      /* stylelint-disable-next-line */
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  /* Esse trecho de css é utilizado para estilizar o botão de cookies do OneTrust */
  #onetrust-policy {
    margin: 1.25em 0 .625em 3.2em !important;
    @media(max-width: 768px)  {
      margin: 1.25em 0 .625em 1.25em !important;
    }
    @media(max-width: 420px)  {
      margin: 1.25em 0 .625em 0em !important;
    }
  }

  @media(max-width: 640px)  {
    .ot-pc-refuse-all-handler {
      margin-bottom: 10px !important;
    }
    #accept-recommended-btn-handler {
      margin-top: 5px !important;
    }

    .save-preference-btn-handler {
      margin-left: 17px !important;
    }

    .ot-pc-logo {
      margin-top: 0px !important;
      width: 80px !important;
    }

    .ot-pc-header {
      display: flex !important;
      align-items: center !important;
      justify-content: left !important;
    }

    .ot-title-cntr {
      width: 100% !important;
    }

    #ot-pc-title {
      font-size: 1em !important;
      white-space: unset !important;
      max-width: 80% !important;
    }
  }

  @media(min-width: 890px) and (max-width: 1279px) {
    #onetrust-button-group {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      flex-wrap: wrap;
      gap: 5px !important;
      margin-top: -1% !important;
    }

     #onetrust-pc-btn-handler {
      margin: 0px !important;
    }

    #onetrust-reject-all-handler {
      margin: 0px !important;
    }

    #onetrust-accept-btn-handler {
      margin: 0px !important;
    }
  }

  .ot-floating-button__front {
    background-color: var(--color-primary) !important;
    background-image: url(${Cookies}) !important;
    background-size: 20px !important;
    border-radius: 0 0 8px 0 !important;
    width: 30px !important;
    height: 42px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  #ot-sdk-btn-floating svg { 
    display: none !important
  }

  #ot-sdk-btn-floating {
    bottom: 10px !important;
    left: 0px !important;
  }

  .ot-pc-open {
    display: none !important;
  }
  `;
