import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  box-shadow: 0px 0px 8px var(--color-card-shadow);
  border-radius: 3px;

  :hover {
    box-shadow: 0px 0px 8px var(--color-card-shadow);
  }

  .cover-container {
    ${({ formatList }) =>
      formatList
        ? css`
            min-height: 279px;
            height: 100%;
          `
        : css`
            height: 114px;
          `}
  }

  .cover {
    object-fit: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: ${props => (props.formatList ? '3px' : '0px')};
    border-top-right-radius: ${props => (props.formatList ? '0px' : '3px')};
    width: 100%;
    ${({ formatList }) =>
      formatList
        ? css`
            min-height: 279px;
            height: 100%;
          `
        : css`
            height: 114px;
          `}
  }

  .row-values {
    height: 40px;
  }

  .extra-info {
    background-color: var(--text-color);
    color: var(--color-white-gray);
    border-bottom-left-radius: ${props => (props.formatList ? '0px' : '3px')};
    border-bottom-right-radius: 3px;
    height: 104px;
  }

  .produto-title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .categoria-title {
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.7px;
  }

  .valor-anterior-style {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.7px;
    font-family: 'Open Sans', sans-serif !important;
    color: var(--color-gray);
  }

  .valor-style {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif !important;
  }

  .duracao {
    font-size: 14px;
    color: var(--color-white-gray);
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif !important;
  }

  .text-sebraeplace-price {
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.7px;
  }

  .col-price-padding {
    padding: 10px 13px;
  }

  .col-duracao {
    height: 60px;
  }

  .line-price {
    border-bottom: 0px solid var(--color-gray);
  }

  .text-de {
    color: var(--color-gray);
  }

  .avatar-comp {
    position: absolute;
    bottom: ${props => (props.formatList ? '10px' : '-25px')};
    width: 45px;
    height: 45px;
    right: 10px;
    border: 1px solid var(--color-white);
    z-index: 1;
  }

  .container-modalidade {
    width: calc(100% - 2rem);
    position: ${props => (props.formatList ? 'relative' : 'absolute')};
    top: ${props => (props.formatList ? 'unset' : '-12px')};
    display: flex;
    justify-content: ${props => (props.formatList ? 'left' : 'center')};
    margin-bottom: 0.5rem;
  }

  .modalidade {
    background: var(--color-curso-online);
    text-align: center;
    border-radius: 3px;
    color: var(--color-white);
    padding: 2px 5px;
  }

  .icon-play {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 12px;
    width: 62px;
    height: 62px;
    opacity: 1;
    background: var(--color-black-transparent);
    backdrop-filter: blur(5px);
  }

  .icon-heart {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 0px 3px 0px 0px;
    text-align: end;
    height: 88px;
    width: 88px;
    /* stylelint-disable-next-line */
    background: transparent linear-gradient(223deg, #2c2c2cd0 0%, #2c2c2c00 43%)
      0% 0% no-repeat padding-box;
  }

  .container-modalidade-selo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ant-rate {
    color: var(--color-primary);
  }

  .texto-modalidade {
    margin-bottom: 0;
  }

  .container-categoria,
  .container-avaliacao {
    display: flex;
    justify-content: start;
    min-height: 39px;
    max-width: ${props =>
      props.formatList ? 'calc(100% - 75px);' : 'calc(100% - 25px);'};
  }

  .container-avaliacao {
    justify-content: space-between;
  }

  .icon-selo {
    position: absolute;
    right: 20;
    width: 28;
    height: 28;
  }

  .container-descriptive {
    margin-top: 1rem;
  }

  .container-content:hover {
    .extra-info {
      background-color: var(--color-primary);
    }
  }
  .product-course__percent-discount {
    background: var(--color-success);
    color: var(--color-white);
    bottom: 26px;
    right: 0;
    width: 150px;
    border-top-left-radius: 16px;
    font-size: 0.875rem;
  }

  .product-course__percent {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 1.25rem;
  }

  ${({ isHome }) =>
    isHome &&
    css`
      .container-content {
        display: flex;
        justify-content: space-between;
        height: 100%;
        flex-direction: column;
      }

      .avatar-comp {
        bottom: -14px;
        width: 30px;
        height: 30px;
      }

      .container-description-and-price {
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
      }

      .container-descriptive {
        margin-top: 0;
      }
    `}

  @media(min-width: 768px) {
    .product-course__percent-discount {
      ${props =>
        props.formatList &&
        css`
          right: unset;
          left: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 16px;
          bottom: 16px;
        `};
    }
  }

  @media (min-width: 992px) {
    .valor-style {
      font-size: 1rem;
    }
  }

  @media (min-width: 1200px) {
    .valor-style {
      font-size: 1.25rem;
    }
  }
`;
