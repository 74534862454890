/*
 * Todas as URLs da aplicação estão neste ENUM
 * Ao criar nova url, adicionar no enum seguindo o padrão:
 * abc-def/abc/:abc = ABCDEF_ABC_ABC
 */

export const RoutesEnum = {
  HOME: '/',
  HOMOLOGADO: '/homologado',
  PESQUISASATISFACAO_EVENTOID: '/pesquisa-satisfacao/:eventoId',
  PESQUISASATISFACAO_EVENTOID_NEGOCIACAOID:
    '/pesquisa-satisfacao/:eventoId/:negociacaoId',
  CADASTRO: '/cadastro',
  CADASTRO_ID: '/cadastro/:id',
  CADASTRO_TIPO: '/cadastro/:tipo',
  CADASTROAFILIADO: '/cadastro-afiliado',
  CADASTROPERFIL: '/cadastro-perfil',
  CADASTROPERFIL_EMPRESA: '/cadastro-perfil/empresa',
  CADASTROPRODUTO: '/cadastro-produto',
  CADASTROPRODUTO_CAPACITACAO: '/cadastro-produto/capacitacao',
  CADASTROPRODUTO_CAPACITACAO_ID: '/cadastro-produto/capacitacao/:id',
  CADASTROPRODUTO_CONSULTORIA: '/cadastro-produto/consultoria',
  CADASTROPRODUTO_CONSULTORIA_ID: '/cadastro-produto/consultoria/:id',
  CADASTROSENHALICENCIADO: '/cadastro-senha-licenciado',
  CANAISDIVULGACAO: '/canais-divulgacao',
  CARTEIRA: '/carteira',
  CARTEIRAAFILIADO: '/carteira-afiliado',
  CADASTROCURSOS: '/cadastro-cursos',
  CADASTROCURSOS_ID: '/cadastro-cursos/:id',
  CADASTROCURSOS_ID_PASSO: '/cadastro-cursos/:id/:passo',
  CARRINHO: '/carrinho',
  CADASTROLICENCIADO: '/catalogo-licenciado',
  CATALOGO: '/catalogo',
  CATALOGO_CLIENTE: '/catalogo/cliente',
  CATALOGO_SEARCH: '/catalogo/:search',
  CHECKOUT: '/checkout',
  CHECKOUT_SEBRAEPAY: '/checkout-sebraepay/:id',
  CONSULTORIA_ID: '/consultoria/:id',
  CONSULTORIA_PROMOCAODESCONTO_ID: '/consultoria/promocao-desconto/:id',
  CURSOSONLINE_GERENCIARPOST_ID: '/cursos-online/gerenciar-post/:id',
  CURSOSONLINE_PRODUTO_ID: '/cursos-online/produto/:id',
  CURSOSONLINE_EDITAR_ID: '/cursos-online/editar/:id',
  CURSOSONLINE_PERGUNTASRESPOSTAS_ID: '/cursos-online/perguntas-respostas/:id',
  CURSOSONLINE_PROGRESSO_ID: '/cursos-online/progresso/:id',
  CURSOSONLINE_PRODUTO_ID_PROMOCAODESCONTO:
    '/cursos-online/produto/:id/promocao-desconto',
  CURSOSONLINE_PRODUTO_IDCURSO_AVALIACAODEPOIMENTOS:
    '/cursos-online/produto/:idCurso/avaliacao-depoimentos',
  CURSOSONLINE_ID: '/cursos-online/:id',
  CURSOSONLINE_HOMOLOGACAOAULAS_CURSOID:
    '/cursos-online/homologacao-aulas/:cursoId',
  CURSOSONLINE_HOMOLOGACAOAULAS_CURSOID_SECAOID:
    '/cursos-online/homologacao-aulas/:cursoId/:secaoId',
  CURSOSONLINE_HOMOLOGACAOAULAS_CURSOID_SECAOID_AULAID:
    '/cursos-online/homologacao-aulas/:cursoId/:secaoId/:aulaId',
  CURSOSONLINE_SALADEAULA_CURSOID: '/cursos-online/sala-de-aula/:cursoId',
  CURSOSONLINE_SALADEAULA_CURSOID_SECAOID:
    '/cursos-online/sala-de-aula/:cursoId/:secaoId',
  CURSOSONLINE_SALADEAULA_CURSOID_SECAOID_AULAID:
    '/cursos-online/sala-de-aula/:cursoId/:secaoId/:aulaId',
  CURSOSONLINE_PESQUISASATISFACAO_MATRICULAID:
    '/cursos-online/pesquisa-satisfacao/:matriculaId',
  DADOSCADASTRAIS: '/dados-cadastrais',
  DADOSCADASTRAIS_EMPRESA: '/dados-cadastrais/empresa',
  DADOSCADASTRAIS_CLIENTE: '/dados-cadastrais/cliente',
  DADOSCADASTRAIS_AFILIADO: '/dados-cadastrais/afiliado',
  DADOSCADASTRAIS_CLIENTE_ALTERARSENHA:
    '/dados-cadastrais/cliente/alterar-senha',
  EVENTO_ID: '/evento/:id',
  EXTRATOFINANCEIRO: '/extrato-financeiro',
  EVENTO_ID_DADOSINGRESSOS: '/evento/:id/dados-ingressos',
  EVENTO_ID_DADOSINGRESSOSLICENCIADO: '/evento/:id/dados-ingressos-licenciado',
  AFILIADO_EXTRATOFINANCEIRO: '/afiliado/extrato-financeiro',
  SOLUCAOINTELIGENTE: '/solucao-inteligente',
  FAQ: '/faq',
  FAVORITOS: '/favoritos',
  MEUSEVENTOS: '/meus-eventos',
  MEUSEVENTOS_CADASTRO: '/meus-eventos/cadastro',
  MEUSEVENTOS_CADASTRO_ID: '/meus-eventos/cadastro/:id',
  MEUSEVENTOS_CHECKIN_EVENTOID: '/meus-eventos/checkin/:eventoId',
  CHECKIN_EVENTOID_UUID: '/checkin/:eventoId/:uuid',
  CURSOSONLINE: '/cursos-online/',
  HOMEAFILIADO: '/home-afiliado/',
  SEBRAETEC: '/sebraetec/',
  SOLUCAO_SEBRAETEC_ID: '/sebraetec/:id',
  SEBRAETEC_UPLOAD_NF: '/sebraetec-upload-nf/:id',
  SOLUCOES_SEBRAETEC: '/solucoes-sebraetec',
  SOLUCOES_SEBRAETEC_PENDENTES: '/solucoes-sebraetec/pendentes',
  SOLUCOES_SEBRAETEC_VINCULADOS: '/solucoes-sebraetec/vinculados',
  SOLUCOES_SEBRAETEC_ATENDER_ID: '/solucoes-sebraetec/atender/:id',
  SEBRAETEC_PESQUISA_SATISFACAO: '/sebraetec/pesquisa-satisfacao/:id',
  ORCAMENTOS_SEBRAETEC: '/orcamentos-sebraetec',
  CADASTROSEBRAETEC: '/cadastro-sebraetec',
  CADASTROSEBRAETEC_ID: '/cadastro-sebraetec/:id',
  MEUSEVENTOS_CONFIGURACAO_ID: '/meus-eventos/configuracao/:id',
  MEUSEVENTOS_PARTICIPANTES_ID: '/meus-eventos/participantes/:id',
  MEUPLANO: '/meu-plano',
  MEUSLINKS: '/meus-links',
  MINHASCOMPRAS: '/minhas-compras',
  MINHASCOMPRAS_ID: '/minhas-compras/:id',
  NEGOCIACOES: '/negociacoes',
  NOTAS: '/notas',
  PAINEL_AFILIADO: '/painel/afiliado',
  PAINEL_CLIENTE: '/painel/cliente',
  PAINEL_LICENCIADO: '/painel/licenciado',
  PAINEL_EMPRESA: '/painel/empresa',
  LISTAR_NOTAS: '/listar-notas',
  MINHASVENDAS: '/minhas-vendas',
  PAINEL: '/painel',
  PERFIL: '/perfil',
  PERFIL_SLUG: '/perfil/:slug',
  PLANOS: '/planos',
  PRODUTOS: '/produtos',
  PRODUTO_ID: '/produto/:id',
  PROFISSIONAIS: '/profissionais',
  SOLUCAOINTELIGENTE_HISTORICOFATURAMENTO_ID:
    '/solucao-inteligente/historico-faturamento/:id',
  MINHASVENDAS_HISTORICOFATURAMENTO_ID:
    '/minhas-vendas/historico-faturamento/:id',
  MINHASCOMPRAS_HISTORICOFATURAMENTO_ID:
    '/minhas-compras/historico-faturamento/:id',
  SOLUCAOINTELIGENTE_PRODUTO_ID: '/solucao-inteligente/produto/:id',
  SOLUCAOINTELIGENTE_PRODUTO_IDPRODUTOSAAS_DADOSACESSO_ID:
    '/solucao-inteligente/produto/:idProdutoSaas/dados-acesso/:id',
  SOLUCAOINTELIGENTE_PERGUNTASRESPOSTAS_ID:
    '/solucao-inteligente/perguntas-respostas/:id',
  SOLUCAOINTELIGENTE_PRODUTO_PROMOCAODESCONTO_ID:
    '/solucao-inteligente/produto/promocao-desconto/:id',
  PERGUNTASERESPOSTAS: '/perguntas-e-respostas',
  SOLUCAOINTELIGENTE_CADASTROPRODUTO: '/solucao-inteligente/cadastro-produto',
  SOLUCAOINTELIGENTE_CADASTROPRODUTO_ID:
    '/solucao-inteligente/cadastro-produto/:id',
  SOLUCAOINTELIGENTE_CADASTROLANDING_ID:
    '/solucao-inteligente/cadastro-landing/:id',
  SOLUCAOINTELIGENTE_LANDING_ID: '/solucao-inteligente/landing/:id',
  SD_ID: '/sd/:id',
  SOLUCAOINTELIGENTE_CADASTROINTEGRACAO:
    '/solucao-inteligente/cadastro-integracao',
  SOLUCAOINTELIGENTE_CADASTROINTEGRACAO_ID:
    '/solucao-inteligente/cadastro-integracao/:id',
  NOTASFISCAISUPLOAD_ID: '/notas-fiscais-upload/:id'
};
