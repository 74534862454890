import styled from 'styled-components';

export const Container = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;

  .link-termos {
    text-decoration: underline;
    color: var(--color-primary);
  }
`;
