import React, { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import { Row, Col } from 'antd';
import { animateScroll as scroll } from 'react-scroll';
import { Container } from './styles';
import i18n from '../../shared/util/i18n';
import { Link } from 'react-router-dom';
import ModalTerms from '../../pages/Cadastro/components/ModalTerms/ModalTerms';
import ModalPrivacy from '../../pages/Cadastro/components/ModalPrivacy/ModalPrivacy';
import { ReactComponent as Facebook } from '../../images/svgs/facebook.svg';
import { ReactComponent as Twitter } from '../../images/svgs/twitter.svg';
import { ReactComponent as Youtube } from '../../images/svgs/youtube.svg';
import { ReactComponent as Instagram } from '../../images/svgs/instagram.svg';
import { ReactComponent as Linkedin } from '../../images/svgs/linkedin.svg';
import { ReactComponent as Sebrae } from '../../images/svgs/sebrae.svg';
// import { ReactComponent as Whatsapp } from '../../images/svgs/whatsapp.svg';
import { ReactComponent as Email } from '../../images/svgs/email.svg';
import RollUpBlue from '../../images/svgs/rollup-blue.svg';
import AmericanExpress from '../../images/svgs/american-express.svg';
import DinersWhite from '../../images/svgs/diners-white.svg';
import Elo from '../../images/svgs/elo.svg';
import Master from '../../images/svgs/master.svg';
import VisaWhite from '../../images/svgs/visa-white.svg';
import BarcodeWhite from '../../images/svgs/barcode-white.svg';
import Pix from '../../images/svgs/pix_footer.svg';
import { tipoTermoUsoEnum } from '../../shared/util/enums/tipoTermoUsoEnum';
import useQueryParams from '../../hooks/useQueryParams';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function Footer({ onlyFooterBottom, menuSideBar }) {
  let query = useQueryParams();
  const location = useLocation();
  const [modalTermsVisible, setModalTermsVisible] = useState(false);
  const [modalPrivacyVisible, setModalPrivacyVisible] = useState(false);
  const [tipoTermoUso, setTipoTermoUso] = useState(null);
  const [paddingFooter, setPaddingFooter] = useState(false);
  const { width } = useWindowDimensions();

  const openTermsOfUse = (e, tipoTermoUso) => {
    e.preventDefault();
    e.stopPropagation();
    setTipoTermoUso(tipoTermoUso);
    setModalTermsVisible(true);
  };

  const handleCloseModalTerms = () => {
    setModalTermsVisible(false);
  };

  const openPrivacy = e => {
    e.preventDefault();
    e.stopPropagation();
    setModalPrivacyVisible(true);
  };

  const handleCloseModalPrivacy = () => {
    setModalPrivacyVisible(false);
  };

  useEffect(() => {
    if (query && query.get('politicaPrivacidade')) {
      setModalPrivacyVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (menuSideBar && width <= 768) {
      setPaddingFooter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container id='footer' menuSideBar={paddingFooter}>
      {modalTermsVisible && (
        <ModalTerms
          tipo={tipoTermoUso}
          modalVisible={modalTermsVisible}
          handleOk={handleCloseModalTerms}
          handleCancel={handleCloseModalTerms}
        />
      )}
      {modalPrivacyVisible && (
        <ModalPrivacy
          modalVisible={modalPrivacyVisible}
          handleOk={handleCloseModalPrivacy}
          handleCancel={handleCloseModalPrivacy}
        />
      )}
      {!onlyFooterBottom && (
        <div className='footer-top'>
          <div className='container pt-5'>
            <Row className='ant-row-flex'>
              <Col md={24} lg={12} className='p-1'>
                <div className='title my-2'>{i18n.t('ABOUT')}</div>
                <p className='text-normal mb-3'>
                  {i18n.t('ABOUT_DESC_1')}
                  <span style={{ fontWeight: 'bold' }}>
                    {' ' + i18n.t('ABOUT_DESC_2')}
                  </span>
                  {i18n.t('ABOUT_DESC_3')}
                </p>
              </Col>
              <Col md={24} lg={6} className='p-1'>
                <div className='title my-2'>{i18n.t('FAST_ACCESS')}</div>
                <div>
                  <Link to='/' className='text-normal'>
                    {i18n.t('HOME')}
                  </Link>
                </div>
                <div>
                  <span
                    onClick={openPrivacy}
                    className='text-normal cursor-pointer'>
                    {i18n.t('PRIVACY_POLICY')}
                  </span>
                </div>
                <div>
                  <span
                    onClick={e => openTermsOfUse(e, tipoTermoUsoEnum.USUARIO)}
                    className='text-normal cursor-pointer'>
                    {i18n.t('CUSTOMER_TERMS_OF_USE')}
                  </span>
                </div>
                <div>
                  <span
                    onClick={e =>
                      openTermsOfUse(e, tipoTermoUsoEnum.FORNECEDOR)
                    }
                    className='text-normal cursor-pointer'>
                    {i18n.t('SUPPLIER_TERMS_OF_USE')}
                  </span>
                </div>
                <div>
                  <Link
                    to='/faq?categoria=CLIENTE'
                    className='text-normal py-2 pr-2'>
                    {i18n.t('FREQUENTLY_ASKED_QUESTIONS')}
                  </Link>
                </div>
                <div>
                  <Link to='/cadastro' className='text-normal py-2 pr-2'>
                    {i18n.t('I_WANT_TO_BE_A_SUPPLIER')}
                  </Link>
                </div>
                <div>
                  <a
                    href='https://www.sebraepr.com.br/artigos/ouvidoria'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-normal py-2 pr-2'>
                    {i18n.t('OMBUDSMAN')}
                  </a>
                </div>
              </Col>
              <Col md={24} lg={6} className='pt-1 pb-1 pl-1'>
                <div className='title mt-2'>{i18n.t('STILL_HAVE_DOUBTS')}</div>
                <div className='mb-2'>
                  <a
                    href={`mailto:${i18n.t(
                      'E_MAIL'
                    )}?cc=ablopes2@outlook.com;Gpsantos02@outlook.com`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='subtitle'>
                    {i18n.t('SEND_AN_EMAIL')}
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <Icon
                    component={Email}
                    style={{ color: 'var(--color-white-gray)', fontSize: 20 }}
                  />
                  <div className='text-normal'>
                    <a
                      href={`mailto:${i18n.t(
                        'E_MAIL'
                      )}?cc=ablopes2@outlook.com;Gpsantos02@outlook.com`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-normal ml-2'>
                      {i18n.t('E_MAIL')}
                    </a>
                  </div>
                </div>
                <div className='title my-2'>{i18n.t('OPENING_HOURS')}</div>
                <div className='d-flex align-items-center'>
                  <span className='text-normal'>
                    {i18n.t('OPENING_HOURS_CUSTOMER')}
                    <br />({i18n.t('MONDAY_TO_FRIDAY')})
                  </span>
                  <div></div>
                </div>
                <div className='d-flex align-items-center mt-2'>
                  <div className='text-normal'>
                    <span className='text-normal'>
                      {i18n.t('OPENING_HOURS_SUPPORT')}
                      <br />({i18n.t('MONDAY_TO_FRIDAY')})
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='ant-row-flex'>
              <Col md={24} lg={12} className='p-1'>
                <div className='title my-2'>{i18n.t('ACCOMPANY')}</div>
                <a
                  href='https://www.facebook.com/prsebrae'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='icon-facebook-style'>
                  <Icon
                    component={Facebook}
                    style={{ fontSize: 24 }}
                    className='mr-5 mb-3'
                  />
                </a>
                <a
                  href='https://x.com/SEBRAE_PR'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='icon-twitter-style'>
                  <Icon
                    component={Twitter}
                    style={{ fontSize: 24 }}
                    className='mr-5 mb-3'
                  />
                </a>
                <a
                  href='https://www.youtube.com/prsebrae'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='icon-youtube-style'>
                  <Icon
                    component={Youtube}
                    style={{ fontSize: 24 }}
                    className='mr-5 mb-3'
                  />
                </a>
                <a
                  href='https://www.instagram.com/sebraepr/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='icon-instagram-style'>
                  <Icon
                    component={Instagram}
                    style={{ fontSize: 24 }}
                    className='mr-5 mb-3'
                  />
                </a>
                <a
                  href='https://www.linkedin.com/company/sebrae-pr/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='icon-linkedin-style'>
                  <Icon
                    component={Linkedin}
                    style={{ fontSize: 24 }}
                    className='mr-5 mb-3'
                  />
                </a>
              </Col>
              <Col md={24} lg={12} className='p-1'>
                <div className='title my-2'>{i18n.t('PAYMENT_TYPES')}</div>
                <img
                  src={AmericanExpress}
                  alt={i18n.t('AMERICAN_EXPRESS')}
                  className='mr-4 mb-3 icon'
                />
                <img
                  src={DinersWhite}
                  alt={i18n.t('DINERS')}
                  className='mr-4 mb-3 icon'
                />
                <img
                  src={Elo}
                  alt={i18n.t('ELO')}
                  className='mr-4 mb-3  icon'
                />
                <img
                  src={Master}
                  alt={i18n.t('MASTERCARD')}
                  className='mr-4 mb-3  icon'
                />
                <img
                  src={VisaWhite}
                  alt={i18n.t('VISA')}
                  className='mr-4 mb-3  icon'
                />
                <img
                  src={BarcodeWhite}
                  alt={i18n.t('BOLETO_BANCARIO')}
                  className='mr-4 mb-3  icon'
                />
                <img src={Pix} alt={i18n.t('PIX')} className='mb-3  icon' />
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div className='footer-bottom'>
        <div className='container h-100'>
          <Row className='ant-row-flex h-100' justify='center' align='middle'>
            <Col
              xs={24}
              sm={4}
              lg={2}
              className='d-flex align-items-center justify-content-center'>
              <Icon
                component={Sebrae}
                style={{ fontSize: 65 }}
                className='mr-0 mr-sm-3'
              />
            </Col>
            <Col xs={24} sm={6} lg={8} className='text-center text-sm-left'>
              {i18n.t('FOOTER_DESC')}
            </Col>
            <Col xs={24} sm={4} className='text-center justify-content-center'>
              <img
                src={RollUpBlue}
                alt={i18n.t('ROLL_UP_ICON_ALT')}
                className='cursor-pointer'
                onClick={() => {
                  scroll.scrollToTop();
                }}
              />
            </Col>
            <Col xs={24} sm={10} className='text-center text-sm-right'></Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}
