import React, { useState } from 'react';
import { Divider, Row, Typography, Checkbox } from 'antd';
import { Container } from './styles';
import i18n from '../../../../../../shared/util/i18n';
import ModalTerms from '../../../../../../pages/Cadastro/components/ModalTerms/ModalTerms';
import { tipoTermoUsoEnum } from '../../../../../../shared/util/enums/tipoTermoUsoEnum';

const { Text, Title } = Typography;

export default function MensagemConvite({ pendingInvitation, onChange }) {
  const [modalTermsVisible, setModalTermsVisible] = useState(false);
  const [tipoTermoUso, setTipoTermoUso] = useState(null);

  const openTermsOfUse = (e, tipoTermoUso) => {
    e.preventDefault();
    e.stopPropagation();
    setTipoTermoUso(tipoTermoUso);
    setModalTermsVisible(true);
  };

  const handleCloseModalTerms = () => {
    setModalTermsVisible(false);
  };

  return (
    <Container>
      {modalTermsVisible && (
        <ModalTerms
          tipo={tipoTermoUso}
          modalVisible={modalTermsVisible}
          handleOk={handleCloseModalTerms}
          handleCancel={handleCloseModalTerms}
        />
      )}
      <div className='d-block w-100'>
        <Row className='mt-2 mb-2'>
          <Title level={4} strong>
            {i18n.t('INVITE')}
          </Title>
          <Text>{i18n.t('INVITATION_DESCRIPTION')}:</Text>
        </Row>

        <Divider />

        <Row>
          <Row className='mb-2'>
            <Text strong>{i18n.t('COMPANY_DATA')}</Text>
          </Row>
          {pendingInvitation &&
            pendingInvitation.empresa &&
            pendingInvitation.empresa.nomeFantasia && (
              <Row className='mb-2'>
                <Text>{i18n.t('COMPANY')}: </Text>
                <Text className='font-weight-bold'>
                  {pendingInvitation.empresa.nomeFantasia}
                </Text>
              </Row>
            )}
          {pendingInvitation &&
            pendingInvitation.pessoa &&
            pendingInvitation.pessoa.nome && (
              <Row>
                <Text>{i18n.t('ADMINISTRATOR')}: </Text>
                <Text className='font-weight-bold'>
                  {pendingInvitation.pessoa.nome}
                </Text>
              </Row>
            )}
        </Row>

        <Divider />

        <Row>
          <Row className='mb-2'>
            <Text strong>{i18n.t('INVITATION_DATA')}</Text>
          </Row>
          {pendingInvitation && pendingInvitation.papel && (
            <Row className='mb-2'>
              <Text>{i18n.t('INVITATION_TO_OFFICE')}: </Text>
              <Text className='font-weight-bold'>
                {pendingInvitation.papel}
              </Text>
            </Row>
          )}
          {pendingInvitation && pendingInvitation.mensagem && (
            <Row className='d-md-flex'>
              <div className='mr-2'>
                <Text>{i18n.t('MESSAGE')}:</Text>
              </div>
              <div>
                <Text className='font-weight-bold'>
                  {pendingInvitation.mensagem}
                </Text>
              </div>
            </Row>
          )}
        </Row>

        <Divider />

        <Checkbox onChange={onChange}>
          {i18n.t('ACCEPTING_THIS_INVITATION_MEMBER_COMPANY')}
          <Text
            className='link-termos'
            onClick={e => openTermsOfUse(e, tipoTermoUsoEnum.FORNECEDOR)}>
            {i18n.t('SUPPLIER_TERMS_OF_USE')}
          </Text>
        </Checkbox>
      </div>
    </Container>
  );
}
