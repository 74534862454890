import React from 'react';
import { Row, Typography, Popover, Rate } from 'antd';
import { Container } from './styles';
import { formatValor } from '../../shared/util/transfoms';
import AvatarPadrao from '../../images/svgs/avatar-padrao.svg';
import LicenciadoLogo from '../../images/svgs/licenciado-sebrae-logo.svg';
import i18n from '../../shared/util/i18n';
import { isInt } from '../../shared/util/validators';
import { Link } from 'react-router-dom';
import { getSmallestImage } from '../../shared/util/imageUtils';
const { Text, Paragraph } = Typography;

export default function ProdutoComponent(props) {
  return (
    <Container isHome={props.isHome} className='position-relative'>
      <Link to={props.data.link} className='container-content'>
        <div>
          <img
            alt={i18n.t('PRODUCT_IMAGE_ALT') + (props.data && props.data.nome)}
            className='img-background'
            src={getSmallestImage(
              props.data.fotoUrlResponsive,
              props.data.fotoUrl
            )}></img>
          <Popover
            content={
              props && props.data && props.data.pessoa && props.data.pessoa.nome
            }
            trigger='hover'>
            <img
              alt={
                i18n.t('AVATAR_ALT') +
                (props.data && props.data.pessoa && props.data.pessoa.nome)
              }
              className='avatar avatar-position'
              src={
                props.data && props.data.pessoa && props.data.pessoa.fotoUrl
                  ? getSmallestImage(
                      props.data.pessoa.fotoUrlResponsive,
                      props.data.pessoa.fotoUrl
                    )
                  : AvatarPadrao
              }
            />
          </Popover>

          {props &&
            props.data &&
            props.data.classificacao &&
            props.data.classificacao.nome && (
              <div className='container-modalidade'>
                <Popover content={props.data.classificacao.nome}>
                  <div
                    className='modalidade'
                    style={{ background: props.data.classificacao.cor }}>
                    {props.data.classificacao.nome}
                  </div>
                </Popover>
              </div>
            )}
          <div className='info-box'>
            <Row className='product-title'>
              <Paragraph
                style={{ fontWeight: 'bold' }}
                ellipsis={{ rows: 2, expandable: false }}>
                {props.data.nome}
              </Paragraph>
            </Row>
            <Row
              className='align-items-center container-category'
              style={{ whiteSpace: 'nowrap' }}>
              <Text style={{ color: 'var(--color-gray)' }}>
                {i18n.t('CATEGORY') + ': '}
              </Text>
              <Text style={{ fontWeight: 'bold' }}>{props.data.categoria}</Text>
            </Row>
            {props && props.data && props.data.mediaAvaliacao ? (
              <Row className='ant-row-flex align-items-center'>
                <Text style={{ color: 'var(--color-gray)' }} className='mr-2'>
                  {i18n.t('AVALIATIONS')}:
                </Text>
                <Rate
                  allowHalf
                  disabled={true}
                  count={1}
                  defaultValue={isInt(props.data.mediaAvaliacao) ? 1 : 0.5}
                />
                <Text
                  className='text-assessment'
                  style={{
                    fontSize: 20
                  }}>
                  {props.data.mediaAvaliacao}
                  {isInt(props.data.mediaAvaliacao) ? '.0' : ''}
                </Text>
                <img
                  alt={i18n.t('LICENSED')}
                  src={LicenciadoLogo}
                  style={{
                    position: 'absolute',
                    right: 20,
                    width: 28,
                    height: 28
                  }}
                />
              </Row>
            ) : (
              <Row className='proposta-valor'>
                {props.data.propostaValor && (
                  <Paragraph
                    ellipsis={{ rows: 1, expandable: false }}
                    className='proposta-valor-text'>
                    {props.data.propostaValor}
                  </Paragraph>
                )}
                <img
                  alt={i18n.t('LICENSED')}
                  src={LicenciadoLogo}
                  style={{
                    position: 'absolute',
                    right: -30,
                    width: 28,
                    height: 28,
                    top: 10
                  }}
                />
              </Row>
            )}
          </div>
        </div>

        <div className='extra-info'>
          <div className='extra-info-position'>
            <div className='valor-position'>
              <Text type='white' className='valor-style'>
                {props.data.preco
                  ? formatValor(props.data.preco, true)
                  : 'Grátis'}
              </Text>
              <Text type='gray'>
                {`Carga Horária: ${props.data.cargaHoraria}`}
              </Text>
            </div>
          </div>
        </div>
      </Link>
    </Container>
  );
}
