import React, { useState, useEffect } from 'react';
import { ReactComponent as StarHalf } from '../../images/svgs/star-half.svg';
import { ReactComponent as StarOutline } from '../../images/svgs/star-outline.svg';
import { ReactComponent as Star } from '../../images/svgs/star.svg';
import Icon from '@ant-design/icons';
import { Row } from 'antd';

export default function Rating({ rating }) {
  const [ratingValue, setRatingValue] = useState(0);

  useEffect(() => {
    setRatingValue(rating);
  }, [rating]);

  function renderRating() {
    let ret = [];
    let aux = ratingValue;

    for (let i = 0; i < 5; i++) {
      if (aux >= 1) {
        ret.push(
          <Icon
            key={i}
            component={Star}
            style={{ fontSize: 18, color: 'var(--color-primary)' }}
          />
        );
      }
      if (aux <= 0) {
        ret.push(
          <Icon
            key={i}
            component={StarOutline}
            style={{ fontSize: 18, color: 'var(--color-primary)' }}
          />
        );
      }
      if (aux > 0 && aux < 1) {
        ret.push(
          <Icon
            key={i}
            component={StarHalf}
            style={{ fontSize: 18, color: 'var(--color-primary)' }}
          />
        );
      }
      aux--;
    }
    return ret;
  }

  return (
    <Row className='ant-row-flex' justify='start'>
      {renderRating()}
    </Row>
  );
}
