import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ fontSize }) =>
    fontSize &&
    css`
      .custom-rate {
        font-size: ${fontSize}px;
      }
    `}

  .ant-rate {
    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}
  }

  .ant-rate-star-second {
    ${({ secondaryColor }) =>
      secondaryColor &&
      css`
        svg {
          color: ${secondaryColor};
        }
      `}
  }

  .ant-rate-star-first {
    ${({ percent }) =>
      percent &&
      css`
        width: ${percent}%;
      `}
  }
`;
