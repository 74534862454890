import gql from 'graphql-tag';

export const CHECK_PF = gql`
  {
    fornecedorPessoaFisica {
      id
    }
  }
`;

export const TERMO_TIPO = gql`
  query($tipo: TipoTermoUsoEnum!) {
    obterTermoVigentePorTipo(tipo: $tipo) {
      id
      texto
      vigente
    }
  }
`;
