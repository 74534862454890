import styled, { css } from 'styled-components';

const stylesMenuVariations = {
  menuClosed: css`
    position: fixed;
    width: 100px;
    padding-top: 50px;
    height: calc(100% - 62px);
    left: 0;
    bottom: 0;
    background: ${props =>
      props.cliente ? 'var(--color-white)' : 'var(--color-gray-light)'};
    ${({ zIndex }) => (zIndex ? `z-index: ${zIndex}` : 'z-index: 999')};
    ${props =>
      props.cliente
        ? 'box-shadow: 0px 0px 12px var(--color-card-shadow);'
        : ''};
  `,
  menuOpen: css`
    position: fixed;
    width: 255px;
    height: calc(100% - 62px);
    left: 0;
    bottom: 0;
    top: unset;
    background: ${props =>
      props.cliente ? 'var(--color-white)' : 'var(--color-gray-light)'};
    ${({ zIndex }) => (zIndex ? `z-index: ${zIndex}` : 'z-index: 999')}
    transition: width 0.3s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 4px;
      height: 100vh;
    }

    ::-webkit-scrollbar-track {
      box-shadow: transparent;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--color-scrollbar);
    }
  `,
  linkOpen: css`
    .ant-typography {
      display: inline-block;
      position: relative;
      bottom: 3px;
    }

    .badge-link {
      right: 22px;
    }
  `,
  linkHover: css`
    padding: 7px 27px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .content-link {
      padding-right: 13px;
      border-radius: 32px;
      background: #9a9a9a21; /* stylelint-disable-line color-no-hex */
    }
    .ant-typography {
      display: unset;
      color: var(--text-color);
    }
    .anticon {
      padding: 13px !important;
      padding-right: 13px !important;
      color: var(--text-color) !important;
    }
  `,
  activeLink: css`
    padding: 7px 27px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .content-link {
      padding: 9px 14px 9px 12px;
      border-radius: 32px;
      background: var(--text-color);
      @-moz-document url-prefix() {
        padding-bottom: 9px;
      }
    }
    .ant-typography {
      display: unset;
      color: var(--color-white);
    }
    .anticon {
      padding: 0px !important;
      padding-right: 13px !important;
      font-size: 20px !important;
      color: unset !important;
      color: var(--color-white) !important;
      margin-top: 3px !important;
    }
  `,
  bgOpen: css`
    position: fixed;
    width: 100%;
    height: calc(100% - 62px);
    left: 0px;
    bottom: 0;
    /* stylelint-disable-next-line function-blacklist */
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(3px);
    ${({ zIndexHover }) =>
      zIndexHover ? `z-index: ${zIndexHover}` : 'z-index: 999'}
  `
};

export const Container = styled.div`
  .bg-sidebar {
    width: 0;
  }

  a:hover {
    .ant-badge-count {
      background: var(--color-primary);
    }
  }

  .badge-link {
    position: absolute;
    top: 3px;
    right: 22px;

    .ant-badge-count {
      padding: 0;
      background: var(--color-primary);
      color: var(--color-white);
    }
  }

  .link {
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 27px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .ant-typography {
      display: none;
    }
    .anticon {
      padding: 13px !important;
      font-size: 20px !important;
      color: ${props =>
        props.cliente ? 'var(--text-color)' : 'var(--color-gray) !important;'};
    }
  }

  .active-link div .anticon {
    border-radius: 50%;
    background: var(--text-color) !important;
    color: var(--color-white) !important;
    padding: 12px !important;
  }

  @media only screen and (max-width: 992px) and (min-width: 820px),
    only screen and (max-width: 1200px) and (min-width: 1060px),
    only screen and (min-width: 1240px) {
    .menu-nav {
      ${stylesMenuVariations['menuClosed']}
    }
    .menu-nav:hover {
      ${stylesMenuVariations['menuOpen']}
      .link {
        ${stylesMenuVariations['linkOpen']}
      }
      .active-link {
        ${stylesMenuVariations['activeLink']}
      }
    }
    .menu-nav:hover ~ .bg-sidebar {
      ${stylesMenuVariations['bgOpen']}
    }
    .content-link {
      width: 100%;
      white-space: nowrap;
    }
    .link:hover {
      ${stylesMenuVariations['linkHover']}
    }
  }

  @media only screen and (max-width: 820px),
    only screen and (max-width: 1060px) and (min-width: 992px),
    only screen and (max-width: 1240px) and (min-width: 1200px) {
    .menu-nav {
      ${stylesMenuVariations['menuClosed']}
      ${stylesMenuVariations['menuOpen']}
    }
    .content-link {
      width: 100%;
    }
    .bg-sidebar {
      ${stylesMenuVariations['bgOpen']}
    }
    .active-link {
      ${stylesMenuVariations['activeLink']}
    }
    .link {
      ${stylesMenuVariations['linkOpen']}
    }
  }

  /* Media query  para container  que suporte 100px width de menu*/
  @media only screen and (max-width: 920px) and (min-width: 820px),
    only screen and (max-width: 1160px) and (min-width: 1060px),
    only screen and (max-width: 1340px) and (min-width: 1240px) {
    .menu-nav {
      width: 50px;
      .link {
        padding: 5px 2px;
        width: 100%;
      }
    }

    .badge-link {
      position: absolute;
      top: 1px;
      right: 3px;
    }

    .active-link {
      padding: 5px 2px;
    }

    .menu-nav:hover {
      .active-link {
        padding: 7px 27px;
      }

      .link {
        padding: 7px 27px;
      }
    }
  }
`;
