import styled from 'styled-components';

export const Container = styled.div`
  .item-carousel__btn-know-more {
    min-width: 210px;
  }

  .title-be-supplier {
    letter-spacing: 1.2px;
  }

  .description-be-supplier {
    letter-spacing: 0.7px;
    line-height: 26px;
    font-size: 14px;
    font-weight: 100;
  }
`;
