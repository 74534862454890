import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  box-shadow: 0px 0px 8px var(--color-card-shadow);
  border-radius: 3px;

  .cover-container {
    ${({ formatList }) =>
      formatList
        ? css`
            min-height: 279px;
            height: 100%;
          `
        : css`
            height: 114px;
          `}
  }

  .cover {
    object-fit: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: ${props => (props.formatList ? '3px' : '0px')};
    border-top-right-radius: ${props => (props.formatList ? '0px' : '3px')};
    width: 100%;
    ${({ formatList }) =>
      formatList
        ? css`
            min-height: 279px;
            height: 100%;
          `
        : css`
            height: 114px;
          `}
  }

  .row-values {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

    .ant-typography {
      color: var(--color-white);
    }
  }

  .extra-info {
    background-color: var(--text-color);
    color: var(--color-white-gray);
    border-bottom-left-radius: ${props => (props.formatList ? '0px' : '3px')};
    border-bottom-right-radius: 3px;
    height: 104px;
  }

  .produto-title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .tema-title {
    text-align: left;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 0.7px;
    color: var(--color- secondary);
  }

  .valor-style {
    font-size: 20px;
    line-height: 1.875rem;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif !important;
  }

  .valor-anterior-style {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.7px;
    font-family: 'Open Sans', sans-serif !important;
    color: var(--color-white-gray);
  }

  .line-price {
    border-bottom: 0px solid var(--color-gray);
    margin: 6px 0;
  }

  .icon-popover {
    width: 16px;
    height: 16px;
    fill: var(--color-primary);
    margin: auto 0 auto -4px;
  }

  .text-sebraeplace-price {
    font-size: 13px;
    letter-spacing: 0.7px;
    margin-bottom: 3px;
  }

  .col-price-padding {
    padding: 10px 13px;
  }

  .container-modalidade {
    width: calc(100% - 2rem);
    position: ${props => (props.formatList ? 'relative' : 'absolute')};
    top: ${props => (props.formatList ? 'unset' : '-12px')};
    display: flex;
    justify-content: ${props => (props.formatList ? 'left' : 'center')};
    margin-bottom: 0.5rem;
  }

  .modalidade {
    background: var(--color-sebraetec);
    text-align: center;
    border-radius: 3px;
    color: var(--color-white);
    padding: 2px 5px;
  }

  .icon-heart {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 0px 3px 0px 0px;
    text-align: end;
    height: 88px;
    width: 88px;
    /* stylelint-disable-next-line */
    background: transparent linear-gradient(223deg, #2c2c2cd0 0%, #2c2c2c00 43%)
      0% 0% no-repeat padding-box;
  }

  .texto-modalidade {
    margin-bottom: 0;
  }

  .container-tema {
    display: flex;
    justify-content: start;
    min-height: 39px;
    max-width: ${props =>
      props.formatList ? 'calc(100% - 75px);' : 'calc(100% - 25px);'};
  }

  .container-subsidio {
    height: 39px;
  }

  .container-descriptive {
    margin-top: 1rem;
  }

  .container-content:hover {
    .extra-info {
      background-color: var(--color-primary);
    }

    .icon-popover {
      fill: var(--color-primary) !important;
      border: 1px solid var(--color-white);
      border-radius: 50%;
    }
  }

  ${({ isHome }) =>
    isHome &&
    css`
      .container-content {
        display: flex;
        justify-content: space-between;
        height: 100%;
        flex-direction: column;
      }

      .container-description-and-price {
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
      }

      .container-descriptive {
        margin-top: 0;
      }
    `}

  @media (min-width: 576px) {
    .col-price-padding {
      padding: 10px;
    }

    .valor-style {
      font-size: 1rem;
      line-height: 1.875rem;
    }
  }

  @media (min-width: 1200px) {
    .valor-style {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
`;
