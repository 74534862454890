import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { Container } from './styles';
import i18n from '../../shared/util/i18n';
import ModalResponderConvite from './components/ModalResponderConvite/ModalResponderConvite';

const { Text, Paragraph } = Typography;

export default function HeaderProfessionalInvitation({
  pendingInvitation,
  setPendingInvitation
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container className='w-100'>
      {showModal && (
        <ModalResponderConvite
          showModal={showModal}
          setShowModal={setShowModal}
          pendingInvitation={pendingInvitation}
          setPendingInvitation={setPendingInvitation}
        />
      )}

      <div className='card-alert'>
        <div className='container convite-display'>
          <div className='convite-msg-card'>
            <Paragraph type='white' className='convite-msg'>
              <Text type='white' className='font-highlight'>
                {i18n.t('INVITE').toUpperCase()}
                {'. '}
              </Text>
              {i18n.t('RECEIVED_INVITATION_COMPANY')}{' '}
              {pendingInvitation &&
                pendingInvitation.empresa &&
                pendingInvitation.empresa.nomeFantasia}
            </Paragraph>
          </div>

          <div className='d-flex justify-content-end convite-botao-card'>
            <Button
              onClick={() => setShowModal(true)}
              className='convite-botao'
              type='white'
              shape='round'
              ghost>
              {i18n.t('REPLY_INVITATION')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
