export const defaultTheme = {
  token: {
    fontFamily: `'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    colorPrimary: '#009AF6',
    colorLink: '#009AF6',
    colorSuccess: '#2ACC29',
    colorWarning: '#FDB145',
    colorError: '#FC393E',
    fontSize: 13,
    lineHeight: 1.5,
    colorText: '#2C2C2C',
    colorTextHeading: '#2C2C2C',
    colorTextSecondary: 'rgba(0, 0, 0, .45)',
    colorTextDisabled: 'rgba(0, 0, 0, .25)',
    borderRadius: 4,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    fontWeightStrong: 300,
    fontSizeHeading1: '2.5rem',
    fontSizeHeading2: '2rem',
    fontSizeHeading3: '1.75rem',
    fontSizeHeading4: '1.5rem',
    fontSizeHeading5: '1.25rem'
  },
  components: {
    Tooltip: {
      colorBgSpotlight: 'rgba(0, 154, 246, 0.75)'
    },
    Pagination: {
      itemActiveBg: '#009AF6'
    }
  }
};
