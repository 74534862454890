import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import { Badge, Typography } from 'antd';
import { Container } from './styles';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ContextPerson } from '../../store/contexts/contextPerson';
const { Text } = Typography;

export default function SideBar({
  zIndex,
  zIndexHover,
  isCliente,
  links,
  handleMenu
}) {
  const { userType, numberOfSebraetecRequests } = useContext(ContextPerson);

  return (
    <Container zIndex={zIndex} zIndexHover={zIndexHover} cliente={!isCliente}>
      <nav className='menu-nav'>
        <ul>
          {links.map((link, index) =>
            !link.specificUserTypes ||
            (link.specificUserTypes.length > 0 &&
              link.specificUserTypes.includes(userType)) ? (
              <li key={index}>
                <NavLink
                  onClick={handleMenu}
                  to={link.path}
                  className='link'
                  activeClassName='active-link'
                  data-cy={`btn-sidebar-${link.name.toLowerCase()}`}>
                  {link.hasOrcamentosBadge && numberOfSebraetecRequests > 0 && (
                    <Badge
                      count={numberOfSebraetecRequests}
                      className='badge-link'
                    />
                  )}
                  <div className='content-link'>
                    <Icon component={link.icon} />
                    <Text>{link.name}</Text>
                  </div>
                </NavLink>
              </li>
            ) : null
          )}
        </ul>
      </nav>
      <div className='bg-sidebar' id='bg-sidebar' onClick={handleMenu}></div>
    </Container>
  );
}

SideBar.propTypes = {
  //Define os items do menu
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  // define o z-index quando o menu está fechado
  zIndex: PropTypes.number,
  // define o z-index quando o menu está fechado
  zIndexHover: PropTypes.number,
  // Define se o menu ficara ativo ou não
  handleMenu: PropTypes.func
};

SideBar.defaultProps = {
  zIndex: 999,
  zIndexHover: 997
};
