import { Modal } from 'antd';
import styled from 'styled-components';

export const Container = styled(Modal)``;

export const ContainerContent = styled.div`
  .ant-carousel .slick-slide {
    overflow: hidden;
  }

  .sombra {
    box-shadow: 0px 0px 12px var(--color-card-shadow);
    border-radius: 3px;
  }

  .slick-dots.slick-dots-bottom li {
    background: var(--color-primary);
    opacity: 0.5;
    button {
      background: var(--color-primary);
      width: 30px;
      height: 4px;
    }
  }

  .slick-dots.slick-dots-bottom li.slick-active {
    opacity: 1;
    button {
      background: var(--color-primary);
      width: 40px;
    }
  }

  .subtitle-tutorial {
    font-size: 1rem;
  }

  .affiliate-image-1 {
    max-width: 480px;
    width: 100%;
    height: 216px;
    object-fit: contain;
  }

  .affiliate-image-2 {
    max-width: 156px;
    width: 100%;
    object-fit: contain;
  }

  .affiliate-image-3 {
    max-width: 480px;
    width: 100%;
    height: 178px;
    object-fit: contain;
  }

  .affiliate-image-4 {
    max-width: 480px;
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  .affiliate-image-5 {
    max-width: 480px;
    width: 100%;
    height: 156px;
    object-fit: contain;
  }
`;
