/* eslint-disable no-restricted-syntax */
import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import RemoveIndexFromRoute from './removeIndexFromRoute';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { userTypes } from '../../shared/util/enums/userTypesEnum';

export const ProtectedCatalogoRoute = ({ children, ...rest }) => {
  const { pessoa, habilitarCursosPresenciais, usersAllowed } = useContext(
    ContextPerson
  );
  const history = useHistory();

  const verifyRouteRole = () => {
    if (usersAllowed(userTypes.LICENCIADO) && !habilitarCursosPresenciais) {
      history.push('/painel/licenciado');
      return <></>;
    } else if (usersAllowed(userTypes.FORNECEDOR, userTypes.CLIENTE)) {
      history.push('/catalogo');
      return <></>;
    } else {
      return (
        <React.Fragment>
          <RemoveIndexFromRoute />
          {children}
        </React.Fragment>
      );
    }
  };

  return (
    <>
      {pessoa && pessoa.id && history && (
        <Route
          {...rest}
          render={() => {
            return verifyRouteRole();
          }}
        />
      )}
    </>
  );
};
