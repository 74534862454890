import React from 'react';
import { Container } from './styles';
import Icon from '@ant-design/icons';
import { ReactComponent as TelescopeBlue } from '../../../images/svgs/telescope-blue.svg';
import { ReactComponent as Shadow } from '../../../images/svgs/shadow.svg';
import { ReactComponent as Stars1 } from '../../../images/svgs/stars-1.svg';
import { ReactComponent as Stars2 } from '../../../images/svgs/stars-2.svg';

export default function ErrorBoundary() {
  return (
    <Container className='container'>
      <div className='container-mosaic d-flex justify-content-center align-items-center'>
        <div className='content-mosaic'>
          <div className='error-template'>
            <div className='mosaic'>
              <div className='mosaic__item__sombra'>
                <Icon className='sombra' component={Shadow} />
              </div>
              <div className='mosaic__item__luneta'>
                <Icon className='luneta' component={TelescopeBlue} />
              </div>
              <div className='mosaic__item__grupo1'>
                <Icon className='grupoEstrelas1' component={Stars1} />
              </div>
              <div className='mosaic__item__grupo2'>
                <Icon className='grupoEstrelas2' component={Stars2} />
              </div>
              <div className='mosaic__item__text'>
                <p className='texto'>Erro interno no servidor</p>
              </div>
              <div className='mosaic__item__4__first'>
                <p className='four-first'>5</p>
              </div>
              <div className='mosaic__item__0'>
                <p className='zero'>0</p>
              </div>
              <div className='mosaic__item__4__second'>
                <p className='four-second'>0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
