import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--color-white);
`;

export const ContainerSideBar = styled.div`
  display: ${props => (props.sideBar ? 'unset' : 'none')};

  @media only screen and (max-width: 820px),
    only screen and (max-width: 1060px) and (min-width: 992px),
    only screen and (max-width: 1240px) and (min-width: 1200px) {
    display: ${props => (props.showMenu ? 'unset' : 'none')};
  }
`;
