import React, { useContext } from 'react';
import { Row, Col, Typography } from 'antd';
import i18n from '../../../../shared/util/i18n';
import { Button } from '../../../../components/LinkComponent/LinkComponent';
import { Container } from './styles';
import MenComputer from '../../../../images/webps/section-afiliados.webp';
import { ContextPerson } from '../../../../store/contexts/contextPerson';
const { Paragraph } = Typography;

export default function SecaoSejaUmAfiliado() {
  const { pessoa } = useContext(ContextPerson);

  return (
    <Container>
      <div className='container mb-4'>
        <Row justify='center' className='ant-row-flex know-how-it-works'>
          <Col span={24} md={12} className='p-4 card-4-style'>
            <Row justify='start' className='ant-row-flex mb-4'>
              <h3 className='ant-typography information-title mb-0'>
                {i18n.t('SECTION_AFILIADO_TITLE')}
              </h3>
            </Row>
            <Row className='ant-row-flex' justify='center'>
              <Paragraph className='text-left information-description'>
                {i18n.t('SECTION_AFILIADO_DESC')}
              </Paragraph>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <img
              src={MenComputer}
              alt={i18n.t('SECTION_AFILIADO_ALT')}
              className='card-4-image-style'
            />
          </Col>
        </Row>
        <Row
          justify='space-around'
          className='ant-row-flex register-now-container'>
          <Row className='ant-row-flex w-100 padding-button' justify='start'>
            <Col md={12} sm={24} xs={24}>
              {pessoa && pessoa.afiliacao && !pessoa.vinculo ? (
                <h5 className='ant-typography register-now-text mb-0'>
                  {i18n.t('YOU_ARE_AFFILIATE')}{' '}
                  <span className='sebrae-style'>{i18n.t('SEBRAEPLACE')}</span>
                </h5>
              ) : (
                <h5 className='ant-typography register-now-text mb-0'>
                  {i18n.t('HOME_SECTION_2_CARD_5_TITLE')}
                  <span className='sebrae-style'>{i18n.t('SEBRAEPLACE')}</span>
                </h5>
              )}
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Row className='w-100'>
                <Col span={12} className='pr-1 pl-1'>
                  <Button
                    href='/home-afiliado'
                    ghost
                    type='primary'
                    shape='round'
                    className='px-2 px-md-4 create-account-button w-100'>
                    {i18n.t('KNOW_MORES')}
                  </Button>
                </Col>
                <Col span={12} className='pr-1 pl-1'>
                  <Button
                    href={
                      pessoa && pessoa.afiliacao && !pessoa.vinculo
                        ? '/painel/afiliado'
                        : '/cadastro-afiliado'
                    }
                    type='primary'
                    shape='round'
                    className='px-2 px-md-4 create-account-button w-100'>
                    {pessoa && pessoa.afiliacao && !pessoa.vinculo
                      ? i18n.t('GO_TO_ACCOUNT')
                      : i18n.t('WANTING_BEING_AFFILIATED')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>
    </Container>
  );
}
