import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const RemoveIndexFromRoute = () => {
  useEffect(() => {
    document.querySelectorAll(`meta[name="robots"]`).forEach(meta => {
      if (!meta.hasAttribute('data-react-helmet')) {
        meta.remove();
      }
    });
  }, []);

  return (
    <Helmet>
      <meta name='robots' content='noindex, nofollow' />
    </Helmet>
  );
};

export default RemoveIndexFromRoute;
