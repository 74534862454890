import React from 'react';
import Icon from '@ant-design/icons';
import { Row, Typography } from 'antd';
import { Container } from './styles';
import { ReactComponent as TimesCircle } from '../../../../../../images/svgs/times-circle.svg';
import { ReactComponent as CheckCircle } from '../../../../../../images/svgs/check-circle.svg';
import i18n from '../../../../../../shared/util/i18n';
const { Text, Title } = Typography;

export default function RespostaConvite({ situacao }) {
  return (
    <Container>
      <div className='d-block w-100'>
        <Row className='d-flex justify-content-center'>
          <Title
            level={4}
            strong
            style={{
              color: situacao ? 'var(--color-success)' : 'var(--color-danger)'
            }}>
            {situacao
              ? i18n.t('ACCEPTED_INVITATION')
              : i18n.t('DECLINED_INVITATION')}
          </Title>
        </Row>
        <Row className='d-flex justify-content-center m-2'>
          <Icon
            component={situacao ? CheckCircle : TimesCircle}
            style={{
              fontSize: 150,
              color: situacao ? 'var(--color-success)' : 'var(--color-danger)'
            }}
          />
        </Row>
        <Row>
          <Text>{i18n.t('ADMINISTRATOR_NOTIFIED_RESPONSE')}</Text>
        </Row>
        {situacao && (
          <Row>
            <Text>{i18n.t('LINKED_YOUR_PROFILE')}</Text>
          </Row>
        )}
      </div>
    </Container>
  );
}
