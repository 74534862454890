import React, { useEffect, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import { Carousel, Typography } from 'antd';
import { useLazyQuery } from 'react-apollo';
import { LISTAR_FORNECEDORES_RECENTES } from './queries';
import { ReactComponent as CaretLeftCircle } from '../../images/svgs/caret-left-circle.svg';
import { ReactComponent as CaretRightCircle } from '../../images/svgs/caret-right-circle.svg';
import CardFornecedor from '../../components/CardFornecedor/CardFornecedor';
import i18n from '../../shared/util/i18n';

const { Title } = Typography;

export default function FornecedoresDestaque({
  pageSize,
  limit = 10,
  apenasProdutos = false
}) {
  const [fornecedores, setFornecedores] = useState();
  const carouselProfissionaisRef = useRef(null);
  const [
    carouselProfissionaisPosition,
    setCarouselProfissionaisPosition
  ] = useState();

  const [listarFornecedoresRecentes] = useLazyQuery(
    LISTAR_FORNECEDORES_RECENTES,
    {
      onCompleted(data) {
        if (data && data.listarFornecedoresRecentes)
          setFornecedores(
            data.listarFornecedoresRecentes.map(item => ({
              ...item,
              profileLink: '/perfil/' + item.slug
            }))
          );
      }
    }
  );

  const beforeChangeProfissionais = (_prev, next) =>
    setCarouselProfissionaisPosition(next);

  const carouselProfissionaisSettings = {
    dots: false,
    infinite: false,
    draggable: true,
    speed: 500,
    slidesToShow: pageSize,
    slidesToScroll: 1,
    beforeChange: beforeChangeProfissionais
  };

  useEffect(() => {
    listarFornecedoresRecentes({ variables: { limit, apenasProdutos } });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {fornecedores && fornecedores.length > 0 && (
        <div className='container mt-5  pt-5'>
          <div className='d-flex justify-content-between mb-4 align-items-center'>
            <div className='d-flex flex-column flex-sm-row align-items-end'>
              <Title level={2} className='mb-0 title-home'>
                {i18n.t('RELEVANT_PROFESSIONALS')}
              </Title>
            </div>
            {fornecedores.length > pageSize && (
              <div>
                <Icon
                  component={CaretLeftCircle}
                  onClick={() => {
                    if (
                      carouselProfissionaisPosition > 0 &&
                      carouselProfissionaisRef.current
                    )
                      carouselProfissionaisRef.current.prev();
                  }}
                  style={{
                    fontSize: 32,
                    color:
                      carouselProfissionaisPosition > 0
                        ? 'var(--color-primary)'
                        : 'var(--color-gray-light)'
                  }}
                />
                <Icon
                  component={CaretRightCircle}
                  onClick={() => {
                    if (carouselProfissionaisRef.current)
                      carouselProfissionaisRef.current.next();
                  }}
                  style={{
                    marginLeft: '15px',
                    color:
                      carouselProfissionaisPosition + pageSize >=
                      fornecedores.length
                        ? 'var(--color-gray-light)'
                        : 'var(--color-primary)',
                    fontSize: 32
                  }}
                />
              </div>
            )}
          </div>
          <Carousel
            {...carouselProfissionaisSettings}
            ref={carouselProfissionaisRef}>
            {fornecedores.map(fornecedor => (
              <CardFornecedor
                key={fornecedor.id}
                item={fornecedor}
                showAvaliacoes={false}
                showAvaliacaoLicenciado={false}
                showAvaliacaoFornecedores={true}
              />
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
}
