import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Row, Typography, Col, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { TOGGLE_FAVORITE } from './queries';
import { ReactComponent as HeartSolid } from '../../images/svgs/heart-solid.svg';
import { formatValor } from '../../shared/util/transfoms';
import i18n from '../../shared/util/i18n';
import { Container } from './styles';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getSmallestImage } from '../../shared/util/imageUtils';
import { isRefreshTokenUndefinedOrExpired } from '../../shared/util/utilToken';
import Config from '../../shared/Config';

import { ReactComponent as InfoRound } from '../../images/svgs/info-round.svg';

const { Text, Paragraph } = Typography;

export default function CardProductSebraetec({
  id,
  slug,
  nome,
  tema,
  subtema,
  imgUrl,
  preco,
  formatList,
  isFavorito,
  isFull,
  isHome,
  callBackIsFavorite,
  sm,
  lg,
  xl
}) {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [isFavoriteState, setIsFavoriteState] = useState(isFavorito);

  const [toggleFavorite] = useMutation(TOGGLE_FAVORITE, {
    onCompleted(data) {
      setIsFavoriteState(data.alternarFichaTecnicaSebraetecFavorita);
      if (callBackIsFavorite) {
        callBackIsFavorite();
      }
    }
  });

  const handleAlternarFavorito = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isRefreshTokenUndefinedOrExpired()) {
      const href = window.location.href;
      window.location.href = `${Config.loginUiUrl}/?requestedURL=${href}`;
    } else {
      toggleFavorite({
        variables: {
          fichaTecnicaSebraetecId: id
        }
      });
    }
  };

  const getGridMd = () => {
    if (isFull) {
      return 24;
    }
    return formatList && !isMobile() ? 24 : 12;
  };

  const getGridLg = () => {
    if (isFull) {
      return 24;
    }
    return formatList && !isMobile() ? 24 : 8;
  };

  const isMobile = () => {
    return width <= 768;
  };

  const checkFormatList = () => {
    return formatList && !isMobile();
  };

  return (
    <Col
      xs={24}
      sm={sm || 24}
      md={sm || getGridMd()}
      lg={lg || getGridLg()}
      xl={xl || getGridLg()}
      className='d-flex flex-column'>
      <Link to={`/sebraetec/${slug ? slug : id}`}>
        <Container
          formatList={checkFormatList()}
          className='cursor-pointer h-100'
          isHome={isHome}>
          <Row className='ant-row-flex container-content'>
            <Col span={checkFormatList() ? 12 : 24}>
              <div className='cover-container position-relative'>
                <img
                  className='cover'
                  src={getSmallestImage(imgUrl, imgUrl.url)}
                  alt={i18n.t('SEBRAETEC') + nome}
                />
              </div>

              <div className='icon-heart'>
                <Icon
                  component={HeartSolid}
                  style={{
                    fontSize: 32,
                    color: isFavoriteState
                      ? 'var(--color-danger)'
                      : 'var(--color-white)',
                    transition: 'color 1s'
                  }}
                  onClick={e => handleAlternarFavorito(e, id)}
                />
              </div>
            </Col>

            <Col
              className='container-description-and-price'
              span={checkFormatList() ? 12 : 24}>
              <div className='container-descriptive d-flex flex-column justify-content-around p-3'>
                <div className='container-modalidade'>
                  <Popover content={i18n.t('SEBRAETEC')}>
                    <div className='modalidade'>{i18n.t('SEBRAETEC')}</div>
                  </Popover>
                </div>

                <Row style={{ minHeight: 56 }}>
                  {nome && (
                    <Paragraph
                      className='produto-title'
                      ellipsis={{ rows: 2, expandable: false }}>
                      {nome}
                    </Paragraph>
                  )}
                </Row>

                <div className='container-tema'>
                  <Paragraph
                    className='tema-title mb-0'
                    ellipsis={{ rows: 2, expandable: false }}>
                    {tema && tema.nome} - {subtema && subtema.nome}
                  </Paragraph>
                </div>

                <div className='container-subsidio' />
              </div>

              <div className='extra-info d-flex align-items-center justify-content-between col-price-padding'>
                <Col className='w-100'>
                  <Row>
                    <Col>
                      <Text className='valor-anterior-style' delete>
                        {`${i18n.t('DE')} ${formatValor(preco, true)}`}
                      </Text>
                      <hr className='line-price' />
                    </Col>
                  </Row>
                  <Row className='ant-row-flex row-values'>
                    <Text>
                      <Trans
                        i18nKey='SEBRAE_WILL_COVER_UP_TO_70'
                        components={[
                          <Text strong key={'trans-sebrae-cover-' + id} />
                        ]}
                      />
                    </Text>
                    <Popover
                      placement='bottom'
                      overlayStyle={{
                        maxWidth: 265
                      }}
                      title={
                        <Trans
                          i18nKey='SEBRAETEC_DISCOUNT_ONLY_FOR_MEI'
                          components={[
                            <Text
                              key={'trans-sebrae-discount-' + id}
                              role='button'
                              style={{
                                color: 'var(--color-primary)',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                history.push('/faq'); // eslint-disable-line
                              }}
                            />
                          ]}
                        />
                      }>
                      <InfoRound className='icon-popover' />
                    </Popover>
                  </Row>
                  <Row className='ant-row-flex row-values'>
                    <Text type='white' className='text-sebraeplace-price'>
                      {i18n.t('PAY_UP_TO')}
                    </Text>
                    <Text type='white' className='valor-style'>
                      {formatValor(preco * 0.3, true)}
                    </Text>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </Link>
    </Col>
  );
}
