import gql from 'graphql-tag';

export const OBTER_CONFIGURACAO_POR_NOME = gql`
  query ObterConfiguracaoPorNome($configuracaoEnum: ConfiguracaoEnum!) {
    obterConfiguracaoPorNome(configuracaoNome: $configuracaoEnum) {
      nome
      tipo
      descricao
      valor {
        valorBoolean
      }
    }
  }
`;
