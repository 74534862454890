import React, { useContext, useEffect, useState, Suspense } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';

import { Container, ContainerSideBar } from './styles.js';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SideBar from '../SideBar/SideBar';
import Config from '../../shared/Config';
import links from '../../shared/util/configSideBar';
import linksAfiliado from '../../shared/util/configSideBarAfiliado';
import { ContextPerson } from '../../store/contexts/contextPerson';

export default function MainContainer({ component: Component, ...props }) {
  const { pessoa } = useContext(ContextPerson);
  const location = useLocation();
  const [pathname, setPathname] = useState(Config.baseUrl);
  const [showMenu, setShowMenu] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const handleMenu = () => setShowMenu(!showMenu);

  useEffect(() => {
    setPathname(`${Config.baseUrl}${location.pathname}`);
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(true);
    }, 700);
    return () => setShowSkeleton(false);
  }, [location.pathname]); //eslint-disable-line

  return (
    <React.Fragment>
      <Helmet>
        <link rel='canonical' href={pathname} />
      </Helmet>
      {props.header && (
        <Header
          whiteYellowLogo={props.whiteYellowLogo}
          menuList={props.menuList}
          exitButton={props.exitButton}
          showMenu={showMenu}
          handleMenu={handleMenu}
          withoutBlur={props.withoutBlur}
        />
      )}
      {pessoa && (
        <ContainerSideBar
          sideBar={props.sidebar || props.sidebarAfiliado}
          showMenu={!props.menuList && !props.exitButton && showMenu}>
          <SideBar
            links={
              props.sidebarAfiliado ? linksAfiliado(pessoa) : links(pessoa)
            }
            zIndex={97}
            zIndexHover={96}
            isCliente={pessoa.vinculo}
            handleMenu={handleMenu}
          />
        </ContainerSideBar>
      )}
      <Container>
        {props.lazy ? (
          <Suspense
            fallback={
              <div className='container' style={{ minHeight: '1140px' }}>
                {showSkeleton && (
                  <>
                    <div style={{ height: '100px' }}></div>
                    <Skeleton title active />
                    <Skeleton paragraph rows={4} active />
                    <div style={{ height: '50px' }}></div>
                    <Skeleton title />
                    <Skeleton paragraph rows={4} active />
                    <div style={{ height: '50px' }}></div>
                    <Skeleton title />
                    <Skeleton paragraph rows={4} active />
                    <div style={{ height: '50px' }}></div>
                  </>
                )}
              </div>
            }>
            <Component
              {...props}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />
          </Suspense>
        ) : (
          <Component {...props} showMenu={showMenu} setShowMenu={setShowMenu} />
        )}

        {props.footer && (
          <Footer
            onlyFooterBottom={props.onlyFooterBottom}
            menuSideBar={props.menuSideBar}
          />
        )}
      </Container>
    </React.Fragment>
  );
}
