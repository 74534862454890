import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import Config from './shared/Config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
if (path) {
  if (path.startsWith('/plataforma')) {
    history.replace(path);
  } else {
    history.replace('/plataforma' + path);
  }
}

if (!Config.baseUrl.includes('localhost:3000')) {
  Sentry.init({
    dsn: Config.sentryDsn,
    integrations: [
      new Integrations.BrowserTracing(),
      new RewriteFramesIntegration({
        // Retira o contexto da URL para o correto funcionamento do SourceMap
        iteratee: frame => {
          if (frame.filename) {
            frame.filename = frame.filename.replace('/plataforma/', '/');
          }
          return frame;
        }
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: Config.environment
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
