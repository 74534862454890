const Config = {
  environment: window.env.ENVIRONMENT,
  baseUrl: window.env.BASE_URL,
  apiBaseUrl: window.env.API_BASE_URL,
  saasApiDocsUrl: window.env.SAAS_API_DOCS_URL,
  apiUrl: window.env.HOMOLOGADO_API_URL,
  apiWss: window.env.HOMOLOGADO_WSS_URL,
  saasApiUrl: window.env.SAAS_API_URL,
  ssoApiUrl: window.env.SSO_API_URL,
  loginUiUrl: window.env.LOGIN_UI_URL,
  viaCEP: window.env.VIA_CEP,
  browser: window.env.BROWSER,
  applicationUrl: window.env.APPLICATION_URL,
  googleMapsApiKey: window.env.GOOGLE_MAPS_API_KEY,
  sentryDsn: window.env.SENTRY_DSN,
  chunkSize: window.env.CHUNK_SIZE,
  chunkConcurrency: window.env.CHUNK_CONCURRENCY,
  oneTrustId: window.env.REACT_APP_ONE_TRUST_ID
};

export default Config;
