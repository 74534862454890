import { themesEnum } from '../../shared/util/enums/themesEnum';

export default {
  title: themesEnum.LIGHT,

  colors: {
    background: 'var(--color-white-gray)',
    header: 'rgba(0, 115, 189, 0.75)',
    text: 'var(--text-color)',
    secondary: 'var(--color-secondary)',
    textEspecial: 'var(--text-color)',
    textConteudoCurso: 'var(--text-color)',
    skeleton1: '#ebe9e9',
    skeleton2: '#d9d6d6',
    scrollbar: 'var(--color-scrollbar)',
    sideBar: '#F9FAFC',
    sideBarListSelected: 'var(--color-white)',
    searchBtn: 'var(--color-white)',
    textSearchBtn: 'var(--color-primary)',
    buttonHover: '#1ba6fa',
    textButtonHover: 'var(--text-color)',
    menuButton: 'var(--color-gray-light)',
    sideBarListTag: 'var(--color-gray-light)',
    blurModal: '#ffffff8c',
    selected: 'var(--color-primary)',
    quadro: 'var(--color-white)',
    sombraFullscreen: 'rgba(252, 252, 252, 1)',
    sombraFullScreenZero: 'rgba(252, 252, 252, 0)',
    sombraQuadro: 'rgba(255, 255, 255, 1)',
    sombraQuadroZero: 'rgba(255, 255, 255, 0)',
    progressBar: '#F0F0F060',
    uncheckedClass: '#dbdbdb80',
    uncheckedClassSideBar: '#b0b0b010',
    dividerEspecial: '#88888820',
    dividerTab: '#88888810',
    divider: '#2c2c2c20',
    dividerConteudoCurso: '#f0f0f0',
    tabDivider: 'var(--color-secondary)',
    tabSelected: 'var(--text-color)',
    switch: 'var(--color-secondary)',
    avatarBorder: 'var(--color-primary)',
    textDisabled: 'rgb(58 58 58 / 25%)'
  }
};
