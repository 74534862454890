import { useState, useEffect, useCallback } from 'react';

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(hasWindow => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height
    };
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(hasWindow)
  );
  function handleResize() {
    setWindowDimensions(getWindowDimensions(hasWindow));
  }
  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow, getWindowDimensions]);

  return windowDimensions;
}
