/* eslint-disable no-restricted-syntax */
import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import RemoveIndexFromRoute from './removeIndexFromRoute';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { userTypes } from './enums/userTypesEnum';

export const ProtectedCadastroPerfilPjRoute = ({ children, ...rest }) => {
  const { isPersonLoaded, usersAllowed } = useContext(ContextPerson);
  const history = useHistory();

  const verifyTypeToAccessRoute = () => {
    if (usersAllowed(userTypes.LICENCIADO, userTypes.FORNECEDOR)) {
      return (
        <React.Fragment>
          <RemoveIndexFromRoute />
          {children}
        </React.Fragment>
      );
    }
    history.push('/cadastro-perfil');
    return <></>;
  };

  return (
    <>
      {isPersonLoaded && history && (
        <Route
          {...rest}
          render={() => {
            return verifyTypeToAccessRoute();
          }}
        />
      )}
    </>
  );
};
