import React, { useContext, useEffect, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Carousel, Typography } from 'antd';
import i18n from '../../shared/util/i18n';
import { ReactComponent as TimesRound } from '../../images/svgs/times-round.svg';
import AffiliateImage1 from '../../images/webps/affiliate-tutorial-step-1.webp';
import AffiliateImage2 from '../../images/webps/affiliate-tutorial-step-2.webp';
import AffiliateImage3 from '../../images/webps/affiliate-tutorial-step-3.webp';
import AffiliateImage4 from '../../images/webps/affiliate-tutorial-step-4.webp';
import AffiliateImage5 from '../../images/webps/affiliate-tutorial-step-5.webp';
import { CHANGE_SHOW_AFFILIATE_TUTORIAL } from './queries';
import { Container, ContainerContent } from './styles';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { useMutation } from 'react-apollo';
import useInternalAlert from '../../hooks/useInternalAlert';
import { getGraphQLErrors } from '../../shared/util/util-error';

const { Text, Title } = Typography;

function AffiliationTutorial({ visible, setVisible }) {
  const { pessoa, setMustUpdatePerson } = useContext(ContextPerson);
  const {
    Component: AlertComponent,
    setAlert,
    clearAlert
  } = useInternalAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const carouselRef = useRef(null);

  const [handleFinishedTutorial] = useMutation(CHANGE_SHOW_AFFILIATE_TUTORIAL, {
    onCompleted() {
      setMustUpdatePerson(true);
    },
    onError(e) {
      setAlert({
        description: getGraphQLErrors(e),
        type: 'error',
        closable: true
      });
    }
  });

  useEffect(() => {
    if (pessoa && pessoa.afiliacao && pessoa.afiliacao.mostrarTutorial) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [pessoa]);

  const onChange = stepValue => {
    setStep(stepValue);
  };

  const submitFinishedTutorial = () => {
    clearAlert();
    handleFinishedTutorial({
      variables: {
        mostrar: false
      }
    });
    if (visible) setVisible(false);
  };

  const next = () => {
    if (step >= 4) {
      submitFinishedTutorial();
      return;
    }
    if (carouselRef.current) carouselRef.current.next();
  };

  const previous = () => {
    if (carouselRef.current) carouselRef.current.prev();
  };

  const handleCloseModal = () => {
    submitFinishedTutorial();
  };

  if (!isOpen && !visible) {
    return <></>;
  }

  return (
    <Container
      title=''
      closeIcon={<Icon component={TimesRound} />}
      open={isOpen || visible}
      onCancel={() => handleCloseModal()}
      width='646px'
      footer={[
        <>
          {step > 0 && (
            <Button
              onClick={() => previous()}
              key='previous_step'
              type='primary'
              shape='round'
              className='wide w-100 w-md-auto'
              ghost>
              {`${i18n.t('PREVIOUS_STEP')}`}
            </Button>
          )}
        </>,
        <Button
          onClick={() => next()}
          key='change_plan'
          type='primary'
          shape='round'
          className='wide w-100 w-md-auto mt-2 mt-md-0 ml-0 ml-md-3'>
          {step >= 4 ? `${i18n.t('LET_IS_START')}!` : i18n.t('NEXT_STEP')}
        </Button>
      ]}>
      <ContainerContent>
        {AlertComponent && <AlertComponent />}
        <Title
          level={4}
          type='primary'
          className='text-center d-block mb-0 mt-3'>
          {i18n.t('SEBRAEPLACE_AFFILIATE_TUTORIAL')}
        </Title>
        <Carousel afterChange={onChange} ref={carouselRef}>
          <div clssName='d-flex flex-column'>
            <Text className='text-center mb-4 d-block subtitle-tutorial'>
              {i18n.t('SEE_THE_STEP_BY_STEP')}!
            </Text>
            <Title level={3} strong className='mb-2'>
              {`1. ${i18n.t('SEARCH_TUTORIAL')}`}
            </Title>
            <Text type='secondary'>
              {i18n.t(
                'SEARCH_FOR_A_PRODUCT_CATEGORY_OR_TYPE_THE_NAME_OF_THE_SOLUTION_YOU_WANT_TO_SEARCH_FOR'
              )}
              :
            </Text>
            <div className='d-flex justify-content-center mt-4 mb-5'>
              <img
                className='affiliate-image-1 rounded'
                src={AffiliateImage1}
                alt={i18n.t('SEARCH_TUTORIAL')}
              />
            </div>
          </div>
          <div clssName='d-flex flex-column'>
            <Text className='text-center mb-4 d-block subtitle-tutorial'>
              {i18n.t('SEE_THE_STEP_BY_STEP')}!
            </Text>
            <Title level={3} strong className='mb-2'>
              {`2. ${i18n.t('CHOICE_TUTORIAL')}`}
            </Title>
            <Text type='secondary'>
              {i18n.t(
                'CHOOSE_THE_SOLUTION_THAT_BEST_SUITS_THE_PROFILE_OF_THE_ENTERPRISE_OR_SMALL_BUSINESS_TO_WHOM_IT_WILL_OFFER'
              )}
            </Text>
            <div className='d-flex justify-content-center mt-4 mb-5'>
              <img
                className='affiliate-image-2 shadow rounded sombra'
                src={AffiliateImage2}
                alt={i18n.t('CHOICE_TUTORIAL')}
              />
            </div>
          </div>
          <div clssName='d-flex flex-column'>
            <Text className='text-center mb-4 d-block subtitle-tutorial'>
              {i18n.t('THIS_IS_IMPORTANT')}!
            </Text>
            <Title level={3} strong className='mb-2'>
              {`3. ${i18n.t('GENERATE_A_LINK')}`}
            </Title>
            <Text type='secondary'>
              {i18n.t(
                'DO_NOT_FORGET_TO_GENERATE_A_LINK_VIA_THE_NOTIFICATION_BAR_INFO'
              )}
            </Text>
            <div className='d-flex justify-content-center mt-4 mb-5'>
              <img
                className='affiliate-image-3 rounded'
                src={AffiliateImage3}
                alt={i18n.t('GENERATE_A_LINK')}
              />
            </div>
          </div>
          <div clssName='d-flex flex-column'>
            <Text className='text-center mb-4 d-block subtitle-tutorial'>
              {i18n.t('SEE_THE_STEP_BY_STEP')}!
            </Text>
            <Title level={3} strong className='mb-2'>
              {`4. ${i18n.t('DISCLOSE')}`}
            </Title>
            <Text type='secondary'>
              {i18n.t(
                'ADVERTISE_ON_YOUR_SOCIAL_NETWORKS_THE_MORE_YOU_ADVERTISE_THE_MORE_CHANCE'
              )}
            </Text>
            <div className='d-flex justify-content-center mt-4 mb-5'>
              <img
                className='affiliate-image-4 rounded'
                src={AffiliateImage4}
                alt={i18n.t('DISCLOSE')}
              />
            </div>
          </div>
          <div clssName='d-flex flex-column'>
            <Text className='text-center mb-4 d-block subtitle-tutorial'>
              {i18n.t('SEE_THE_STEP_BY_STEP')}!
            </Text>
            <Title level={3} strong className='mb-2'>
              {`5. ${i18n.t('RECEIVE_SALES_COMMISSION')}`}
            </Title>
            <Text type='secondary'>
              {i18n.t(
                'FOLLOW_ALL_THE_MOVEMENT_OF_THE_COMMISSIONS_GENERATED_ON_YOUR_PANEL_IN_THE_STATEMENT_TAB'
              )}
            </Text>
            <div className='d-flex justify-content-center mt-4 mb-5'>
              <img
                className='affiliate-image-5 rounded'
                src={AffiliateImage5}
                alt={i18n.t('RECEIVE_SALES_COMMISSION')}
              />
            </div>
          </div>
        </Carousel>
      </ContainerContent>
    </Container>
  );
}

export default AffiliationTutorial;
