export const enderecoEnum = {
  ENDERECO_STRING: 'ENDERECO_STRING',
  LOGRADOURO: 'LOGRADOURO',
  NUMERO: 'NUMERO',
  COMPLEMENTO: 'COMPLEMENTO',
  BAIRRO: 'BAIRRO',
  CIDADE: 'CIDADE',
  ESTADO: 'ESTADO',
  CEP: 'CEP'
};
