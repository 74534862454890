import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Row, Typography, Col, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { TOGGLE_FAVORITE } from './queries';
import AvatarPadrao from '../../images/svgs/avatar-padrao.svg';
import StarRating from '../StarRating/StarRating';
import { ReactComponent as HeartSolid } from '../../images/svgs/heart-solid.svg';
import { ReactComponent as PlayVideo } from '../../images/svgs/play-video.svg';
import { ReactComponent as ConexaoCertificada } from '../../images/svgs/conexao-certificada-sebrae-logo.svg';
import { ReactComponent as ConexaoCertificadaTexto } from '../../images/svgs/conexao-certificada-sebrae-logo-texto.svg';
import { formatValor } from '../../shared/util/transfoms';
import i18n from '../../shared/util/i18n';
import { Container } from './styles';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getSmallestImage } from '../../shared/util/imageUtils';
import { isTokenUndefinedOrExpired } from '../../shared/util/utilToken';
import Config from '../../shared/Config';

const { Text, Paragraph } = Typography;

export default function CardProductCourse({
  id,
  slug,
  nome,
  categoria,
  cover,
  imagemResponsive,
  fotoUrl,
  fotoUrlResponsive,
  mediaAvaliacao,
  valor,
  pessoa,
  valorFinal,
  valorMercado,
  duracao,
  idPerfil,
  formatList,
  isFavorito,
  isFull,
  isHome,
  isGratuito,
  percentualDesconto,
  callBackIsFavorite
}) {
  const { width } = useWindowDimensions();
  const [isFavoriteState, setIsFavoriteState] = useState(isFavorito);

  useEffect(() => {
    setIsFavoriteState(isFavorito);
    // eslint-disable-next-line
  }, [isFavorito]);

  const [toggleFavorite] = useMutation(TOGGLE_FAVORITE, {
    onCompleted(data) {
      setIsFavoriteState(data.alternarCursoFavorito);
      if (callBackIsFavorite) {
        callBackIsFavorite();
      }
    }
  });

  const redirectToPerfil = () =>
    idPerfil ? `/perfil/${idPerfil}` : `/cursos-online/${slug ? slug : id}`;

  const handleAlternarFavorito = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (isTokenUndefinedOrExpired()) {
      const href = window.location.href;
      window.location.href = `${Config.loginUiUrl}/?requestedURL=${href}`;
    } else {
      toggleFavorite({
        variables: {
          cursoId: id
        }
      });
    }
  };

  const isMobile = () => {
    return width <= 768;
  };

  const getPercentRating = () => {
    if (mediaAvaliacao) {
      return (mediaAvaliacao * 100) / 5;
    }
    return 0;
  };

  const formatRating = () => {
    if (mediaAvaliacao) {
      return Number(mediaAvaliacao).toFixed(1);
    }
    return '0.0';
  };

  const getGridMd = () => {
    if (isFull) {
      return 24;
    }
    return formatList && !isMobile() ? 24 : 12;
  };

  const getGridLg = () => {
    if (isFull) {
      return 24;
    }
    return formatList && !isMobile() ? 24 : 8;
  };

  const checkFormatList = () => {
    return formatList && !isMobile();
  };

  return (
    <Col
      xs={24}
      md={getGridMd()}
      lg={getGridLg()}
      className='d-flex flex-column'>
      <Link to={`/cursos-online/${slug ? slug : id}`}>
        <Container
          formatList={checkFormatList()}
          className='cursor-pointer h-100'
          isHome={isHome}>
          <Row className='ant-row-flex container-content'>
            <Col span={checkFormatList() ? 12 : 24}>
              <div className='cover-container position-relative'>
                {percentualDesconto && (
                  <div className='product-course__percent-discount px-2 py-1 position-absolute'>
                    <span className='product-course__percent'>
                      {percentualDesconto}%
                    </span>
                    {` ${i18n.t('OF_DISCOUNT')}`}
                  </div>
                )}
                <img
                  className='cover'
                  src={getSmallestImage(imagemResponsive, cover)}
                  alt={i18n.t('COURSE_CARD_IMAGE_ALT') + nome}
                />
              </div>
              <div className='d-flex align-items-center justify-content-center icon-play'>
                <Icon
                  component={PlayVideo}
                  style={{
                    fontSize: 20,
                    color: 'var(--color-white)'
                  }}
                />
              </div>

              <div className='icon-heart'>
                <Icon
                  component={HeartSolid}
                  style={{
                    fontSize: 32,
                    color: isFavoriteState
                      ? 'var(--color-danger)'
                      : 'var(--color-white)',
                    transition: 'color 1s'
                  }}
                  onClick={e => handleAlternarFavorito(e, id)}
                />
              </div>

              <Link to={redirectToPerfil()}>
                <Popover content={pessoa && pessoa.nome} trigger='hover'>
                  <img
                    alt={i18n.t('AVATAR_ALT') + (pessoa && pessoa.nome)}
                    className='avatar avatar-comp'
                    src={
                      fotoUrl
                        ? getSmallestImage(fotoUrlResponsive, fotoUrl)
                        : AvatarPadrao
                    }
                  />
                </Popover>
              </Link>
            </Col>
            <Col
              className='container-description-and-price'
              span={checkFormatList() ? 12 : 24}>
              <div className='container-descriptive d-flex flex-column justify-content-around p-3'>
                <Row style={{ minHeight: 56 }}>
                  {nome && (
                    <Paragraph
                      className='produto-title'
                      ellipsis={{ rows: 2, expandable: false }}>
                      {nome}
                    </Paragraph>
                  )}
                </Row>
                <div className='container-modalidade'>
                  <Popover content={i18n.t('COURSE_ONLINE')}>
                    <div className='modalidade'>{i18n.t('COURSE_ONLINE')}</div>
                  </Popover>
                </div>
                {categoria && (
                  <div className='container-categoria'>
                    <Text type='secondary' className='categoria-title' ellipsis>
                      {categoria}
                    </Text>
                  </div>
                )}
                <div className='container-avaliacao'>
                  {mediaAvaliacao && (
                    <Row className='ant-row-flex' align='middle'>
                      <Paragraph
                        type='secondary'
                        className='texto-modalidade mr-2'>
                        {i18n.t('AVALIATIONS')}:
                      </Paragraph>
                      <div className='d-flex'>
                        <StarRating percent={getPercentRating()} />
                        <Text style={{ marginLeft: 2, fontSize: 20 }}>
                          {formatRating()}
                        </Text>
                      </div>
                    </Row>
                  )}
                  <Row className='ant-row-flex' align='middle'>
                    <Popover
                      content={i18n.t('SEBRAE_CERTIFICATE')}
                      trigger='hover'>
                      <Icon
                        component={
                          checkFormatList()
                            ? ConexaoCertificadaTexto
                            : ConexaoCertificada
                        }
                        style={{ fontSize: checkFormatList() ? 80 : 25 }}
                        className='icon-selo'
                      />
                    </Popover>
                  </Row>
                </div>
              </div>
              <div className='extra-info d-flex align-items-center justify-content-between col-price-padding'>
                <Col className='w-100 mt-3'>
                  <Row>
                    <Col>
                      {valorMercado && (
                        <>
                          <Text className='text-de'>{`${i18n.t('DE')} `}</Text>
                          <Text className='valor-anterior-style' delete>
                            {formatValor(valorMercado, true)}
                          </Text>
                          <hr className='line-price' />
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {valor && valorMercado && (
                      <Text type='white' className='text-sebraeplace-price'>
                        {i18n.t('IN_SEBRAEPLACE')}&nbsp;
                        {valorFinal && valor > 0 && (
                          <Text className='valor-anterior-style' delete>
                            {formatValor(valor, true)}
                          </Text>
                        )}
                      </Text>
                    )}
                  </Row>
                  <Row className='d-flex align-items-end row-values'>
                    <Col className='d-flex flex-column mb-1' span={14}>
                      {(valorFinal > 0 || valor > 0) && (
                        <>
                          {!valorMercado && (
                            <span>{i18n.t('IN_SEBRAEPLACE')}&nbsp;</span>
                          )}
                          <Text type='white' className='valor-style'>
                            {valorFinal
                              ? formatValor(valorFinal, true)
                              : formatValor(valor, true)}
                          </Text>
                        </>
                      )}

                      {isGratuito && (
                        <Text type='white' className='valor-style'>
                          {i18n.t('GRATUITOUS')}
                        </Text>
                      )}
                    </Col>
                    <Col
                      className='d-flex align-items-end text-right col-duracao'
                      span={10}>
                      {duracao && (
                        <div className='mb-2'>
                          <Text className='duracao'>{`${duracao} ${i18n.t(
                            'IN_TOTAL'
                          )}`}</Text>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </Link>
    </Col>
  );
}
