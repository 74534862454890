import gql from 'graphql-tag';

export const OBTER_TERMOS_E_POLITICA = gql`
  query {
    termos: obterTermoVigentePorTipo(tipo: USUARIO) {
      id
      texto
      vigente
    }
  }
`;

export const ACEITAR_TERMOS = gql`
  mutation aceitarTermoUsoUsuario {
    aceitarTermoUsoUsuario {
      id
      termoUsoAceito {
        id
      }
    }
  }
`;
