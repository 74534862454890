import styled from 'styled-components';

export const Container = styled.div`
  .be-a-supplier__conteiner-description {
    /* stylelint-disable-next-line color-no-hex */
    background: linear-gradient(to right, #0069a7, #009af6);
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .be-a-supplier__description {
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 0.7px;
    line-height: 26px;
  }

  .be-a-supplier__container-corousel {
    padding: 2.5rem 0;
  }

  .home-section-5-subtitle {
    font-weight: lighter;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: var(--text-color);
    line-height: 2em;
  }

  .ant-carousel .slick-slide {
    overflow: hidden;
  }

  .ant-carousel .slick-dots-bottom {
    display: block;
    text-align: center;
    bottom: 0;
  }

  .slick-dots.slick-dots-bottom li {
    background: var(--color-primary);
    opacity: 0.5;
    width: auto;
    button {
      background: var(--color-primary);
      width: 30px;
      height: 4px;
    }
  }

  .slick-dots.slick-dots-bottom li.slick-active {
    opacity: 1;
    button {
      background: var(--color-primary);
      width: 40px;
    }
  }

  .be-a-supplier__title {
    letter-spacing: 1.6px;
  }

  .ant-carousel .slick-slider .slick-list {
    padding-bottom: 25px;
  }

  @media (min-width: 768px) {
    .be-a-supplier__conteiner-description {
      padding-top: 240px;
      padding-bottom: 206px;
    }

    .be-a-supplier__title {
      max-width: 250px;
    }

    .be-a-supplier__description {
      max-width: 350px;
    }

    .ant-carousel .slick-dots {
      bottom: -20px;
    }

    .ant-carousel .slick-slider .slick-list {
      padding-bottom: 0;
    }
    .ant-carousel .slick-initialized {
      padding-bottom: 45px;
    }
  }

  @media (min-width: 1200px) {
    .ant-carousel .slick-dots {
      bottom: 12px;
    }
  }

  @media (min-width: 1200px) {
    .be-a-supplier__conteiner-description {
      padding-top: 190px;
      padding-bottom: 156px;
    }

    .ant-carousel .slick-dots {
      bottom: 12px;
    }
  }
`;
