import React from 'react';
import { Typography } from 'antd';
import { Container } from './styles';

const { Title, Paragraph } = Typography;

function ItemCarouselBeASupplier({
  title,
  description,
  children,
  isCapitalize
}) {
  return (
    <Container clssName='d-flex flex-column'>
      <Title
        className={`${isCapitalize &&
          'text-capitalize'} title-be-supplier mb-2`}
        level={4}>
        {title}
      </Title>
      <Paragraph className='d-block mb-3 mb-lg-5 mt-4 description-be-supplier'>
        {description}
      </Paragraph>
      {children}
    </Container>
  );
}

export default ItemCarouselBeASupplier;
