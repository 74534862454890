import React, { lazy, useReducer, useState, useMemo, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider, Layout } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import locale from 'antd/locale/pt_BR';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { reducerAlert, initialStateAlert } from './store/reducers/reducerAlert';
import ApolloClientProvider from './graphql/ApolloClientProvider';
import MainContainer from './components/MainContainer/MainContainer';
import { AlertGlobal } from './components/AlertGlobal/AlertGlobal';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AffiliateComponent from './components/AffiliateComponent/AffiliateComponent';
import InitInfoUser from './components/InitInfoUser/InitInfoUser';
import PersonComponent from './components/PersonComponent/PersonComponent';
import DocumentHead from './components/DocumentHead/DocumentHead';
import { RoutesEnum } from './Routes';
import Home from './pages/Home/Home';
import ErrorBoundary from './pages/Erro/ErrorBoundary/ErrorBoundary';
import { ContextAlert } from './store/contexts/contextAlert';
import { ContextAffiliate } from './store/contexts/contextAffiliate';
import { ContextUser } from './store/contexts/contextUser';
import { ContextPerson } from './store/contexts/contextPerson';
import { ProtectedRoute } from './shared/util/ProtectedRoute';
import { ProtectedAfiliadoRoute } from './shared/util/ProtectedAfiliadoRoute';
import { ProtectedAllowsAffiliateRoute } from './shared/util/ProtectedAllowsAffiliateRoute';
import { ProtectedCadastroPerfilPjRoute } from './shared/util/ProtectedCadastroPerfilPjRoute';
import { ProtectedCatalogoRoute } from './shared/util/ProtectedCatalogoRoute';
import { ProtectedPainelRoute } from './shared/util/ProtectedPainelRoute';
import { getDefaultTheme } from './shared/util/cursosOnlineUtils';
import useFunctionAsState from './hooks/useFunctionAsState';
import { tipoProdutoEnum } from './shared/util/enums/tipoProdutoEnum';
import { userTypes } from './shared/util/enums/userTypesEnum';
import { defaultTheme } from './styles/themes/theme';
import * as Sentry from '@sentry/react';

// O GlobalAntdComponents é importado para o GlobalStyle funcionar
// eslint-disable-next-line
import GlobalAntdComponents from './styles/globalAntdComponents';
// O GlobalStyle deve ser o último import
import GlobalStyle from './styles/global';
import { ContextSettings } from './store/contexts/contextSettings';
import SettingsComponent from './components/SettingsComponent/SettingsComponent';

// Lazy Loading Pages
const AlterarSenha = lazy(() => import('./pages/AlterarSenha/AlterarSenha'));
const AvaliacaoDepoimentos = lazy(() =>
  import('./pages/ProdutoCurso/pages/AvaliacaoDepoimentos/AvaliacaoDepoimentos')
);
const CadastroSebraetec = lazy(() =>
  import('./pages/CadastroSebraetec/CadastroSebraetec')
);
const Cadastro = lazy(() => import('./pages/Cadastro/Cadastro'));
const CadastroAfiliado = lazy(() =>
  import('./pages/CadastroAfiliado/CadastroAfiliado')
);
const CadastroCurso = lazy(() => import('./pages/CadastroCurso/CadastroCurso'));
const CadastroEvento = lazy(() =>
  import('./pages/CadastroEvento/CadastroEvento')
);
const CadastroLandingSaas = lazy(() =>
  import('./pages/CadastroLandingSaas/CadastroLandingSaas')
);
const CadastroPerfilPF = lazy(() =>
  import('./pages/CadastroPerfil/CadastroPerfilPF/CadastroPerfilPF')
);
const CadastroPerfilPJ = lazy(() =>
  import('./pages/CadastroPerfil/CadastroPerfilPJ/CadastroPerfilPJ')
);
const CadastroPesquisaSatisfacao = lazy(() =>
  import('./pages/PesquisaSatisfacao/CadastroPesquisaSatisfacao')
);
const CadastroProdutoHome = lazy(() =>
  import('./pages/CadastroProduto/CadastroProdutoHome')
);
const CadastroProdutoCapacitacao = lazy(() =>
  import('./pages/CadastroProduto/Capacitacao/CadastroProdutoCapacitacao')
);
const CadastroProdutoConsultoria = lazy(() =>
  import('./pages/CadastroProduto/Consultoria/CadastroProdutoConsultoria')
);
const CadastroSaas = lazy(() => import('./pages/CadastroSaas/CadastroSaas'));
const CadastroIntegracaoSaas = lazy(() =>
  import('./pages/CadastroIntegracaoSaas/CadastroIntegracaoSaas')
);
const CanaisDivulgacao = lazy(() =>
  import('./pages/CanaisDivulgacao/CanaisDivulgacao')
);
const Carteira = lazy(() => import('./pages/Carteira/Carteira'));
const CarteiraAfiliado = lazy(() =>
  import('./pages/CarteiraAfiliado/CarteiraAfiliado')
);
const Carrinho = lazy(() => import('./pages/Carrinho/Carrinho'));
const Catalogo = lazy(() => import('./pages/Catalogo/Catalogo'));
const CatalogoCliente = lazy(() =>
  import('./pages/CatalogoCliente/CatalogoCliente')
);
const Chat = lazy(() => import('./components/Chat/Chat'));
const PesquisaCES = lazy(() => import('./components/PesquisaCES/PesquisaCES'));
const Checkin = lazy(() => import('./pages/Checkin/Checkin'));
const Checkout = lazy(() => import('./pages/Checkout/Checkout'));
const CheckoutSebraepay = lazy(() =>
  import('./pages/CheckoutSebraepay/CheckoutSebraepay')
);
const CompleteRegistration = lazy(() =>
  import('./pages/CompleteRegistration/CompleteRegistration')
);
const ConfiguracoesDoEvento = lazy(() =>
  import('./pages/ConfiguracoesDoEvento/ConfiguracoesDoEvento')
);
const Consultor = lazy(() => import('./pages/Consultor/Consultor'));
const Curso = lazy(() => import('./pages/Curso/Curso'));
const CursoPesquisaSatisfacao = lazy(() =>
  import('./pages/CursoPesquisaSatisfacao/CursoPesquisaSatisfacao')
);
const DadosAcesso = lazy(() => import('./pages/DadosAcesso/DadosAcesso'));
const DadosCadastrais = lazy(() =>
  import('./pages/DadosCadastrais/pages/DadosCadastraisEmpresa/DadosCadastrais')
);
const DadosCadastraisCliente = lazy(() =>
  import(
    './pages/DadosCadastrais/pages/DadosCadastraisCliente/DadosCadastraisCliente'
  )
);
const DadosCadastraisAfiliado = lazy(() =>
  import(
    './pages/DadosCadastrais/pages/DadosCadastraisAfiliado/DadosCadastraisAfiliado'
  )
);
const DadosCadastraisContainer = lazy(() =>
  import('./pages/DadosCadastrais/DadosCadastraisContainer')
);
const Evento = lazy(() => import('./pages/Evento/Evento'));
const ExtratoFinanceiro = lazy(() =>
  import('./pages/ExtratoFinanceiro/ExtratoFinanceiro')
);
const ExtratoFinanceiroAfiliado = lazy(() =>
  import('./pages/ExtratoFinanceiroAfiliado/ExtratoFinanceiroAfiliado')
);
const DadosIngressos = lazy(() =>
  import('./pages/Evento/dadosIngressos/DadosIngressos')
);
const ErrorPage = lazy(() => import('./pages/Erro/Erro'));
const FacaParteSaas = lazy(() => import('./pages/FacaParteSaas/FacaParteSaas'));
const Faq = lazy(() => import('./pages/Faq/Faq'));
const Favoritos = lazy(() => import('./pages/Favoritos/Favoritos'));
const GerenciarPosts = lazy(() =>
  import('./pages/GerenciarPosts/GerenciarPosts')
);
const HistoricoFaturamento = lazy(() =>
  import('./pages/HistoricoFaturamento/HistoricoFaturamento')
);
const SalaDeAula = lazy(() => import('./pages/SalaDeAula/SalaDeAula'));
// const Home = lazy(() => import('./pages/Home/Home'));
const HomeSebraetec = lazy(() => import('./pages/HomeSebraetec/HomeSebraetec'));
const HomeAfiliado = lazy(() => import('./pages/HomeAfiliado/HomeAfiliado'));
const HomeCursos = lazy(() => import('./pages/HomeCursos/HomeCursos'));
const Homologado = lazy(() => import('./pages/Homologado/Homologado'));
const LandingSaas = lazy(() => import('./pages/LandingSaas/LandingSaas'));
const MeuPlano = lazy(() => import('./pages/MeuPlano/MeuPlano'));
const MeusEventos = lazy(() => import('./pages/MeusEventos/MeusEventos'));
const MeusLinks = lazy(() => import('./pages/MeusLinks/MeusLinks'));
const MinhasCompras = lazy(() => import('./pages/MinhasCompras/MinhasCompras'));
const Negociacoes = lazy(() => import('./pages/Negociacoes/Negociacoes'));
const NotaFiscal = lazy(() => import('./pages/NotaFiscal/NotaFiscal'));
const OrcamentosSebraetec = lazy(() =>
  import('./pages/OrcamentosSebraetec/OrcamentosSebraetec')
);
const PainelContainer = lazy(() => import('./pages/Painel/PainelContainer'));
const PainelAfiliado = lazy(() =>
  import('./pages/Painel/pages/PainelAfiliado/PainelAfiliado')
);
const PainelCliente = lazy(() =>
  import('./pages/Painel/pages/PainelCliente/PainelCliente')
);
const PainelEmpresa = lazy(() =>
  import('./pages/Painel/pages/PainelEmpresa/PainelEmpresa')
);
const PainelLicenciado = lazy(() =>
  import('./pages/Painel/pages/PainelLicenciado/PainelLicenciado')
);
const Participantes = lazy(() => import('./pages/Participantes/Participantes'));
const Perfil = lazy(() => import('./pages/Perfil/Perfil'));
const PerguntasRespostasSaas = lazy(() =>
  import('./pages/PerguntasRespostasSaas/PerguntasRespostasSaas')
);
const PerguntasRespostasGeral = lazy(() =>
  import('./pages/PerguntasRespostasGeral/PerguntasRespostasGeral')
);
const Planos = lazy(() => import('./pages/Planos/Planos'));
const Produto = lazy(() => import('./pages/Produto/Produto'));
const ProdutoCurso = lazy(() => import('./pages/ProdutoCurso/ProdutoCurso'));
const EditAd = lazy(() => import('./pages/ProdutoCurso/pages/EditAd/EditAd'));
const PerguntasRespostasCurso = lazy(() =>
  import('./pages/PerguntasRespostasCurso/PerguntasRespostasCurso')
);
const ProdutoSaas = lazy(() => import('./pages/ProdutoSaas/ProdutoSaas'));
const ProdutosContainer = lazy(() =>
  import('./pages/Produtos/ProdutosContainer')
);
const Profissionais = lazy(() => import('./pages/Profissionais/Profissionais'));
const ProgressoCurso = lazy(() =>
  import('./pages/ProgressoCurso/ProgressoCurso')
);
const PromocaoDesconto = lazy(() =>
  import('./pages/ProdutoCurso/pages/PromocaoDesconto/PromocaoDesconto')
);
const SolucaoSebraetec = lazy(() =>
  import('./pages/SolucaoSebraetec/SolucaoSebraetec')
);
const SolucoesSebraetec = lazy(() =>
  import('./pages/SolucoesSebraetec/SolucoesSebraetec')
);
const SolucoesSebraetecAtender = lazy(() =>
  import('./pages/SolucoesSebraetecAtender/SolucoesSebraetecAtender')
);
const SolucoesStatusSebraetec = lazy(() =>
  import('./pages/SolucoesStatusSebraetec/SolucoesStatusSebraetec')
);
const SebraetecPesquisaSatisfacao = lazy(() =>
  import('./pages/SebraetecPesquisaSatisfacao/SebraetecPesquisaSatisfacao')
);
const SebraetecUploadNF = lazy(() =>
  import('./pages/SebraetecUploadNF/SebraetecUploadNF')
);
const ListarNotasFornecedor = lazy(() =>
  import('./pages/ListarNotasFornecedor/ListarNotasFornecedor')
);
const MinhasVendas = lazy(() => import('./pages/MinhasVendas/MinhasVendas'));
const UploadNotaFiscal = lazy(() =>
  import('./pages/UploadNotaFiscal/UploadNotaFiscal')
);
const MockPage = lazy(() => import('./pages/MockPage/MockPage'));

export default function App() {
  // eslint-disable-next-line no-restricted-syntax
  dayjs.locale('pt-br');
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(customParseFormat);
  dayjs.extend(objectSupport);
  let [stateAlert, dispatchAlert] = useReducer(reducerAlert, initialStateAlert);
  const [hideAlerts, setHideAlerts] = useState(false);
  const [user, setUser] = useState(undefined);
  const [person, setPerson] = useState(undefined);
  const [personLoading, setPersonLoading] = useState(false);
  const [pessoa, setPessoa] = useState(undefined);
  const [isPersonLoaded, setIsPersonLoaded] = useState(false);
  const [vinculos, setVinculos] = useState([]);
  const [mustUpdatePerson, setMustUpdatePerson] = useState(true);
  const [empresasCrm, setEmpresasCrm] = useState([]);
  const [mustUpdateEmpresasCrm, setMustUpdateEmpresasCrm] = useState(false);
  const [specialRedirect, setSpecialRedirect] = useState();
  const [usersAllowed, setUsersAllowed] = useFunctionAsState(() => false);
  const [userType, setUserType] = useState(userTypes.PUBLICO);
  const [allowsAffiliate, setAllowsAffiliate] = useState(false);
  const [allowsAffiliateLoaded, setAllowsAffiliateLoaded] = useState(false);
  const [numberOfSebraetecRequests, setNumberOfSebraetecRequests] = useState(0);
  const [theme, setTheme] = useState(getDefaultTheme());
  const [habilitarCursosPresenciais, setHabilitarCursosPresenciais] = useState(
    false
  );
  const [habilitarModalidadeLead, setHabilitarModalidadeLead] = useState(false);
  const [habilitarModalidadeVitrine, setHabilitarModalidadeVitrine] = useState(
    false
  );

  const personProvider = useMemo(
    () => ({
      person: person,
      setPerson: setPerson,
      personLoading: personLoading,
      setPersonLoading: setPersonLoading,
      pessoa: pessoa,
      setPessoa: setPessoa,
      isPersonLoaded: isPersonLoaded,
      setIsPersonLoaded: setIsPersonLoaded,
      vinculos: vinculos,
      setVinculos: setVinculos,
      mustUpdatePerson: mustUpdatePerson,
      setMustUpdatePerson: setMustUpdatePerson,
      empresasCrm: empresasCrm,
      setEmpresasCrm: setEmpresasCrm,
      mustUpdateEmpresasCrm: mustUpdateEmpresasCrm,
      setMustUpdateEmpresasCrm: setMustUpdateEmpresasCrm,
      specialRedirect: specialRedirect,
      setSpecialRedirect: setSpecialRedirect,
      usersAllowed: usersAllowed,
      setUsersAllowed: setUsersAllowed,
      userType: userType,
      setUserType: setUserType,
      numberOfSebraetecRequests,
      setNumberOfSebraetecRequests,
      theme: theme,
      setTheme: setTheme,
      habilitarCursosPresenciais: habilitarCursosPresenciais,
      setHabilitarCursosPresenciais: setHabilitarCursosPresenciais
    }),
    [
      person,
      setPerson,
      personLoading,
      setPersonLoading,
      pessoa,
      setPessoa,
      isPersonLoaded,
      setIsPersonLoaded,
      vinculos,
      setVinculos,
      mustUpdatePerson,
      setMustUpdatePerson,
      empresasCrm,
      setEmpresasCrm,
      mustUpdateEmpresasCrm,
      setMustUpdateEmpresasCrm,
      specialRedirect,
      setSpecialRedirect,
      usersAllowed,
      setUsersAllowed,
      userType,
      setUserType,
      numberOfSebraetecRequests,
      setNumberOfSebraetecRequests,
      theme,
      setTheme,
      habilitarCursosPresenciais,
      setHabilitarCursosPresenciais
    ]
  );

  const affiliateProvider = useMemo(
    () => ({
      allowsAffiliate: allowsAffiliate,
      setAllowsAffiliate: setAllowsAffiliate,
      allowsAffiliateLoaded: allowsAffiliateLoaded,
      setAllowsAffiliateLoaded: setAllowsAffiliateLoaded
    }),
    [
      allowsAffiliate,
      setAllowsAffiliate,
      allowsAffiliateLoaded,
      setAllowsAffiliateLoaded
    ]
  );

  const settingsProvider = useMemo(
    () => ({
      habilitarModalidadeLead: habilitarModalidadeLead,
      setHabilitarModalidadeLead: setHabilitarModalidadeLead,
      habilitarModalidadeVitrine: habilitarModalidadeVitrine,
      setHabilitarModalidadeVitrine: setHabilitarModalidadeVitrine
    }),
    [
      habilitarModalidadeLead,
      setHabilitarModalidadeLead,
      habilitarModalidadeVitrine,
      setHabilitarModalidadeVitrine
    ]
  );

  return (
    <React.Fragment>
      <Sentry.ErrorBoundary fallback={() => <ErrorBoundary />}>
        <ContextAlert.Provider
          value={{ stateAlert, dispatchAlert, hideAlerts, setHideAlerts }}>
          <ApolloClientProvider>
            <ConfigProvider theme={defaultTheme} locale={locale}>
              <ContextUser.Provider value={{ user: user, setUser: setUser }}>
                <ContextPerson.Provider value={personProvider}>
                  <ContextAffiliate.Provider value={affiliateProvider}>
                    <ContextSettings.Provider value={settingsProvider}>
                      <InitInfoUser />
                      <PersonComponent />
                      <AffiliateComponent />
                      <SettingsComponent />
                      <AlertGlobal />
                      <ThemeProvider theme={theme}>
                        <Layout id='rootApp' className='min-h-100'>
                          <Router basename={'/plataforma'}>
                            <DocumentHead />
                            <ScrollToTop />
                            <Suspense fallback={<></>}>
                              <Chat />
                              <PesquisaCES />
                            </Suspense>
                            {/* SE ADICIONAR NOVA ROTA, COLOCAR ELA NO ENUM */}
                            <Switch>
                              <Route exact path={[RoutesEnum.HOME]}>
                                <MainContainer
                                  component={Home}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <Route exact path={[RoutesEnum.HOMOLOGADO]}>
                                <MainContainer
                                  lazy
                                  component={Homologado}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.PESQUISASATISFACAO_EVENTOID,
                                  RoutesEnum.PESQUISASATISFACAO_EVENTOID_NEGOCIACAOID
                                ]}>
                                <MainContainer
                                  lazy
                                  component={CadastroPesquisaSatisfacao}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <Route
                                exact
                                path={RoutesEnum.SOLUCAO_SEBRAETEC_ID}>
                                <MainContainer
                                  lazy
                                  component={SolucaoSebraetec}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.SEBRAETEC_PESQUISA_SATISFACAO}>
                                <MainContainer
                                  lazy
                                  component={SebraetecPesquisaSatisfacao}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CADASTROSEBRAETEC,
                                  RoutesEnum.CADASTROSEBRAETEC_ID
                                ]}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroSebraetec}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CADASTRO,
                                  RoutesEnum.CADASTRO_ID,
                                  RoutesEnum.CADASTRO_TIPO
                                ]}>
                                <MainContainer
                                  lazy
                                  component={Cadastro}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CADASTROAFILIADO}
                                specificUserTypes={[userTypes.CLIENTE]}>
                                <ProtectedAllowsAffiliateRoute
                                  exact
                                  path={RoutesEnum.CADASTROAFILIADO}>
                                  <MainContainer
                                    lazy
                                    component={CadastroAfiliado}
                                    header={true}
                                    footer={false}
                                  />
                                </ProtectedAllowsAffiliateRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CADASTROPERFIL}>
                                <MainContainer
                                  lazy
                                  component={CadastroPerfilPF}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CADASTROPERFIL_EMPRESA}>
                                <ProtectedCadastroPerfilPjRoute>
                                  <MainContainer
                                    lazy
                                    component={CadastroPerfilPJ}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedCadastroPerfilPjRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CADASTROPRODUTO}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroProdutoHome}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CADASTROPRODUTO_CAPACITACAO,
                                  RoutesEnum.CADASTROPRODUTO_CAPACITACAO_ID
                                ]}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroProdutoCapacitacao}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CADASTROPRODUTO_CONSULTORIA,
                                  RoutesEnum.CADASTROPRODUTO_CONSULTORIA_ID
                                ]}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroProdutoConsultoria}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <Route
                                exact
                                path={RoutesEnum.CADASTROSENHALICENCIADO}>
                                <MainContainer
                                  lazy
                                  component={CompleteRegistration}
                                  header={false}
                                  footer={false}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CANAISDIVULGACAO}>
                                <MainContainer
                                  lazy
                                  component={CanaisDivulgacao}
                                  header={true}
                                  footer={false}
                                  sidebarAfiliado={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute path={RoutesEnum.CARTEIRA}>
                                <MainContainer
                                  lazy
                                  component={Carteira}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                path={RoutesEnum.CARTEIRAAFILIADO}>
                                <ProtectedAllowsAffiliateRoute
                                  path={RoutesEnum.CARTEIRAAFILIADO}>
                                  <MainContainer
                                    lazy
                                    component={CarteiraAfiliado}
                                    header={true}
                                    footer={false}
                                    sidebarAfiliado={true}
                                  />
                                </ProtectedAllowsAffiliateRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CADASTROCURSOS,
                                  RoutesEnum.CADASTROCURSOS_ID,
                                  RoutesEnum.CADASTROCURSOS_ID_PASSO
                                ]}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroCurso}
                                  header={true}
                                  footer={true}
                                  onlyFooterBottom={true}
                                  whiteYellowLogo={true}
                                  withoutBlur={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute exact path={RoutesEnum.CARRINHO}>
                                <MainContainer
                                  lazy
                                  component={Carrinho}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CADASTROLICENCIADO}>
                                <ProtectedCatalogoRoute
                                  exact
                                  path={RoutesEnum.CADASTROLICENCIADO}>
                                  <MainContainer
                                    lazy
                                    component={Catalogo}
                                    header={true}
                                    footer={true}
                                  />
                                </ProtectedCatalogoRoute>
                              </ProtectedRoute>
                              <Route
                                exact
                                path={[
                                  RoutesEnum.CATALOGO,
                                  RoutesEnum.CATALOGO_CLIENTE,
                                  RoutesEnum.CATALOGO_SEARCH
                                ]}>
                                <MainContainer
                                  lazy
                                  component={CatalogoCliente}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute path={RoutesEnum.CHECKOUT}>
                                <MainContainer
                                  lazy
                                  component={Checkout}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                path={RoutesEnum.CHECKOUT_SEBRAEPAY}>
                                <MainContainer
                                  lazy
                                  component={CheckoutSebraepay}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <Route exact path={RoutesEnum.CONSULTORIA_ID}>
                                <MainContainer
                                  lazy
                                  component={Consultor}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.CONSULTORIA_PROMOCAODESCONTO_ID
                                }>
                                <MainContainer
                                  lazy
                                  component={PromocaoDesconto}
                                  sidebar={true}
                                  header={true}
                                  footer={false}
                                  typeProduct={tipoProdutoEnum.CONSULTORIA}
                                  specificUserTypes={[userTypes.FORNECEDOR]}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CURSOSONLINE_GERENCIARPOST_ID}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={GerenciarPosts}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CURSOSONLINE_PRODUTO_ID}>
                                <MainContainer
                                  lazy
                                  component={ProdutoCurso}
                                  header={true}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CURSOSONLINE_EDITAR_ID}>
                                <MainContainer
                                  lazy
                                  component={EditAd}
                                  header={true}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.CURSOSONLINE_PERGUNTASRESPOSTAS_ID
                                }>
                                <MainContainer
                                  lazy
                                  component={PerguntasRespostasCurso}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.CURSOSONLINE_PROGRESSO_ID}>
                                <MainContainer
                                  lazy
                                  component={ProgressoCurso}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.CURSOSONLINE_PRODUTO_ID_PROMOCAODESCONTO
                                }>
                                <MainContainer
                                  lazy
                                  component={PromocaoDesconto}
                                  sidebar={true}
                                  header={true}
                                  footer={false}
                                  typeProduct={tipoProdutoEnum.CURSO}
                                  specificUserTypes={[userTypes.FORNECEDOR]}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.CURSOSONLINE_PRODUTO_IDCURSO_AVALIACAODEPOIMENTOS
                                }>
                                <MainContainer
                                  lazy
                                  component={AvaliacaoDepoimentos}
                                  header={true}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <Route exact path={[RoutesEnum.CURSOSONLINE_ID]}>
                                <MainContainer
                                  lazy
                                  component={Curso}
                                  whiteYellowLogo={true}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CURSOSONLINE_HOMOLOGACAOAULAS_CURSOID,
                                  RoutesEnum.CURSOSONLINE_HOMOLOGACAOAULAS_CURSOID_SECAOID,
                                  RoutesEnum.CURSOSONLINE_HOMOLOGACAOAULAS_CURSOID_SECAOID_AULAID
                                ]}>
                                <MainContainer
                                  lazy
                                  component={SalaDeAula}
                                  header={true}
                                  footer={true}
                                  exitButton={true}
                                  whiteYellowLogo={true}
                                  withoutBlur={true}
                                  onlyFooterBottom={true}
                                  onlyBackOfficeUser={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CURSOSONLINE_SALADEAULA_CURSOID,
                                  RoutesEnum.CURSOSONLINE_SALADEAULA_CURSOID_SECAOID,
                                  RoutesEnum.CURSOSONLINE_SALADEAULA_CURSOID_SECAOID_AULAID
                                ]}
                                specificUserTypes={[userTypes.CLIENTE]}>
                                <MainContainer
                                  lazy
                                  component={SalaDeAula}
                                  header={true}
                                  footer={true}
                                  exitButton={true}
                                  whiteYellowLogo={true}
                                  withoutBlur={true}
                                  onlyFooterBottom={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.CURSOSONLINE_PESQUISASATISFACAO_MATRICULAID
                                ]}
                                specificUserTypes={[userTypes.CLIENTE]}>
                                <MainContainer
                                  lazy
                                  component={CursoPesquisaSatisfacao}
                                  header={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.DADOSCADASTRAIS}>
                                <MainContainer
                                  lazy
                                  component={DadosCadastraisContainer}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.DADOSCADASTRAIS_EMPRESA}>
                                <MainContainer
                                  lazy
                                  component={DadosCadastrais}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.DADOSCADASTRAIS_CLIENTE}>
                                <MainContainer
                                  lazy
                                  component={DadosCadastraisCliente}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedAfiliadoRoute
                                exact
                                path={RoutesEnum.DADOSCADASTRAIS_AFILIADO}>
                                <MainContainer
                                  lazy
                                  component={DadosCadastraisAfiliado}
                                  header={true}
                                  footer={false}
                                  sidebarAfiliado={true}
                                />
                              </ProtectedAfiliadoRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.DADOSCADASTRAIS_CLIENTE_ALTERARSENHA
                                }>
                                <MainContainer
                                  lazy
                                  component={AlterarSenha}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <Route exact path={RoutesEnum.EVENTO_ID}>
                                <MainContainer
                                  lazy
                                  component={Evento}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.EXTRATOFINANCEIRO}>
                                <MainContainer
                                  lazy
                                  component={ExtratoFinanceiro}
                                  header={true}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.EVENTO_ID_DADOSINGRESSOS}>
                                <MainContainer
                                  lazy
                                  component={DadosIngressos}
                                  header={true}
                                  footer={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.EVENTO_ID_DADOSINGRESSOSLICENCIADO
                                }
                                specificUserTypes={[userTypes.LICENCIADO]}>
                                <MainContainer
                                  lazy
                                  component={DadosIngressos}
                                  header={true}
                                  footer={true}
                                  userLicenciado={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.AFILIADO_EXTRATOFINANCEIRO}
                                specificUserTypes={[userTypes.CLIENTE]}>
                                <MainContainer
                                  lazy
                                  component={ExtratoFinanceiroAfiliado}
                                  header={true}
                                  sidebarAfiliado={true}
                                />
                              </ProtectedRoute>
                              <Route
                                exact
                                path={[RoutesEnum.SOLUCAOINTELIGENTE]}>
                                <MainContainer
                                  lazy
                                  component={FacaParteSaas}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <Route exact path={RoutesEnum.FAQ}>
                                <MainContainer
                                  lazy
                                  component={Faq}
                                  header={true}
                                  footer={true}
                                  sidebar={false}
                                />
                              </Route>
                              <ProtectedRoute exact path={RoutesEnum.FAVORITOS}>
                                <MainContainer
                                  lazy
                                  component={Favoritos}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.MEUSEVENTOS}
                                specificUserTypes={[userTypes.LICENCIADO]}>
                                <MainContainer
                                  lazy
                                  component={MeusEventos}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.MEUSEVENTOS_CADASTRO,
                                  RoutesEnum.MEUSEVENTOS_CADASTRO_ID
                                ]}
                                specificUserTypes={[userTypes.LICENCIADO]}>
                                <MainContainer
                                  lazy
                                  component={CadastroEvento}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[RoutesEnum.MEUSEVENTOS_CHECKIN_EVENTOID]}
                                specificUserTypes={[userTypes.LICENCIADO]}>
                                <MainContainer
                                  lazy
                                  component={Checkin}
                                  isPublic={false}
                                  sidebar={true}
                                  header={true}
                                  footer={false}
                                />
                              </ProtectedRoute>
                              <Route
                                exact
                                path={RoutesEnum.CHECKIN_EVENTOID_UUID}>
                                <MainContainer
                                  lazy
                                  component={Checkin}
                                  isPublic={true}
                                  header={false}
                                  footer={false}
                                  sidebar={false}
                                />
                              </Route>
                              <Route exact path={RoutesEnum.CURSOSONLINE}>
                                <MainContainer
                                  lazy
                                  component={HomeCursos}
                                  isPublic={true}
                                  header={true}
                                  footer={true}
                                  sidebar={false}
                                />
                              </Route>
                              <Route exact path={RoutesEnum.HOMEAFILIADO}>
                                <ProtectedAllowsAffiliateRoute
                                  exact
                                  path={RoutesEnum.HOMEAFILIADO}>
                                  <MainContainer
                                    lazy
                                    component={HomeAfiliado}
                                    header={true}
                                    footer={true}
                                    sidebar={false}
                                  />
                                </ProtectedAllowsAffiliateRoute>
                              </Route>
                              <Route exact path={RoutesEnum.SEBRAETEC}>
                                <MainContainer
                                  lazy
                                  component={HomeSebraetec}
                                  isPublic={true}
                                  header={true}
                                  footer={true}
                                  sidebar={false}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.MEUSEVENTOS_CONFIGURACAO_ID}
                                specificUserTypes={[userTypes.LICENCIADO]}>
                                <MainContainer
                                  lazy
                                  component={ConfiguracoesDoEvento}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.MEUSEVENTOS_PARTICIPANTES_ID}
                                specificUserTypes={[userTypes.LICENCIADO]}>
                                <MainContainer
                                  lazy
                                  component={Participantes}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute exact path={RoutesEnum.MEUPLANO}>
                                <MainContainer
                                  lazy
                                  component={MeuPlano}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute exact path={RoutesEnum.MEUSLINKS}>
                                <ProtectedAfiliadoRoute
                                  exact
                                  path={RoutesEnum.MEUSLINKS}>
                                  <MainContainer
                                    lazy
                                    component={MeusLinks}
                                    header={true}
                                    footer={false}
                                    sidebarAfiliado={true}
                                  />
                                </ProtectedAfiliadoRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.ORCAMENTOS_SEBRAETEC}>
                                <MainContainer
                                  lazy
                                  component={OrcamentosSebraetec}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.MINHASCOMPRAS}>
                                <MainContainer
                                  lazy
                                  component={MinhasCompras}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.MINHASCOMPRAS_ID}>
                                <MainContainer
                                  lazy
                                  component={MinhasCompras}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute path={RoutesEnum.NEGOCIACOES}>
                                <MainContainer
                                  lazy
                                  component={Negociacoes}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute path={RoutesEnum.NOTAS}>
                                <MainContainer
                                  lazy
                                  component={NotaFiscal}
                                  header={true}
                                  footer={false}
                                  sidebarAfiliado={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PAINEL_AFILIADO}>
                                <ProtectedAfiliadoRoute
                                  exact
                                  path={RoutesEnum.PAINEL_AFILIADO}>
                                  <MainContainer
                                    lazy
                                    component={PainelAfiliado}
                                    header={true}
                                    footer={false}
                                    sidebarAfiliado={true}
                                  />
                                </ProtectedAfiliadoRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PAINEL_CLIENTE}>
                                <ProtectedPainelRoute
                                  exact
                                  role='CLIENTE'
                                  path={RoutesEnum.PAINEL_CLIENTE}>
                                  <MainContainer
                                    lazy
                                    component={PainelCliente}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PAINEL_LICENCIADO}>
                                <ProtectedPainelRoute
                                  exact
                                  role='LICENCIADO'
                                  path={RoutesEnum.PAINEL_LICENCIADO}>
                                  <MainContainer
                                    lazy
                                    component={PainelLicenciado}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PAINEL_EMPRESA}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={RoutesEnum.PAINEL_EMPRESA}>
                                  <MainContainer
                                    lazy
                                    component={PainelEmpresa}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.LISTAR_NOTAS}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={RoutesEnum.LISTAR_NOTAS}>
                                  <MainContainer
                                    lazy
                                    component={ListarNotasFornecedor}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.MINHASVENDAS}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={RoutesEnum.MINHASVENDAS}>
                                  <MainContainer
                                    lazy
                                    component={MinhasVendas}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.SOLUCOES_SEBRAETEC}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={RoutesEnum.SOLUCOES_SEBRAETEC}>
                                  <MainContainer
                                    lazy
                                    component={SolucoesSebraetec}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.SOLUCOES_SEBRAETEC_PENDENTES,
                                  RoutesEnum.SOLUCOES_SEBRAETEC_VINCULADOS
                                ]}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={[
                                    RoutesEnum.SOLUCOES_SEBRAETEC_PENDENTES,
                                    RoutesEnum.SOLUCOES_SEBRAETEC_VINCULADOS
                                  ]}>
                                  <MainContainer
                                    lazy
                                    component={SolucoesStatusSebraetec}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[RoutesEnum.SEBRAETEC_UPLOAD_NF]}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={[RoutesEnum.SEBRAETEC_UPLOAD_NF]}>
                                  <MainContainer
                                    lazy
                                    component={SebraetecUploadNF}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.SOLUCOES_SEBRAETEC_ATENDER_ID
                                ]}>
                                <ProtectedPainelRoute
                                  exact
                                  role='FORNECEDOR'
                                  path={[
                                    RoutesEnum.SOLUCOES_SEBRAETEC_ATENDER_ID
                                  ]}>
                                  <MainContainer
                                    lazy
                                    component={SolucoesSebraetecAtender}
                                    header={true}
                                    footer={false}
                                    sidebar={true}
                                  />
                                </ProtectedPainelRoute>
                              </ProtectedRoute>
                              <ProtectedRoute exact path={RoutesEnum.PAINEL}>
                                <MainContainer
                                  lazy
                                  component={PainelContainer}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute exact path={RoutesEnum.PERFIL}>
                                <MainContainer
                                  lazy
                                  component={Perfil}
                                  header={true}
                                  footer={true}
                                />
                              </ProtectedRoute>
                              <Route exact path={RoutesEnum.PERFIL_SLUG}>
                                <MainContainer
                                  lazy
                                  component={Perfil}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <ProtectedRoute exact path={RoutesEnum.PLANOS}>
                                <MainContainer
                                  lazy
                                  component={Planos}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PRODUTOS}
                                specificUserTypes={[
                                  userTypes.LICENCIADO,
                                  userTypes.FORNECEDOR
                                ]}>
                                <MainContainer
                                  lazy
                                  component={ProdutosContainer}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PRODUTO_ID}>
                                <MainContainer
                                  lazy
                                  component={Produto}
                                  header={true}
                                  footer={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PROFISSIONAIS}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={Profissionais}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.SOLUCAOINTELIGENTE_HISTORICOFATURAMENTO_ID,
                                  RoutesEnum.MINHASVENDAS_HISTORICOFATURAMENTO_ID,
                                  RoutesEnum.MINHASCOMPRAS_HISTORICOFATURAMENTO_ID
                                ]}>
                                <MainContainer
                                  lazy
                                  component={HistoricoFaturamento}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.SOLUCAOINTELIGENTE_PRODUTO_ID}>
                                <MainContainer
                                  lazy
                                  component={ProdutoSaas}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.SOLUCAOINTELIGENTE_PRODUTO_IDPRODUTOSAAS_DADOSACESSO_ID
                                }>
                                <MainContainer
                                  lazy
                                  component={DadosAcesso}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.SOLUCAOINTELIGENTE_PERGUNTASRESPOSTAS_ID
                                }>
                                <MainContainer
                                  lazy
                                  component={PerguntasRespostasSaas}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.SOLUCAOINTELIGENTE_PRODUTO_PROMOCAODESCONTO_ID
                                }>
                                <MainContainer
                                  lazy
                                  specificUserTypes={[userTypes.FORNECEDOR]}
                                  component={PromocaoDesconto}
                                  sidebar={true}
                                  header={true}
                                  footer={false}
                                  typeProduct={tipoProdutoEnum.SAAS}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={RoutesEnum.PERGUNTASERESPOSTAS}>
                                <MainContainer
                                  lazy
                                  component={PerguntasRespostasGeral}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                exact
                                path={[
                                  RoutesEnum.SOLUCAOINTELIGENTE_CADASTROPRODUTO,
                                  RoutesEnum.SOLUCAOINTELIGENTE_CADASTROPRODUTO_ID
                                ]}
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroSaas}
                                  header={true}
                                />
                              </ProtectedRoute>
                              <Route
                                exact
                                path={
                                  RoutesEnum.SOLUCAOINTELIGENTE_CADASTROLANDING_ID
                                }
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroLandingSaas}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <Route
                                exact
                                path={RoutesEnum.SOLUCAOINTELIGENTE_LANDING_ID}>
                                <MainContainer
                                  lazy
                                  component={LandingSaas}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <Route exact path={RoutesEnum.SD_ID}>
                                <MainContainer
                                  lazy
                                  component={LandingSaas}
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                              <Route
                                exact
                                path={
                                  RoutesEnum.SOLUCAOINTELIGENTE_CADASTROINTEGRACAO
                                }
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroIntegracaoSaas}
                                  header={true}
                                />
                              </Route>
                              <ProtectedRoute
                                exact
                                path={
                                  RoutesEnum.SOLUCAOINTELIGENTE_CADASTROINTEGRACAO_ID
                                }
                                specificUserTypes={[userTypes.FORNECEDOR]}>
                                <MainContainer
                                  lazy
                                  component={CadastroIntegracaoSaas}
                                  header={true}
                                />
                              </ProtectedRoute>
                              <ProtectedRoute
                                path={RoutesEnum.NOTASFISCAISUPLOAD_ID}>
                                <MainContainer
                                  lazy
                                  component={UploadNotaFiscal}
                                  header={true}
                                  footer={false}
                                  sidebar={true}
                                />
                              </ProtectedRoute>
                              <Route exact path='/mock-page'>
                                <MainContainer lazy component={MockPage} />
                              </Route>
                              <Route path='*'>
                                <MainContainer
                                  lazy
                                  component={ErrorPage}
                                  code='404'
                                  header={true}
                                  footer={true}
                                />
                              </Route>
                            </Switch>
                          </Router>
                        </Layout>
                      </ThemeProvider>
                    </ContextSettings.Provider>
                  </ContextAffiliate.Provider>
                </ContextPerson.Provider>
              </ContextUser.Provider>
            </ConfigProvider>
          </ApolloClientProvider>
        </ContextAlert.Provider>
        <GlobalStyle />
      </Sentry.ErrorBoundary>
    </React.Fragment>
  );
}
