import dayjs from 'dayjs';
import { canaisDivulgacaoEnum } from './enums/canaisDivulgacaoEnum';
import { enderecoEnum } from './enums/enderecoEnum';

export function transformPhone(num) {
  if (num) {
    num = num.toString();
    num = num.replace(/\D/g, '');
    if (num.length > 8) {
      num = num.substring(0, 11);
    }
    switch (num.length) {
      case 2:
        num = num.replace(/(\d{2})/, '$1');
        break;
      case 3:
        num = num.replace(/(\d{2})(\d{1})/, '($1) $2');
        break;
      case 4:
        num = num.replace(/(\d{2})(\d{2})/, '($1) $2');
        break;
      case 5:
        num = num.replace(/(\d{2})(\d{3})/, '($1) $2');
        break;
      case 6:
        num = num.replace(/(\d{2})(\d{4})/, '($1) $2');
        break;
      case 7:
        num = num.replace(/(\d{2})(\d{4})(\d{1})/, '($1) $2-$3');
        break;
      case 8:
        num = num.replace(/(\d{2})(\d{4})(\d{2})/, '($1) $2-$3');
        break;
      case 9:
        num = num.replace(/(\d{2})(\d{4})(\d{3})/, '($1) $2-$3');
        break;
      case 10:
        num = num.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        break;
      case 11:
        num = num.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        break;
      default:
        return num;
    }
  }
  return num;
}

export function transformCPF(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      value = value.substring(0, 11);
    }
    switch (value.length) {
      case 4:
        value = value.replace(/(\d+)(\d{1})/, '$1.$2');
        break;
      case 5:
        value = value.replace(/(\d+)(\d{2})/, '$1.$2');
        break;
      case 6:
        value = value.replace(/(\d+)(\d{3})/, '$1.$2');
        break;
      case 7:
        value = value.replace(/(\d+)(\d{3})(\d{1})/, '$1.$2.$3');
        break;
      case 8:
        value = value.replace(/(\d+)(\d{3})(\d{2})/, '$1.$2.$3');
        break;
      case 9:
        value = value.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
        break;
      case 10:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
        break;
      case 11:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformCEP(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.substring(0, 8);
    }
    switch (value.length) {
      case 6:
        value = value.replace(/(\d+)(\d{1})/, '$1-$2');
        break;
      case 7:
        value = value.replace(/(\d+)(\d{2})/, '$1-$2');
        break;
      case 8:
        value = value.replace(/(\d+)(\d{3})/, '$1-$2');
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformCNPJ(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 14) {
      value = value.substring(0, 14);
    }
    switch (value.length) {
      case 4:
        value = value.replace(/(\d+)(\d{1})/, '$1.$2');
        break;
      case 5:
        value = value.replace(/(\d+)(\d{2})/, '$1.$2');
        break;
      case 6:
        value = value.replace(/(\d+)(\d{3})/, '$1.$2');
        break;
      case 7:
        value = value.replace(/(\d+)(\d{3})(\d{1})/, '$1.$2.$3');
        break;
      case 8:
        value = value.replace(/(\d+)(\d{3})(\d{2})/, '$1.$2.$3');
        break;
      case 9:
        value = value.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
        break;
      case 10:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
        break;
      case 11:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        break;
      case 12:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4');
        break;
      case 13:
        value = value.replace(
          /(\d+)(\d{3})(\d{3})(\d{4})(\d{1})/,
          '$1.$2.$3/$4-$5'
        );
        break;
      case 14:
        value = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/,
          '$1.$2.$3/$4-$5'
        );
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformInscricaoEstadual(value) {
  if (value) {
    if (value.length > 14) {
      value = value.substring(0, 14);
    }
    return value;
  }
}

export function transformYear(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 4) {
      return value.substring(0, 4);
    }
    return value;
  }
}

export function formatWeekDateTime(value) {
  // eslint-disable-next-line
  dayjs.locale('pt-br');
  return dayjs(value).format('ddd., D [de] MMM. HH:mm');
}

export function transformDate(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.substring(0, 8);
    }
    switch (value.length) {
      case 3:
        value = value.replace(/(\d+)(\d{1})/, '$1/$2');
        break;
      case 4:
        value = value.replace(/(\d+)(\d{2})/, '$1/$2');
        break;
      case 5:
        value = value.replace(/(\d+)(\d{2})(\d{1})/, '$1/$2/$3');
        break;
      case 6:
        value = value.replace(/(\d+)(\d{2})(\d{2})/, '$1/$2/$3');
        break;
      case 7:
        value = value.replace(/(\d+)(\d{2})(\d{3})/, '$1/$2/$3');
        break;
      case 8:
        value = value.replace(/(\d+)(\d{2})(\d{4})/, '$1/$2/$3');
        break;
      default:
        break;
    }
  }
  return value;
}

export function transformValor13Digitos(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 13) {
      value = value.substring(0, 13);
    }
    switch (value.length) {
      case 3:
        value = value.replace(/(\d{1})(\d+)/, '$1,$2');
        break;
      case 4:
        value = value.replace(/(\d{2})(\d+)/, '$1,$2');
        break;
      case 5:
        value = value.replace(/(\d{3})(\d+)/, '$1,$2');
        break;
      case 6:
        value = value.replace(/(\d{1})(\d{3})(\d+)/, '$1.$2,$3');
        break;
      case 7:
        value = value.replace(/(\d{2})(\d{3})(\d+)/, '$1.$2,$3');
        break;
      case 8:
        value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2,$3');
        break;
      case 9:
        value = value.replace(/(\d{1})(\d{3})(\d{3})(\d+)/, '$1.$2.$3,$4');
        break;
      case 10:
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d+)/, '$1.$2.$3,$4');
        break;
      case 11:
        value = value.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3,$4');
        break;
      case 12:
        value = value.replace(
          /(\d{1})(\d{3})(\d{3})(\d{3})(\d+)/,
          '$1.$2.$3.$4,$5'
        );
        break;
      case 13:
        value = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{3})(\d+)/,
          '$1.$2.$3.$4,$5'
        );
        break;
      default:
        return value;
    }
  }
  return value;
}

export function getContentType(contentType) {
  const contents = contentType.split(';');

  return contents[0];
}

export function transformHoraMinuto(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 5) {
      value = value.substring(0, 5);
    }
    switch (value.length) {
      case 3:
        value = value.replace(/(\d{1})(\d+)/, '$1:$2');
        break;
      case 4:
        value = value.replace(/(\d{2})(\d+)/, '$1:$2');
        break;
      case 5:
        value = value.replace(/(\d{3})(\d+)/, '$1:$2');
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformHHMM(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 4) {
      value = value.substring(0, 4);
    }
    switch (value.length) {
      case 3:
        value = value.replace(/(\d{1})(\d+)/, '$1:$2');
        break;
      case 4:
        value = value.replace(/(\d{2})(\d+)/, '$1:$2');
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformBankAgency(value, hasFiveDigits) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    const limitSize = hasFiveDigits ? 5 : 4;
    if (value.length > limitSize) {
      value = value.substring(0, limitSize);
    }
    if (value.length === limitSize && limitSize === 5)
      value = value.replace(/(\d+)(\d{1})/, '$1-$2');
  }
  return value;
}

export function transformBankAccount(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 12) {
      value = value.substring(0, 12);
    }
    value = value.replace(/(\d+)(\d{1})/, '$1-$2');
  }
  return value;
}

export function transformBanckAccountIugu(value, pos) {
  let ret = value;
  ret = ret.replace(/\D/g, '');

  switch (pos) {
    case 0:
      ret = ret.replace(/\D/g, '');
      break;
    case 3:
      ret = ret.replace(/(\d+)(\d{3})/, '$1-$2');
      break;
    default:
      ret = ret.replace(/(\d+)(\d{1})/, '$1-$2');
      break;
  }

  return ret;
}

export const normalizeValueOrNull = value => {
  if (!value) {
    return null;
  }

  if (!isNaN(value)) {
    return Number(value);
  }
  return formatCurrencyStringToFloat(value, ',');
};

export function formatValor(value, showCurrencyName) {
  if (value === null || value === undefined) {
    return 0;
  }
  let numero = value.toFixed(2).split('.');
  numero[0] =
    (!showCurrencyName ? '' : 'R$ ') +
    numero[0].split(/(?=(?:...)*$)/).join('.');
  return numero.join(',');
}

export function formatIntegerToString(integerValue) {
  if (integerValue) {
    const formatter = new Intl.NumberFormat('pt-BR');
    return formatter.format(integerValue);
  }
  return '0';
}

export function formatCurrencyStringToFloat(value, separadorDecimal) {
  let valueClean;
  if (value) {
    if (separadorDecimal === ',') {
      valueClean = value
        .replace(/\./g, '')
        .replace(',', '.')
        .replace(/(?!-)[^0-9.]/g, '');
    } else {
      valueClean = value.replace(',', '').replace(/(?!-)[^0-9.]/g, '');
    }

    return Number(valueClean);
  }

  return '';
}

export function formatFloatToCurrencyString(value, separadorDecimal, prefixo) {
  if (!separadorDecimal) {
    separadorDecimal = ',';
  }

  if (!prefixo && prefixo !== '') {
    prefixo = 'R$';
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2
  });
  let valueClean;
  if (value) {
    if (separadorDecimal === ',') {
      valueClean = value
        .toString()
        .replace(/\./g, '')
        .replace(',', '.')
        .replace(/(?!-)[^0-9.]/g, '');
    } else {
      valueClean = value
        .toString()
        .replace(',', '')
        .replace(/(?!-)[^0-9.]/g, '');
    }

    return `${prefixo} ${formatter.format(Number(valueClean))}`;
  }

  return '0,00';
}

export function applyMaskCurrency(
  valorConverter,
  aceitaNumeroNegativo,
  separadorDecimal,
  separadorMilhar,
  prefixo
) {
  let valorNum;
  let valueNegative = false;
  valorConverter =
    valorConverter && valorConverter.toString ? valorConverter.toString() : '';
  if (aceitaNumeroNegativo) {
    valueNegative = valorConverter.match(/-/) ? true : false;
    if (valorConverter === `-`) {
      valorNum = '-0';
    } else {
      valorNum = verifyLengthNumber(valorConverter.replace(/\D/g, ''));
    }
  } else {
    valorNum = verifyLengthNumber(valorConverter.replace(/\D/g, ''));
  }

  let valorMask = '';
  let valor;

  if (!valorNum) {
    return '';
  }
  if (parseInt(valorNum.replace(/\D/g, ''), 10).toString().length <= 3) {
    valor = parseInt(valorNum.replace(/\D/g, ''), 10).toString();
  } else {
    valor = valorNum.toString();
    if (valor[0] === '0') {
      valor = valor.substring(1);
    }
  }

  switch (valor.length) {
    case 1:
      valorMask = '0' + separadorDecimal + '0' + valor;
      break;
    case 2:
      valorMask = '0' + separadorDecimal + valor;
      break;
    case 3:
      valorMask = valor.substr(0, 1) + separadorDecimal + valor.substr(1, 2);
      break;
    default:
      break;
  }

  if (valorMask === '') {
    let sepMilhar = 0;
    for (let i = valor.length - 3; i >= 0; i--) {
      if (sepMilhar === 3) {
        valorMask = separadorMilhar + valorMask;
        sepMilhar = 0;
      }
      valorMask = valor.charAt(i) + valorMask;
      sepMilhar++;
    }
    valorMask =
      valorMask + separadorDecimal + valor.substr(valor.length - 2, 2);
  }

  if (prefixo !== '' && prefixo !== undefined) {
    valorMask = prefixo + ' ' + valorMask;
  }

  if (valueNegative && aceitaNumeroNegativo) {
    valorMask = `-${valorMask}`;
  }

  return valorMask;
}

export function verifyLengthNumber(valor) {
  if (valor && valor.length > 15) {
    return valor.substring(0, 15);
  } else {
    return valor;
  }
}

export function handleNomeUsuarioRedeSocial(channel) {
  switch (channel.redeSocial) {
    case canaisDivulgacaoEnum.FACEBOOK:
    case canaisDivulgacaoEnum.TWITTER:
    case canaisDivulgacaoEnum.INSTAGRAM:
    case canaisDivulgacaoEnum.YOUTUBE:
    case canaisDivulgacaoEnum.LINKEDIN:
      return cleanNameImage(channel.nomeUsuario);

    default:
      return channel.nomeUsuario;
  }
}

export function stringToPascalCase(string) {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w+)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export function transformOnlyNumberInteger(value, maxLength) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (maxLength && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }
    return Number(value);
  }
  return value;
}

export function removeSpaces(value) {
  if (value) {
    return value.replace(' ', '');
  }
  return value;
}

export function removeAccents(value) {
  if (value) {
    value = value.replace(/[ÀÁÂÃÄÅ]/g, 'A');
    value = value.replace(/[àáâãäå]/g, 'a');
    value = value.replace(/[éèê]/g, 'e');
    value = value.replace(/[ÉÈÊ]/g, 'E');
    value = value.replace(/[ÍÌÎ]/g, 'I');
    value = value.replace(/[íìî]/g, 'i');
    value = value.replace(/[ÓÒÔÕ]/g, 'O');
    value = value.replace(/[óòôõ]/g, 'o');
    value = value.replace(/[ÚÙÛ]/g, 'U');
    value = value.replace(/[úùû]/g, 'u');
    value = value.replace(/[Ç]/g, 'C');
    value = value.replace(/[ç]/g, 'c');
    value = value.replace(/[/.',~!@#$%&-]/g, '');
    return value;
  }
  return value;
}

export function parseToEditorJS(data) {
  let editorData = removeLineBreakAndParse(data);
  editorData = createLinkURLEditorJsText(editorData);
  return editorData;
}

export function getRawTextFromEditorJS(data) {
  let editorData = removeLineBreakAndParse(data);
  if (editorData && Array.isArray(editorData.blocks)) {
    editorData = editorData.blocks
      .map(b => {
        if (b.data && b.data.text) {
          return b.data.text;
        }

        if (b.data && b.data.items) {
          return b.data.items.map((i, index) => index + 1 + '. ' + i).join(' ');
        }

        return '';
      })
      .join(' ')
      .replace(/(&nbsp;|<br>)/g, '');
    return editorData;
  }

  return '';
}

export function getDaysUntilDate(date) {
  if (date) {
    const inputDate = dayjs(date, undefined, true);

    if (inputDate.isValid()) {
      return inputDate.diff(dayjs(), 'days');
    }
  }

  return;
}

export function removeLineBreakAndParse(data) {
  try {
    return JSON.parse(data.replace(/(\\n)/g, ' '));
  } catch {
    return null;
  }
}

export function createLinkURLEditorJsText(data) {
  if (
    data &&
    data.blocks &&
    Array.isArray(data.blocks) &&
    data.blocks.length > 0
  ) {
    data.blocks.forEach(b => {
      if (b && b.data && b.data.text) {
        b.data.text = b.data.text.replace(
          /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
          '<a target="_blank" href="$1">$1</a>'
        );
      }
    });
  }
  return data;
}

export function formatAndParseJson(data) {
  try {
    let jsonFormat = data.replace(/(\\n)/g, '');
    jsonFormat = jsonFormat.replace(/(\\")/g, `"`);
    return JSON.parse(jsonFormat);
  } catch {
    return null;
  }
}

export function quantityAndMinimumParcelsValue(
  value,
  maxParcels,
  minValuePerParcel
) {
  let numberOfParcels = 1;
  if (value > minValuePerParcel) {
    numberOfParcels = Math.floor(value / minValuePerParcel);
  }
  let parcels = 0;
  for (
    let index = 1;
    index <= numberOfParcels && index <= maxParcels;
    index++
  ) {
    parcels++;
  }
  return { parcels: parcels, value: value / parcels };
}

export const removeImageSignature = urlImage => {
  if (urlImage) {
    const spliceImage = urlImage.split('?');

    if (spliceImage.length > 1) {
      spliceImage.pop();
    }

    return spliceImage.join('');
  }

  return '';
};

export const cleanNameImage = urlImage => {
  if (typeof urlImage === 'string') {
    const spliceImage = urlImage.split('/');
    const fileName = spliceImage[spliceImage.length - 1];

    return removeImageSignature(fileName);
  }
  return '';
};

export function getCroppedImage(imageObj) {
  if (imageObj && imageObj.imagemCortada) {
    return imageObj.imagemCortada;
  }

  if (imageObj && imageObj.imagemOriginal) {
    return imageObj.imagemOriginal;
  }
}

export function invertColor(hex, bw) {
  function padZero(color) {
    if (!color) {
      return 0;
    }
    return color;
  }
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#2c2c2c' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

export function getIdYouTube(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2] ? match[2] : null;
}

export function isUrlValidYouTube(userInput) {
  // eslint-disable-next-line
  const regExp = /(http(s)?:\/\/.)?(www\.)?(youtube\.com|youtu\.?be)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;
  let res = userInput.match(regExp);
  if (res == null) {
    return false;
  } else {
    return true;
  }
}

export function transformSlug(slugNotFormated) {
  if (slugNotFormated) {
    let slugFormated = slugNotFormated.toLowerCase();
    return slugFormated
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/([^\w]+|\s+)/g, '-')
      .replace(/(^-+|-+$)/, '');
  }

  return '';
}

export function transformEventSlug(slug) {
  if (slug) {
    let newSlug = slug
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/([^\w]+|\s+)/g, '-');

    return newSlug;
  }

  return '';
}

export function convertHtmlToText(passHtmlBlock) {
  const regExp = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g;
  let str = passHtmlBlock.toString();
  return str.replace(regExp, '');
}

export function getUrlYouTube(url) {
  if (url && isUrlValidYouTube(url)) {
    const cleanUrl = convertHtmlToText(url);
    const urlFormated = getIdYouTube(cleanUrl);
    if (cleanUrl.search('embed') > 0) {
      return cleanUrl;
    } else if (urlFormated) {
      return `//www.youtube.com/embed/${urlFormated}`;
    }
  }
  return '';
}

export function transformDirection(direction) {
  return direction === 'ascend' ? 'ASC' : 'DSC';
}

export function transformSecondsToHoursAndMinutesAndSeconds(seconds) {
  const hours = Math.floor(seconds / (60 * 60));

  let rest = seconds % (60 * 60);
  const minutes = Math.floor(rest / 60);

  rest %= 60;
  const secondsRest = Math.ceil(rest);

  const hoursAndMinutesAndSeconds = {
    hours: hours,
    minutes: minutes,
    seconds: secondsRest
  };
  return hoursAndMinutesAndSeconds;
}

export function transformSecondsToHHmm(seconds) {
  if (seconds) {
    const time = transformSecondsToHoursAndMinutesAndSeconds(seconds);
    if (time.hours) {
      return `${time.hours}h${time.minutes ? `${time.minutes}min` : ''}`;
    } else if (time.minutes) {
      return `${time.minutes}min`;
    }
  }
  return `0min`;
}

export function transformSecondsToHHOrMM(seconds) {
  if (seconds) {
    const time = transformSecondsToHoursAndMinutesAndSeconds(seconds);
    if (time.hours) {
      return `${time.hours}h`;
    } else if (time.minutes) {
      return `${time.minutes}min`;
    }
  }
  return `0min`;
}

export function transformSecondsToHHmmss(seconds, showHH) {
  if (seconds) {
    return dayjs('2021-01-01')
      .second(seconds)
      .format(seconds >= 3600 || showHH ? 'HH:mm:ss' : 'mm:ss');
  }
  return showHH ? '00:00:00' : '00:00';
}

export function transformDaysToMonth(days) {
  let months = 0;
  if (days) {
    months = (days / 30).toFixed(0);
  }
  return months;
}

export function getAddress(address, fields) {
  if (address && fields && Array.isArray(fields)) {
    if (
      address.enderecoString &&
      fields.find(f => f === enderecoEnum.ENDERECO_STRING)
    ) {
      return address.enderecoString;
    }
    let addressFormat = '';
    if (address.logradouro && fields.find(f => f === enderecoEnum.LOGRADOURO)) {
      addressFormat += address.logradouro;
    }
    if (address.numero && fields.find(f => f === enderecoEnum.NUMERO)) {
      addressFormat += `, ${address.numero}`;
    }

    if (
      address.complemento &&
      fields.find(f => f === enderecoEnum.COMPLEMENTO)
    ) {
      addressFormat += ` ${address.complemento}`;
    }

    if (address.bairro && fields.find(f => f === enderecoEnum.BAIRRO)) {
      addressFormat += ` - ${address.bairro}`;
    }

    if (address.cidade && fields.find(f => f === enderecoEnum.CIDADE)) {
      addressFormat += ` - ${address.cidade}`;
    }

    if (address.estado && fields.find(f => f === enderecoEnum.ESTADO)) {
      addressFormat += `, ${address.estado}`;
    }

    return addressFormat;
  } else if (address) {
    if (address.enderecoString) {
      return address.enderecoString;
    }
    let addressFormat = '';
    if (address.logradouro) {
      addressFormat += address.logradouro;
    }
    if (address.numero) {
      addressFormat += `, ${address.numero}`;
    }

    if (address.complemento) {
      addressFormat += ` ${address.complemento}`;
    }

    if (address.bairro) {
      addressFormat += ` - ${address.bairro}`;
    }

    if (address.cidade) {
      addressFormat += ` - ${address.cidade}`;
    }

    if (address.estado) {
      addressFormat += `, ${address.estado}`;
    }

    return addressFormat;
  }
  return '';
}

export function fillBrokenCep(cep) {
  if (!cep) return;

  const cleanCep = cep.replace(/\D/g, '');

  if (cep.length >= 8) return cleanCep.replace(/(\d+)(\d{3})/, '$1-$2');

  return fillBrokenCep('0' + cleanCep);
}

export function transformDataOutputjs(data) {
  try {
    return JSON.parse(data);
  } catch {
    return '';
  }
}

export function transformEnumToArrayAndBeutify(enumValue) {
  return Object.values(enumValue).map(channel =>
    stringToPascalCase(channel.toLowerCase())
  );
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizeAllFirstLetter(string) {
  const words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
  }

  return words.join(' ');
}

export function transformDataEditorjs(data) {
  try {
    return JSON.parse(data);
  } catch {
    return {};
  }
}

export function convertToExtenso(vlr) {
  if (vlr === 0) {
    return 'zero';
  } else if (vlr === 1) {
    return 'uma';
  } else {
    var inteiro = parseInt(vlr); // parte inteira do valor
    if (inteiro < 1000000000000000) {
      var vlrS = inteiro.toString();

      var cont = vlrS.length;
      var extenso = '';
      var auxnumero;
      var auxnumero2;
      var auxnumero3;

      var unidade = [
        '',
        'uma',
        'duas',
        'três',
        'quatro',
        'cinco',
        'seis',
        'sete',
        'oito',
        'nove',
        'dez',
        'onze',
        'doze',
        'treze',
        'quatorze',
        'quinze',
        'dezesseis',
        'dezessete',
        'dezoito',
        'dezenove'
      ];

      var centena = [
        '',
        'cem',
        'duzentas',
        'trezentas',
        'quatrocentas',
        'quinhentas',
        'seiscentas',
        'setecentas',
        'oitocentas',
        'novecentas'
      ];

      var dezena = [
        '',
        '',
        'vinte',
        'trinta',
        'quarenta',
        'cinquenta',
        'sessenta',
        'setenta',
        'oitenta',
        'noventa'
      ];

      for (var i = cont; i > 0; i--) {
        auxnumero2 = '';
        auxnumero3 = '';
        auxnumero = 0;
        auxnumero2 = vlrS.substr(cont - i, 1);
        auxnumero = parseInt(auxnumero2);

        if (i === 14 || i === 11 || i === 8 || i === 5 || i === 2) {
          auxnumero2 = vlrS.substr(cont - i, 2);
          auxnumero = parseInt(auxnumero2);
        }

        if (i === 15 || i === 12 || i === 9 || i === 6 || i === 3) {
          extenso = extenso + centena[auxnumero];
          auxnumero2 = vlrS.substr(cont - i + 1, 1);
          auxnumero3 = vlrS.substr(cont - i + 2, 1);

          if (auxnumero2 !== '0' || auxnumero3 !== '0') extenso += ' e ';
        } else if (auxnumero > 19) {
          auxnumero2 = vlrS.substr(cont - i, 1);
          auxnumero = parseInt(auxnumero2);
          extenso = extenso + dezena[auxnumero];
          auxnumero3 = vlrS.substr(cont - i + 1, 1);

          if (auxnumero3 !== '0' && auxnumero2 !== '1') extenso += ' e ';
        } else if (
          auxnumero <= 19 &&
          auxnumero > 9 &&
          (i === 14 || i === 11 || i === 8 || i === 5 || i === 2)
        ) {
          extenso = extenso + unidade[auxnumero];
        } else if (
          auxnumero < 10 &&
          (i === 13 || i === 10 || i === 7 || i === 4 || i === 1)
        ) {
          auxnumero3 = vlrS.substr(cont - i - 1, 1);
          if (auxnumero3 !== '1' && auxnumero3 !== '')
            extenso = extenso + unidade[auxnumero];
        }
      }

      return extenso;
    }
  }
}
