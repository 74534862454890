export const modalidade = {
  CURSOS: 'CURSOS',
  CURSO: 'CURSO',
  CONSULTORIAS: 'CONSULTORIAS',
  SEBRAETEC_FICHA_TECNICA: 'SEBRAETEC_FICHA_TECNICA',
  CONSULTORIA: 'CONSULTORIA',
  EVENTOS: 'EVENTOS',
  EVENTO: 'EVENTO',
  SAAS_PRODUTO: 'SAAS_PRODUTO'
};

export const convertTypeToEnum = modality => {
  switch (modality) {
    case 'curso':
      return modalidade.CURSO;
    case 'consultoria':
      return modalidade.CONSULTORIA;
    case 'sebraetec':
      return modalidade.SEBRAETEC_FICHA_TECNICA;
    case 'evento':
      return modalidade.EVENTO;
    case 'saas':
      return modalidade.SAAS_PRODUTO;
    default:
      return '';
  }
};
