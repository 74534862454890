import gql from 'graphql-tag';

export const GET_VINCULOS = gql`
  query ListarVinculoPorPessoa {
    listarVinculoPorPessoa {
      id
      situacao
      pessoa {
        id
        nome
        email
        fone
        fotoUrl
        fotoUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
        capaUrl
        capaUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
        endereco {
          cep
          estado
          cidade
          bairro
          logradouro
          numero
          complemento
        }
        dataNascimento
        ocupacao
        bio {
          sobre
          descricao
        }
        redesSociais {
          ordem
          redeSocial
          nomeUsuario
        }
        experienciasProfissionais {
          ocupacao
          empresa
          estado
          cidade
        }
        referenciasWeb {
          titulo
          url
        }
        secoesPersonalizadas {
          titulo
          descricao
        }
        planoContratado {
          planoTrial
          dataContrato
          dataContratoTrial
          dataEncerramento
          dataProximaCobranca
          modalidadeContrato
          plano {
            id
            nome
            subtitulo
            descricao
            valorMensal
            valorAnual
            permiteMensal
            permiteAnual
            descricao
            beneficios {
              id
              nome
              descricao
              ativoNoPlano
            }
            principal
            ativo
          }
        }
        ultimaRevisao {
          situacao
        }
      }
      empresa {
        id
        nomeFantasia
        tipo
        fotoUrl
        fotoUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
        slug
        razaoSocial
        cnpj
        cartaoCnpj
        certidaoNegativa
        portifolioEmpresa
        videoApresentacao
        administrador {
          id
          nome
        }
        capaUrl
        capaUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
      }
      papel {
        nome
        funcionalidades {
          nome
          descricao
        }
      }
    }
  }
`;

export const GET_CURRENT_PERSON = gql`
  query ObterPessoaAtualPorUsuarioLogado {
    obterPessoaAtualPorUsuarioLogado {
      id
      nome
      email
      fone
      dataInclusao
      fotoUrl
      fotoUrlResponsive {
        url
        urlXs
        urlMd
        urlXl
        urlXxl
      }
      capaUrl
      capaUrlResponsive {
        url
        urlXs
        urlMd
        urlXl
        urlXxl
      }
      imagens {
        url
        urlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
      }
      videos
      cpf
      empresa
      slug
      endereco {
        cep
        estado
        cidade
        bairro
        logradouro
        numero
        complemento
      }
      dataNascimento
      ocupacao
      valorHora
      regiaoAtendimento
      bio {
        sobre
        descricao
      }
      redesSociais {
        ordem
        redeSocial
        nomeUsuario
      }
      experienciasProfissionais {
        ocupacao
        empresa
        estado
        cidade
      }
      referenciasWeb {
        titulo
        url
      }
      clientesAtendidos {
        nome
        fotoUrl
        fotoUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
      }
      arquivosDownload {
        nome
        url
      }
      termoUsoAceito {
        id
        tipo
        texto
        vigente
      }
      secoesPersonalizadas {
        titulo
        descricao
      }
      planoContratado {
        planoTrial
        dataContrato
        dataContratoTrial
        dataEncerramento
        dataProximaCobranca
        modalidadeContrato
        cartaoCredito {
          nomeTitular
          cpfTitular
          principal
          bandeira
          numeroCartao
        }
        plano {
          id
          nome
          descricao
          valorMensal
          valorAnual
          permiteMensal
          permiteAnual
          descricao
          beneficios {
            id
            nome
            descricao
            ativoNoPlano
            sigla
          }
          principal
          ativo
        }
      }
      ultimaRevisao {
        situacao
      }
      vinculo {
        id
        situacao
        permiteMarketingAfiliado
        permiteCupomDescontoSebrae
        permiteCampanhaDescontoSebrae
        aceiteServicosSebraeplace
        empresa {
          id
          nomeFantasia
          tipo
          fotoUrl
          fotoUrlResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
          slug
          razaoSocial
          cnpj
          cartaoCnpj
          certidaoNegativa
          portifolioEmpresa
          videoApresentacao
          capaUrl
          capaUrlResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
          email
          fone
          dataInclusao
          contratoUrl
          dataExpiracaoContrato
          inscricaoEstadual
          certidaoFgts
          certidaoFgtsVencimento
          certidaoInss
          certidaoInssVencimento
          administrador {
            id
            nome
          }
          cidadesHabilitadas {
            id
            municipio
            uf
          }
          endereco {
            cep
            estado
            cidade
            bairro
            logradouro
            numero
            complemento
          }
          redesSociais {
            ordem
            redeSocial
            nomeUsuario
          }
          referenciasWeb {
            titulo
            url
          }
          secoesPersonalizadas {
            titulo
            descricao
          }
          bio {
            sobre
            descricao
          }
          planoContratado {
            planoTrial
            dataContrato
            dataContratoTrial
            dataEncerramento
            dataProximaCobranca
            modalidadeContrato
            plano {
              subtitulo
              id
              nome
              descricao
              valorMensal
              valorAnual
              permiteMensal
              permiteAnual
              descricao
              beneficios {
                id
                nome
                descricao
                ativoNoPlano
              }
              principal
              ativo
            }
          }
          ultimaRevisao {
            situacao
          }
        }
        papel {
          nome
          id
        }
        termoUsoAceito {
          id
          tipo
          vigente
        }
      }
      afiliacao {
        mostrarTutorial
      }
      alcanceRedeSocial {
        id
        minValue
        maxValue
        ativo
      }
      canaisDivulgacao
    }
  }
`;

export const OBTER_QTD_ORCAMENTOS_SEBRAETEC_AGUARDANDO = gql`
  query QtdOrcamentosSebraetecAguardando {
    qtdOrcamentosSebraetecAguardando
  }
`;

export const LISTAR_EMPRESAS_USUARIO_LOGADO = gql`
  query ObterEmpresasPorUsuarioLogado {
    obterEmpresasPorUsuarioLogado {
      codigo
      nome
      cnpj
      fone
      razaoSocial
      nomeFantasia
      email
      porte
      endereco {
        cep
        logradouro
        numero
        complemento
        bairro
        cidade
        estado
      }
    }
  }
`;

export const OBTER_CONFIGURACAO_POR_NOME = gql`
  query ObterConfiguracaoPorNome($configuracaoEnum: ConfiguracaoEnum!) {
    obterConfiguracaoPorNome(configuracaoNome: $configuracaoEnum) {
      nome
      tipo
      descricao
      valor {
        valorBoolean
      }
    }
  }
`;
