import styled, { css } from 'styled-components';
import { modalidade } from '../../../../shared/util/enums/modalidadeCatalogoEnum';

export const Container = styled.div`
  position: relative;
  box-shadow: 0px 0px 8px var(--color-card-shadow);
  border-radius: 3px;

  .extra-info {
    background-color: var(--text-color);
    color: var(--color-white-gray);
    border-bottom-left-radius: ${props => (props.formatList ? '0px' : '3px')};
    border-bottom-right-radius: 3px;
    height: 104px;
  }
  .icon-play {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 12px;
    width: 52px;
    height: 52px;
    opacity: 1;
    background: var(--color-black-transparent);
    backdrop-filter: blur(5px);
  }

  .line-price {
    border-bottom: 0px solid var(--color-gray);
  }

  .text-de {
    color: var(--color-gray);
  }

  .text-sebraeplace-price {
    font: normal normal 300 14px/25px Open Sans, sans-serif;
    letter-spacing: 0.7px;
    color: var(--color-white);
    opacity: 1;
  }

  :hover {
    box-shadow: 0px 0px 8px var(--color-card-shadow);
  }

  :hover .extra-info {
    background-color: var(--color-primary);
  }

  .produto-title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .img-produtos {
    object-fit: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: ${props => (props.formatList ? '3px' : '0px')};
    border-top-right-radius: ${props => (props.formatList ? '0px' : '3px')};
    width: 100%;
    height: ${props => (props.formatList ? '263px' : '114px')};
  }

  .valor-position {
    display: flex;
    flex-direction: column;
  }

  .valor-style {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif !important;
  }

  .valor-anterior-style {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.7px;
    font-family: 'Open Sans', sans-serif !important;
    color: var(--color-white-gray);
  }

  .duracao {
    font-size: 14px;
    color: var(--color-white-gray);
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif !important;
  }

  .avatar-comp {
    position: absolute;
    bottom: ${props => (props.formatList ? '10px' : '-15px')};
    right: 10px;
    border: 1px solid var(--color-white);
    z-index: 1;
  }

  .container-modalidade {
    width: calc(100% - 2rem);
    position: ${props => (props.formatList ? 'relative' : 'absolute')};
    top: ${props =>
      props.formatList
        ? 'unset'
        : props.item.tipo === modalidade.EVENTOS ||
          props.item.tipo === modalidade.EVENTO
        ? '-24px'
        : '-12px'};
    display: flex;
    justify-content: ${props => (props.formatList ? 'left' : 'center')};
    margin-bottom: 0.5rem;
  }

  .modalidade {
    background: ${props =>
      props.item.tipo === modalidade.CONSULTORIAS ||
      props.item.tipo === modalidade.CONSULTORIA
        ? 'var(--color-consultoria)'
        : props.item.tipo === 'SAAS_PRODUTO'
        ? 'var(--color-solucao-digital)'
        : props.item.tipo === 'CURSO'
        ? 'var(--color-curso-online)'
        : 'var(--color-primary-dark)'};
    text-align: center;
    border-radius: 3px;
    color: var(--color-white);
    padding: 2px 5px;
    max-width: ${props =>
      props.item.tipo === modalidade.EVENTOS ||
      props.item.tipo === modalidade.EVENTO
        ? '88px'
        : ''};
  }

  .classificacao {
    background: ${props =>
      props.item.tipo === modalidade.EVENTO ||
      props.item.tipo === modalidade.EVENTOS
        ? props.item.classificacao.cor
        : 'var(--color-primary)'};
    border-radius: 3px;
    text-align: center;
    color: var(--color-white);
    padding: 2px 5px;
  }

  .container-classificacao {
    position: relative;
    display: flex;
    justify-content: left;
  }

  .icon-heart {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 0px 3px 0px 0px;
    text-align: end;
    height: 88px;
    width: 88px;
    /* stylelint-disable-next-line */
    background: transparent linear-gradient(223deg, #2c2c2cd0 0%, #2c2c2c00 43%)
      0% 0% no-repeat padding-box;
  }

  .date-card {
    position: absolute;
    top: 16px;
    left: 18px;
    width: 53px;
    height: 53px;
    background-color: #ffffff88; /* stylelint-disable-line */
    box-shadow: 0px 0px 99px var(--color-card-shadow);
    border: 1px solid var(--color-white-gray);
    border-radius: 14px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px); /* stylelint-disable-line */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .month {
    position: absolute;
    font-size: 11px;
    line-height: 1;
    top: 10px;
  }

  .hora-style {
    font-size: 14px;
    font-weight: 100;
    font-family: 'Open Sans', sans-serif;
    color: var(--color-white-gray);
    letter-spacing: 0.7px;
  }

  .container-modalidade-selo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ant-rate {
    color: var(--color-primary);
  }

  .texto-modalidade {
    margin-bottom: 0;
  }

  .container-icon-modalidade-selo,
  .container-avaliacao {
    display: flex;
    justify-content: start;
    min-height: 39px;
    max-width: ${props =>
      props.formatList ? 'calc(100% - 75px);' : 'calc(100% - 25px);'};
  }

  .container-icon-modalidade-selo {
    align-items: start;
  }

  .container-avaliacao {
    justify-content: space-between;
  }

  .icon-selo,
  .items-catalogo__p-background {
    position: absolute;
    right: 15px;
    width: 28px;
    height: 28px;
  }

  .icon-selo-format {
    position: absolute;
  }

  .items-catalogo__p-background {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #93c01f; /* stylelint-disable-line */
  }

  .know-more {
    color: var(--color-primary);
    font-size: 11px;
    cursor: pointer;
  }

  .plan-style {
    display: flex;
    font-size: 11px;
    font-weight: 100;
    font-family: 'Open Sans', sans-serif;
    color: var(--color-white-gray);
    letter-spacing: 0.7px;
  }

  .plan-style-valor {
    display: flex;
    font-size: 14px;
    font-weight: 100;
    font-family: 'Open Sans', sans-serif;
    color: var(--color-white-gray);
    letter-spacing: 0.7px;
    margin-bottom: 4px;
  }

  .real-price {
    font-size: 14px;
  }

  .real-price-valor {
    font-family: 'open-sans', sans-serif !important;
    letter-spacing: 1px;
    font-size: 20px;
  }

  .plan-button {
    font-size: 11px;
  }

  .line-price-value {
    text-decoration: line-through;
  }

  .product-course__percent-discount {
    background: var(--color-success);
    color: var(--color-white);
    bottom: 26px;
    right: 0;
    width: 150px;
    border-top-left-radius: 16px;
    font-size: 0.875rem;
  }

  .product-course__percent {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .no-price__description {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .no-price__button {
    background: transparent;
    border: 1px solid;
  }

  @media (min-width: 768px) {
    .product-course__percent-discount {
      ${props =>
        props.formatList &&
        css`
          right: unset;
          left: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 16px;
          bottom: 16px;
        `};
    }
  }
`;
