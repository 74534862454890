import Icon from '@ant-design/icons';
import { Carousel, Col, Row, Typography } from 'antd';
import React, { useContext, useRef } from 'react';
import i18n from '../../../../shared/util/i18n';
import { Button } from '../../../../components/LinkComponent/LinkComponent';
import { ReactComponent as CaretLeftCircle } from '../../../../images/svgs/caret-left-circle.svg';
import { ReactComponent as CaretRightCircle } from '../../../../images/svgs/caret-right-circle.svg';

import { Container } from './styles';
import ItemCarouselBeASupplier from './components/ItemCarouselBeASupplier/ItemCarouselBeASupplier';
import { ContextPerson } from '../../../../store/contexts/contextPerson';

const { Title, Paragraph } = Typography;

function SecaoSejaFornecedor() {
  const carouselRef = useRef(null);
  const redirectHome = () => '/homologado';

  const { habilitarCursosPresenciais } = useContext(ContextPerson);

  return (
    <Container className='mt-5 pt-5'>
      <Row className='ant-row-flex' align='middle' justify='center'>
        <Col xs={24} md={12} className='be-a-supplier__conteiner-description'>
          <div className='container container-without-right-margin d-flex flex-column flex-fill justify-content-center '>
            <Title
              strong
              level={3}
              type='white'
              className='be-a-supplier__title mb-0 text-center text-md-left'>
              {i18n.t('HOME_SECTION_5_TITLE')}
            </Title>
            <Paragraph
              className='be-a-supplier__description mt-3 text-left'
              type='white'>
              {i18n.t('BE_A_SUPPLIER_HOME_INFO')}
            </Paragraph>
          </div>
        </Col>
        <Col xs={24} md={12} className='be-a-supplier__container-corousel'>
          <div className='px-2 pr-md-0 pl-md-3 pl-lg-5 container-without-left-margin'>
            <div className='d-flex justify-content-end pb-5'>
              <Icon
                component={CaretLeftCircle}
                onClick={() => {
                  if (carouselRef.current) carouselRef.current.prev();
                }}
                style={{
                  fontSize: 32,
                  color: 'var(--color-primary)'
                }}
              />
              <Icon
                component={CaretRightCircle}
                onClick={() => {
                  if (carouselRef.current) carouselRef.current.next();
                }}
                style={{
                  marginLeft: '15px',
                  color: 'var(--color-primary)',
                  fontSize: 32
                }}
              />
            </div>
            <Carousel ref={carouselRef}>
              <ItemCarouselBeASupplier
                title={i18n.t('SEBRAETEC')}
                description={i18n.t('SEBRAETEC_HOME_INFO')}
                isCapitalize>
                <Col span={24} className='text-center'>
                  <Button
                    type='primary'
                    shape='round'
                    className='wide mb-3 mr-xl-3'
                    href={redirectHome()}>
                    {i18n.t('I_WANT_TO_BE_SUPPLIER')}
                  </Button>
                  <Button
                    type='primary'
                    shape='round'
                    ghost
                    className='wide item-carousel__btn-know-more'
                    href='/sebraetec'>
                    {i18n.t('KNOW_MORE')}
                  </Button>
                </Col>
              </ItemCarouselBeASupplier>
              <ItemCarouselBeASupplier
                title={i18n.t('ONLINE_COURSES_TITLE')}
                description={i18n.t('ONLINE_COURSES_HOME_INFO')}
                isCapitalize>
                <Row
                  justify='center'
                  align='middle'
                  className='ant-row-flex mb-2 mb-md-5 text-center text-md-left'>
                  <Col span={24} className='text-center'>
                    <Button
                      type='primary'
                      shape='round'
                      className='wide mb-3 mr-xl-3'
                      href={redirectHome()}>
                      {i18n.t('I_WANT_TO_BE_SUPPLIER')}
                    </Button>
                    <Button
                      type='primary'
                      shape='round'
                      ghost
                      className='wide item-carousel__btn-know-more'
                      href='/cursos-online'>
                      {i18n.t('KNOW_MORE')}
                    </Button>
                  </Col>
                </Row>
              </ItemCarouselBeASupplier>
              <ItemCarouselBeASupplier
                title={i18n.t('CONSULTING_TITLE')}
                description={i18n.t('CONSULTANCY_HOME_INFO')}
                isCapitalize>
                <Row
                  justify='center'
                  align='middle'
                  className='ant-row-flex mb-2 mb-md-5 text-center text-md-left'>
                  <Col span={24} className='text-center'>
                    <Button
                      type='primary'
                      shape='round'
                      className='wide'
                      href={redirectHome()}>
                      {i18n.t('I_WANT_TO_BE_SUPPLIER')}
                    </Button>
                  </Col>
                </Row>
              </ItemCarouselBeASupplier>
              {habilitarCursosPresenciais && (
                <ItemCarouselBeASupplier
                  title={i18n.t('COURSE_PRESENCIAL_EVENTS_CARD')}
                  description={i18n.t(
                    'COURSE_PRESENCIAL_EVENTS_CARD_HOME_INFO'
                  )}>
                  <Row
                    justify='center'
                    align='middle'
                    className='ant-row-flex mb-2 mb-md-5 text-center text-md-left'>
                    <Col span={24} className='text-center'>
                      <Button
                        type='primary'
                        shape='round'
                        className='wide mb-3 mr-xl-3'
                        href={redirectHome()}>
                        {i18n.t('I_WANT_TO_BE_SUPPLIER')}
                      </Button>
                    </Col>
                  </Row>
                </ItemCarouselBeASupplier>
              )}
              <ItemCarouselBeASupplier
                title={i18n.t('SAAS')}
                description={i18n.t('SAAS_HOME_INFO')}
                isCapitalize>
                <Row
                  justify='center'
                  align='middle'
                  className='ant-row-flex mb-2 mb-md-5 text-center text-md-left'>
                  <Col span={24} className='text-center'>
                    <Button
                      type='primary'
                      shape='round'
                      className='wide mb-3 mr-xl-3'
                      href={redirectHome()}>
                      {i18n.t('I_WANT_TO_BE_SUPPLIER')}
                    </Button>
                    <Button
                      type='primary'
                      shape='round'
                      ghost
                      className='wide item-carousel__btn-know-more'
                      href='/solucao-inteligente'>
                      {i18n.t('KNOW_MORE')}
                    </Button>
                  </Col>
                </Row>
              </ItemCarouselBeASupplier>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SecaoSejaFornecedor;
