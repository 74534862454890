import dayjs from 'dayjs';
import i18n from '../../shared/util/i18n';
import { TipoNegociacao } from './enums/negociacaoEnums';
import {
  negociacoesCanceladas,
  negociacoesFeitoPagamento
} from './negotiationUtils';
import { defaultObjSupportSebrae } from './suporteSebraeUtils';
import { normalizeValueOrNull } from './transfoms';

export function validatorPhone(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');

    if (value.length === 11) {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
  return Promise.reject('Número inválido. ');
}

export function validatorYear(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length === 4) {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
  return Promise.reject('Ano inválido. ');
}

export function validatorMaxYearCurrent(value) {
  const currentYear = new Date().getFullYear();
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length === 4) {
      const year = Number(value);
      if (year <= currentYear) {
        return Promise.resolve();
      }
    }
  } else {
    return Promise.resolve();
  }
  return Promise.reject('Ano deve ser menor ou igual a ' + currentYear + '. ');
}

export function validatorCPF(value) {
  const error = 'CPF inválido. ';
  let strCPF;
  if (value) {
    strCPF = value;
  } else {
    return Promise.resolve();
  }
  if (strCPF) {
    strCPF = strCPF.toString();
    strCPF = strCPF.replace(/\D/g, '');
    let some;
    let rest;
    some = 0;
    if (strCPF === '00000000000') {
      return Promise.reject(error);
    } else if (strCPF === '11111111111') {
      return Promise.reject(error);
    } else if (strCPF === '22222222222') {
      return Promise.reject(error);
    } else if (strCPF === '33333333333') {
      return Promise.reject(error);
    } else if (strCPF === '44444444444') {
      return Promise.reject(error);
    } else if (strCPF === '55555555555') {
      return Promise.reject(error);
    } else if (strCPF === '66666666666') {
      return Promise.reject(error);
    } else if (strCPF === '77777777777') {
      return Promise.reject(error);
    } else if (strCPF === '88888888888') {
      return Promise.reject(error);
    } else if (strCPF === '99999999999') {
      return Promise.reject(error);
    }

    for (let i = 1; i <= 9; i++) {
      some = some + parseInt(strCPF.substring(i - 1, i), 0) * (11 - i);
    }
    rest = (some * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(strCPF.substring(9, 10), 0)) {
      return Promise.reject(error);
    }

    some = 0;
    for (let i2 = 1; i2 <= 10; i2++) {
      some = some + parseInt(strCPF.substring(i2 - 1, i2), 0) * (12 - i2);
    }
    rest = (some * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(strCPF.substring(10, 11), 0)) {
      return Promise.reject(error);
    }
    return Promise.resolve();
  }
  return Promise.reject(error);
}

export function validatorCNPJ(value, validateCadpro = false) {
  let cnpj = value;
  const error = validateCadpro ? 'CNPJ/CADPRO inválido' : 'CNPJ inválido ';
  const validate = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let dig1 = 0;
  let dig2 = 0;

  if (!cnpj || !cnpj.toString()) {
    return Promise.resolve();
  }

  if (validateCadpro && cnpj.length === 10) return Promise.resolve();

  cnpj = cnpj.toString().replace(/\.|-|\//g, '');
  const digito = parseInt(cnpj.charAt(12) + cnpj.charAt(13), 0);

  for (let i = 0; i < validate.length; i++) {
    dig1 += i > 0 ? parseInt(cnpj.charAt(i - 1), 0) * validate[i] : 0;
    dig2 += parseInt(cnpj.charAt(i), 0) * validate[i];
  }

  dig1 = dig1 % 11 < 2 ? 0 : 11 - (dig1 % 11);
  dig2 = dig2 % 11 < 2 ? 0 : 11 - (dig2 % 11);

  if (dig1 * 10 + dig2 !== digito) {
    return Promise.reject(error);
  }
  return Promise.resolve();
}

export function validatorCEP(value) {
  let cep = value;
  const error = 'CEP inválido. ';
  if (!cep || !cep.toString()) {
    return Promise.resolve();
  }
  cep = cep.toString().replace(/\D/g, '');

  if (cep.length === 8) {
    return Promise.resolve();
  }
  return Promise.reject(error);
}

export function validatorDate(value) {
  let date = value;
  const error = 'Data de nascimento inválida. ';
  if (!date || !date.toString()) {
    return Promise.resolve();
  }

  date = date.toString().replace(/\D/g, '');

  if (date.length === 8) {
    var dateSplit = value.split('/');
    var validDate = new Date(
      parseInt(dateSplit[2]),
      parseInt(dateSplit[1]) - 1,
      parseInt(dateSplit[0])
    );

    if (
      validDate.getDate() === parseInt(dateSplit[0]) &&
      validDate.getMonth() + 1 === parseInt(dateSplit[1]) &&
      validDate.getFullYear() === parseInt(dateSplit[2])
    ) {
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

export function validatorDateGreaterThanToday(value) {
  if (!value) {
    return Promise.resolve();
  }

  const date = dayjs(value, 'DD/MM/YYYY', true);

  if (!date.isValid()) {
    return Promise.reject('Data inválida.');
  }

  if (!date.isAfter(dayjs(), 'day')) {
    return Promise.reject('Data deve ser maior que hoje.');
  }

  return Promise.resolve();
}

export function validatorCheckBox(value) {
  const error = 'É necessário aceitar este campo. ';

  if (value) {
    return Promise.resolve();
  }

  return Promise.reject(error);
}

export function isEmailValido(value) {
  let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (value) return regExp.test(value);
  else return false;
}

export function isTelefoneValido(value) {
  let regExp = /\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}/;
  if (value) return regExp.test(value);
  else return false;
}

export function validatorTimeHHMM(value) {
  if (value) {
    if (value.length >= 3 && value.length <= 5) {
      const HHMM = value.split(':');
      if (
        HHMM &&
        HHMM.length > 0 &&
        HHMM[0] >= 0 &&
        HHMM[0] <= 23 &&
        HHMM[1] >= 0 &&
        HHMM[1] <= 59
      ) {
        return Promise.resolve();
      }
    }
  } else {
    return Promise.resolve();
  }
  return Promise.reject('Formato de hora inválido(HH:MM).');
}

export function isValidateTimeHHMM(time) {
  const hoursAndMinutes = time.split(':');
  return (
    hoursAndMinutes &&
    hoursAndMinutes.length > 0 &&
    hoursAndMinutes[0] &&
    hoursAndMinutes[1] &&
    Number(hoursAndMinutes[0]) >= 0 &&
    Number(hoursAndMinutes[0]) <= 23 &&
    Number(hoursAndMinutes[1]) >= 0 &&
    Number(hoursAndMinutes[1]) <= 59
  );
}

export function validateIfStartTimeIsLessThanEndTime(timeStart, timeEnd) {
  const hoursAndMinutesStart = timeStart.split(':');
  const hoursAndMinutesEnd = timeEnd.split(':');
  const minutesStart = hoursAndMinutesStart[0] * 60 + hoursAndMinutesStart[1];
  const minutesEnd = hoursAndMinutesEnd[0] * 60 + hoursAndMinutesEnd[1];
  return Number(minutesStart) <= Number(minutesEnd);
}

export function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}
export const canCancelNegotiation = negotiation => {
  if (negociacoesCanceladas.includes(negotiation.status)) {
    return false;
  }

  if (
    negotiation.tipo === TipoNegociacao.CONSULTORIA &&
    negociacoesFeitoPagamento.includes(negotiation.status)
  ) {
    return false;
  }

  if (negotiation.tipo === TipoNegociacao.SAAS_CONTRATO) {
    return false;
  }

  if (negotiation.tipo === defaultObjSupportSebrae.SEBRAE) {
    return false;
  }

  if (negotiation.tipo === TipoNegociacao.SEBRAETEC) {
    return false;
  }

  if (
    negotiation.tipo === TipoNegociacao.PRODUTO &&
    negotiation.evento &&
    negotiation.evento.agenda &&
    dayjs().isAfter(
      negotiation &&
        negotiation.evento &&
        negotiation.evento.agenda &&
        negotiation.evento.agenda[0] &&
        negotiation.evento.agenda[0].dataHoraInicial,
      'hours'
    )
  ) {
    return false;
  }

  return true;
};

export function validatorDateBetween(dates, startDate, endDate) {
  if (!dates) {
    return Promise.resolve();
  }
  if (!dayjs(dates[0]).isBetween(startDate, endDate, 'day', '[]')) {
    return Promise.reject(
      `Data início deve estar entre as datas 
      ${startDate.format('DD/MM/YYYY')} a  ${endDate.format('DD/MM/YYYY')}`
    );
  }

  if (!dayjs(dates[1]).isBetween(startDate, endDate, 'day', '[]')) {
    return Promise.reject(
      `Data término deve estar entre as datas 
      ${startDate.format('DD/MM/YYYY')} a  ${endDate.format('DD/MM/YYYY')}`
    );
  }
  return Promise.resolve();
}

export const validatorAgency = (value = '', agencyHasFiveDigits) => {
  try {
    const length = value.replace(/\D+/g, '').length;
    if (
      (!agencyHasFiveDigits && length === 4) ||
      (agencyHasFiveDigits && length === 5)
    ) {
      return Promise.resolve();
    }
    if (agencyHasFiveDigits) {
      return Promise.reject(i18n.t('AGENCY_WITH_VERIFYING_DIGIT'));
    } else {
      return Promise.reject(i18n.t('AGENCY_WITHOUT_VERIFYING_DIGIT'));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const validatorAccount = (
  value,
  checkDigitNumberPosition,
  cef = false
) => {
  try {
    const length = value.replace(/\D+/g, '').length;
    if (length > checkDigitNumberPosition) {
      return Promise.resolve();
    }
    if (checkDigitNumberPosition === 0) {
      return Promise.resolve();
    } else if (checkDigitNumberPosition >= length) {
      if (cef) {
        return Promise.reject(
          i18n.t('ACCOUNT_WITH_VERIFYING_DIGIT_PLUS_OPERATION')
        );
      } else {
        return Promise.reject(i18n.t('ACCOUNT_WITH_VERIFYING_DIGIT'));
      }
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export function validatorMaxValue(value, maxValue, fieldName) {
  let error = `Valor do campo deve ser menor ou igual a ${
    maxValue || maxValue > 0 ? maxValue : '0'
  }`;

  if (!maxValue && fieldName) {
    error = `Valor do campo deve ser menor ou igual ao campo ${fieldName}`;
  }

  if (value || value === 0) {
    const valueInteger = parseInt(value);
    if (valueInteger <= maxValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorMaxValueCurrency(
  value,
  maxValue,
  fieldName,
  messageError
) {
  value = normalizeValueOrNull(value);
  maxValue = normalizeValueOrNull(maxValue);

  let error = `${i18n.t('FILL_VALUE_LOWER')} ${
    maxValue || maxValue > 0 ? maxValue : '0'
  }`;

  if (!maxValue && fieldName) {
    error = `${i18n.t('FILL_VALUE_LOWER_FIELD')} ${fieldName}`;
  }

  if (messageError) {
    error = messageError;
  }

  if (value || value > 0) {
    if (value < maxValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorMaxOrEqualValueCurrency(
  value,
  maxValue,
  fieldName,
  messageError
) {
  value = normalizeValueOrNull(value);
  maxValue = normalizeValueOrNull(maxValue);

  let error = `${i18n.t('FILL_VALUE_LOWER_OR_EQUAL')} ${
    maxValue || maxValue > 0 ? maxValue : '0'
  }`;

  if (!maxValue && fieldName) {
    error = `${i18n.t('FILL_VALUE_LOWER_OR_EQUAL_FIELD')} ${fieldName}`;
  }

  if (messageError) {
    error = messageError;
  }

  if (value || value > 0) {
    if (value <= maxValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorMinValue(value, minValue, fieldName) {
  let error = `Valor do campo deve ser maior ou igual a ${
    minValue || minValue === 0 ? minValue : 0
  }`;

  if (!minValue && fieldName) {
    error = `Valor do campo deve ser maior ou igual ao campo ${fieldName}`;
  }

  if (value || value === 0) {
    const valueInteger = parseInt(value);
    if (valueInteger >= minValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorMinValueCurrency(
  value,
  minValue,
  fieldName,
  messageError
) {
  value = normalizeValueOrNull(value);
  minValue = normalizeValueOrNull(minValue);
  let error = `Valor do campo deve ser maior  ou igual a ${
    minValue || minValue === 0 ? minValue : 0
  }`;

  if (!minValue && fieldName) {
    error = `Valor do campo deve ser maior ou igual ao campo ${fieldName}.`;
  }

  if (messageError) {
    error = messageError;
  }

  if (value || value > 0) {
    if (value > minValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorValueGreaterThan(value, minValue) {
  const error = `${i18n.t('FIELD_VALUE_MUST_BE_GREATER_THAN')} ${
    minValue ? minValue : 0
  }`;

  if (value || value === 0) {
    const valueInteger = parseInt(value);
    if (valueInteger > minValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorValueGreaterThanCurrency(value, minValue) {
  value = normalizeValueOrNull(value);
  const error = `Valor do campo deve ser maior que ${minValue ? minValue : 0}.`;

  if (value || value === 0) {
    if (value > minValue) {
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  return Promise.resolve();
}

export function validatorPreco(valueFloat, minValue, minValueFormatted) {
  if (valueFloat >= minValue) {
    return Promise.resolve();
  }
  return Promise.reject(
    `${i18n.t('FILL_VALUE_EQUAL_HIGHER')} ${minValueFormatted}`
  );
}

export function isCreditCardValid(value) {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  let nCheck = 0;
  let bEven = false;
  value = value.replace(/\D/g, '');

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

export function strzero(dado) {
  let varTemp = dado;
  varTemp = varTemp.replace('-', '');
  varTemp = varTemp.replace('.', '');
  return varTemp;
}

export function validatorInscricaoEstadual(value) {
  const error = 'Inscrição estadual inválida';

  if (value) {
    let isInscEstValid = /^[0-9]*$/.test(value);

    if (isInscEstValid) {
      if (value.length < 8) {
        return Promise.reject(error);
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.resolve();
  }
}

export function checkErrorsInForm(errorObjectFromForm) {
  if (errorObjectFromForm) {
    return Object.keys(errorObjectFromForm).some(field => {
      if (
        errorObjectFromForm[field].errors &&
        errorObjectFromForm[field].errors.length > 0
      ) {
        return true;
      }

      return false;
    });
  }

  return false;
}
