import gql from 'graphql-tag';

// TODO: PEGAR A QUERY CORRETA QUANDO FOR CRIADA A ESPECIFICA PARA EVENTOS
export const EVENTOS_QUERY = gql`
  query($limit: Int) {
    obterProdutosAprovados(limit: $limit) {
      id
      nome
      propostaValor
      preco
      fotoUrl
      pessoa {
        fotoUrl
        nome
        email
      }
    }
  }
`;

export const GET_CONSULTORIAS_SIMILARES = gql`
  query listarConsultoriasSimilares($catalogoInput: CatalogoInput) {
    listarConsultoriasSimilares(catalogoInput: $catalogoInput) {
      id
      nome
      areas {
        nome
      }
      nome
      valorHora
      mediaAvaliacao
      fotoUrl
      pessoa {
        nome
        fotoUrl
        endereco {
          cep
          estado
          cidade
          bairro
          logradouro
          numero
          complemento
        }
      }
      empresa {
        nomeFantasia
        fotoUrl
      }
    }
  }
`;

export const GET_LISTAR_PRODUTOS_HOME = gql`
  query ListarProdutosHome($limit: Int) {
    listarProdutosHome(limit: $limit) {
      id
      nome
      slug
      preco
      modalidadePreco
      fotoUrl
      fotoUrlResponsive {
        url
        urlXs
        urlMd
        urlXl
        urlXxl
      }
      imagem {
        imagemOriginal
        imagemCortada
        imagemResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
      }
      pessoa {
        id
        nome
        fotoUrl
        fotoUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
        endereco {
          cep
          estado
          cidade
          bairro
          logradouro
          numero
          complemento
        }
      }
      empresa {
        nomeFantasia
        fotoUrl
        fotoUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
      }
      classificacao {
        nome
        cor
        descricao
        slug
      }
      area {
        nome
        slug
        ativo
      }
      areas {
        nome
        slug
        ativo
      }
      mediaAvaliacao
      tipo
      categoria
      cargaHoraria
      quantidadeSegundos
      valor
      valorFinal
      valorDesconto
      valorMercado
      consultoria {
        fotoUrl
        fotoUrlResponsive {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
      }
      sebraetecFichaTecnica {
        id
        slug
        nome
        isFavorito
        mediaAvaliacao
        preco
        imgUrl {
          url
          urlXs
          urlMd
          urlXl
          urlXxl
        }
        tema {
          id
          nome
        }
        subtema {
          id
          nome
        }
      }
      saasProduto {
        id
        nome
        slug
        situacao
        mediaAvaliacao
        preco
        precoMercado
        pessoa {
          id
          nome
        }
        empresa {
          id
          razaoSocial
        }
        landingPage {
          secaoCabecalho {
            descricao
            imagem {
              imagemOriginal
              imagemCortada
              imagemResponsive {
                url
                urlXs
                urlMd
                urlXl
                urlXxl
              }
            }
            descricao
          }
        }
        categorias {
          id
          slug
        }
        segmentos {
          id
          slug
        }
        portes {
          id
          slug
        }
      }
      evento {
        id
        nome
        dataInicial
        pessoa {
          id
          nome
          fotoUrl
          fotoUrlResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
          endereco {
            cep
            estado
            cidade
            bairro
            logradouro
            numero
            complemento
          }
        }
        empresa {
          nomeFantasia
          fotoUrl
          fotoUrlResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
        }
        imagensCapa {
          url
          urlResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
        }
        temas {
          id
          nome
          slug
        }
        classificacao {
          nome
          cor
          descricao
          slug
        }
        loteIngresso {
          preco
        }
        isFavorito
        mediaAvaliacao
        endereco {
          cep
          logradouro
          numero
          complemento
          bairro
          cidade
          estado
          lat
          lng
          enderecoString
        }
      }
      curso {
        id
        nome
        slug
        isFavorito
        categoria {
          nome
        }
        subcategoria {
          nome
        }
        imagemCapa {
          imagemOriginal
          imagemCortada
          imagemResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
        }
        pessoa {
          id
          nome
          fotoUrl
          fotoUrlResponsive {
            url
            urlXs
            urlMd
            urlXl
            urlXxl
          }
        }
        mediaAvaliacao
        valor
        valorFinal
        valorMercado
        quantidadeSegundos
        isGratuito
        campanhaDesconto {
          percentualDesconto
        }
      }
    }
  }
`;

export const ARE_FAVORITOS = gql`
  query areFavoritos($entidadesId: [String!]!) {
    areFavoritos(entidadesId: $entidadesId) {
      id
      isFavorito
      consultoria {
        id
      }
      evento {
        id
      }
      curso {
        id
      }
    }
  }
`;

export const ALTERNAR_CONSULTORIA_FAVORITA = gql`
  mutation AlternarConsultoriaFavorita($consultoriaId: String!) {
    alternarConsultoriaFavorita(consultoriaId: $consultoriaId)
  }
`;

export const ALTERNAR_EVENTO_FAVORITO = gql`
  mutation AlternarEventoFavorito($eventoId: String!) {
    alternarEventoFavorito(eventoId: $eventoId)
  }
`;

export const GET_INDICADORES_PRODUTOS_HOME = gql`
  query GetIndicadoresProdutosHome {
    getIndicadoresProdutosHome {
      possuiConsultorias
      possuiEventos
      possuiSolucoesDigitais
      possuiCursosOnline
      possuiVinculosSebraetec
    }
  }
`;
