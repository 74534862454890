/* eslint-disable no-restricted-syntax */
import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import RemoveIndexFromRoute from './removeIndexFromRoute';
import { ContextPerson } from '../../store/contexts/contextPerson';

export const ProtectedPainelRoute = ({ role, children, ...rest }) => {
  const { pessoa } = useContext(ContextPerson);
  const history = useHistory();

  const verifyRouteRole = () => {
    if (role === 'FORNECEDOR') {
      return verifyRouteRoleFornecedor();
    } else if (role === 'LICENCIADO') {
      return verifyRouteRoleLicenciado();
    } else {
      return verifyRouteRoleCliente();
    }
  };

  const verifyRouteRoleFornecedor = () => {
    if (pessoa.vinculo && pessoa.vinculo.empresa) {
      if (pessoa.vinculo.empresa.tipo === 'FORNECEDOR') {
        return (
          <React.Fragment>
            <RemoveIndexFromRoute />
            {children}
          </React.Fragment>
        );
      }
      if (pessoa.vinculo.empresa.tipo === 'LICENCIADO') {
        history.push('/painel/licenciado');
        return <></>;
      }
    } else {
      history.push('/painel/cliente');
      return <></>;
    }
  };

  const verifyRouteRoleLicenciado = () => {
    if (pessoa.vinculo && pessoa.vinculo.empresa) {
      if (pessoa.vinculo.empresa.tipo === 'LICENCIADO') {
        return (
          <React.Fragment>
            <RemoveIndexFromRoute />
            {children}
          </React.Fragment>
        );
      }
      if (pessoa.vinculo.empresa.tipo === 'FORNECEDOR') {
        history.push('/painel/empresa');
        return <></>;
      }
    } else {
      history.push('/painel/cliente');
      return <></>;
    }
  };

  const verifyRouteRoleCliente = () => {
    if (pessoa.vinculo && pessoa.vinculo.empresa) {
      if (pessoa.vinculo.empresa.tipo === 'LICENCIADO') {
        history.push('/painel/licenciado');
        return <></>;
      }
      if (pessoa.vinculo.empresa.tipo === 'FORNECEDOR') {
        history.push('/painel/empresa');
        return <></>;
      }
    } else {
      return (
        <React.Fragment>
          <RemoveIndexFromRoute />
          {children}
        </React.Fragment>
      );
    }
  };

  return (
    <>
      {pessoa && pessoa.id && history && (
        <Route
          {...rest}
          render={() => {
            return verifyRouteRole();
          }}
        />
      )}
    </>
  );
};
