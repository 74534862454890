export const generateSrcSet = imgResponsive => {
  let urls = ``;

  if (imgResponsive) {
    if (imgResponsive.urlXs) {
      urls = `${imgResponsive.urlXs} 476w`;
    }
    if (imgResponsive.urlMd) {
      urls = `${urls ? `${urls}, ` : ''}${imgResponsive.urlMd} 768w`;
    }
    if (imgResponsive.urlXl) {
      urls = `${urls ? `${urls}, ` : ''}${imgResponsive.urlXl} 1200w`;
    }
    if (imgResponsive.urlXxl) {
      urls = `${urls ? `${urls}, ` : ''}${imgResponsive.urlXxl} 1600w`;
    }
  }

  return urls;
};

export const getSmallestImage = (imgResponsive, imgOriginal) => {
  let img = imgOriginal;

  if (imgResponsive) {
    if (imgResponsive.urlXxl && imgResponsive.urlXxl !== imgOriginal) {
      img = imgResponsive.urlXxl;
    }
    if (imgResponsive.urlXl && imgResponsive.urlXl !== imgOriginal) {
      img = imgResponsive.urlXl;
    }
    if (imgResponsive.urlMd && imgResponsive.urlMd !== imgOriginal) {
      img = imgResponsive.urlMd;
    }
    if (imgResponsive.urlXs && imgResponsive.urlXs !== imgOriginal) {
      img = imgResponsive.urlXs;
    }
  }

  return img;
};

export const getImageOfSize = (imgResponsive, imgOriginal, size) => {
  if (imgResponsive) {
    switch (size) {
      case 'sm':
        return imgResponsive.urlXs ? imgResponsive.urlXs : imgOriginal;
      case 'md':
        return imgResponsive.urlMd ? imgResponsive.urlMd : imgOriginal;
      case 'lg':
        return imgResponsive.urlXl ? imgResponsive.urlXl : imgOriginal;
      case 'xlg':
        return imgResponsive.urlXxl ? imgResponsive.urlXxl : imgOriginal;
      default:
        return imgOriginal;
    }
  } else {
    return imgOriginal;
  }
};

export const getImagemDoBancoResponsive = index => {
  const bancoDeImagensResponsive = [
    {},
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/1/banco-de-imagens-1.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/1/banco-de-imagens-1-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/1/banco-de-imagens-1-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/1/banco-de-imagens-1-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/1/banco-de-imagens-1-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/2/banco-de-imagens-2.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/2/banco-de-imagens-2-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/2/banco-de-imagens-2-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/2/banco-de-imagens-2-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/2/banco-de-imagens-2-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/3/banco-de-imagens-3.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/3/banco-de-imagens-3-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/3/banco-de-imagens-3-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/3/banco-de-imagens-3-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/3/banco-de-imagens-3-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/4/banco-de-imagens-4.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/4/banco-de-imagens-4-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/4/banco-de-imagens-4-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/4/banco-de-imagens-4-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/4/banco-de-imagens-4-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/5/banco-de-imagens-5.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/5/banco-de-imagens-5-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/5/banco-de-imagens-5-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/5/banco-de-imagens-5-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/5/banco-de-imagens-5-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/6/banco-de-imagens-6.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/6/banco-de-imagens-6-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/6/banco-de-imagens-6-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/6/banco-de-imagens-6-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/6/banco-de-imagens-6-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/7/banco-de-imagens-7.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/7/banco-de-imagens-7-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/7/banco-de-imagens-7-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/7/banco-de-imagens-7-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/7/banco-de-imagens-7-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/8/banco-de-imagens-8.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/8/banco-de-imagens-8-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/8/banco-de-imagens-8-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/8/banco-de-imagens-8-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/8/banco-de-imagens-8-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/9/banco-de-imagens-9.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/9/banco-de-imagens-9-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/9/banco-de-imagens-9-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/9/banco-de-imagens-9-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/9/banco-de-imagens-9-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/10/banco-de-imagens-10.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/10/banco-de-imagens-10-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/10/banco-de-imagens-10-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/10/banco-de-imagens-10-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/10/banco-de-imagens-10-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/11/banco-de-imagens-11.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/11/banco-de-imagens-11-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/11/banco-de-imagens-11-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/11/banco-de-imagens-11-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/11/banco-de-imagens-11-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/12/banco-de-imagens-12.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/12/banco-de-imagens-12-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/12/banco-de-imagens-12-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/12/banco-de-imagens-12-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/12/banco-de-imagens-12-1600.webp'
    },
    {
      url:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/13/banco-de-imagens-13.webp',
      urlXs:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/13/banco-de-imagens-13-476.webp',
      urlMd:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/13/banco-de-imagens-13-768.webp',
      urlXl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/13/banco-de-imagens-13-1200.webp',
      urlXxl:
        'https://sebraeplace.sebraepr.com.br/images/banco-de-imagens/13/banco-de-imagens-13-1600.webp'
    }
  ];

  if (bancoDeImagensResponsive[index]) {
    return bancoDeImagensResponsive[index];
  }
};
