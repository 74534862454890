export const situacaoSebraetecOrcamentoEnum = {
  CONCLUIDO: 'CONCLUIDO',
  AGUARDANDO_ENVIO_NF: 'AGUARDANDO_ENVIO_NF',
  AGUARDANDO_ENVIO_NF_DEVOLUCAO: 'AGUARDANDO_ENVIO_NF_DEVOLUCAO',
  AGUARDANDO_REVISAO_NF: 'AGUARDANDO_REVISAO_NF',
  AGUARDANDO_REVISAO_NF_DEVOLUCAO: 'AGUARDANDO_REVISAO_NF_DEVOLUCAO',
  AGUARDANDO_EXECUCAO: 'AGUARDANDO_EXECUCAO',
  AGUARDANDO_CONFIRMACAO_CLIENTE: 'AGUARDANDO_CONFIRMACAO_CLIENTE',
  AGUARDANDO_PAGAMENTO: 'AGUARDANDO_PAGAMENTO',
  AGUARDANDO_ESCOLHA: 'AGUARDANDO_ESCOLHA',
  SOLICITADO: 'SOLICITADO',
  CANCELADO: 'CANCELADO',
  DEVOLUCAO_CONCLUIDA: 'DEVOLUCAO_CONCLUIDA',
  DEVOLUCAO_EM_ANALISE: 'DEVOLUCAO_EM_ANALISE',
  EXPIRADO_FORNECEDOR: 'EXPIRADO_FORNECEDOR',
  EXPIRADO_CLIENTE: 'EXPIRADO_CLIENTE'
};
