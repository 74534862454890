export const TipoNegociacao = {
  PRODUTO: 'PRODUTO',
  CONSULTORIA: 'CONSULTORIA',
  SAAS_CONTRATO: 'SAAS_CONTRATO',
  SEBRAETEC: 'SEBRAETEC'
};

export const StatusNegociacao = {
  EM_ANDAMENTO: 'EM_ANDAMENTO',
  PROPOSTA_ENVIADA: 'PROPOSTA_ENVIADA',
  PROPOSTA_ACEITA: 'PROPOSTA_ACEITA',
  PROPOSTA_RECUSADA: 'PROPOSTA_RECUSADA',
  AGUARDANDO_PAGAMENTO: 'AGUARDANDO_PAGAMENTO',
  AGUARDANDO_EXECUCAO: 'AGUARDANDO_EXECUCAO',
  AGUARDANDO_CONFIRMACAO_PAGAMENTO: 'AGUARDANDO_CONFIRMACAO_PAGAMENTO',
  AGUARDANDO_CONFIRMACAO_CLIENTE: 'AGUARDANDO_CONFIRMACAO_CLIENTE',
  EM_DISPUTA: 'EM_DISPUTA',
  CONCLUIDA: 'CONCLUIDA',
  CONCLUIDA_DISPUTA: 'CONCLUIDA_DISPUTA',
  CANCELADA: 'CANCELADA'
};

export const StatusStepNegociacao = {
  PROPOSTO: 'PROPOSTO',
  ACEITO: 'ACEITO',
  RECUSADO: 'RECUSADO',
  DISABLED: 'DISABLED'
};

export const ChaveStepNegociacao = {
  AGENDA: 'agenda',
  DESLOCAMENTO: 'deslocamento',
  HOSPEDAGEM: 'hospedagem',
  ALIMENTACAO: 'alimentacao',
  MATERIAIS: 'materiais',
  VALOR: 'valor'
};

export const UserRoleNegociacao = {
  FORNECEDOR: 'FORNECEDOR',
  LICENCIADO: 'LICENCIADO',
  CONSULTOR: 'CONSULTOR',
  CLIENTE: 'CLIENTE'
};

export const NotificacoesNegociacao = {
  TODAS: 'TODAS',
  RECUSAR: 'RECUSAR',
  CANCELADA: 'CANCELADA',
  FINALIZAR: 'FINALIZAR',
  CONCLUIDA: 'CONCLUIDA',
  RESPONDER: 'RESPONDER',
  NOTA_FISCAL: 'NOTA_FISCAL',
  ABRIR_DISPUTA: 'ABRIR_DISPUTA',
  MEDIACAO_SEBRAE: 'MEDIACAO_SEBRAE',
  VER_DISPUTA_SAAS: 'VER_DISPUTA_SAAS',
  FINALIZAR_EXECUCAO: 'FINALIZAR_EXECUCAO',
  AGUARDANDO_PAGAMENTO: 'AGUARDANDO_PAGAMENTO',
  PROPOSTA_ACEITA_CLIENTE: 'PROPOSTA_ACEITA_CLIENTE',
  PROPOSTA_ACEITA_LICENCIADO: 'PROPOSTA_ACEITA_LICENCIADO',
  AGUARDANDO_CONFIRMACAO_PAGAMENTO: 'AGUARDANDO_CONFIRMACAO_PAGAMENTO',
  SEBRAETEC_AGUARDANDO_NF: 'SEBRAETEC_AGUARDANDO_NF',
  SEBRAETEC_NF_EM_ANALISE: 'SEBRAETEC_NF_EM_ANALISE',
  SEBRAETEC_NF_REPROVADA: 'SEBRAETEC_NF_REPROVADA',
  SEBRAETEC_DEVOLUCAO_CLIENTE: 'SEBRAETEC_DEVOLUCAO_CLIENTE',
  SEBRAETEC_DEVOLUCAO_AGUARDANDO_FORNECEDOR:
    'SEBRAETEC_DEVOLUCAO_AGUARDANDO_FORNECEDOR',
  SEBRAETEC_DEVOLUCAO_EM_ANALISE: 'SEBRAETEC_DEVOLUCAO_EM_ANALISE',
  SEBRAETEC_DEVOLUCAO_AJUSTES_FORNECEDOR:
    'SEBRAETEC_DEVOLUCAO_AJUSTES_FORNECEDOR',
  SEBRAETEC_AGUARDANDO_CONFIRMACAO_CLIENTE:
    'SEBRAETEC_AGUARDANDO_CONFIRMACAO_CLIENTE'
};

export const ModalidadeNegociacao = {
  PRESENCIAL: 'PRESENCIAL',
  REMOTO: 'REMOTO'
};

export const InfoNegociacao = {
  NECESSARIA_ACAO: 'NECESSARIO_ACAO',
  NAO_NECESSARIA_ACAO: 'NAO_NECESSARIA_ACAO',
  PROBLEMA_ENCONTRADO: 'PROBLEMA_ENCONTRADO'
};

export const StatusPagamento = {
  PENDENTE: 'PENDENTE',
  AGUARDANDO_CONFIRMACAO: 'AGUARDANDO_CONFIRMACAO',
  PAGO: 'PAGO',
  CANCELADO: 'CANCELADO',
  REEMBOLSADO: 'REEMBOLSADO',
  REEMBOLSADO_PARCIALMENTE: 'REEMBOLSADO_PARCIALMENTE',
  EXPIRADO: 'EXPIRADO'
};
