import { themesEnum } from '../../shared/util/enums/themesEnum';

export default {
  title: themesEnum.DARK,

  colors: {
    background: 'var(--color-black-gray)',
    header: 'rgba(0, 0, 0, 0.25)',
    text: 'var(--color-white)',
    secondary: 'var(--color-gray)',
    textEspecial: '#FBFCFF',
    textConteudoCurso: 'var(--color-gray)',
    skeleton1: '#151414',
    skeleton2: '#201f1f',
    scrollbar: '#353535',
    sideBar: 'var(--color-header-black)',
    sideBarListSelected: 'var(--color-black-gray)',
    searchBtn: 'var(--color-primary)',
    textSearchBtn: 'var(--color-white)',
    buttonHover: 'var(--color-primary)',
    textButtonHover: 'var(--color-white)',
    menuButton: '#464646',
    sideBarListTag: '#1F1F1F',
    blurModal: '#2c2c2c8c',
    disabledButtonColor: 'rgb(58 58 58 / 25%)',
    disabledButtonBackground: '#222121',
    disabledButtonBorder: '#585858',
    selected: 'var(--color-primary)',
    quadro: '#131414',
    sombraFullscreen: 'rgba(27, 27, 27, 1)',
    sombraFullScreenZero: 'rgba(27, 27, 27, 0)',
    sombraQuadro: 'rgba(19, 20, 20, 1)',
    sombraQuadroZero: 'rgba(19, 20, 20, 0)',
    progressBar: '#32323260',
    uncheckedClass: '#ffffff10',
    uncheckedClassSideBar: '#ffffff10',
    dividerConteudoCurso: '#ffffff10',
    dividerEspecial: '#F0F0F020',
    dividerTab: '#F0F0F010',
    divider: '#F0F0F040',
    tabDivider: 'var(--color-gray-light)',
    tabSelected: '#FBFCFF',
    switch: '#464646',
    avatarBorder: 'var(--color-white)',
    textDisabled: 'rgb(200 200 200 / 25%)'
  }
};
