import i18n from './i18n';
import { StatusNegociacao } from './enums/negociacaoEnums';
import LogoSebrae from '../../images/svgs/logo-sebrae.svg';
import PIconBlue from '../../images/svgs/p-icon-blue.svg';

export const handleItemCardSebrae = item => ({
  ...item,
  id: defaultObjSupportSebrae.id,
  idSuporte: item && item.id,
  status: defaultObjSupportSebrae.status,
  nome:
    item && item.pessoaAtendente && item.pessoaAtendente.nome
      ? item.pessoaAtendente.nome
      : i18n.t('RESPOND_SOON'),
  pessoaNome: defaultObjSupportSebrae.pessoaNome,
  empresaNome: defaultObjSupportSebrae.empresaNome,
  tipo: defaultObjSupportSebrae.SEBRAE,
  avatar: PIconBlue,
  imgTitle: LogoSebrae,
  headerDesc: defaultObjSupportSebrae.headerDesc,
  conversa: {
    ...(item && item.conversa),
    id: item && item.conversa && item.conversa.id
  }
});

export const defaultObjSupportSebrae = {
  SEBRAE: 'SEBRAE',
  id: 'suporte-sebraeplace',
  status: StatusNegociacao.EM_ANDAMENTO,
  pessoaNome: 'Customer Service',
  empresaNome: 'SEBRAEPLACE',
  headerDesc: 'Customer Service Sebraeplace'
};

export const defaultObjBudgetSebraetec = {
  SEBRAETEC: 'SEBRAETEC'
};
