import { stringToPascalCase } from '../transfoms';

export const situacaoEnum = {
  EM_CADASTRAMENTO: 'EM_CADASTRAMENTO',
  AGUARDANDO_INTEGRACAO: 'AGUARDANDO_INTEGRACAO',
  AGUARDANDO_HOMOLOGACAO_AUTOMATICA: 'AGUARDANDO_HOMOLOGACAO_AUTOMATICA',
  CADASTRADO: 'CADASTRADO',
  EM_REVISAO: 'EM_REVISAO',
  APROVADO: 'APROVADO',
  REPROVADO: 'REPROVADO',
  INATIVO: 'INATIVO',
  EXCLUIDO: 'EXCLUIDO'
};

export function situacaoBeautify(situacao) {
  switch (situacao) {
    case situacaoEnum.EM_CADASTRAMENTO:
      return 'Em cadastramento';
    case situacaoEnum.AGUARDANDO_HOMOLOGACAO_AUTOMATICA:
      return 'Aguardando validação automática';
    case situacaoEnum.AGUARDANDO_INTEGRACAO:
      return 'Aguardando integração';
    case situacaoEnum.CADASTRADO:
      return 'Aguardando homologação';
    case situacaoEnum.EM_REVISAO:
      return 'Em revisão';
    case situacaoEnum.APROVADO:
      return 'Aprovado';
    case situacaoEnum.REPROVADO:
      return 'Reprovado';
    case situacaoEnum.INATIVO:
      return 'Inativo';
    case situacao.EXCLUIDO:
      return 'Excluído';
    case situacao.AGUARDANDO_INTEGRACAO:
      return 'Aguardando integração';
    default:
      return stringToPascalCase(situacao);
  }
}
