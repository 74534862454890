import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { OBTER_CONFIGURACAO_AFILIADOS } from './queries';
import { ContextAffiliate } from '../../store/contexts/contextAffiliate';
import { ConfiguracaoEnum } from '../../shared/util/enums/configuracaoEnum';

export default function AffiliateComponent() {
  const { setAllowsAffiliate, setAllowsAffiliateLoaded } = useContext(
    ContextAffiliate
  );

  const [getConfiguracaoMarketingAfiliados] = useLazyQuery(
    OBTER_CONFIGURACAO_AFILIADOS,
    {
      onCompleted(data) {
        setAllowsAffiliateLoaded(true);
        if (data && data.obterConfiguracaoMarketingAfiliados) {
          setAllowsAffiliate(data.obterConfiguracaoMarketingAfiliados);
        } else {
          setAllowsAffiliate(false);
        }
      },
      onError() {
        setAllowsAffiliateLoaded(true);
      }
    }
  );

  useEffect(() => {
    getConfiguracaoMarketingAfiliados({
      variables: {
        configuracaoEnum: ConfiguracaoEnum.MARKETING_AFILIADOS
      }
    });
  }, [getConfiguracaoMarketingAfiliados]);

  return <></>;
}
