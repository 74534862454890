import React, { Suspense, lazy, useState } from 'react';
import Icon from '@ant-design/icons';
import { Modal, Typography, Row, Col, Button, Checkbox } from 'antd';
import { ReactComponent as TimesRound } from '../../images/svgs/times-round.svg';
import { useQuery, useMutation } from 'react-apollo';
import { OBTER_TERMOS_E_POLITICA, ACEITAR_TERMOS } from './queries';
import { clearTokenAndRefreshToken } from '../../shared/util/utilToken';
import Config from '../../shared/Config';
import i18n from '../../shared/util/i18n';
import { Container } from './styles';

const Output = lazy(() => import('editorjs-react-renderer'));
const { Title } = Typography;

export default function ModalAceiteTermo({
  modalVisible,
  fecharModal,
  atualizacao
}) {
  const [disableButton, setDisableButton] = useState(true);
  const onChange = e => {
    setDisableButton(!e.target.checked);
  };

  const closeHandle = () => {
    clearTokenAndRefreshToken();

    setTimeout(() => {
      window.location.href = Config.baseUrl;
    }, 0);
  };

  const submitHandle = () => {
    aceitarTermos();
    fecharModal();
  };

  const { data } = useQuery(OBTER_TERMOS_E_POLITICA, {});

  const [aceitarTermos] = useMutation(ACEITAR_TERMOS);

  return (
    <Container>
      <Modal
        closeIcon={<Icon component={TimesRound} />}
        open={modalVisible}
        onCancel={closeHandle}
        footer={false}
        width='800px'
        bodyStyle={{ padding: '24px 40px' }}>
        <Container>
          <Row className='mt-2 mb-2'>
            <Title level={4} strong>
              {atualizacao
                ? i18n.t('TERMS_OF_USE_HAVE_CHANGED')
                : i18n.t('USE_TERMS_MODAL')}
            </Title>
          </Row>

          <Row className='row-text'>
            <Col className='col-text'>
              <div>
                {data && data.termos && (
                  <Suspense fallback={<></>}>
                    <Output
                      data={
                        data && data.termos && data.termos.texto
                          ? JSON.parse(data.termos.texto)
                          : { block: [] }
                      }
                    />
                  </Suspense>
                )}
              </div>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col xs={24} md={14}>
              <Checkbox onChange={onChange}>
                {i18n.t('READ_AGREE_TERMS')}
              </Checkbox>
            </Col>
            <Col xs={24} md={10} className='col-botao-salvar-alteracoes'>
              <Button
                type='primary'
                shape='round'
                className='w-100 botao-salvar-alteracoes'
                onClick={submitHandle}
                disabled={disableButton}>
                {i18n.t('AGREE_MODAL')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </Container>
  );
}
