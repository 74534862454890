import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;

  .information-title {
    font-weight: 400;
    font-size: 23px;
    letter-spacing: 1.2px;
    color: var(--color-white);
  }

  .information-description {
    text-align: left;
    font-weight: lighter;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: var(--color-white);
    line-height: 2em;
  }

  .container-items {
    background-color: var(--text-color);
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
  }

  .know-how-it-works {
    color: var(--color-white);
  }

  .register-now-container {
    background-color: var(--color-gray-medium);
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    padding: 15px;
  }

  .learn-more-link {
    letter-spacing: 0.65px;
    color: var(--color-white);
    font-size: 13px;
    font-weight: 500;
  }

  .icon-style {
    padding-top: 15px;
    padding-left: 15px;
  }

  .sebrae-style {
    color: var(--color-primary);
  }

  .padding-button {
    padding: 0 60px;
  }

  .card-4-style {
    border-top-left-radius: 19px;
    background: var(--text-color);
  }

  .card-4-image-style {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 80% 20%;
    border-top-right-radius: 19px;
  }

  .card-4-links {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.65px;
    color: var(--text-white);
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;
  }

  .register-now-text {
    font-weight: 600;
    font-size: 19px;
    letter-spacing: 1.2px;
    color: var(--text-color);
  }

  .create-account-button {
    width: 100%;
    margin-top: 20px;
  }

  .item:not(:last-child) {
    border-bottom: 1px solid var(--color-white);
  }

  .row-w-100 {
    width: auto;
  }

  @media (max-width: 768px) {
    .padding-button {
      padding: 0;
    }
    .row-w-100 {
      width: 100%;
    }

    .card-4-style {
      border-top-left-radius: 19px;
      border-top-right-radius: 19px;
      background: var(--text-color);
    }

    .card-4-image-style {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 80% 20%;
      border-top-right-radius: 0;
    }
  }

  @media (min-width: 768px) {
    .item:not(:last-child) {
      border-right: 1px solid var(--color-white);
      border-bottom: unset;
    }

    .create-account-button {
      width: 160px;
      margin-top: unset;
    }

    .register-now-text {
      font-size: 24px;
    }
  }

  @media (min-width: 992px) {
    .create-account-button {
      width: 360px;
      margin-top: unset;
    }
  }
`;
