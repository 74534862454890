import React from 'react';
const configuracaoIncorreta =
  'O ContextPerson não está configurado corretamente';
export const ContextPerson = React.createContext({
  person: undefined,
  setPerson: () => {
    throw new Error(configuracaoIncorreta);
  },
  personLoading: false,
  setPersonLoading: () => {
    throw new Error(configuracaoIncorreta);
  },
  pessoa: undefined,
  setPessoa: () => {
    throw new Error(configuracaoIncorreta);
  },
  isPersonLoaded: false,
  setIsPersonLoaded: () => {
    throw new Error(configuracaoIncorreta);
  },
  vinculos: [],
  setVinculos: () => {
    throw new Error(configuracaoIncorreta);
  },
  mustUpdatePerson: true,
  setMustUpdatePerson: () => {
    throw new Error(configuracaoIncorreta);
  },
  specialRedirect: '',
  setSpecialRedirect: () => {
    throw new Error(configuracaoIncorreta);
  },
  usersAllowed: () => {
    throw new Error(configuracaoIncorreta);
  },
  setUsersAllowed: () => {
    throw new Error(configuracaoIncorreta);
  },
  empresasCrm: [],
  setEmpresasCrm: () => {
    throw new Error(configuracaoIncorreta);
  },
  mustUpdateEmpresasCrm: false,
  setMustUpdateEmpresasCrm: () => {
    throw new Error(configuracaoIncorreta);
  },
  numberOfSebraetecRequests: 0,
  setNumberOfSebraetecRequests: () => {
    throw new Error(configuracaoIncorreta);
  },
  userType: '',
  setUserType: () => {
    throw new Error(configuracaoIncorreta);
  },
  habilitarCursosPresenciais: false,
  setHabilitarCursosPresenciais: () => {
    throw new Error(configuracaoIncorreta);
  }
});
