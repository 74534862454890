import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Container, StyledModalTerms as Modal } from './styles';
import Icon from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { useQuery } from 'react-apollo';
import { TERMO_TIPO } from '../../queries';
import { parseToEditorJS } from '../../../../shared/util/transfoms';
import { ReactComponent as TimesRound } from '../../../../images/svgs/times-round.svg';

const Output = lazy(() => import('editorjs-react-renderer'));

export default function ModalTerms(props) {
  const [modalVisible, setModalVisible] = useState(false);

  let tipo = 'FORNECEDOR';
  if (props.tipo) {
    tipo = props.tipo;
  }

  const { data, loading: loadingTermo } = useQuery(TERMO_TIPO, {
    variables: { tipo: tipo }
  });

  useEffect(() => {
    setModalVisible(props.modalVisible);
  }, [props.modalVisible]);

  return (
    <Modal
      closeIcon={<Icon component={TimesRound} />}
      title='Termos de uso'
      open={modalVisible}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={[
        <Button
          key='submit'
          type='primary'
          shape='round'
          className='wide'
          onClick={props.handleOk}>
          Ok
        </Button>
      ]}>
      <Container>
        <Skeleton active loading={loadingTermo}>
          {data && data.obterTermoVigentePorTipo && (
            <Suspense fallback={<></>}>
              <Output
                data={parseToEditorJS(data.obterTermoVigentePorTipo.texto)}
              />
            </Suspense>
          )}
        </Skeleton>
      </Container>
    </Modal>
  );
}
