import styled from 'styled-components';

export const Container = styled.div`
  .avatar-fornecedor-popular {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  .ant-rate {
    color: var(--color-primary);
  }
  .sombra {
    box-shadow: 0px 0px 12px var(--color-card-shadow);
    border-radius: 3px;
  }
  .icon-heart {
    position: absolute;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background: var(--color-gray);
    right: 25px;
    top: 5px;
  }

  .divider-style {
    margin: 0px 0 20px 0;
  }

  .ocupacao-col {
    min-height: 50px;
  }

  @media (min-width: 766px) {
    .icon-heart {
      right: 66px;
    }
  }
  @media (min-width: 767px) {
    .icon-heart {
      right: -25px;
    }
  }
  @media (min-width: 992px) {
    .icon-heart {
      right: 25px;
    }
  }
`;
