import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled.div`
  overflow-y: scroll;
  height: 250px;
  white-space: pre-line;
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 100vh !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: transparent !important;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    background-color: var(--color-scrollbar) !important;
  }

  @media (max-width: 767px) {
    height: 50vh;
  }
`;

export const StyledModalPrivacy = styled(Modal)`
  @media (max-width: 767px) {
    top: 30px !important;
  }
`;
