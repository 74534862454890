import styled, { css } from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 0px 12px var(--color-card-shadow);
  border-radius: 3px;
  margin: 10px;
  height: 276px;
  margin-bottom: 4rem !important;

  .ant-rate {
    color: var(--color-primary);
  }

  .img-background {
    width: 100%;
    height: 157px;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .favorite-img {
    position: absolute;
    top: 19px;
    right: 19px;
  }

  .avatar-position {
    position: absolute;
    top: 140px;
    right: 20px;
    z-index: 99;
  }

  .container-modalidade {
    position: absolute;
    top: 145px;
    display: flex;
    justify-content: center;
    width: 94%;
  }

  .modalidade {
    text-align: center;
    border-radius: 3px;
    color: var(--color-white);
    padding: 2px 5px;
  }

  .info-box {
    padding: 10px 40px 10px 20px;
  }

  .product-title {
    height: 44px;
    font-size: 15px;
  }

  .proposta-valor {
    height: 49px;
  }

  .proposta-valor-text {
    font-size: 12px;
    font-weight: 300;
    color: var(--color-gray);
  }

  .extra-info {
    background-color: var(--text-color);
    padding: 10px;
    color: var(--color-white-gray);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0px 0px 20px var(--text-color);
  }

  .extra-info-position {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .valor-position {
    display: flex;
    flex-direction: column;
  }

  .valor-style {
    font-size: 18px;
    font-weight: 500;
  }

  .condicoes-style {
    font-size: 12px;
    font-weight: 300;
  }

  .share-icon-size {
    font-size: 1rem;
  }

  .text-assessment {
    font-weight: bold;
  }

  :hover .extra-info {
    background-color: var(--color-primary);
  }

  :hover {
    box-shadow: 0px 0px 8px var(--color-card-shadow);
  }

  @media (min-width: 576px) {
    height: 276px;

    .proposta-valor {
      height: 49px;
    }
  }

  @media (min-width: 768px) {
    height: 280px;

    .proposta-valor {
      height: 58px;
    }
  }

  @media (min-width: 992px) {
    height: 295px;

    .proposta-valor {
      height: 74px;
    }
  }

  @media (min-width: 1200px) {
    height: 276px;

    .proposta-valor {
      height: 49px;
    }
  }

  ${({ isHome }) =>
    isHome &&
    css`
      margin: unset;
      height: 100%;
      margin-bottom: 0px !important;

      .container-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }

      .img-background {
        height: 114px;
      }

      .extra-info {
        box-shadow: none;
      }

      .info-box {
        padding: 1rem;
      }

      .product-title {
        min-height: 56px;
      }

      .container-category {
        min-height: 39px;
      }

      .container-modalidade {
        top: 102px;
      }

      .avatar-position {
        top: 101px;
        right: 10px;
      }

      .text-assessment {
        font-weight: 500;
      }

      @media (min-width: 576px) {
        height: 100%;
      }

      @media (min-width: 768px) {
        hheight: 100%;
      }

      @media (min-width: 992px) {
        height: 100%;
      }

      @media (min-width: 1200px) {
        height: 100%;
      }
    `}
`;
