import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 767px) {
    .col-botao-salvar-alteracoes {
      margin-top: 15px;
    }
  }

  .row-text {
    max-height: 42vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
      height: 100vh;
    }

    ::-webkit-scrollbar-track {
      box-shadow: transparent;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--color-scrollbar);
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1px solid var(--color-primary) !important;
    border-top: 0 !important;
    border-left: 0 !important;
    top: 10px !important;
  }

  /* stylelint-disable-next-line */
  .ant-checkbox-inner::after {
    width: 11.714286px;
    height: 11.142857px;
  }
`;
