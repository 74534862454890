import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'antd';
import { ContextAlert } from '../store/contexts/contextAlert';
import styled from 'styled-components';

function useInternalAlert() {
  const { setHideAlerts } = useContext(ContextAlert);
  const [state, setState] = useState();
  useEffect(() => {
    setHideAlerts(true);
    return function cleanup() {
      setHideAlerts(false);
    };
  }, [setHideAlerts]);
  const Component = () => (
    <Container>
      <Alert
        key={1}
        message={state.message}
        description={state.description}
        type={state.type}
        className='align-items-center'
        showIcon
        closable={state.closable}
      />
    </Container>
  );

  return {
    Component: state ? Component : null,
    /**
     * @param {Object} options - useInternalAlert options
     * @param {string} options.message - Message to show on the alert
     * @param {string} options.description - Description of the message on the alert
     * @param {('info'|'error'|'success'|'warning')} options.type - One of the AlertTypeEnum values.
     * @param {boolean} options.closable - If the alert is closable.
     */
    setAlert: ({ message, description, type, closable }) => {
      setState({
        message: message,
        description: description,
        type: type,
        closable
      });
    },
    clearAlert: () => setState(null)
  };
}

const Container = styled.div`
  @media (min-width: 992px) {
    .ant-alert-with-description {
      padding: 0 15px !important;
      min-height: 55px;
    }

    .ant-alert-description {
      margin: 15px 35px 15px 0 !important;
    }

    .ant-alert-with-description .ant-alert-message {
      margin: 15px 0px 15px 15px !important;
    }

    .ant-alert-with-description .ant-alert-icon {
      position: initial !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
`;

export default useInternalAlert;
