import React from 'react';
import { Row } from 'antd';

import CardCategoria from '../CardCategoria/CardCategoria';
import Astronaut from '../../../../images/svgs/astronaut.svg';
import AstronautFlag from '../../../../images/svgs/astronaut-flag.svg';
import AstronautFishing from '../../../../images/svgs/astronaut-fishing.svg';
import AstronautSitting from '../../../../images/svgs/astronaut-sitting.svg';

import { Container } from './styles';
import { CategoriasEnum } from '../../../../shared/util/enums/categoriasEnum';

export default function CategoriasProdutos({ loading, indicadoresProdutos }) {
  const showBtnSearch = attr =>
    indicadoresProdutos && indicadoresProdutos[attr] && !loading;

  const showComingSoon = attr =>
    indicadoresProdutos &&
    indicadoresProdutos[attr] !== undefined &&
    !indicadoresProdutos[attr] &&
    !loading;

  const categories = [
    {
      categoria: CategoriasEnum.SEBRAETEC,
      title: 'I_WANT_SEBRAETEC',
      imgSrc: AstronautFlag,
      alt: 'SEBRAETEC',
      showBtnSearch: showBtnSearch('possuiVinculosSebraetec'),
      showComingSoon: showComingSoon('possuiVinculosSebraetec'),
      href: '/catalogo?tipo=sebraetec'
    },
    {
      categoria: CategoriasEnum.CONSULTORIA,
      title: 'I_WANT_CONSULTANCY',
      imgSrc: Astronaut,
      alt: 'CONSULTANCY',
      showBtnSearch: showBtnSearch('possuiConsultorias'),
      showComingSoon: showComingSoon('possuiConsultorias'),
      href: '/catalogo?tipo=consultoria'
    },
    {
      categoria: CategoriasEnum.SOLUCOES_DIGITAIS,
      title: 'I_WANT_DIGITAL_SOLUTIONS',
      imgSrc: AstronautFishing,
      alt: 'DIGITAL_SOLUTIONS',
      showBtnSearch: showBtnSearch('possuiSolucoesDigitais'),
      showComingSoon: showComingSoon('possuiSolucoesDigitais'),
      href: '/catalogo?tipo=saas'
    },
    {
      categoria: CategoriasEnum.CURSOS_ONLINE,
      title: 'I_WANT_ONLINE_COURSE',
      imgSrc: AstronautSitting,
      alt: 'ONLINE_COURSE',
      showBtnSearch: showBtnSearch('possuiCursosOnline'),
      showComingSoon: showComingSoon('possuiCursosOnline'),
      href: '/catalogo?tipo=curso'
    }
  ];

  return (
    <Container>
      <Row className='ant-row-flex' gutter={[10, 0]}>
        {categories.map((category, index) => (
          <CardCategoria key={index} {...category} />
        ))}
      </Row>
    </Container>
  );
}
