import React, { useState, useContext, useEffect } from 'react';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { useMutation, useLazyQuery, useSubscription } from 'react-apollo';
import HeaderProfessionalInvitation from '../HeaderProfessionalInvitation/HeaderProfessionalInvitation';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import Config from '../../shared/Config';
import links from '../../shared/util/configHeader';
import { ConfiguracaoEnum } from '../../shared/util/enums/configuracaoEnum';
import { situacaoEnum } from '../../shared/util/enums/situacaoEnum';
import {
  OBSERVAR_NOTIFICATIONS,
  OBSERVAR_USUARIO,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ALL,
  SET_CURRENT_PERSON,
  OBTER_CONFIGURACAO_POR_NOME,
  MARCAR_NOTIFICACOES_COMO_LIDAS,
  MARCAR_NOTIFICACAO_LIDA_POR_ID,
  GET_CONVITE_FORNECEDOR_PENDENTE
} from './queries';
import { useHistory } from 'react-router-dom';
import { getToken, isTerceiro } from '../../shared/util/utilToken';
import { situacaoConviteEnum } from '../../shared/util/enums/situacaoConviteEnum';
import { ContextAffiliate } from '../../store/contexts/contextAffiliate';

const ObservarNotificacoes = ({ idLogado, setMustQueryNotifications }) => {
  useSubscription(OBSERVAR_NOTIFICATIONS, {
    variables: {
      pessoaId: idLogado
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: data => {
      if (!data.subscriptionData || !data.subscriptionData.data) {
        return;
      }
      setMustQueryNotifications(true);
    }
  });
  return <div style={{ display: 'none' }}></div>;
};

const ObservarUsuario = ({
  idLogado,
  setMustCheckVinculo,
  setMustUpdatePerson
}) => {
  useSubscription(OBSERVAR_USUARIO, {
    variables: {
      pessoaId: idLogado
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      setMustCheckVinculo(true);
      setMustUpdatePerson(true);
    }
  });
  return <div style={{ display: 'none' }}></div>;
};

export default function Header({
  showMenu,
  handleMenu,
  whiteYellowLogo,
  menuList,
  exitButton,
  withoutBlur
}) {
  const {
    pessoa,
    vinculos,
    isPersonLoaded,
    specialRedirect,
    setSpecialRedirect,
    setMustUpdatePerson
  } = useContext(ContextPerson);
  const [vinculoAtual, setVinculoAtual] = useState(undefined);
  const [mustCheckVinculo, setMustCheckVinculo] = useState(false);
  const history = useHistory();
  const [canRenderActions, setCanRenderActions] = useState(false);
  const [pendingInvitation, setPendingInvitation] = useState();
  const [allowsThird, setAllowsThird] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [mustQueryNotifications, setMustQueryNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [seeAll, setSeeAll] = useState(false);
  const { allowsAffiliate } = useContext(ContextAffiliate);
  const [getNotifications] = useLazyQuery(GET_NOTIFICATIONS, {
    onCompleted(data) {
      if (data && data.notificacoesNaoLidas) {
        setNotifications(data.notificacoesNaoLidas);
      }
      setMustQueryNotifications(false);
    }
  });

  const [getConviteFornecedorPendente] = useLazyQuery(
    GET_CONVITE_FORNECEDOR_PENDENTE,
    {
      onCompleted(data) {
        if (data.getConviteFornecedorPendente) {
          setPendingInvitation(data.getConviteFornecedorPendente);
        }
      }
    }
  );

  const [getNotificationsAll, { loading: notificacoesLoading }] = useLazyQuery(
    GET_NOTIFICATIONS_ALL,
    {
      onCompleted(data) {
        setNotifications(data.notificacoesNaoLidas);
        setMustQueryNotifications(false);
      }
    }
  );

  const [obterConfiguracaoPorNome] = useLazyQuery(OBTER_CONFIGURACAO_POR_NOME, {
    onCompleted(data) {
      if (
        data &&
        data.obterConfiguracaoPorNome &&
        data.obterConfiguracaoPorNome.valor &&
        data.obterConfiguracaoPorNome.valor.valorBoolean
      ) {
        setAllowsThird(data.obterConfiguracaoPorNome.valor.valorBoolean);
      }
    }
  });

  if (getToken() && pessoa && pessoa.id) {
    if (!hasSearched) {
      setHasSearched(true);
      getNotifications();
    }
  }
  const [setCurrentPerson, { loading: setCurrentPersonLoading }] = useMutation(
    SET_CURRENT_PERSON,
    {
      onCompleted() {
        setMustCheckVinculo(true);
        setMustUpdatePerson(true);
      }
    }
  );

  const [marcarNotificacoesComoLidas] = useMutation(
    MARCAR_NOTIFICACOES_COMO_LIDAS
  );

  const [marcarUmaNotificacaoComoLida] = useMutation(
    MARCAR_NOTIFICACAO_LIDA_POR_ID
  );

  const verTodas = link => {
    marcarNotificacoesComoLidas().then(() => history.push(link)); // eslint-disable-line no-restricted-syntax
  };

  const visualizarNotificacao = item => {
    marcarUmaNotificacaoComoLida({
      variables: {
        notificacaoId: item.id
      }
    }).then(() => history.push(item.link)); // eslint-disable-line no-restricted-syntax
  };

  useEffect(() => {
    obterConfiguracaoPorNome({
      variables: {
        configuracaoEnum:
          ConfiguracaoEnum.PERMITE_AFILIACAO_COMISSIONADA_TERCEIRO
      }
    });
  }, [obterConfiguracaoPorNome]);

  useEffect(() => {
    setHasSearched(false);
    if (!pessoa) return;
    if (pessoa) {
      setCanRenderActions(true);
    }
    if (vinculoAtual !== pessoa.vinculo) {
      setVinculoAtual(pessoa.vinculo);
      if (!mustCheckVinculo) return;
      setMustCheckVinculo(false);
      if (specialRedirect) {
        setSpecialRedirect();
        history.push(specialRedirect); // eslint-disable-line no-restricted-syntax
      } else if (
        pessoa.vinculo &&
        pessoa.vinculo.empresa &&
        pessoa.vinculo.empresa.tipo === 'LICENCIADO'
      ) {
        history.push('/painel'); // eslint-disable-line no-restricted-syntax
      } else history.push('/'); // eslint-disable-line no-restricted-syntax
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pessoa]);

  useEffect(() => {
    if (mustQueryNotifications && pessoa) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustQueryNotifications]);

  useEffect(() => {
    if (!pendingInvitation && getToken() && pessoa && pessoa.id) {
      getConviteFornecedorPendente();
    }
  }, [pessoa, pendingInvitation, getConviteFornecedorPendente]);

  return (
    <React.Fragment>
      <HeaderComponent
        setarPessoaAtual={async pessoaToSet => {
          if (
            pessoaToSet &&
            pessoaToSet.vinculo &&
            pessoaToSet.vinculo.papel &&
            pessoaToSet.vinculo.empresa &&
            pessoaToSet.vinculo.empresa.tipo === 'FORNECEDOR' &&
            pessoaToSet.vinculo.situacao !== situacaoEnum.APROVADO
          ) {
            history.push('/cadastro'); // eslint-disable-line no-restricted-syntax
          } else {
            await setCurrentPerson({
              variables: {
                empresaId: pessoaToSet && pessoaToSet.id ? pessoaToSet.id : null
              }
            });
          }
        }}
        setarPessoaAtualLoading={setCurrentPersonLoading}
        isPersonLoaded={isPersonLoaded ? isPersonLoaded : null}
        pessoa={pessoa && pessoa.id ? pessoa : null}
        showTornarSeFornecedor={false}
        vinculos={vinculos}
        okThirdSetup={!isTerceiro() || (isTerceiro() && allowsThird)}
        allowsAffiliate={allowsAffiliate}
        canRenderActions={canRenderActions}
        notifications={notifications}
        showNotifications={pessoa && pessoa.id}
        links={links(pessoa)}
        hasNotifications={!!(notifications && notifications.length)}
        qtdNotificacoes={(notifications && notifications.length) || 0}
        getNotificationsAll={getNotificationsAll}
        seeAll={seeAll}
        setSeeAll={setSeeAll}
        notificacoesLoading={notificacoesLoading}
        marcarTodasNotificacoesComoLidas={link => verTodas(link)} // TODO
        marcarNotificacaoComoLida={item => visualizarNotificacao(item)} // TODO
        environment={Config.environment}
        whiteYellowLogo={whiteYellowLogo}
        menuList={menuList}
        exitButton={exitButton}
        handleMenu={handleMenu}
        showMenu={showMenu}
        withoutBlur={withoutBlur}
      />
      {hasSearched && (
        <ObservarNotificacoes
          idLogado={pessoa && pessoa.id}
          setMustQueryNotifications={e => setMustQueryNotifications(e)}
        />
      )}
      {pessoa && pessoa.id && (
        <ObservarUsuario
          idLogado={pessoa.id}
          setMustCheckVinculo={e => setMustCheckVinculo(e)}
          setMustUpdatePerson={e => setMustUpdatePerson(e)}
        />
      )}
      {pendingInvitation &&
        pendingInvitation.situacao === situacaoConviteEnum.ENVIADO && (
          <HeaderProfessionalInvitation
            pendingInvitation={pendingInvitation}
            setPendingInvitation={setPendingInvitation}
          />
        )}
    </React.Fragment>
  );
}
