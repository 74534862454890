import React from 'react';
import PropTypes from 'prop-types';
import { Rate } from 'antd';

import { Container } from './styles';

function StarRating(props) {
  return (
    <Container {...props}>
      <Rate
        className='custom-rate'
        allowHalf
        disabled={true}
        count={1}
        defaultValue={0.5}
      />
    </Container>
  );
}

export default StarRating;

StarRating.propTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  fontSize: PropTypes.number
};

StarRating.defaultProps = {
  color: 'var(--color-primary)',
  secondaryColor: ''
};

export const getPercentRating = value => {
  if (value) {
    return (value * 100) / 5;
  }
  return 0;
};
