import React, { useState, useContext, useEffect } from 'react';
import { ContextUser } from '../../store/contexts/contextUser';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { getUsuarioLogado } from '../../shared/services/ssoApiService';
import Config from '../../shared/Config';
import { actionAlert } from '../../store/actions/actionAlert';
import { getToken } from '../../shared/util/utilToken';
import { Button } from 'antd';
import { ContextAlert } from '../../store/contexts/contextAlert';
import { useMutation } from 'react-apollo';
import ModalAceiteTermo from '../../components/ModalAceiteTermo/ModalAceiteTermo';
import { ASSOCIAR_LINK_AFILIADO } from './queries';
import i18n from '../../shared/util/i18n';

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

export default function InitInfoUser() {
  const { dispatchAlert } = useContext(ContextAlert);
  const { setUser } = useContext(ContextUser);
  const { pessoa } = useContext(ContextPerson);
  const [modalVisible, setModalVisible] = useState(false);
  const search = window.location.search;

  const [associarLinkAfiliado] = useMutation(ASSOCIAR_LINK_AFILIADO);

  useEffect(() => {
    if (getQueryStringParams(search).link) {
      localStorage.setItem('linkAfiliadoId', getQueryStringParams(search).link);
    }

    getUsuarioLogado()
      .then(usuario => {
        if (usuario) {
          setUser(usuario);
          localStorage.setItem('codAgente', usuario.codAgente);
          if (!usuario.emailConfirmado) {
            alertEmailConfirmacao();
          }
        } else {
          setUser({});
          localStorage.removeItem('codAgente');
        }
        if (localStorage.getItem('linkAfiliadoId') && usuario) {
          associarLinkAfiliado({
            variables: {
              linkAfiliadoId: localStorage.getItem('linkAfiliadoId')
            }
          }).then(() => {
            localStorage.removeItem('linkAfiliadoId');
          });
        } else if (localStorage.getItem('linkAfiliadoId')) {
          associarLinkAfiliado({
            variables: {
              linkAfiliadoId: localStorage.getItem('linkAfiliadoId')
            }
          });
        }
      })
      .catch(() => {
        setUser({});
        localStorage.removeItem('codAgente');
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      (pessoa && pessoa.termoUsoAceito && !pessoa.termoUsoAceito.id) ||
      (pessoa &&
        pessoa.termoUsoAceito &&
        pessoa.termoUsoAceito.vigente !== null &&
        pessoa.termoUsoAceito.vigente === false)
    ) {
      setModalVisible(true);
    }
    // eslint-disable-next-line
  }, [pessoa]);

  const alertEmailConfirmacao = () => {
    dispatchAlert({
      type: actionAlert.ADD_NO_SETTIMEOUT,
      payload: {
        message: 'INFO',
        description: i18n.t('CONFIRM_EMAIL'),
        button: (
          <Button
            type='white'
            shape='round'
            ghost={true}
            style={{ color: '#fff' }}
            onClick={reenviarEmailConfirmacao}>
            {i18n.t('RESEND_EMAIL')}
          </Button>
        ),
        type: 'info'
      }
    });
  };

  const alertEmailEnviado = () => {
    dispatchAlert({
      type: actionAlert.ADD,
      payload: {
        message: 'INFO',
        description: i18n.t('RESEND_EMAIL_SENT'),
        type: 'info'
      }
    });
  };

  const alertErroAoEnviarEmail = () => {
    dispatchAlert({
      type: actionAlert.ADD,
      payload: {
        message: 'ERROR',
        description: i18n.t('RESEND_EMAIL_ERROR'),
        type: 'error'
      }
    });
  };

  const reenviarEmailConfirmacao = () => {
    const accessToken = getToken();
    let config = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    fetch(`${Config.ssoApiUrl}/confirmacao-email/reenviar`, config)
      .then(res => {
        if (res.ok) {
          alertEmailEnviado();
        } else {
          throw new Error(i18n.t('RESEND_EMAIL_ERROR'));
        }
      })
      .catch(() => {
        alertErroAoEnviarEmail();
      });
  };

  const fecharModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {modalVisible && (
        <ModalAceiteTermo
          modalVisible={modalVisible}
          fecharModal={fecharModal}
          atualizacao={
            pessoa &&
            pessoa.termoUsoAceito &&
            pessoa.termoUsoAceito.vigente !== null &&
            pessoa.termoUsoAceito.vigente === false
          }
        />
      )}
    </>
  );
}
