import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { OBTER_CONFIGURACAO_POR_NOME } from './queries';
import { ConfiguracaoEnum } from '../../shared/util/enums/configuracaoEnum';
import { ContextSettings } from '../../store/contexts/contextSettings';

export default function SettingsComponent() {
  const {
    setHabilitarModalidadeLead,
    setHabilitarModalidadeVitrine
  } = useContext(ContextSettings);

  const { data: setHabilitarModalidadeLeadData } = useQuery(
    OBTER_CONFIGURACAO_POR_NOME,
    {
      variables: {
        configuracaoEnum: ConfiguracaoEnum.HABILITAR_MODALIDADE_LEAD
      }
    }
  );

  setHabilitarModalidadeLead(
    setHabilitarModalidadeLeadData &&
      setHabilitarModalidadeLeadData.obterConfiguracaoPorNome.valor.valorBoolean
  );

  const { data: setHabilitarModalidadeVitrineData } = useQuery(
    OBTER_CONFIGURACAO_POR_NOME,
    {
      variables: {
        configuracaoEnum: ConfiguracaoEnum.HABILITAR_MODALIDADE_VITRINE
      }
    }
  );

  setHabilitarModalidadeVitrine(
    setHabilitarModalidadeVitrineData &&
      setHabilitarModalidadeVitrineData.obterConfiguracaoPorNome.valor
        .valorBoolean
  );

  return <></>;
}
