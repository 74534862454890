import Config from '../Config';

function links(pessoa) {
  const urlApplication = '/';
  const urlLogin = Config.loginUiUrl;
  return {
    applicationUrl: urlApplication,
    painel: '/painel',
    cadastrarNovoFornecedor: '/cadastro-novo-fornecedor',
    editarPerfilPf: '/cadastro-perfil',
    editarPerfilPj: '/cadastro-perfil/empresa',
    alterarSenhaPf: '/dados-cadastrais/cliente/alterar-senha',
    verPerfilPf: `/perfil/${pessoa && pessoa.slug ? pessoa.slug : ''}`,
    verPerfilPj: `/perfil/${
      pessoa &&
      pessoa.vinculo &&
      pessoa.vinculo.empresa &&
      pessoa.vinculo.empresa.slug
        ? pessoa.vinculo.empresa.slug
        : ''
    }`,
    homeAfiliado: '/home-afiliado',
    sair: '/',
    login: urlLogin,
    cadastroFornecedorCliente: '/homologado',
    cadastroFornecedor: '/cadastro',
    cadastroPj: '/cadastro',
    negociacao: '/negociacoes',
    painelLicenciado: '/painel',
    carteira: '/carteira',
    dadosCadastrais: '/dados-cadastrais',
    notificacoes: '/notificacoes',
    carrinho: '/carrinho',
    catalogoCliente: '/catalogo',
    landingPage: '/sd',
    cadastroIntegracao: '/solucao-inteligente/cadastro-integracao',
    solucaoDigitalPerguntasRespostas:
      '/solucao-inteligente/perguntas-e-respostas',
    painelAfiliado: '/painel/afiliado',
    cursoOnline: '/cursos-online',
    cursoSalaDeAula: '/cursos-online/sala-de-aula',
    cursosPerguntas: '/cursos-online/perguntas-respostas'
  };
}

export default links;
