import React, { useContext, useEffect, useState } from 'react';
import { ContextAlert } from '../../store/contexts/contextAlert';
import { toast, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import Icon from '@ant-design/icons';
import { ReactComponent as InfoCircle } from '../../images/svgs/info-circle.svg';
import { ReactComponent as CloseCircle } from '../../images/svgs/close-circle.svg';
import { ReactComponent as Close } from '../../images/svgs/close.svg';
import { ReactComponent as CheckCircle } from '../../images/svgs/check-circle.svg';
import { ReactComponent as Warning } from '../../images/svgs/warning.svg';

export function AlertGlobal() {
  const { stateAlert, hideAlerts } = useContext(ContextAlert);
  const [toastId, setToastId] = useState(null);

  const CloseButton = ({ closeToast }) => (
    <Icon
      component={Close}
      style={{ fontSize: '24px' }}
      className='mr-2'
      onClick={closeToast}
    />
  );

  useEffect(() => {
    if (
      !stateAlert.show ||
      stateAlert.initAnimation ||
      stateAlert.description === 'undefined ' ||
      hideAlerts
    ) {
      return;
    }
    setToastId(stateAlert.description);
    toast.configure({
      position: 'top-center',
      closeOnClick: false,
      transition: Slide,
      closeButton: <CloseButton />,
      bodyClassName: 'container position-relative',
      style: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        zIndex: '98',
        top: '0'
      }
    });

    if (toastId === stateAlert.description && !stateAlert.noSetTimeout) {
      toast.dismiss(toastId);
    }

    if (stateAlert.type === 'info') {
      toast.info(
        <div className='d-flex justify-content-between align-items-center w-100 flex-nowrap'>
          <div className='d-flex justify-content-start align-items-center'>
            <Icon
              component={InfoCircle}
              style={{ fontSize: '24px' }}
              className='mr-2'
            />
            <div className='mr-2'>{stateAlert.description}</div>
          </div>
          {stateAlert.button && <div>{stateAlert.button}</div>}
        </div>,
        {
          toastId: stateAlert.description,
          autoClose: stateAlert.noSetTimeout ? false : 8000,
          delay: 500
        }
      );
    } else if (stateAlert.type === 'error') {
      toast.error(
        <div className='d-flex justify-content-between align-items-center w-100 flex-nowrap'>
          <div className='d-flex justify-content-start align-items-center'>
            <Icon
              component={CloseCircle}
              style={{ fontSize: '24px' }}
              className='mr-2'
            />
            <div className='mr-2'>{stateAlert.description}</div>
          </div>
          {stateAlert.button && <div>{stateAlert.button}</div>}
        </div>,
        {
          toastId: stateAlert.description,
          autoClose: stateAlert.noSetTimeout ? false : 8000,
          delay: 500
        }
      );
    } else if (stateAlert.type === 'success') {
      toast.success(
        <div className='d-flex justify-content-between align-items-center w-100 flex-nowrap'>
          <div className='d-flex justify-content-start align-items-center'>
            <Icon
              component={CheckCircle}
              style={{ fontSize: '24px' }}
              className='mr-2'
            />
            <div className='mr-2'>{stateAlert.description}</div>
          </div>
          {stateAlert.button && <div>{stateAlert.button}</div>}
        </div>,
        {
          toastId: stateAlert.description,
          autoClose: stateAlert.noSetTimeout ? false : 8000,
          delay: 500
        }
      );
    } else if (stateAlert.type === 'warning') {
      toast.warn(
        <div className='d-flex justify-content-between align-items-center w-100 flex-nowrap'>
          <div className='d-flex justify-content-start align-items-center'>
            <Icon
              component={Warning}
              style={{ fontSize: '24px' }}
              className='mr-2'
            />
            <div className='mr-2'>{stateAlert.description}</div>
          </div>
          {stateAlert.button && <div>{stateAlert.button}</div>}
        </div>,
        {
          toastId: stateAlert.description,
          autoClose: stateAlert.noSetTimeout ? false : 8000,
          delay: 500
        }
      );
    }

    // eslint-disable-next-line
  }, [stateAlert]);
  return <></>;
}
