import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Container, StyledModalPrivacy as Modal } from './styles';
import Icon from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { useQuery } from 'react-apollo';
import { TERMO_TIPO } from '../../queries';
import { ReactComponent as TimesRound } from '../../../../images/svgs/times-round.svg';
import { editorRenderStyles } from '../../../../shared/util/editor-render-styles';
import { parseToEditorJS } from '../../../../shared/util/transfoms';

const Output = lazy(() => import('editorjs-react-renderer'));

export default function ModalPrivacy(props) {
  const [modalVisible, setModalVisible] = useState(false);

  const { data, loadingTermo } = useQuery(TERMO_TIPO, {
    variables: { tipo: 'PRIVACIDADE' }
  });

  useEffect(() => {
    setModalVisible(props.modalVisible);
  }, [props.modalVisible]);

  return (
    <Modal
      closeIcon={<Icon component={TimesRound} />}
      title='Poítica de Privacidade'
      open={modalVisible}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={[
        <Button
          key='submit'
          type='primary'
          shape='round'
          className='wide'
          onClick={props.handleOk}>
          Ok
        </Button>
      ]}>
      <Container>
        <Skeleton active loading={loadingTermo}>
          {data && data.obterTermoVigentePorTipo && (
            <Suspense fallback={<></>}>
              <Output
                data={parseToEditorJS(data.obterTermoVigentePorTipo.texto)}
                style={editorRenderStyles}
              />
            </Suspense>
          )}
        </Skeleton>
      </Container>
    </Modal>
  );
}
