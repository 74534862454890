import React, { useContext, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Button as ButtonLink } from '../../../LinkComponent/LinkComponent';
import { useMutation } from 'react-apollo';
import { Container } from './styles';
import { RESPONDER_CONVITE_FORNECEDOR } from './queries';
import RespostaConvite from './components/RespostaConvite/RespostaConvite';
import MensagemConvite from './components/MensagemConvite/MensagemConvite';
import { ReactComponent as TimesRound } from '../../../../images/svgs/times-round.svg';
import { ContextPerson } from '../../../../store/contexts/contextPerson';
import { situacaoConviteEnum } from '../../../../shared/util/enums/situacaoConviteEnum';
import i18n from '../../../../shared/util/i18n';

export default function ModalResponderConvite({
  showModal,
  setShowModal,
  pendingInvitation,
  setPendingInvitation
}) {
  const { setMustUpdatePerson } = useContext(ContextPerson);
  const [pendingInvitationUpdate, setPendingInvitationUpdate] = useState();
  const [situacao, setSituacao] = useState();
  const [aceiteTermo, setAceiteTermo] = useState(false);

  const [responderConviteFornecedor, { loading }] = useMutation(
    RESPONDER_CONVITE_FORNECEDOR,
    {
      onCompleted: data => {
        if (data && data.responderConviteFornecedor) {
          setPendingInvitationUpdate(data.responderConviteFornecedor);
          setMustUpdatePerson(true);

          data.responderConviteFornecedor.situacao ===
            situacaoConviteEnum.ACEITO && setSituacao(true);

          data.responderConviteFornecedor.situacao ===
            situacaoConviteEnum.RECUSADO && setSituacao(false);
        }
      }
    }
  );

  const handleReplyInvitation = status =>
    responderConviteFornecedor({
      variables: {
        conviteFornecedorId: pendingInvitation.id,
        situacao: status
      }
    });

  const goToHomepage = () => '/';

  const goToPainel = () => '/painel';

  const onChange = e => {
    setAceiteTermo(e.target.checked);
  };

  return (
    <Container>
      <Modal
        closeIcon={<Icon component={TimesRound} />}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={
          situacao === undefined
            ? [
                <Button
                  onClick={() =>
                    handleReplyInvitation(situacaoConviteEnum.RECUSADO)
                  }
                  disabled={loading}
                  key='cancel'
                  type='primary'
                  danger
                  shape='round'
                  className='wide'>
                  {i18n.t('REFUSE')}
                </Button>,
                <Button
                  onClick={() =>
                    handleReplyInvitation(situacaoConviteEnum.ACEITO)
                  }
                  loading={loading}
                  key='accept'
                  type='success'
                  shape='round'
                  className='wide'
                  disabled={!aceiteTermo}>
                  {i18n.t('ACCEPT')}
                </Button>
              ]
            : [
                !situacao && (
                  <ButtonLink
                    onClick={() =>
                      setPendingInvitation(pendingInvitationUpdate)
                    }
                    href={goToPainel()}
                    key='cancel'
                    type='primary'
                    ghost
                    shape='round'
                    className='wide'>
                    {i18n.t('BACK_TO_PAINEL')}
                  </ButtonLink>
                ),
                <ButtonLink
                  onClick={() => setPendingInvitation(pendingInvitationUpdate)}
                  href={situacao ? goToPainel() : goToHomepage()}
                  key='accept'
                  type='primary'
                  shape='round'
                  className='wide'>
                  {situacao
                    ? i18n.t('BACK_TO_PAINEL')
                    : i18n.t('GO_TO_HOME_PAGE')}
                </ButtonLink>
              ]
        }
        width='600px'
        bodyStyle={{ padding: '24px 40px' }}>
        {situacao === undefined ? (
          <MensagemConvite
            pendingInvitation={pendingInvitation}
            onChange={onChange}
          />
        ) : (
          <RespostaConvite situacao={situacao} />
        )}
      </Modal>
    </Container>
  );
}
