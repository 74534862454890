/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import axios from 'axios';
import Config from '../shared/Config';
import {
  ACCESS_TOKEN,
  clearTokenAndRefreshToken,
  getRefreshToken,
  getToken,
  isTokenUndefinedOrExpired,
  setToken
} from '../shared/util/utilToken';
import { actionAlert } from '../store/actions/actionAlert';
import { ContextAlert } from '../store/contexts/contextAlert';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

const { buildAxiosFetch } = require('@lifeomic/axios-fetch');

function ApolloClientProvider({ children }) {
  const { dispatchAlert } = useContext(ContextAlert);
  const apolloClient = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        let errors = '';
        graphQLErrors.forEach(({ message }) => {
          errors += `${
            typeof message === 'string' ? message : message.message
          } `;
        });
        dispatchAlert({
          type: actionAlert.ADD,
          payload: {
            message: 'ERROR',
            description: errors,
            type: 'error'
          }
        });
      }

      if (networkError) {
        dispatchAlert({
          type: actionAlert.ADD,
          payload: {
            message: `Problemas na rede: ${operation.operationName}`,
            description:
              'Problemas de comunicação, verifique sua conexão e tente novamente.',
            type: 'error'
          }
        });
      }
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${getToken()}`
        }
      };
    });

    const tokenRefreshLink = new TokenRefreshLink({
      accessTokenField: ACCESS_TOKEN,
      isTokenValidOrUndefined: () => !isTokenUndefinedOrExpired(),
      fetchAccessToken: () =>
        fetch(`${Config.ssoApiUrl}/auth/refresh-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: `{ "refreshToken": "${getRefreshToken()}" }`
        }),
      handleFetch: accessToken => {
        setToken(accessToken);
      },
      handleError: () => {
        // full control over handling token fetch Error

        clearTokenAndRefreshToken();
        // window.location.href = `${Config.loginUiUrl}?requestedURL=${window.location.href}`;
        // A linha acima foi comentada por causa da Issue #479. Agora a rota
        // será protegida no App.js e não mais no refresh-token
      }
    });

    const uploadLink = createUploadLink({
      uri: Config.apiUrl,
      // headers: {
      //   'keep-alive': 'true'
      // },
      fetch: buildAxiosFetch(axios, (config, input, init) => ({
        ...config,
        onUploadProgress: init.onUploadProgress
      }))
    });

    // Create a WebSocket link:
    const wsLink = new WebSocketLink({
      uri: Config.apiWss,
      options: {
        reconnect: true,
        lazy: true
      }
    });

    const cache = new InMemoryCache({
      addTypename: false,
      resultCaching: false,
      cacheRedirects: false
    });
    const stateLink = withClientState({
      cache
    });

    // TODO: estudar formas de utilizar o cache na aplicação.
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    };

    return new ApolloClient({
      link: split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        ApolloLink.from([
          errorLink,
          stateLink,
          tokenRefreshLink,
          authLink,
          // httpLink,
          uploadLink
        ])
      ),
      cache,
      defaultOptions: defaultOptions
    });
  }, []);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export default ApolloClientProvider;
