import styled from 'styled-components';

export const DivMain = styled.div`
  .title-highlight {
    color: var(--color-primary);
    font-weight: 600;
  }

  .slick-slide > div {
    display: flex;
    margin: 10px;
    justify-content: center;
  }

  .img-header {
    object-fit: cover;
    height: 440px;
    border: 1px solid #707070; /* stylelint-disable-line color-no-hex */
  }

  .image-div {
    position: absolute;
    width: 100%;
  }

  .scroll-to-learn {
    text-align: center;
    font-weight: lighter;
    font-size: 12px;
    letter-spacing: 0.7px;
    color: var(--text-color);
  }

  .divPodeGostar {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .divPodeGostar > :last-child {
    margin-right: 0;
  }

  .button-ghost {
    border-color: var(--color-white) !important;
    color: var(--color-white) !important;
  }

  .button-ghost:hover {
    background-color: #29b8ff !important; /* stylelint-disable-line color-no-hex */
  }

  .favoriteImg {
    position: absolute;
    right: 10px;
  }

  .col-texts {
    max-width: 750px;
  }

  .title {
    margin-top: 95px;
    font-size: 32px;
    color: var(--color-white);
    letter-spacing: 1.6px;
  }

  .break-line-title {
    display: none;
  }

  @media (max-width: 992px) {
    .title {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .title {
      padding: 0 40px 0 0;
    }
  }

  @media (max-width: 480px) {
    .break-line-title {
      display: block;
    }
  }

  @media (max-width: 385px) {
    .title {
      font-size: 28px !important;
      padding: 0 16px 0 0;
    }

    .sub-title {
      font-size: 14px !important;
    }
  }

  .sub-title {
    font-size: 16px !important;
    font-weight: lighter;
    color: var(--color-white);
    letter-spacing: 1px;
    margin-top: 25px;
    line-height: 35px;
  }

  .vertical-line {
    width: 1px;
    background-color: var(--color-gray);
    height: 15px;
    opacity: 0.5;
    margin-top: 3px;
  }

  .card-options {
    /*  margin-top: 40px; */
  }

  .divQueroMeTornar {
    /* stylelint-disable-next-line color-no-hex */
    background: linear-gradient(to right, #0069a7, #009af6);
  }

  .divQueroMeTornar .ant-btn-primary {
    border-color: var(--color-white);
    color: var(--color-white);
    float: right;
  }

  .colConteComSebrae {
    height: 272px;
    /* stylelint-disable-next-line color-no-hex */
    background: linear-gradient(to left, #0069a7, #009af6);
    padding: 0 3rem 0 3rem;
  }

  .containerHome {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: var(--color-white);
  }

  .solutions-subtitle {
    font-weight: lighter;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: var(--text-color);
    line-height: 2em;
  }

  .title-home {
    text-align: left;
    font-size: 26px;
    font-weight: lighter;
    letter-spacing: 2px;
    color: var(--text-color);
  }

  .curatorship-description,
  .be-a-supplier-description {
    padding: 3em 0;
  }

  .home-section-4-subtitle {
    text-align: left;
    font-weight: lighter;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: var(--color-white-gray);
    line-height: 2em;
  }

  .carousel-item {
    padding: 10px 5px;
  }

  @media (min-width: 768px) {
    .title-home {
      text-align: center;
      font-size: 26px;
    }

    .curatorship-description,
    .be-a-supplier-description {
      padding: 3em;
    }

    .home-section-4-subtitle {
      text-align: center;
    }
  }

  .container-conte {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .title-home {
      font-size: 15px;
    }
  }

  @media (min-width: 992px) {
    .title {
      margin-top: 170px;
    }
  }
`;

export const DivBuscar = styled.div`
  background-color: var(--color-primary);
  width: 100%;
  min-height: 63px;
  border-radius: 63px;

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 10px;
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    display: none;
  }

  .ant-btn .anticon {
    display: block;
  }

  .ant-btn > span {
    display: none;
  }

  .ant-btn-primary {
    border-color: var(--color-white);
    color: var(--color-white);
    float: right;
  }

  @media (max-width: 767px) {
    .container-search {
      width: 100% !important;
      padding: 0px 20px !important;
    }
  }

  @media (min-width: 768px) {
    .ant-btn {
      width: 260px;
    }

    .ant-btn > span,
    .ant-input-affix-wrapper .ant-input-suffix {
      display: block;
    }

    .ant-btn .anticon {
      display: none;
    }

    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 0;
    }

    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 35px;
    }
  }
`;

export const ContainerFornecedorPopular = styled.div`
  .avatar-fornecedor-popular {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
