import {
  TipoNegociacao,
  UserRoleNegociacao,
  ChaveStepNegociacao,
  StatusNegociacao,
  NotificacoesNegociacao,
  StatusStepNegociacao
} from './enums/negociacaoEnums';
import { sebraetecSituacaoNotaFiscalEnum } from './enums/sebraetecSituacaoNotaFiscalEnum';
import { situacaoDevolucaoOrcamentoEnum } from './enums/situacaoDevolucaoOrcamentoEnum';
import { situacaoSebraetecOrcamentoEnum } from './enums/situacaoSebraetecOrcamentoEnum';
import { getSmallestImage } from './imageUtils';

export function prepareNegotiation(neg, person) {
  const newNeg = { ...neg };
  newNeg.participante = getOtherParticipant(neg.participantes, person);
  newNeg.pessoa = newNeg.participante.pessoa;
  if (newNeg.participante.empresa) {
    newNeg.pessoa.empresa = newNeg.participante.empresa.nomeFantasia;
  }
  newNeg.tipo = getTipoNegociacao(neg);
  newNeg.userRole = getUserRole(newNeg, person);
  newNeg.dataFinal = getDataRealizacaoNegociacao(newNeg);
  return newNeg;
}

export function getDataRealizacaoNegociacao(neg) {
  if (!neg || !neg.itens) return null;
  const agenda = neg.itens.find(item => item.chave === 'agenda');
  if (!agenda || !agenda.valor || !agenda.valor.dataFinal) return null;
  return agenda.valor.dataFinal;
}
export function getUserRole(negociacaoTipada, person) {
  const index = participanteIndex(negociacaoTipada, person);

  if (negociacaoTipada.tipo === TipoNegociacao.CONSULTORIA) {
    return index === 0
      ? UserRoleNegociacao.CLIENTE
      : UserRoleNegociacao.CONSULTOR;
  }

  if (negociacaoTipada.tipo === TipoNegociacao.SAAS_CONTRATO) {
    return index === 0
      ? UserRoleNegociacao.CLIENTE
      : UserRoleNegociacao.FORNECEDOR;
  }

  if (negociacaoTipada.tipo === TipoNegociacao.PRODUTO) {
    return index === 0
      ? UserRoleNegociacao.LICENCIADO
      : UserRoleNegociacao.FORNECEDOR;
  }

  if (negociacaoTipada.tipo === TipoNegociacao.SEBRAETEC) {
    return index === 0
      ? UserRoleNegociacao.CLIENTE
      : UserRoleNegociacao.FORNECEDOR;
  }

  return UserRoleNegociacao.CLIENTE;
}

export function getTipoNegociacao(negociacao) {
  if (negociacao.produto) return TipoNegociacao.PRODUTO;
  if (negociacao.consultoria) return TipoNegociacao.CONSULTORIA;
  if (negociacao.saasContrato) return TipoNegociacao.SAAS_CONTRATO;
  if (negociacao.orcamentoSebraetec) return TipoNegociacao.SEBRAETEC;

  return 'NEGOCIAÇÃO INVÁLIDA';
}

export function getActualParticipant(participantes, pessoa) {
  if (!participantes || !pessoa || !participantes.length > 1) return;
  if (pessoa.vinculo && pessoa.vinculo.empresa && participantes[0].empresa)
    return pessoa.vinculo.empresa.id === participantes[0].empresa.id
      ? participantes[0]
      : participantes[1];
  return pessoa.id === participantes[0].pessoa.id
    ? participantes[0]
    : participantes[1];
}

export function getOtherParticipant(participantes, pessoa) {
  if (!participantes || !pessoa || !participantes.length > 1) return;
  if (pessoa.vinculo && pessoa.vinculo.empresa && participantes[0].empresa)
    return pessoa.vinculo.empresa.id === participantes[0].empresa.id
      ? participantes[1]
      : participantes[0];
  return pessoa.id === participantes[0].pessoa.id
    ? participantes[1]
    : participantes[0];
}

export function isActualPerson(currentPerson, id) {
  if (!currentPerson || !id) return false;
  return !!(
    currentPerson.id === id ||
    (currentPerson.vinculo && currentPerson.vinculo.empresa.id === id)
  );
}

export function participanteIndex(negociacaoTipada, person) {
  return negociacaoTipada.participantes.findIndex(p => {
    let personIndex;
    if (p.empresa && p.empresa.id && p.empresa.id === person.id)
      personIndex = p.empresa.id === person.id;
    if (p.pessoa && p.pessoa.id && p.pessoa.id === person.id)
      personIndex = p.pessoa.id === person.id;
    return personIndex;
  });
}

/**
 * Função que retorna notificações da negociação
 * @param {Object} negotiation Objeto da negociação que será verificado
 * @param {boolean} needCount True para retornar o numero e cor da notificação, false retorna apenas se existe a notificação
 * @param {string} type Tipo de notificação que deseja verificar. Ex: NotificacoesNegociacao.TODAS, NotificacoesNegociacao.CONCLUIDA
 */
export function getNotification(negotiation, needCount, type) {
  let qtdColor = { qtd: 0, color: '' };

  const all = type === NotificacoesNegociacao.TODAS;

  const cliente = negotiation.userRole === UserRoleNegociacao.CLIENTE;
  const consultor = negotiation.userRole === UserRoleNegociacao.CONSULTOR;
  const fornecedor = negotiation.userRole === UserRoleNegociacao.FORNECEDOR;
  const licenciado = negotiation.userRole === UserRoleNegociacao.LICENCIADO;

  const isFinalizada = () => {
    let negItens = negotiation.itens;
    if (negotiation.tipo === TipoNegociacao.CONSULTORIA) {
      negItens = negItens.filter(neg => neg.chave !== 'materiais');
    }
    if (
      negotiation.tipo === TipoNegociacao.CONSULTORIA &&
      !negotiation.presencial
    ) {
      negItens = negItens.filter(
        neg => !disabledStepsOnRemote.includes(neg.chave)
      );
    }
    return (
      negItens.every(
        item =>
          item.status === StatusStepNegociacao.PROPOSTO ||
          item.status === StatusStepNegociacao.ACEITO
      ) && negotiation.status !== StatusNegociacao.PROPOSTA_ENVIADA
    );
  };

  if (
    (type === NotificacoesNegociacao.CONCLUIDA || all) &&
    negotiation.status === StatusNegociacao.CONCLUIDA &&
    !(
      fornecedor &&
      negotiation.tipo === TipoNegociacao.SEBRAETEC &&
      negotiation.orcamentoSebraetec &&
      (negotiation.orcamentoSebraetec.situacao ===
        situacaoSebraetecOrcamentoEnum.AGUARDANDO_ENVIO_NF_DEVOLUCAO ||
        negotiation.orcamentoSebraetec.situacao ===
          situacaoSebraetecOrcamentoEnum.AGUARDANDO_REVISAO_NF_DEVOLUCAO)
    )
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'success';
  }

  if (
    (type === NotificacoesNegociacao.CANCELADA || all) &&
    negotiation.status === StatusNegociacao.CANCELADA
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type === NotificacoesNegociacao.AGUARDANDO_PAGAMENTO || all) &&
    (consultor || fornecedor) &&
    negociacoesEmAndamento.includes(negotiation.status) &&
    (negotiation.status === StatusNegociacao.PROPOSTA_ACEITA ||
      negotiation.status === StatusNegociacao.AGUARDANDO_PAGAMENTO)
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'success';
  }

  if (
    (type === NotificacoesNegociacao.PROPOSTA_ACEITA_CLIENTE || all) &&
    cliente &&
    (negotiation.status === StatusNegociacao.PROPOSTA_ACEITA ||
      negotiation.status === StatusNegociacao.AGUARDANDO_PAGAMENTO)
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.PROPOSTA_ACEITA_LICENCIADO || all) &&
    licenciado &&
    (negotiation.status === 'PROPOSTA_ACEITA' ||
      negotiation.status === 'AGUARDANDO_PAGAMENTO')
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.AGUARDANDO_CONFIRMACAO_PAGAMENTO || all) &&
    (cliente || licenciado) &&
    negotiation.status === StatusNegociacao.AGUARDANDO_CONFIRMACAO_PAGAMENTO
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'success';
  }

  if (
    (type === NotificacoesNegociacao.NOTA_FISCAL || all) &&
    (negotiation.notaFiscalPdf ||
      ((consultor || fornecedor) &&
        negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
        new Date(negotiation.dataFinal) <= new Date()))
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = negotiation.notaFiscalPdf ? 'success' : 'warning';
  }

  if (
    (type === NotificacoesNegociacao.ABRIR_DISPUTA || all) &&
    cliente &&
    negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
    negotiation.permiteDisputa
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.FINALIZAR_EXECUCAO || all) &&
    cliente &&
    negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
    negotiation.permiteConcluir
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.RECUSAR || all) &&
    (licenciado || consultor) &&
    negotiation.status === 'PROPOSTA_RECUSADA' &&
    !isFinalizada()
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type === NotificacoesNegociacao.FINALIZAR || all) &&
    (licenciado || consultor) &&
    (negotiation.status === 'EM_ANDAMENTO' ||
      negotiation.status === 'PROPOSTA_RECUSADA') &&
    isFinalizada()
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.RESPONDER || all) &&
    !licenciado &&
    !consultor &&
    negotiation.status === 'PROPOSTA_ENVIADA'
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.VER_DISPUTA_SAAS || all) &&
    negotiation.tipo === TipoNegociacao.SAAS_CONTRATO &&
    negotiation.saasContrato &&
    ((fornecedor && negotiation.saasContrato.reclamacaoEmAberto) ||
      negotiation.saasContrato.situacao === StatusNegociacao.EM_DISPUTA)
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.MEDIACAO_SEBRAE || all) &&
    cliente &&
    negotiation.tipo === TipoNegociacao.SAAS_CONTRATO &&
    negotiation.saasContrato &&
    negotiation.saasContrato.situacao !== StatusNegociacao.EM_DISPUTA &&
    negotiation.saasContrato.reclamacaoEmAberto
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_AGUARDANDO_NF || all) &&
    fornecedor &&
    negotiation.orcamentoSebraetec &&
    (negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.AGUARDANDO_ENVIO_NF ||
      negotiation.orcamentoSebraetec.situacao ===
        situacaoSebraetecOrcamentoEnum.AGUARDANDO_ENVIO_NF_DEVOLUCAO) &&
    negotiation.orcamentoSebraetec.situacaoNotaFiscal !==
      sebraetecSituacaoNotaFiscalEnum.REPROVADO
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_NF_EM_ANALISE || all) &&
    fornecedor &&
    negotiation.orcamentoSebraetec &&
    (negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.AGUARDANDO_REVISAO_NF ||
      negotiation.orcamentoSebraetec.situacao ===
        situacaoSebraetecOrcamentoEnum.AGUARDANDO_REVISAO_NF_DEVOLUCAO)
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'info';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_NF_REPROVADA || all) &&
    fornecedor &&
    negotiation.orcamentoSebraetec &&
    negotiation.orcamentoSebraetec.situacaoNotaFiscal ===
      sebraetecSituacaoNotaFiscalEnum.REPROVADO
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_DEVOLUCAO_CLIENTE || all) &&
    cliente &&
    negotiation.orcamentoSebraetec &&
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.DEVOLUCAO_EM_ANALISE
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_DEVOLUCAO_AJUSTES_FORNECEDOR ||
      all) &&
    fornecedor &&
    negotiation.orcamentoSebraetec &&
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.AGUARDANDO_EXECUCAO &&
    negotiation.orcamentoSebraetec.devolucao &&
    negotiation.orcamentoSebraetec.devolucao.situacao ===
      situacaoDevolucaoOrcamentoEnum.ENCERRADA
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_AGUARDANDO_CONFIRMACAO_CLIENTE ||
      all) &&
    cliente &&
    negotiation.orcamentoSebraetec &&
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.AGUARDANDO_CONFIRMACAO_CLIENTE
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type ===
      NotificacoesNegociacao.SEBRAETEC_DEVOLUCAO_AGUARDANDO_FORNECEDOR ||
      all) &&
    fornecedor &&
    negotiation.orcamentoSebraetec &&
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.DEVOLUCAO_EM_ANALISE &&
    negotiation.orcamentoSebraetec.devolucao &&
    negotiation.orcamentoSebraetec.devolucao.situacao ===
      situacaoDevolucaoOrcamentoEnum.AGUARDANDO_AVALIACAO_FORNECEDOR
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'danger';
  }

  if (
    (type === NotificacoesNegociacao.SEBRAETEC_DEVOLUCAO_EM_ANALISE || all) &&
    fornecedor &&
    negotiation.orcamentoSebraetec &&
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.DEVOLUCAO_EM_ANALISE &&
    negotiation.orcamentoSebraetec.devolucao &&
    negotiation.orcamentoSebraetec.devolucao.situacao !==
      situacaoDevolucaoOrcamentoEnum.AGUARDANDO_AVALIACAO_FORNECEDOR
  ) {
    qtdColor.qtd = qtdColor.qtd + 1;
    qtdColor.color = 'warning';
  }

  return needCount ? qtdColor : qtdColor.qtd > 0;
}

export const getAvatarNegotiation = negotiation => {
  if (negotiation.tipo === UserRoleNegociacao.LICENCIADO) {
    return (
      negotiation.participante &&
      negotiation.participante.empresa &&
      getSmallestImage(
        negotiation.participante.empresa.fotoUrlResponsive,
        negotiation.participante.empresa.fotoUrl
      )
    );
  }
  return (
    negotiation.pessoa &&
    getSmallestImage(
      negotiation.pessoa.fotoUrlResponsive,
      negotiation.pessoa.fotoUrl
    )
  );
};

export const getNegotiationCompanyName = negotiation => {
  if (negotiation === TipoNegociacao.PRODUTO && negotiation.produto) {
    return (
      negotiation.participante &&
      negotiation.participante.empresa &&
      negotiation.participante.empresa.nomeFantasia
    );
  }
  if (
    negotiation.tipo === TipoNegociacao.CONSULTORIA &&
    negotiation.consultoria
  ) {
    return negotiation.consultoria.empresa &&
      negotiation.participante &&
      negotiation.participante.empresa &&
      negotiation.consultoria.empresa.id === negotiation.participante.empresa.id
      ? negotiation.consultoria.empresa.nomeFantasia
      : undefined;
  }
};

export const isRejectedProposal = negotiation =>
  negotiation.status === StatusNegociacao.PROPOSTA_RECUSADA &&
  (negotiation.userRole === UserRoleNegociacao.CONSULTOR ||
    negotiation.userRole === UserRoleNegociacao.LICENCIADO);

export const isAwaitingPayment = negotiation =>
  (negotiation.status === StatusNegociacao.PROPOSTA_ACEITA &&
    negotiation.userRole === UserRoleNegociacao.CONSULTOR) ||
  ((negotiation.status === StatusNegociacao.AGUARDANDO_PAGAMENTO ||
    negotiation.status === StatusNegociacao.AGUARDANDO_CONFIRMACAO_PAGAMENTO) &&
    (negotiation.userRole === UserRoleNegociacao.CONSULTOR ||
      negotiation.userRole === UserRoleNegociacao.FORNECEDOR));

export const isMakePayment = negotiation =>
  (negotiation.status === StatusNegociacao.PROPOSTA_ACEITA ||
    negotiation.status === StatusNegociacao.AGUARDANDO_PAGAMENTO) &&
  (negotiation.userRole === UserRoleNegociacao.CLIENTE ||
    negotiation.userRole === UserRoleNegociacao.LICENCIADO);

export const isConfirmingPayment = negotiation =>
  negotiation.status === StatusNegociacao.AGUARDANDO_CONFIRMACAO_PAGAMENTO &&
  (negotiation.userRole === UserRoleNegociacao.CLIENTE ||
    negotiation.userRole === UserRoleNegociacao.LICENCIADO);

export const isAnswerProposal = negotiation =>
  negotiation.status === StatusNegociacao.PROPOSTA_ENVIADA &&
  (negotiation.userRole === UserRoleNegociacao.CLIENTE ||
    negotiation.userRole === UserRoleNegociacao.FORNECEDOR);

export const isSendInvoice = negotiation =>
  negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
  (negotiation.userRole === UserRoleNegociacao.CONSULTOR ||
    negotiation.userRole === UserRoleNegociacao.FORNECEDOR) &&
  new Date(negotiation.dataFinal) <= new Date() &&
  !negotiation.notaFiscalPdf;

export const isSentInvoice = negotiation =>
  negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
  (negotiation.userRole === UserRoleNegociacao.CONSULTOR ||
    negotiation.userRole === UserRoleNegociacao.FORNECEDOR) &&
  new Date(negotiation.dataFinal) <= new Date() &&
  negotiation.notaFiscalPdf;

export const isAwaitingInvoice = negotiation =>
  negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
  (negotiation.userRole === UserRoleNegociacao.CLIENTE ||
    negotiation.userRole === UserRoleNegociacao.LICENCIADO) &&
  new Date(negotiation.dataFinal) <= new Date() &&
  !negotiation.notaFiscalPdf;

export const isConfirmExecution = negotiation =>
  negotiation.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
  (negotiation.userRole === UserRoleNegociacao.CLIENTE ||
    negotiation.userRole === UserRoleNegociacao.LICENCIADO) &&
  new Date(negotiation.dataFinal) <= new Date() &&
  negotiation.notaFiscalPdf;

export const isSebraetecDone = negotiation =>
  negotiation.status === StatusNegociacao.CONCLUIDA &&
  negotiation.tipo === TipoNegociacao.SEBRAETEC;

export const isSebraetecReturnDone = negotiation =>
  negotiation.tipo === TipoNegociacao.SEBRAETEC &&
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacao ===
    situacaoSebraetecOrcamentoEnum.DEVOLUCAO_CONCLUIDA;

export const isSebraetecClientReturnDone = negotiation =>
  negotiation.userRole === UserRoleNegociacao.CLIENTE &&
  negotiation.tipo === TipoNegociacao.SEBRAETEC &&
  negotiation.status === StatusNegociacao.CONCLUIDA &&
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacao &&
  (negotiation.orcamentoSebraetec.situacao ===
    situacaoSebraetecOrcamentoEnum.AGUARDANDO_ENVIO_NF_DEVOLUCAO ||
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.AGUARDANDO_REVISAO_NF_DEVOLUCAO ||
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.DEVOLUCAO_CONCLUIDA);

export const isSebraetecWaitingInvoice = negotiation =>
  negotiation.orcamentoSebraetec &&
  (negotiation.orcamentoSebraetec.situacao ===
    situacaoSebraetecOrcamentoEnum.AGUARDANDO_ENVIO_NF ||
    negotiation.orcamentoSebraetec.situacao ===
      situacaoSebraetecOrcamentoEnum.AGUARDANDO_ENVIO_NF_DEVOLUCAO);

export const isSebraetecWaitingInvoiceReview = negotiation =>
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacaoNotaFiscal ===
    sebraetecSituacaoNotaFiscalEnum.PENDENTE;

export const isSebraetecInvoiceRejected = negotiation =>
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacaoNotaFiscal ===
    sebraetecSituacaoNotaFiscalEnum.REPROVADO;

export const isSebraetecWaitingConfirmation = negotiation =>
  negotiation.tipo === TipoNegociacao.SEBRAETEC &&
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacao ===
    StatusNegociacao.AGUARDANDO_CONFIRMACAO_CLIENTE;

export const isSebraetecReturn = negotiation =>
  negotiation.tipo === TipoNegociacao.SEBRAETEC &&
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacao ===
    situacaoSebraetecOrcamentoEnum.DEVOLUCAO_EM_ANALISE;

export const isSebraetecReturnWaitingSupplier = negotiation =>
  negotiation.tipo === TipoNegociacao.SEBRAETEC &&
  negotiation.orcamentoSebraetec &&
  negotiation.orcamentoSebraetec.situacao ===
    situacaoSebraetecOrcamentoEnum.DEVOLUCAO_EM_ANALISE &&
  negotiation.orcamentoSebraetec.devolucao &&
  negotiation.orcamentoSebraetec.devolucao.situacao ===
    situacaoDevolucaoOrcamentoEnum.AGUARDANDO_AVALIACAO_FORNECEDOR;

/**
 * Função que retorna um objeto simplificado para o card da lista de negociações
 * @param {Object} list Array do objeto da negociação puro
 */
export const handleListNegotiations = list =>
  list &&
  list.map(
    item =>
      item && {
        ...item,
        id: item.id,
        nome: item.nome,
        pessoaNome: item.pessoa && item.pessoa.nome,
        empresaNome: getNegotiationCompanyName(item),
        tipo: item.participante.tipo,
        avatar: getAvatarNegotiation(item),
        qtdMensagensNaoLidas:
          item.conversa && item.conversa.qtdMensagensNaoLidas,
        propostaRecusada: isRejectedProposal(item),
        aguardandoPagamento: isAwaitingPayment(item),
        realizarPagamento: isMakePayment(item),
        confirmandoPagamento: isConfirmingPayment(item),
        responderProposta: isAnswerProposal(item),
        aguardandoExecucao:
          item.status === StatusNegociacao.AGUARDANDO_EXECUCAO &&
          new Date(item.dataFinal) > new Date(item),
        enviarNF: isSendInvoice(item),
        enviadaNF: isSentInvoice(item),
        aguardandoNF: isAwaitingInvoice(item),
        confirmarExecucao: isConfirmExecution(item),
        emDisputa: item.status === StatusNegociacao.EM_DISPUTA,
        disputaConcluida: item.status === StatusNegociacao.CONCLUIDA_DISPUTA,
        concluida: item.status === StatusNegociacao.CONCLUIDA,
        cancelada: item.status === StatusNegociacao.CANCELADA,
        isSebraetecDone: isSebraetecDone(item),
        isSebraetecWaitingInvoice: isSebraetecWaitingInvoice(item),
        isSebraetecInvoiceRejected: isSebraetecInvoiceRejected(item),
        isSebraetecWaitingInvoiceReview: isSebraetecWaitingInvoiceReview(item),
        isSebraetecWaitingConfirmation: isSebraetecWaitingConfirmation(item),
        isSebraetecReturn: isSebraetecReturn(item),
        isSebraetecReturnDone: isSebraetecReturnDone(item),
        isSebraetecClientReturnDone: isSebraetecClientReturnDone(item),
        isSebraetecReturnWaitingSupplier: isSebraetecReturnWaitingSupplier(item)
      }
  );

export const disabledStepsOnRemote = [
  ChaveStepNegociacao.ALIMENTACAO,
  ChaveStepNegociacao.DESLOCAMENTO,
  ChaveStepNegociacao.HOSPEDAGEM
];

export function filtroDisabledSteps(negotiation, item) {
  if (
    negotiation.tipo === TipoNegociacao.CONSULTORIA &&
    !negotiation.presencial &&
    (item.tipo === 'BOOLEAN' || item.tipo === 'MATERIAIS')
  ) {
    return false;
  }

  if (
    negotiation.tipo === TipoNegociacao.CONSULTORIA &&
    item.tipo === 'MATERIAIS' &&
    negotiation.presencial
  ) {
    return false;
  }

  const notFiltered =
    !negotiation.tipo === TipoNegociacao.CONSULTORIA ||
    negotiation.presencial ||
    !disabledStepsOnRemote.includes(item.chave);

  return notFiltered;
}

export const negociacoesEmAndamento = [
  StatusNegociacao.EM_ANDAMENTO,
  StatusNegociacao.PROPOSTA_ACEITA,
  StatusNegociacao.PROPOSTA_ENVIADA,
  StatusNegociacao.PROPOSTA_RECUSADA,
  StatusNegociacao.AGUARDANDO_PAGAMENTO,
  StatusNegociacao.AGUARDANDO_CONFIRMACAO_PAGAMENTO,
  StatusNegociacao.AGUARDANDO_EXECUCAO,
  StatusNegociacao.EM_DISPUTA
];

export const negociacoesFeitoPagamento = [
  StatusNegociacao.AGUARDANDO_EXECUCAO,
  StatusNegociacao.EM_DISPUTA,
  StatusNegociacao.CONCLUIDA,
  StatusNegociacao.CONCLUIDA_DISPUTA
];

export const negociacoesFinalizadas = [
  StatusNegociacao.CONCLUIDA,
  StatusNegociacao.CONCLUIDA_DISPUTA
];

export const negociacoesCanceladas = [StatusNegociacao.CANCELADA];

export const negociacoesPropostas = [
  StatusNegociacao.PROPOSTA_ACEITA,
  StatusNegociacao.AGUARDANDO_PAGAMENTO,
  StatusNegociacao.AGUARDANDO_EXECUCAO,
  StatusNegociacao.EM_DISPUTA,
  StatusNegociacao.CONCLUIDA,
  StatusNegociacao.CONCLUIDA_DISPUTA
];

export const observacaoObrigatorio = [
  ChaveStepNegociacao.AGENDA,
  ChaveStepNegociacao.ALIMENTACAO,
  ChaveStepNegociacao.HOSPEDAGEM,
  ChaveStepNegociacao.MATERIAIS,
  ChaveStepNegociacao.VALOR
];
