import { Col } from 'antd';
import styled from 'styled-components';

export const CardContainer = styled(Col)`
  .card {
    background-color: var(--color-gray-transparent);
    backdrop-filter: blur(46px);
    position: relative;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px 10px !important;
    border-radius: 19px;
    border: 1px solid var(--color-white);
    background-image: linear-gradient(
      var(--color-gray-light),
      var(--color-white) 100%
    );

    @-moz-document url-prefix() {
      /* stylelint-disable-next-line */
      background-color: #f8f8f8;
    }

    .titulo {
      color: var(--text-color);
    }
  }

  .div-images {
    margin-bottom: 45px;
  }

  @-moz-document url-prefix() {
    @media (max-width: 768px) {
      .card-firefox {
        margin-top: 30px;
      }

      .titulo {
        color: var(--color-white-gray) !important;
      }
    }
  }

  .titulo {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    white-space: break-spaces;
  }

  .astronauta {
    height: 74px;
  }

  .container-btn-search {
    position: relative;
    top: -16px;
  }

  .div-btn-produtos {
    position: relative;
    top: -9px;
    z-index: 1;
  }

  .text-soon {
    color: var(--color-primary);
  }

  .card-padding {
    margin: 0 0 52px 0;
  }

  .mirror-img {
    transform: scaleX(-1);
  }
`;
