import { stringToPascalCase } from '../transfoms';

export const papelEnum = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  REPRESENTANTE: 'REPRESENTANTE',
  CONSULTOR: 'CONSULTOR'
};

export function papelBeautify(papel) {
  switch (papel) {
    case papelEnum.ADMINISTRADOR:
      return 'Administrador';
    case papelEnum.REPRESENTANTE:
      return 'Representante';
    case papelEnum.CONSULTOR:
      return 'Consultor/Instrutor';
    default:
      return stringToPascalCase(papel);
  }
}

export const listarPapelProfissionalEnum = ['ADMINISTRADOR', 'CONSULTOR'];
