import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Rate, Divider } from 'antd';
import { Container } from './styles';
import i18n from '../../shared/util/i18n';
import AvatarPadrao from '../../images/svgs/avatar-padrao.svg';
import Rating from '../Rating/Rating';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
import { getSmallestImage } from '../../shared/util/imageUtils';

const { Text, Paragraph } = Typography;

export default function CardFornecedor(props) {
  const { width } = useWindowDimensions();
  return (
    <Container>
      <Row
        gutter={[20]}
        justify={'center'}
        align='top'
        style={{ margin: 0 }}
        className='ant-row-flex text-center'>
        <Col
          className='sombra d-flex justify-content-center align.items-flex-end flex-wrap'
          style={{
            width: width < 578 ? 300 : 252,
            height: 350,
            padding: 0,
            margin: '15px 0'
          }}>
          <Link
            className={'w-100'}
            style={{ height: 276 }}
            to={props.item.profileLink}>
            <Row style={{ marginTop: 30 }}>
              <Col className='d-flex justify-content-center'>
                <img
                  src={
                    props.item.fotoUrl
                      ? getSmallestImage(
                          props.item.fotoUrlResponsive,
                          props.item.fotoUrl
                        )
                      : AvatarPadrao
                  }
                  alt={
                    i18n.t('AVATAR_ALT') +
                    (props.item.nome
                      ? props.item.nome
                      : props.item.nomeFantasia)
                  }
                  className='avatar-fornecedor-popular'
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col style={{ minHeight: '40px' }}>
                <Text style={{ fontSize: '14px' }}>
                  {props.item.nome ? props.item.nome : props.item.nomeFantasia}
                </Text>
              </Col>
            </Row>
            <Row className='mt-1 mx-3'>
              <Col className='ocupacao-col'>
                <Paragraph
                  style={{ fontWeight: '300' }}
                  ellipsis={{ rows: 2, expandable: false }}>
                  {props.item.ocupacao}
                </Paragraph>
              </Col>
            </Row>

            {props.showAvaliacaoFornecedores && (
              <Row>
                <Divider className='divider-style' />

                <Row>
                  <Col span={12}>
                    <Text>{i18n.t('AVALIATIONS')}:</Text>
                  </Col>
                  <Col span={12}>
                    <Rating rating={props.item.mediaAvaliacao} />
                  </Col>
                </Row>
              </Row>
            )}
          </Link>
          <Row
            className={'w-100 align-items-center'}
            style={{
              height: 72,
              background: 'var(--text-color)',
              borderBottomLeftRadius: 3,
              borderBottomRightRadius: 3,
              display: props.showAvaliacoes ? 'flex' : 'none'
            }}>
            <Col
              md={props.showAvaliacaoLicenciado ? 11 : 24}
              sm={props.showAvaliacaoLicenciado ? 11 : 24}
              xs={props.showAvaliacaoLicenciado ? 11 : 24}
              className={'d-flex flex-wrap justify-content-center'}>
              <Text
                className={'w-100 text-center'}
                style={{ color: 'var(--color-gray)' }}>
                Clientes
              </Text>
              <Rate
                allowHalf
                disabled={true}
                count={1}
                defaultValue={props.item.mediaAvaliacaoCliente}
              />
              <Text
                className='ml-2'
                style={{ color: 'var(--color-gray)', fontSize: 20 }}>
                {props.item.mediaAvaliacaoClienteLabel}
              </Text>
            </Col>
            <Col
              md={2}
              sm={2}
              xs={2}
              style={{
                display: props.showAvaliacaoLicenciado ? 'flex' : 'none'
              }}
              className={'justify-content-center align-items-center'}>
              <div
                style={{
                  height: 43,
                  width: 1,
                  background: 'var(--color-gray)'
                }}></div>
            </Col>
            <Col
              md={11}
              sm={11}
              xs={11}
              style={{
                display: props.showAvaliacaoLicenciado ? 'flex' : 'none'
              }}
              className={'flex-wrap justify-content-center'}>
              <Text
                className={'w-100 text-center'}
                style={{ color: 'var(--color-gray)' }}>
                Licenciados
              </Text>
              <br />
              <Rate
                allowHalf
                disable={true}
                count={1}
                defaultValue={props.item.mediaAvaliacaoLicenciadoLabel}
              />
              <Text
                className='ml-2'
                style={{ color: 'var(--color-gray)', fontSize: 20 }}>
                {props.item.mediaAvaliacaoLicenciadoLabel}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
