/* eslint-disable no-restricted-syntax */
import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import RemoveIndexFromRoute from './removeIndexFromRoute';
import { ContextAffiliate } from '../../store/contexts/contextAffiliate';

export const ProtectedAllowsAffiliateRoute = ({ children, ...rest }) => {
  const { allowsAffiliate, allowsAffiliateLoaded } = useContext(
    ContextAffiliate
  );

  const history = useHistory();

  const verifyRoute = () => {
    if (allowsAffiliateLoaded) {
      if (allowsAffiliate) {
        return (
          <React.Fragment>
            <RemoveIndexFromRoute />
            {children}
          </React.Fragment>
        );
      } else {
        history.push('/');
        return <></>;
      }
    }
  };

  return (
    <Route
      {...rest}
      render={() => {
        return verifyRoute();
      }}
    />
  );
};
