import { useState, useCallback } from 'react';

export default function useFunctionAsState(fn) {
  const [val, setVal] = useState(() => fn);

  const setFunc = useCallback(fn => {
    setVal(() => fn);
  }, []);

  return [val, setFunc];
}
