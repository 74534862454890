import gql from 'graphql-tag';

export const TOGGLE_FAVORITE = gql`
  mutation AlternarFichaTecnicaSebraetecFavorita(
    $fichaTecnicaSebraetecId: String!
  ) {
    alternarFichaTecnicaSebraetecFavorita(
      fichaTecnicaSebraetecId: $fichaTecnicaSebraetecId
    )
  }
`;
