import { ReactComponent as Box } from '../../images/svgs/box.svg';
import { ReactComponent as Document } from '../../images/svgs/document.svg';
import { ReactComponent as ThLarge } from '../../images/svgs/th-large.svg';
import { ReactComponent as Pen } from '../../images/svgs/pen-edit.svg';
import { ReactComponent as Wallet } from '../../images/svgs/wallet.svg';
import { ReactComponent as CommentAlt } from '../../images/svgs/comment-alt.svg';
import { ReactComponent as CartSolid } from '../../images/svgs/cart-solid.svg';
import { ReactComponent as HeartSolid } from '../../images/svgs/heart-solid.svg';
import { ReactComponent as User } from '../../images/svgs/user.svg';
import { ReactComponent as Bag } from '../../images/svgs/bag-filled.svg';
import { ReactComponent as Flag } from '../../images/svgs/flag.svg';
import { ReactComponent as Users } from '../../images/svgs/users.svg';
import { ReactComponent as Extract } from '../../images/svgs/extract.svg';
import { ReactComponent as TagSolid } from '../../images/svgs/tag-solid.svg';
import { ReactComponent as Nota } from '../../images/svgs/nf.svg';

import { userTypes } from './enums/userTypesEnum';

function links(pessoa) {
  const vinculo = pessoa && pessoa.vinculo;

  let items = [
    {
      path: '/painel',
      icon: ThLarge,
      name: 'Painel'
    },
    {
      path: `/cadastro-perfil${vinculo ? '/empresa' : ''}`,
      icon: Pen,
      name: 'Editar Perfil'
    },
    {
      path: `/produtos`,
      icon: Box,
      name: 'Produtos',
      specificUserTypes: [userTypes.FORNECEDOR]
    },
    {
      path: `/solucoes-sebraetec`,
      icon: Document,
      name: 'Soluções Sebraetec',
      hasOrcamentosBadge: true,
      specificUserTypes: [userTypes.FORNECEDOR]
    },
    {
      path: '/minhas-compras',
      icon: Bag,
      name: 'Minhas Compras',
      specificUserTypes: [userTypes.CLIENTE]
    },
    {
      path: '/minhas-vendas',
      icon: TagSolid,
      name: 'Minhas Vendas',
      specificUserTypes: [userTypes.FORNECEDOR]
    },
    {
      path: '/meus-eventos',
      icon: Flag,
      name: 'Meus Eventos',
      specificUserTypes: [userTypes.LICENCIADO]
    },
    {
      path: '/orcamentos-sebraetec',
      icon: Document,
      name: 'Orçamentos Sebraetec',
      specificUserTypes: [userTypes.CLIENTE]
    },
    {
      path: '/carteira',
      icon: Wallet,
      name: 'Carteira'
    },
    {
      path: '/extrato-financeiro',
      icon: Extract,
      name: 'Extrato',
      specificUserTypes: [userTypes.FORNECEDOR, userTypes.LICENCIADO]
    },
    {
      path: '/negociacoes',
      icon: CommentAlt,
      name: 'Negociações'
    },
    {
      path: '/carrinho',
      icon: CartSolid,
      name: 'Carrinho'
    },
    {
      path: '/favoritos',
      icon: HeartSolid,
      name: 'Favoritos'
    },
    {
      path: '/dados-cadastrais',
      icon: User,
      name: 'Dados Cadastrais'
    },
    {
      path: '/profissionais',
      icon: Users,
      name: 'Profissionais',
      specificUserTypes: [userTypes.FORNECEDOR]
    },
    {
      path: `/listar-notas`,
      icon: Nota,
      name: 'Notas Fiscais',
      specificUserTypes: [userTypes.FORNECEDOR]
    }
  ];

  return items;
}

export default links;
