import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// LANGUAGE FILES
import { pt_BR } from '../../lang/pt_BR';

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false
  },
  fallbackLng: 'pt-BR',
  resources: {
    'pt-BR': {
      translation: pt_BR
    }
  }
});

export default i18n;
