export const ConfiguracaoEnum = {
  EMAIL_CUSTOMER_SERVICE: 'EMAIL_CUSTOMER_SERVICE',
  EMAIL_CURADORIA: 'EMAIL_CURADORIA',
  PROTHEUS_CCUSTO: 'PROTHEUS_CCUSTO',
  DIAS_ENVIO_INGRESSO_EVENTO: 'DIAS_ENVIO_INGRESSO_EVENTO',
  PROTHEUS_COD_PRODUTO_COMISSAO: 'PROTHEUS_COD_PRODUTO_COMISSAO',
  PROTHEUS_COD_PRODUTO_MENSALIDADE: 'PROTHEUS_COD_PRODUTO_MENSALIDADE',
  IUGU_SUBCONTA_FINES: 'IUGU_SUBCONTA_FINES',
  IUGU_SUBCONTA_PER_DAY_INTEREST: 'IUGU_SUBCONTA_PER_DAY_INTEREST',
  IUGU_SUBCONTA_LATE_PAYMENT_FINE: 'IUGU_SUBCONTA_LATE_PAYMENT_FINE',
  IUGU_SUBCONTA_AUTO_ADVANCE: 'IUGU_SUBCONTA_AUTO_ADVANCE',
  IUGU_SUBCONTA_PAYMENT_EMAIL_NOTIFICATION:
    'IUGU_SUBCONTA_PAYMENT_EMAIL_NOTIFICATION',
  IUGU_SUBCONTA_EARLY_PAYMENT_DISCOUNT: 'IUGU_SUBCONTA_EARLY_PAYMENT_DISCOUNT',
  IUGU_SUBCONTA_DISABLED_WITHDRAW: 'IUGU_SUBCONTA_DISABLED_WITHDRAW',
  IUGU_SUBCONTA_EXTRA_DUE: 'IUGU_SUBCONTA_EXTRA_DUE',
  IUGU_SUBCONTA_REPRINT_EXTRA_DUE: 'IUGU_SUBCONTA_REPRINT_EXTRA_DUE',
  IUGU_SUBCONTA_SOFT_DESCRIPTOR: 'IUGU_SUBCONTA_SOFT_DESCRIPTOR',
  HABILITAR_CHAT_ATENDIMENTO: 'HABILITAR_CHAT_ATENDIMENTO',
  IUGU_SUBCONTA_INSTALLMENTS: 'IUGU_SUBCONTA_INSTALLMENTS',
  IUGU_SUBCONTA_MAX_INSTALLMENTS_CONSULTORIA:
    'IUGU_SUBCONTA_MAX_INSTALLMENTS_CONSULTORIA',
  IUGU_SUBCONTA_MAX_INSTALLMENTS_PRODUTO:
    'IUGU_SUBCONTA_MAX_INSTALLMENTS_PRODUTO',
  IUGU_SUBCONTA_MAX_INSTALLMENTS_EVENTO:
    'IUGU_SUBCONTA_MAX_INSTALLMENTS_EVENTO',
  IUGU_SUBCONTA_TWO_STEP_TRANSACTION: 'IUGU_SUBCONTA_TWO_STEP_TRANSACTION',
  IUGU_JOBS: 'IUGU_JOBS',
  PROTHEUS_JOBS: 'PROTHEUS_JOBS',
  EVENTO_DIAS_ENVIO_CERTIFICADO: 'EVENTO_DIAS_ENVIO_CERTIFICADO',
  EVENTO_PORCENTAGEM_PARTICIPACAO_CERTIFICADO:
    'EVENTO_PORCENTAGEM_PARTICIPACAO_CERTIFICADO',
  EVENTO_HORAS_EXPIRACAO_INSCRICAO: 'EVENTO_HORAS_EXPIRACAO_INSCRICAO',
  DIA_ROTINA_SAQUE: 'DIA_ROTINA_SAQUE',
  NEGOCIACAO_PORCENTAGEM_ANTECIPACAO: 'NEGOCIACAO_PORCENTAGEM_ANTECIPACAO',
  HORAS_LIBERACAO_TITULO: 'HORAS_LIBERACAO_TITULO',
  DIAS_COBRAR_DIVIDA_TITULO: 'DIAS_COBRAR_DIVIDA_TITULO',
  FORNECEDOR_PRAZO_EXPIRACAO_CONVITE: 'FORNECEDOR_PRAZO_EXPIRACAO_CONVITE',
  NEGOCIACAO_HORAS_LIMITE_CANCELAMENTO: 'NEGOCIACAO_HORAS_LIMITE_CANCELAMENTO',
  NEGOCIACAO_PRAZO_RESPOSTA_ORCAMENTO_CONSULTORIA:
    'NEGOCIACAO_PRAZO_RESPOSTA_ORCAMENTO_CONSULTORIA',
  NEGOCIACAO_HORAS_EXPIRACAO_PAGAMENTO: 'NEGOCIACAO_HORAS_EXPIRACAO_PAGAMENTO',
  LIMITE_VALOR_MINIMO_CONSULTORIA: 'LIMITE_VALOR_MINIMO_CONSULTORIA',
  LIMITE_VALOR_MINIMO_PRODUTO_PRESENCIAL:
    'LIMITE_VALOR_MINIMO_PRODUTO_PRESENCIAL',
  LIMITE_VALOR_MINIMO_INGRESSO: 'LIMITE_VALOR_MINIMO_INGRESSO',
  LIMITE_VALOR_MINIMO_SOLUCAO_DIGITAL: 'LIMITE_VALOR_MINIMO_SOLUCAO_DIGITAL',
  LIMITE_SAQUE_AFILIADO: 'LIMITE_SAQUE_AFILIADO',
  LIMITE_TEMPO_WEBHOOK_NOVA_ASSINATURA: 'LIMITE_TEMPO_WEBHOOK_NOVA_ASSINATURA',
  LIMITE_TEMPO_WEBHOOK_CANCELAMENTO: 'LIMITE_TEMPO_WEBHOOK_CANCELAMENTO',
  LIMITE_TEMPO_WEBHOOK_MUDANCA_PLANO: 'LIMITE_TEMPO_WEBHOOK_MUDANCA_PLANO',
  PRODUTO_PRAZO_CADASTRO: 'PRODUTO_PRAZO_CADASTRO',
  URL_PLANO_AULA_PDF: 'URL_PLANO_AULA_PDF',
  URL_PLANO_AULA_DOCX: 'URL_PLANO_AULA_DOCX',
  CURSO_PERCENTUAL_CONCLUSAO_AULA: 'CURSO_PERCENTUAL_CONCLUSAO_AULA',
  CONSULTORIA_PRAZO_CADASTRO: 'CONSULTORIA_PRAZO_CADASTRO',
  AFILIADO_TERCEIRO: 'AFILIADO_TERCEIRO',
  AFILIADO_COLABORADOR: 'AFILIADO_COLABORADOR',
  MARKETING_AFILIADOS: 'MARKETING_AFILIADOS',
  PERMITE_AFILIACAO_COMISSIONADA_TERCEIRO:
    'PERMITE_AFILIACAO_COMISSIONADA_TERCEIRO',
  SAAS_CONTRATO_HORAS_EXPIRACAO_PAGAMENTO:
    'SAAS_CONTRATO_HORAS_EXPIRACAO_PAGAMENTO',
  SAAS_CONTRATO_PRAZO_RESPOSTA_CANCELAMENTO:
    'SAAS_CONTRATO_PRAZO_RESPOSTA_CANCELAMENTO',
  SAAS_LEAD_VALOR: 'SAAS_LEAD_VALOR',
  DENUNCIA_SOMENTE_DONO: 'DENUNCIA_SOMENTE_DONO',
  CURSO_PERCENTUAL_MAXIMO_CANCELAR_MATRICULA:
    'CURSO_PERCENTUAL_MAXIMO_CANCELAR_MATRICULA',
  CURSO_PERCENTUAL_MAXIMO_CAMPANHA_DESCONTO:
    'CURSO_PERCENTUAL_MAXIMO_CAMPANHA_DESCONTO',
  CURSO_DIAS_EXPIRACAO_GRATUITO: 'CURSO_DIAS_EXPIRACAO_GRATUITO',
  CURSO_DIAS_EXPIRACAO_PAGO: 'CURSO_DIAS_EXPIRACAO_PAGO',
  CURSO_VALOR_MINIMO: 'CURSO_VALOR_MINIMO',
  CUPOM_PERCENTUAL_MAXIMO_DESCONTO: 'CUPOM_PERCENTUAL_MAXIMO_DESCONTO',
  CUPOM_VALOR_MAXIMO_DESCONTO: 'CUPOM_VALOR_MAXIMO_DESCONTO',
  CURSO_QUANTIDADE_MINIMA_AULAS: 'CURSO_QUANTIDADE_MINIMA_AULAS',
  CURSO_DURACAO_MINIMA: 'CURSO_DURACAO_MINIMA',
  CURSO_LIMITE_ANEXOS_POR_AULA: 'CURSO_LIMITE_ANEXOS_POR_AULA',
  CURSO_SEGUNDOS_PROXIMA_AULA: 'CURSO_SEGUNDOS_PROXIMA_AULA',
  SEBRAETEC_VIDEO_APRESENTACAO: 'SEBRAETEC_VIDEO_APRESENTACAO',
  SEBRAETEC_ORCAMENTO_DIAS_ENVIO_PROPOSTA:
    'SEBRAETEC_ORCAMENTO_DIAS_ENVIO_PROPOSTA',
  ATIVAR_DESATIVAR_AFILIADO_PF_PJ: 'ATIVAR_DESATIVAR_AFILIADO_PF_PJ',
  HABILITAR_DESABILITAR_MAPS: 'HABILITAR_DESABILITAR_MAPS',
  HABILITAR_CAPACITACAO_PRESENCIAL: 'HABILITAR_CAPACITACAO_PRESENCIAL',
  HABILITAR_MODALIDADE_LEAD: 'HABILITAR_MODALIDADE_LEAD',
  HABILITAR_MODALIDADE_VITRINE: 'HABILITAR_MODALIDADE_VITRINE'
};
