import gql from 'graphql-tag';

export const SET_CURRENT_PERSON = gql`
  mutation SetCurrentPerson($empresaId: String) {
    setarPessoaAtual(empresaId: $empresaId) {
      id
      vinculo {
        empresa {
          tipo
        }
      }
    }
  }
`;

export const MARCAR_NOTIFICACOES_COMO_LIDAS = gql`
  mutation MarcarNotificacoesComoLida {
    marcarNotificacoesComoLida {
      id
    }
  }
`;

export const OBTER_CONFIGURACAO_POR_NOME = gql`
  query ObterConfiguracaoPorNome($configuracaoEnum: ConfiguracaoEnum!) {
    obterConfiguracaoPorNome(configuracaoNome: $configuracaoEnum) {
      nome
      tipo
      descricao
      valor {
        valorBoolean
      }
    }
  }
`;

export const MARCAR_NOTIFICACAO_LIDA_POR_ID = gql`
  mutation MarcarUmaNotificacaoComoLida($notificacaoId: String!) {
    marcarNotificacaoComoLidaPorId(notificacaoId: $notificacaoId) {
      id
    }
  }
`;

export const GET_CONVITE_FORNECEDOR_PENDENTE = gql`
  query GetConviteFornecedorPendente {
    getConviteFornecedorPendente {
      id
      situacao
      empresa {
        id
        nomeFantasia
      }
      pessoa {
        id
        nome
      }
      dataInclusao
      dataExpiracao
      dataAceiteRecusa
      cpf
      email
      papel
      plano {
        id
        nome
      }
      mensagem
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query NotificationsNaoLidas {
    notificacoesNaoLidas(limit: 5) {
      id
      pessoa {
        id
        nome
      }
      tipo
      descricao
      valor
      situacao
      ativoEm
    }
  }
`;

export const GET_NOTIFICATIONS_ALL = gql`
  query NotificationsNaoLidas {
    notificacoesNaoLidas {
      id
      pessoa {
        id
        nome
      }
      tipo
      descricao
      valor
      situacao
      ativoEm
    }
  }
`;

export const OBSERVAR_NOTIFICATIONS = gql`
  subscription ObservarNotificacoes($pessoaId: String!) {
    observarNotificacoes(pessoaId: $pessoaId)
  }
`;

export const OBSERVAR_USUARIO = gql`
  subscription ObservarUsuario($pessoaId: String!) {
    observarUsuario(pessoaId: $pessoaId)
  }
`;
