import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Config from '../../shared/Config';

export const defaultTitle = 'Sebraeplace - Consultoria Empresarial';
export const defaultDescription =
  'Sebraeplace - Somos a maior plataforma de consultoria empresarial para os pequenos negocios, temos cursos online e profissionalizantes para empreendedores.';
export const defaultImage =
  'https://sebraeplace.sebraepr.com.br/plataforma/android-chrome-256x256.png';

const DocumentHead = React.memo(
  ({
    title: titleProp,
    description: descriptionProp,
    image = defaultImage
  }) => {
    const location = useLocation();
    const [pathname, setPathname] = useState(Config.baseUrl);

    useEffect(() => {
      setPathname(`${Config.baseUrl}${location.pathname}`);
    }, [location.pathname]);

    // Meta description should have maximum 160 characters
    // although it is not a rule, it is a good practice
    const getFirst160Characters = text => {
      const words = text.split(' ');
      let result = '';
      for (let i = 0; i < words.length; i++) {
        if (result.length + words[i].length < 159) {
          result = `${result} ${words[i]}`;
        } else {
          break;
        }
      }

      return result.trim();
    };

    const description = () =>
      descriptionProp
        ? getFirst160Characters(descriptionProp)
        : defaultDescription;

    const title = () =>
      titleProp ? `Sebraeplace - ${titleProp}` : defaultTitle;

    // Removes current meta tags (OpenGraph and Twitter) from the DOM
    // because when data-react-helmet is added
    // it invalidates some tags
    useEffect(() => {
      const selectors = [
        'property="og:url"',
        'property="og:title"',
        'property="og:description"',
        'property="og:image"',
        'property="twitter:url"',
        'property="twitter:title"',
        'property="twitter:description"',
        'property="twitter:image"'
      ];

      selectors.forEach(selector => {
        document.querySelectorAll(`meta[${selector}]`).forEach(meta => {
          if (!meta.hasAttribute('data-react-helmet')) {
            meta.remove();
          }
        });
      });
    }, []);

    return (
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{title()}</title>
        <meta name='title' content={title()} />
        <meta name='description' content={description()} />

        {/* Open Graph / Facebook */}
        <meta property='og:url' content={pathname} />
        <meta property='og:title' content={title()} />
        <meta property='og:description' content={description()} />
        <meta property='og:image' content={image} />

        {/* Twitter */}
        <meta property='twitter:url' content={pathname} />
        <meta property='twitter:title' content={title()} />
        <meta property='twitter:description' content={description()} />
        <meta property='twitter:image' content={image} />
      </Helmet>
    );
  }
);

DocumentHead.displayName = 'DocumentHead';

export default DocumentHead;
