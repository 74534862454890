import PropTypes, { string } from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { isRefreshTokenUndefinedOrExpired } from './utilToken';
import Config from './../Config';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { ContextAlert } from '../../store/contexts/contextAlert';
import { actionAlert } from '../../store/actions/actionAlert';
import RemoveIndexFromRoute from './removeIndexFromRoute';
import i18n from './i18n';

export const ProtectedRoute = ({
  component: Component,
  children,
  specificUserTypes,
  redirectUrl = '/painel',
  ...rest
}) => {
  const { isPersonLoaded, userType } = useContext(ContextPerson);
  const { dispatchAlert } = useContext(ContextAlert);
  const [mustRedirect, setMustRedirect] = useState(false);
  const history = useHistory();

  const handleUndefinedOrExpiredRefreshToken = () => {
    window.location.href = `${Config.loginUiUrl}/?requestedURL=${window.location.href}`;
  };

  const handleRedirectOfLoggedUser = () => {
    if (!mustRedirect) {
      dispatchAlert({
        type: actionAlert.ADD,
        payload: {
          message: 'WARNING',
          description: i18n.t('ALERT_REDIRECTED_UNAUTHORIZED_ACCESS'),
          type: 'warning'
        }
      });
      setMustRedirect(true);
    }
  };

  useEffect(() => {
    if (mustRedirect) {
      setMustRedirect(false);
      history.push(redirectUrl); // eslint-disable-line
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustRedirect]);

  if (isRefreshTokenUndefinedOrExpired()) {
    handleUndefinedOrExpiredRefreshToken();
    return null;
  }

  if (specificUserTypes && specificUserTypes.length > 0) {
    if (!isPersonLoaded) return null;
    if (!specificUserTypes.includes(userType)) {
      handleRedirectOfLoggedUser();
      return null;
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (children) {
          return (
            <React.Fragment>
              <RemoveIndexFromRoute />
              {children}
            </React.Fragment>
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  specificPermissions: PropTypes.arrayOf(string)
};
