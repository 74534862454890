import { ReactComponent as ThLarge } from '../../images/svgs/th-large.svg';
import { ReactComponent as Wallet } from '../../images/svgs/wallet.svg';
import { ReactComponent as TicketNf } from '../../images/svgs/ticket-nf.svg';
import { ReactComponent as Link } from '../../images/svgs/link.svg';
import { ReactComponent as Share } from '../../images/svgs/share.svg';
import { ReactComponent as Extract } from '../../images/svgs/extract.svg';
import { ReactComponent as User } from '../../images/svgs/user.svg';

function linksAfiliado() {
  let items = [
    {
      path: '/painel/afiliado',
      icon: ThLarge,
      name: 'Painel'
    },
    {
      path: '/carteira-afiliado',
      icon: Wallet,
      name: 'Carteira'
    },
    {
      path: '/notas',
      icon: TicketNf,
      name: 'Notas Fiscais'
    },
    {
      path: '/meus-links',
      icon: Link,
      name: 'Links gerados'
    },
    {
      path: '/canais-divulgacao',
      icon: Share,
      name: 'Canais de divulgação'
    },
    {
      path: '/afiliado/extrato-financeiro',
      icon: Extract,
      name: 'Extrato'
    },
    {
      path: '/dados-cadastrais/afiliado',
      icon: User,
      name: 'Dados Cadastrais'
    }
  ];

  return items;
}

export default linksAfiliado;
