import React from 'react';

import { Button } from '../../../../components/LinkComponent/LinkComponent';
import i18n from '../../../../shared/util/i18n';

import { CardContainer } from './styles';
import { Popover } from 'antd';
import { CategoriasEnum } from '../../../../shared/util/enums/categoriasEnum';

export default function CardCategoria({
  title,
  imgSrc,
  alt,
  showBtnSearch,
  showComingSoon,
  href,
  categoria
}) {
  const getPopoverText = categoria => {
    switch (categoria) {
      case CategoriasEnum.SEBRAETEC:
        return i18n.t('CUSTOMIZED_TECHNOLOGICAL_SOLUTINS_FOR_YOUR_BUSINESS');
      default:
        return '';
    }
  };
  return (
    <CardContainer xs={24} md={12} lg={6} className='container-card'>
      <Popover content={getPopoverText(categoria)}>
        <div className='card w-100 card-firefox'>
          <div className='titulo'>{i18n.t(title)}</div>
          <div className='div-images'>
            <img src={imgSrc} className='astronauta' alt={i18n.t(alt)} />
          </div>
        </div>
      </Popover>
      {showBtnSearch && (
        <div className='container-btn-search w-100 text-center'>
          <Button
            type='primary'
            shape='round'
            size='default'
            className='wide w-100'
            href={href}>
            {i18n.t('SEARCH')}
          </Button>
        </div>
      )}
      {showComingSoon && (
        <div className='div-btn-produtos text-center w-100'>
          <span className='text-soon'>{i18n.t('SOON')}</span>
        </div>
      )}
    </CardContainer>
  );
}
