import React from 'react';
const configuracaoIncorreta =
  'O ContextSettings não está configurado corretamente';
export const ContextSettings = React.createContext({
  habilitarModalidadeLead: false,
  setHabilitarModalidadeLead: () => {
    throw new Error(configuracaoIncorreta);
  },
  habilitarModalidadeVitrine: false,
  setHabilitarModalidadeVitrine: () => {
    throw new Error(configuracaoIncorreta);
  }
});
