import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Row, Typography, Col, Popover, Rate, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  formatValor,
  transformSecondsToHHmm
} from '../../../../shared/util/transfoms';
import { isTokenUndefinedOrExpired } from '../../../../shared/util/utilToken';
import Config from '../../../../shared/Config';
import { Container } from './styles';
import { modalidade } from '../../../../shared/util/enums/modalidadeCatalogoEnum';
import AvatarPadrao from '../../../../images/svgs/avatar-padrao.svg';
import { ReactComponent as MapMarker } from '../../../../images/svgs/map-marker.svg';
import { ReactComponent as HeartSolid } from '../../../../images/svgs/heart-solid.svg';
import { ReactComponent as GlobeLine } from '../../../../images/svgs/globe-line.svg';
import i18n from '../../../../shared/util/i18n';
import { isInt } from '../../../../shared/util/validators';
import { ReactComponent as ConexaoCertificada } from '../../../../images/svgs/conexao-certificada-sebrae-logo.svg';
import { ReactComponent as PIcon } from '../../../../images/svgs/p.svg';
import { ReactComponent as ConexaoCertificadaTexto } from '../../../../images/svgs/conexao-certificada-sebrae-logo-texto.svg';
import { ReactComponent as PlayVideo } from '../../../../images/svgs/play-video.svg';
import { getSmallestImage } from '../../../../shared/util/imageUtils';
const { Text, Paragraph, Title } = Typography;

export default function ItemsCatalogo({
  item,
  formatList,
  isFavorite,
  alternarConsultoriaFavorita,
  alternarEventoFavorito,
  isFull
}) {
  const history = useHistory();

  const [isFavorito, setIsFavorito] = useState(isFavorite);

  useEffect(() => {
    setIsFavorito(isFavorite);
    // eslint-disable-next-line
  }, [isFavorite]);

  const getUrl = () => {
    if (item.tipo === modalidade.EVENTOS || item.tipo === modalidade.EVENTO) {
      return `/evento/${item.id}`;
    } else if (
      item.tipo === modalidade.CONSULTORIAS ||
      item.tipo === modalidade.CONSULTORIA
    ) {
      return `/consultoria/${item.id}`;
    } else if (item.tipo === modalidade.SAAS_PRODUTO) {
      return `/sd/${item.slug ? item.slug : item.id}`;
    } else if (item.tipo === modalidade.CURSO) {
      return `/cursos-online/${item.curso && item.curso.slug}`;
    }
  };

  const redirectToPerfil = e => {
    e.preventDefault();
    e.stopPropagation();

    history.push(getUrlPerfil()); // eslint-disable-line no-restricted-syntax
  };

  const getUrlPerfil = () => {
    if (
      item.tipo === modalidade.EVENTOS &&
      item &&
      item.empresa &&
      item.empresa.id
    ) {
      return `/perfil/${item.empresa.id}`;
    } else if (item && item.pessoa && item.pessoa.id) {
      return `/perfil/${item.pessoa.id}`;
    }

    return getUrl();
  };

  const handleAlternarFavorito = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (isTokenUndefinedOrExpired()) {
      window.location.href = Config.loginUiUrl;
    } else if (
      item.tipo === modalidade.CONSULTORIAS ||
      item.tipo === modalidade.CONSULTORIA
    ) {
      alternarConsultoriaFavorita({
        variables: {
          consultoriaId: id
        }
      });
      setIsFavorito(!isFavorito);
    } else if (
      item.tipo === modalidade.EVENTOS ||
      item.tipo === modalidade.EVENTO
    ) {
      alternarEventoFavorito({
        variables: {
          eventoId: id
        }
      });
      setIsFavorito(!isFavorito);
    }
  };
  const imagemCapa = () => {
    return item && item.imagensCapa && item.imagensCapa.length > 0
      ? getSmallestImage(
          item.imagensCapa[0].urlResponsive,
          item.imagensCapa[0].url
        )
      : getSmallestImage(item.fotoUrlResponsive, item.fotoUrl);
  };

  const getImagemSaas = () => {
    if (item.imageSaas) {
      return getSmallestImage(
        item.imageSaas.imagemResponsive,
        item.imageSaas.imagemOriginal
      );
    }
    if (item.imagem) {
      return getSmallestImage(
        item.imagem.imagemResponsive,
        item.imagem.imagemOriginal
      );
    }
  };

  const getImagemCurso = () => {
    if (item.curso && item.curso.imagemCapa) {
      return getSmallestImage(
        item.curso.imagemCapa.imagemResponsive,
        item.curso.imagemCapa.imagemCortada
      );
    }
  };

  const isEvento =
    item &&
    item.tipo &&
    (item.tipo === modalidade.EVENTOS || item.tipo === modalidade.EVENTO)
      ? true
      : false;

  const getFormattedAddress = item => {
    if (item && item.endereco) {
      if (item.endereco.cidade && item.endereco.estado)
        return `${item.endereco.cidade} - ${item.endereco.estado}`;
      if (item.endereco.enderecoString)
        return `${item.endereco.enderecoString}`;
    }
    if (item && item.pessoa && item.pessoa.endereco) {
      return `${item.pessoa.endereco.cidade} - ${item.pessoa.endereco.estado}`;
    }
    return '';
  };

  const getMenorPrecoIngresso = () => {
    if (item && item.loteIngresso && item.loteIngresso.length) {
      let menorPreco = item.loteIngresso.reduce((a, b) =>
        a.preco < b.preco ? a : b
      );

      return menorPreco.preco;
    }
  };

  const getGridMd = () => {
    if (isFull) {
      return 24;
    }
    return formatList ? 24 : 12;
  };

  const getGridLg = () => {
    if (isFull) {
      return 24;
    }
    return formatList ? 24 : 8;
  };

  return (
    <Col md={getGridMd()} lg={getGridLg()}>
      <Link to={getUrl()}>
        <Container
          formatList={formatList}
          item={item}
          className='cursor-pointer'
          data-cy='card-catalogo'>
          <Row>
            <Col span={formatList ? 12 : 24} className='d-flex'>
              {item.curso &&
                item.curso.campanhaDesconto &&
                item.curso.campanhaDesconto.percentualDesconto && (
                  <div className='product-course__percent-discount px-2 py-1 position-absolute'>
                    <span className='product-course__percent'>
                      {item.curso.campanhaDesconto.percentualDesconto}%
                    </span>
                    {` ${i18n.t('OF_DISCOUNT')}`}
                  </div>
                )}
              <img
                alt={i18n.t('PRODUCT_IMAGE_ALT') + (item && item.nome)}
                className='img-produtos'
                src={
                  item.tipo === modalidade.CONSULTORIAS ||
                  item.tipo === modalidade.CONSULTORIA
                    ? getSmallestImage(item.fotoUrlResponsive, item.fotoUrl)
                    : item.tipo === modalidade.SAAS_PRODUTO
                    ? getImagemSaas()
                    : item.tipo === modalidade.CURSO
                    ? getImagemCurso()
                    : imagemCapa()
                }></img>
              {item.tipo === modalidade.CURSO && (
                <div className='d-flex align-items-center justify-content-center icon-play'>
                  <Icon
                    component={PlayVideo}
                    style={{
                      fontSize: 20,
                      color: 'var(--color-white)'
                    }}
                  />
                </div>
              )}
              {item.tipo !== modalidade.SAAS_PRODUTO &&
                item.tipo !== modalidade.CURSO && (
                  <div className='icon-heart'>
                    <Icon
                      component={HeartSolid}
                      style={{
                        fontSize: 32,
                        color: isFavorito
                          ? 'var(--color-danger)'
                          : 'var(--color-white)',
                        transition: 'color 1s'
                      }}
                      onClick={e => handleAlternarFavorito(e, item.id)}
                    />
                  </div>
                )}

              {item.dataInicial && (
                <div className='date-card'>
                  <Text className='month' strong>
                    {dayjs(item.dataInicial)
                      .format('MMMM')
                      .substring(0, 3)
                      .toUpperCase()}
                  </Text>
                  <Title level={4} type='primary' className='day'>
                    {dayjs(item.dataInicial).format('DD')}
                  </Title>
                </div>
              )}
              {item.tipo !== modalidade.SAAS_PRODUTO && (
                <div>
                  <Popover
                    content={
                      isEvento
                        ? item && item.empresa && item.empresa.nomeFantasia
                        : item && item.pessoa && item.pessoa.nome
                    }
                    trigger='hover'>
                    <img
                      onClick={e => redirectToPerfil(e)}
                      alt={
                        i18n.t('AVATAR_ALT') +
                        (isEvento
                          ? item && item.empresa && item.empresa.nomeFantasia
                          : item && item.pessoa && item.pessoa.nome)
                      }
                      className='avatar avatar-comp'
                      src={
                        isEvento
                          ? item && item.empresa && item.empresa.fotoUrl
                            ? getSmallestImage(
                                item.empresa.fotoUrlResponsive,
                                item.empresa.fotoUrl
                              )
                            : AvatarPadrao
                          : item && item.pessoa && item.pessoa.fotoUrl
                          ? getSmallestImage(
                              item.pessoa.fotoUrlResponsive,
                              item.pessoa.fotoUrl
                            )
                          : AvatarPadrao
                      }
                    />
                  </Popover>
                </div>
              )}
            </Col>
            <Col span={formatList ? 12 : 24}>
              {item.tipo === modalidade.EVENTOS && (
                <div className='mt-3 px-3 container-classificacao'>
                  <div className='classificacao'>
                    {item && item.classificacao && item.classificacao.nome}
                  </div>
                </div>
              )}
              <div
                className={`d-flex flex-column justify-content-around px-3 pb-3 ${
                  item.tipo === modalidade.EVENTOS ? 'pt-1' : 'pt-3'
                }`}>
                <Row style={{ minHeight: 56 }}>
                  <Paragraph
                    className='produto-title'
                    ellipsis={{ rows: 2, expandable: false }}>
                    {item.nome}
                  </Paragraph>
                </Row>
                <div className='container-modalidade '>
                  <Popover
                    content={
                      item.tipo === modalidade.CONSULTORIAS ||
                      item.tipo === modalidade.CONSULTORIA
                        ? 'Consultoria exclusiva Sebraeplace'
                        : item.tipo === modalidade.SAAS_PRODUTO
                        ? 'Solução inteligente'
                        : item.tipo === modalidade.CURSO
                        ? 'Cursos-Online '
                        : item.classificacao.descricao
                    }>
                    <div className='modalidade'>
                      {item.tipo === modalidade.CONSULTORIAS ||
                      item.tipo === modalidade.CONSULTORIA
                        ? 'Consultoria'
                        : item.tipo === modalidade.SAAS_PRODUTO
                        ? 'Solução inteligente'
                        : item.tipo === modalidade.CURSO
                        ? 'Cursos-Online '
                        : 'Capacitação Presencial'}
                    </div>
                  </Popover>
                </div>
                <div className='container-icon-modalidade-selo'>
                  {item.tipo !== modalidade.SAAS_PRODUTO ? (
                    <>
                      <Icon
                        className='mr-1'
                        component={
                          item.tipo === modalidade.CONSULTORIAS ||
                          item.tipo === modalidade.CONSULTORIA
                            ? GlobeLine
                            : MapMarker
                        }
                        style={{ fontSize: 16, color: 'var(--color-gray)' }}
                      />

                      <Paragraph
                        type='secondary'
                        className='texto-modalidade'
                        ellipsis={{ rows: 2, expandable: false }}>
                        {(item.tipo === modalidade.CONSULTORIAS ||
                          item.tipo === modalidade.CONSULTORIA) &&
                        item.pessoa &&
                        item.pessoa.endereco
                          ? item.areas &&
                            item.areas.map(
                              area => `${i18n.t('CONSULTANCY_OF')} ${area.nome}`
                            )
                          : getFormattedAddress(item)}
                      </Paragraph>
                    </>
                  ) : (
                    <Paragraph
                      type='secondary'
                      className='texto-modalidade'
                      ellipsis={{ rows: 2, expandable: false }}>
                      {item &&
                        item.saasProduto &&
                        item.saasProduto.landingPage &&
                        item.saasProduto.landingPage.secaoCabecalho &&
                        item.saasProduto.landingPage.secaoCabecalho.descricao &&
                        item.saasProduto.landingPage.secaoCabecalho.descricao}
                      <span className='know-more ml-1'>
                        {i18n.t('KNOW_MORE_SAAS')}
                      </span>
                    </Paragraph>
                  )}
                </div>
                <div className='container-avaliacao'>
                  {item && item.mediaAvaliacao ? (
                    <Row className='ant-row-flex align-items-center'>
                      <Paragraph
                        type='secondary'
                        className='texto-modalidade mr-2'>
                        {i18n.t('AVALIATIONS')}
                      </Paragraph>
                      <div>
                        <Rate
                          allowHalf
                          disabled={true}
                          count={1}
                          defaultValue={isInt(item.mediaAvaliacao) ? 1 : 0.5}
                        />
                        <Text style={{ marginLeft: 2, fontSize: 20 }}>
                          {item.mediaAvaliacao}
                          {isInt(item.mediaAvaliacao) ? '.0' : ''}
                        </Text>
                      </div>
                    </Row>
                  ) : (
                    <Row className='ant-row-flex align-items-center'></Row>
                  )}
                  <Row className='ant-row-flex align-items-center'>
                    {(item.tipo === modalidade.CONSULTORIAS ||
                      item.tipo === modalidade.CONSULTORIA ||
                      item.tipo === modalidade.SAAS_PRODUTO ||
                      item.tipo === modalidade.CURSO) && (
                      <Popover
                        content='Conexão Certificada Sebrae'
                        trigger='hover'>
                        <Icon
                          component={
                            formatList
                              ? ConexaoCertificadaTexto
                              : ConexaoCertificada
                          }
                          style={{
                            fontSize: formatList ? 80 : 25
                          }}
                          className='icon-selo-format'
                        />
                      </Popover>
                    )}
                    {(item.tipo === modalidade.EVENTOS ||
                      item.tipo === modalidade.EVENTO) && (
                      <Popover
                        content={i18n.t('LOCAL_PARTNER')}
                        trigger='hover'>
                        <div className='items-catalogo__p-background'>
                          <Icon
                            component={PIcon}
                            style={{
                              color: 'var(--color-white)',
                              fontSize: 18
                            }}
                          />
                        </div>
                      </Popover>
                    )}
                  </Row>
                </div>
              </div>

              {item.tipo !== modalidade.CURSO && (
                <div className='extra-info p-3 d-flex align-items-center'>
                  <Col className='w-100'>
                    {item.tipo === modalidade.CONSULTORIAS && (
                      <Row>
                        <Col>
                          {item && item.valorMercado && (
                            <>
                              <Text className='valor-anterior-style' delete>
                                {`${i18n.t('DE')} ${formatValor(
                                  item.valorMercado,
                                  true
                                )}`}
                              </Text>
                              <hr className='line-price my-1' />
                            </>
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row className='d-flex align-items-end'>
                      {((item.loteIngresso && item.loteIngresso.length > 0) ||
                        (item.valorHora || !!item.preco)) &&
                        item.tipo !== 'SAAS_PRODUTO' &&
                        item.tipo !== 'CURSO' && (
                          <React.Fragment>
                            <Row>
                              {!item.valorMercado && item.preco && (
                                <Col>
                                  <Text
                                    type='white'
                                    className='text-sebraeplace-price'>
                                    {i18n.t('IN_SEBRAEPLACE')}
                                  </Text>
                                </Col>
                              )}
                              <Col>
                                <Text type='white' className='real-price-valor'>
                                  {!!(
                                    item &&
                                    item.loteIngresso &&
                                    item.loteIngresso.length &&
                                    item.loteIngresso.length > 0
                                  ) && (
                                    <Text
                                      type='white'
                                      className='plan-style-valor'>
                                      {i18n.t('SEBRAEPLACE_FROM_PRICE')}
                                    </Text>
                                  )}
                                  {item.tipo === 'CONSULTORIAS' && (
                                    <Row>
                                      <span className='hora-style'>
                                        {i18n.t('IN_SEBRAEPLACE')}
                                      </span>
                                    </Row>
                                  )}
                                  {formatValor(
                                    item &&
                                      item.loteIngresso &&
                                      item.loteIngresso.length
                                      ? getMenorPrecoIngresso()
                                      : item.valorHora
                                      ? item.valorHora
                                      : item.preco,
                                    true
                                  )}
                                  {(item.valorHora || item.preco) &&
                                    item.tipo !== 'SAAS_PRODUTO' &&
                                    item.tipo !== 'CURSO' && (
                                      <span className='hora-style'>
                                        /{i18n.t('HOUR')}
                                      </span>
                                    )}
                                </Text>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}

                      {item.tipo === modalidade.SAAS_PRODUTO &&
                        !item.valorHora && (
                          <>
                            {!!item.preco && (
                              <Col className='w-100'>
                                <Row>
                                  <Col>
                                    {item && item.precoMercado && (
                                      <>
                                        <Text className='text-de'>
                                          {`${i18n.t('DE')} `}
                                        </Text>
                                        <Text
                                          className='valor-anterior-style'
                                          delete>
                                          {` ${formatValor(item.precoMercado)}`}
                                        </Text>
                                        <hr className='line-price my-2' />
                                      </>
                                    )}
                                  </Col>
                                </Row>
                                <Row className='d-flex align-items-end'>
                                  <Col className='d-flex flex-column'>
                                    <Row className='ant-row-flex flex-column'>
                                      <span
                                        className={
                                          item && item.valor
                                            ? 'plan-style'
                                            : 'plan-style-valor'
                                        }>
                                        {i18n.t('SEBRAEPLACE_FROM_PRICE')}
                                        {item && item.valor && (
                                          <div className='line-price-value ml-1'>
                                            {formatValor(item.valor, true)}
                                          </div>
                                        )}
                                      </span>
                                      <div
                                        className={
                                          item && item.valor
                                            ? 'real-price'
                                            : 'real-price-valor'
                                        }>
                                        {formatValor(item.preco, true)}
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            {item.preco === 0 && (
                              <Col className='w-100 no-price__description'>
                                <Row>
                                  <Col>
                                    <Text className='text-de'>
                                      {i18n.t('TO_KNOW_MORE')}
                                    </Text>
                                  </Col>
                                </Row>
                                <Row className='d-flex align-items-end align-self-center'>
                                  <Col className='d-flex flex-column'>
                                    <Row className='ant-row-flex flex-column'>
                                      <Button
                                        className='no-price__button'
                                        type='primary'
                                        shape='round'>
                                        {i18n.t('REQUEST_CONTACT')}
                                      </Button>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </>
                        )}
                    </Row>
                  </Col>
                </div>
              )}
              {item.tipo === modalidade.CURSO && (
                <div className='extra-info p-3 d-flex align-items-center justify-content-between'>
                  <div className='d-flex flex-column'>
                    {item.curso && item.curso.valorFinal && item.curso.valor && (
                      <Text className='valor-anterior-style' delete>
                        {formatValor(item.curso.valor, true)}
                      </Text>
                    )}
                    {item.curso && (item.curso.valor || item.curso.valorFinal) && (
                      <>
                        <Text
                          type='white'
                          className='valor-style'
                          style={{ fontSize: 18 }}>
                          {item.curso && item.curso.valorFinal
                            ? formatValor(item.curso.valorFinal, true)
                            : formatValor(item.curso.valor, true)}
                        </Text>
                      </>
                    )}
                    {item.curso && item.curso.isGratuito && (
                      <Text type='white' className='valor-style'>
                        {i18n.t('GRATUITOUS')}
                      </Text>
                    )}
                  </div>
                  <div className='d-flex align-items-center'>
                    {item.curso && item.curso.quantidadeSegundos && (
                      <Text className='duracao'>{`${transformSecondsToHHmm(
                        item.curso.quantidadeSegundos
                      )} ${i18n.t('IN_TOTAL')}`}</Text>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Link>
    </Col>
  );
}
