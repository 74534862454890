import styled from 'styled-components';
import { Menu } from 'antd';

export const Container = styled.div`
  /* stylelint-disable-next-line function-blacklist */
  background: ${({ withoutBlur }) =>
    withoutBlur ? '' : 'rgba(0, 0, 0, 0.25) 0% 0% no-repeat padding-box'};
  height: 62px !important;
  opacity: 1;
  position: fixed;
  width: 100%;
  backdrop-filter: ${({ withoutBlur }) => (withoutBlur ? '' : 'blur(50px)')};
  z-index: 100;
  /* stylelint-disable-next-line */
  -webkit-transform: translateZ(9999px);

  .search-input {
    width: 500px;
  }

  .search-input .ant-input-wrapper .ant-input-group-addon {
    position: absolute;
    right: 171px;
    top: 0;
  }

  .search-input .ant-input-wrapper .ant-input,
  .search-input .ant-input-wrapper .ant-input:hover,
  .search-input .ant-input-wrapper .ant-input:focus {
    z-index: unset !important;
    border: 0px !important;
    padding-right: 180px;
  }

  .search-input .ant-input-wrapper .ant-input-group-addon button {
    border-top-left-radius: 32px !important;
    border-bottom-left-radius: 32px !important;
    width: 172px !important;
    background-color: var(--text-color);
    border-color: var(--color-white);
    height: 30px;
  }

  .search-input .ant-input-wrapper .ant-input-group-addon button:hover {
    background-color: var(--text-color-light);
    border-color: var(--color-white);
  }

  .tornar-se-fornecedor {
    margin-right: 20px;
  }

  .notification-bell {
    position: relative;
    cursor: pointer;
  }

  .notification-bubble {
    bottom: 8px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    opacity: 1;
    background: var(--color-danger);
    position: absolute;
    right: 15px;
  }

  .menu-header {
    display: none;
  }

  .menu-list-header {
    display: none;
  }

  .back-button-header {
    width: 28px;
    #menu-icon {
      font-size: 28px;
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--color-white);
    }
  }

  @media only screen and (max-width: 800px),
    only screen and (max-width: 1040px) and (min-width: 992px) {
    .menu-list-header {
      display: unset;
      width: 24px;
    }

    #menu-icon {
      font-size: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--color-white);
    }
  }

  @media only screen and (max-width: 820px),
    only screen and (max-width: 1060px) and (min-width: 992px),
    only screen and (max-width: 1240px) and (min-width: 1200px) {
    .menu-header {
      display: unset;
      width: 24px;
    }

    #menu-icon {
      font-size: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--color-white);
    }
  }

  .icon-menu {
    margin-top: 10px;
    padding-bottom: 10px;
    margin-right: 16px;
  }

  .person-detail {
    margin-bottom: 0 !important;
    background-color: var(--color-danger);
  }

  .flex-column {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    .tornar-se-fornecedor {
      display: none;
    }
  }

  .header-avatar {
    width: 45px;
    height: 45px;
    border: 1px solid var(--color-gray-light);
    object-position: center;
    object-fit: cover;
    border-radius: 50px;
  }

  .header-logo svg {
    width: 166px;
    height: 62px;
  }

  .position-mobile {
    position: absolute;
    top: 62px;
    left: 0;
    padding: 10px;
    display: flex;
    justify-content: flex-end !important;
    /* stylelint-disable-next-line function-blacklist */
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    backdrop-filter: blur(50px);
    transition: all 0.5s ease;
  }

  .position-mobile .anticon {
    transition: all 0.2s ease;
  }

  .openedMenu {
    height: auto;
  }

  .openedMenu .anticon {
    font-size: 24px !important;
  }

  .closedMenu {
    height: 0;
    padding: 0;
  }

  @media (max-width: 767px) {
    .closedMenu .anticon {
      font-size: 0 !important;
    }

    .header-logo svg {
      width: 95px;
      height: 62px;
    }
  }

  @media (min-width: 768px) {
    .position-mobile {
      display: flex;
      position: static;
      background: transparent;
      backdrop-filter: none;
      transition: unset;
    }

    .closedMenu {
      height: auto;
      padding: 10px;
    }

    .closedMenu i {
      font-size: 24 !important;
    }
  }

  .avatar-padrao {
    width: 45px;
    height: 45px;
    border: 1px solid var(--color-gray-light);
    object-position: center;
    object-fit: cover;
    border-radius: 50px;
  }
`;

export const StyledMenuNotificacoes = styled(Menu)`
  .menu-item__custom {
    height: auto;
    padding: 0 !important;
    margin: 0;
    line-height: unset;
  }

  .menu-item__custom.ant-dropdown-menu-item:hover,
  .menu-item__custom.ant-dropdown-menu-submenu-title:hover {
    background-color: unset;
    border-radius: unset;
    color: unset;
  }

  .container-notificacoes {
    max-height: 50vh;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--color-scrollbar) !important;
      border: 3px solid var(--color-white) !important;
    }

    ::-webkit-resizer {
      display: none;
    }

    ::-webkit-scrollbar-button {
      display: block;
    }
  }

  .container-notificacoes:hover {
    cursor: pointer;
  }

  .dropdown-menu-item,
  .dropdown-menu-item-selected {
    display: flex;
    min-width: 290px;
    margin: 7px;
    padding: 2px 4px 2px 0px;
    border: 1px solid rgb(240, 240, 240); /* stylelint-disable-line */
    border-radius: 20px;
    min-height: 34px !important;
  }

  .container-notificacoes
    > .dropdown-menu-item-selected
    > .flex-column
    > div
    > .nome-pessoa {
    color: var(--color-white-gray);
  }

  .container-notificacoes
    > .dropdown-menu-item-selected
    > .flex-column
    > div
    > .nome-empresa {
    color: var(--color-white-gray);
  }

  .container-notificacoes
    > .dropdown-menu-item
    > .flex-column
    > div
    > .nome-pessoa {
    color: rgba(0, 0, 0, 0.65); /* stylelint-disable-line */
  }

  .container-notificacoes
    > .dropdown-menu-item
    > .flex-column
    > div
    > .nome-empresa {
    color: rgba(0, 0, 0, 0.65); /* stylelint-disable-line */
  }

  .avatar {
    margin-left: 1px;
    margin-top: 1px;
  }

  .dropdown-menu-item:hover,
  .dropdown-menu-submenu-title:hover {
    background-color: var(--color-gray-light);
    border-radius: 25px;
    color: var(--color-scrollbar);
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 2px 40px 2px 10px;
  }

  .date-text {
    color: var(--color-secondary-light);
    font-size: 0.563rem;
  }

  &:before {
    content: unset !important;
  }
  @media (max-width: 359px) {
    max-width: calc(100% - 20px);
    right: 10px;
    left: 10px;
  }
  @media (min-width: 360px) {
    max-width: 300px;
    &:before {
      content: ' ' !important;
    }
  }

  @media (min-width: 576px) {
    max-width: 360px;
    .dropdown-menu-item,
    .dropdown-menu-item-selected {
      padding: 2px 15px 2px 0px;
    }
  }
  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 992px) {
    max-width: 600px;
  }

  @media (min-width: 1200px) {
    max-width: 700px;
  }
`;

export const StyledMenu = styled(Menu)`
  .menu-item__custom {
    height: auto;
    padding: 0 !important;
    margin: 0;
    line-height: unset;
  }

  .menu-item__custom.ant-dropdown-menu-item:hover,
  .menu-item__custom.ant-dropdown-menu-submenu-title:hover {
    background-color: unset;
    border-radius: unset;
    color: unset;
  }

  .container-vinculos {
    max-height: 29vh;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--color-scrollbar) !important;
      border: 3px solid var(--color-white) !important;
    }

    ::-webkit-resizer {
      display: none;
    }

    ::-webkit-scrollbar-button {
      display: block;
    }
  }

  @media (min-width: 1200px) {
    .container-vinculos {
      max-height: 38vh;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        box-shadow: transparent;
        border-radius: 2px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: var(--color-scrollbar);
        border: 3px solid var(--color-white) !important;
      }

      ::-webkit-resizer {
        display: none;
      }

      ::-webkit-scrollbar-button {
        display: block;
      }
    }
  }

  .container-vinculos:hover {
    cursor: pointer;
  }

  .dropdown-menu-item,
  .dropdown-menu-item-selected {
    display: flex;
    min-width: 290px;
    margin: 7px;
  }

  .container-vinculos
    > .dropdown-menu-item-selected
    > .flex-column
    > div
    > .nome-pessoa {
    color: var(--color-white-gray);
  }

  .container-vinculos
    > .dropdown-menu-item-selected
    > .flex-column
    > div
    > .nome-empresa {
    color: var(--color-white-gray);
  }

  .container-vinculos
    > .dropdown-menu-item
    > .flex-column
    > div
    > .nome-pessoa {
    /* stylelint-disable-next-line function-blacklist */
    color: rgba(0, 0, 0, 0.65);
  }

  .container-vinculos
    > .dropdown-menu-item
    > .flex-column
    > div
    > .nome-empresa {
    /* stylelint-disable-next-line function-blacklist */
    color: rgba(0, 0, 0, 0.65);
  }

  .avatar {
    margin-left: 1px;
    margin-top: 1px;
  }

  .spotlight-text {
    font-weight: bold;
    color: var(--color-primary) !important;
  }

  .dropdown-menu-item:hover,
  .dropdown-menu-submenu-title:hover {
    background-color: var(--color-gray-light);
    border-radius: 25px;
    color: var(--text-color);
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 2px 40px 2px 10px;
  }
`;
