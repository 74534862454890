import React, { useContext, useEffect } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { ContextUser } from '../../store/contexts/contextUser';
import { ContextPerson } from '../../store/contexts/contextPerson';
import {
  GET_VINCULOS,
  GET_CURRENT_PERSON,
  OBTER_QTD_ORCAMENTOS_SEBRAETEC_AGUARDANDO,
  OBTER_CONFIGURACAO_POR_NOME,
  LISTAR_EMPRESAS_USUARIO_LOGADO
} from './queries';
import { userTypes } from '../../shared/util/enums/userTypesEnum';
import { ConfiguracaoEnum } from '../../shared/util/enums/configuracaoEnum';

export default function PersonComponent() {
  const {
    setPessoa,
    setIsPersonLoaded,
    setPerson,
    setPersonLoading,
    setVinculos,
    mustUpdatePerson,
    setMustUpdatePerson,
    setUsersAllowed,
    setNumberOfSebraetecRequests,
    setEmpresasCrm,
    mustUpdateEmpresasCrm,
    setMustUpdateEmpresasCrm,
    userType,
    setUserType,
    setHabilitarCursosPresenciais
  } = useContext(ContextPerson);
  const { user } = useContext(ContextUser);

  const { data: habilitarCapacitacaoPresencialData } = useQuery(
    OBTER_CONFIGURACAO_POR_NOME,
    {
      variables: {
        configuracaoEnum: ConfiguracaoEnum.HABILITAR_CAPACITACAO_PRESENCIAL
      }
    }
  );

  setHabilitarCursosPresenciais(
    habilitarCapacitacaoPresencialData &&
      habilitarCapacitacaoPresencialData.obterConfiguracaoPorNome.valor
        .valorBoolean
  );

  const [getVinculos, { data: vinculosData }] = useLazyQuery(GET_VINCULOS, {
    onCompleted() {
      if (!vinculosData || !vinculosData.listarVinculoPorPessoa) {
        handleSetVinculos([]);
        return;
      }
      const compatibleVinculos = vinculosData.listarVinculoPorPessoa.map(
        vinculo => {
          const pessoa = { ...vinculo.pessoa, ...vinculo.empresa };
          pessoa.nome = vinculo.pessoa.nome;
          pessoa.papel = vinculo.papel;
          pessoa.papeis = [];
          pessoa.fotoUrl = vinculo.empresa.fotoUrl;
          pessoa.fotoUrlResponsive = vinculo.empresa.fotoUrlResponsive;
          pessoa.papeis.push(vinculo.empresa.tipo);
          pessoa.papeis.push(vinculo.papel.nome);
          pessoa.etapaCadastroFornecedor = vinculo.situacao;
          pessoa.empresa = vinculo.empresa;
          pessoa.empresa.nome = vinculo.empresa.nomeFantasia;
          pessoa.vinculoPessoaEmpresaId = vinculo.id;
          return pessoa;
        }
      );
      handleSetVinculos(compatibleVinculos);
    }
  });
  const [
    getPessoa,
    { data: personData, loading: personLoading }
  ] = useLazyQuery(GET_CURRENT_PERSON, {
    onCompleted() {
      if (personData && personData.obterPessoaAtualPorUsuarioLogado) {
        setPessoa(personData.obterPessoaAtualPorUsuarioLogado);
        setPerson(gerarNovoPerson(personData.obterPessoaAtualPorUsuarioLogado));
        setUserType(getUserType(personData.obterPessoaAtualPorUsuarioLogado));
        getVinculos();
        listarEmpresasCrm();
      } else {
        setPessoa({});
        setPerson({});
        handleSetVinculos([]);
      }
    }
  });

  const [
    getOrcamentosSebraetecAguardando,
    { data: numOrcamentosData }
  ] = useLazyQuery(OBTER_QTD_ORCAMENTOS_SEBRAETEC_AGUARDANDO, {
    pollInterval: 1 * 60 * 1000
  });

  const [listarEmpresasCrm] = useLazyQuery(LISTAR_EMPRESAS_USUARIO_LOGADO, {
    onCompleted: data => {
      if (!data || !data.obterEmpresasPorUsuarioLogado) return;

      setEmpresasCrm(data.obterEmpresasPorUsuarioLogado);
    }
  });

  useEffect(() => {
    if (mustUpdateEmpresasCrm) {
      listarEmpresasCrm();
      setMustUpdateEmpresasCrm(false);
    }
  }, [mustUpdateEmpresasCrm]); // eslint-disable-line

  useEffect(() => {
    setPersonLoading(personLoading);
  }, [personLoading, setPersonLoading]);

  const handleSetVinculos = newVinculos => {
    setIsPersonLoaded(true);
    setVinculos(newVinculos);
  };

  const noUser = () => {
    setVinculos([]);
    setUserType(null);
    setIsPersonLoaded(false);
    setPessoa(undefined);
    setPerson(undefined);
  };

  const getUserType = pessoa => {
    if (!pessoa) {
      return userTypes.PUBLICO;
    } else if (pessoa && pessoa.vinculo && pessoa.vinculo.empresa) {
      if (pessoa.vinculo.empresa.tipo === userTypes.FORNECEDOR) {
        return userTypes.FORNECEDOR;
      }
      if (pessoa.vinculo.empresa.tipo === userTypes.LICENCIADO) {
        return userTypes.LICENCIADO;
      }
    }

    return userTypes.CLIENTE;
  };

  useEffect(() => {
    const isAllowedFuction = types => {
      return types.includes(userType);
    };
    setUsersAllowed((...types) => isAllowedFuction(types));

    if (userType === userTypes.FORNECEDOR) {
      getOrcamentosSebraetecAguardando();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    if (!user) {
      noUser();
      return;
    }
    setMustUpdatePerson(false);
    if (!user.id) {
      setPessoa({});
      setPerson({});
      setUserType(null);
      handleSetVinculos([]);
      return;
    }

    if (mustUpdatePerson) {
      getPessoa();
    }
    // eslint-disable-next-line
  }, [user, mustUpdatePerson]);

  useEffect(() => {
    if (
      numOrcamentosData &&
      numOrcamentosData.qtdOrcamentosSebraetecAguardando !== null
    )
      setNumberOfSebraetecRequests(
        numOrcamentosData.qtdOrcamentosSebraetecAguardando
      );
  }, [numOrcamentosData, setNumberOfSebraetecRequests]);

  //TODO REMOVER O PERSON DO CONTEXT
  const gerarNovoPerson = pessoaQuery => {
    const pessoa = pessoaQuery;
    let novoPerson = pessoa;
    let PJ = false;
    if (pessoa.vinculo) {
      novoPerson = { ...pessoa, ...pessoa.vinculo.empresa };
      PJ = true;
    }
    novoPerson = {
      ...novoPerson,
      tipo: PJ ? 'PESSOA_JURIDICA' : 'PESSOA_FISICA',
      identificador: PJ ? novoPerson.cnpj : novoPerson.cpf,
      nome: PJ ? novoPerson.nomeFantasia : novoPerson.nome,
      etapaCadastroFornecedor: PJ ? novoPerson.vinculo.situacao : null,
      papeis: PJ
        ? [novoPerson.vinculo.papel.nome, novoPerson.tipo, 'CLIENTE']
        : ['CLIENTE']
    };
    return novoPerson;
  };

  return <></>;
}
