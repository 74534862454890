import React from 'react';
import { Button as ButtonAntd } from 'antd';
import { Link } from 'react-router-dom';

export function Button({ href, children, ...props }) {
  return (
    <Link to={href}>
      <ButtonAntd {...props}>{children}</ButtonAntd>
    </Link>
  );
}
