import gql from 'graphql-tag';

export const RESPONDER_CONVITE_FORNECEDOR = gql`
  mutation responderConviteFornecedor(
    $conviteFornecedorId: String!
    $situacao: SituacaoConviteEnum!
  ) {
    responderConviteFornecedor(
      conviteFornecedorId: $conviteFornecedorId
      situacao: $situacao
    ) {
      id
      situacao
      empresa {
        id
        nomeFantasia
      }
      pessoa {
        id
        nome
      }
      dataInclusao
      dataExpiracao
      dataAceiteRecusa
      cpf
      email
      papel
      plano {
        id
        nome
      }
      mensagem
    }
  }
`;
