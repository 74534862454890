import React, { useState, useContext } from 'react';
import Icon from '@ant-design/icons';
import { Row, Col, Typography, Button } from 'antd';
import i18n from '../../../../shared/util/i18n';
import { Container } from './styles';
import { Link } from 'react-router-dom';
import Config from './../../../../shared/Config';
import WomanWithGlasses from '../../../../images/webps/woman-with-glasses.webp';
import ModalTerms from '../../../../pages/Cadastro/components/ModalTerms/ModalTerms';
import { tipoTermoUsoEnum } from '../../../../shared/util/enums/tipoTermoUsoEnum';
import { ReactComponent as SecurityBlueInverse } from '../../../../images/svgs/security-blue-inverse.svg';
import { ReactComponent as EqualizerBlueInverse } from '../../../../images/svgs/equalizer-blue-inverse.svg';
import { ReactComponent as CreditCardBlueInverse } from '../../../../images/svgs/credit-card-blue-inverse.svg';
import { ContextPerson } from '../../../../store/contexts/contextPerson';
const { Paragraph } = Typography;

export default function Informacoes() {
  const [modalTermsVisible, setModalTermsVisible] = useState(false);
  const [tipoTermoUso, setTipoTermoUso] = useState(null);
  const { pessoa } = useContext(ContextPerson);

  const openTermsOfUse = (e, tipoTermoUso) => {
    e.preventDefault();
    e.stopPropagation();
    setTipoTermoUso(tipoTermoUso);
    setModalTermsVisible(true);
  };

  const handleCloseModalTerms = () => {
    setModalTermsVisible(false);
  };

  return (
    <Container>
      {modalTermsVisible && (
        <ModalTerms
          tipo={tipoTermoUso}
          modalVisible={modalTermsVisible}
          handleOk={handleCloseModalTerms}
          handleCancel={handleCloseModalTerms}
        />
      )}
      <div className='container'>
        <Row justify='center' className='ant-row-flex container-items'>
          <Col span={24} md={8} className='p-4 item'>
            <Row justify='space-between' className='ant-row-flex mb-4'>
              <Icon
                component={EqualizerBlueInverse}
                style={{ fontSize: 45 }}
                className='icon-style'
              />
              <Link className='learn-more-link' to='/faq'>
                {i18n.t('LEARN_MORE')}
              </Link>
            </Row>
            <Row justify='start' className='ant-row-flex mb-4'>
              <h2 className='ant-typography information-title mb-0'>
                {i18n.t('HOME_SECTION_2_CARD_1_TITLE')}
              </h2>
            </Row>
            <Row justify='center' className='ant-row-flex'>
              <Paragraph className='text-left information-description'>
                {i18n.t('HOME_SECTION_2_CARD_1_SUBTITLE_1')}
                <span className='sebrae-style'>{i18n.t('SEBRAE')}</span>
                {i18n.t('HOME_SECTION_2_CARD_1_SUBTITLE_2')}
              </Paragraph>
            </Row>
          </Col>
          <Col span={24} md={8} className='p-4 item'>
            <Row justify='space-between' className='ant-row-flex mb-4'>
              <Icon
                component={SecurityBlueInverse}
                style={{ fontSize: 45 }}
                className='icon-style'
              />
              <Link className='learn-more-link' to='/faq'>
                {i18n.t('LEARN_MORE')}
              </Link>
            </Row>
            <Row justify='start' className='ant-row-flex mb-4'>
              <h2 className='ant-typography information-title mb-0'>
                {i18n.t('HOME_SECTION_2_CARD_2_TITLE')}
              </h2>
            </Row>
            <Row justify='center' className='ant-row-flex'>
              <Paragraph className='text-left information-description'>
                {i18n.t('HOME_SECTION_2_CARD_2_SUBTITLE_1')}
                <span className='sebrae-style'>{i18n.t('SEBRAE')}</span>
                {i18n.t('HOME_SECTION_2_CARD_2_SUBTITLE_2')}
              </Paragraph>
            </Row>
          </Col>
          <Col span={24} md={8} className='p-4 item'>
            <Row justify='space-between' className='ant-row-flex mb-4'>
              <Icon
                component={CreditCardBlueInverse}
                style={{ fontSize: 45 }}
                className='icon-style'
              />
              <Link className='learn-more-link' to='/faq'>
                {i18n.t('LEARN_MORE')}
              </Link>
            </Row>
            <Row justify='start' className='ant-row-flex mb-4'>
              <h2 className='ant-typography information-title mb-0'>
                {i18n.t('HOME_SECTION_2_CARD_3_TITLE')}
              </h2>
            </Row>
            <Row justify='center' className='ant-row-flex'>
              <Paragraph className='text-left information-description'>
                {i18n.t('HOME_SECTION_2_CARD_3_SUBTITLE_1')}
                <span className='sebrae-style'>
                  {i18n.t('HOME_SECTION_2_CARD_3_SUBTITLE_2')}
                </span>
              </Paragraph>
            </Row>
          </Col>
        </Row>
        <Row justify='center' className='ant-row-flex know-how-it-works'>
          <Col span={24} md={12} className='p-4 card-4-style'>
            <Row justify='start' className='ant-row-flex mb-4'>
              <h3 className='ant-typography information-title mb-0'>
                {i18n.t('HOME_SECTION_2_CARD_4_TITLE')}
              </h3>
            </Row>
            <Row justify='center' className='ant-row-flex'>
              <Paragraph className='text-left information-description'>
                {i18n.t('HOME_SECTION_2_CARD_4_SUBTITLE')}
              </Paragraph>
            </Row>
            <Row justify='end' className='ant-row-flex'>
              <p
                className='card-4-links underline'
                onClick={e => openTermsOfUse(e, tipoTermoUsoEnum.USUARIO)}>
                {i18n.t('USE_TERMS_MODAL')}
              </p>
              <Link className='card-4-links ml-4' to='/faq'>
                {i18n.t('LEARN_MORE')}
              </Link>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <img
              src={WomanWithGlasses}
              alt={i18n.t('BANNER_ALT') + i18n.t('HOME_SECTION_2_CARD_4_TITLE')}
              className='card-4-image-style'
            />
          </Col>
        </Row>
        <Row
          justify='space-around'
          className='ant-row-flex register-now-container'>
          <Row justify='start' className='ant-row-flex'>
            <>
              {pessoa && pessoa.vinculo && pessoa.vinculo.empresa && (
                <h5 className='ant-typography register-now-text mb-0'>
                  {i18n.t('YOU_ARE_SUPPLIER')}{' '}
                  <span className='sebrae-style'>{i18n.t('SEBRAEPLACE')}</span>
                </h5>
              )}
              {pessoa && !pessoa.vinculo && Object.keys(pessoa).length !== 0 && (
                <h5 className='ant-typography register-now-text mb-0'>
                  {i18n.t('YOU_ARE_CLIENT')}{' '}
                  <span className='sebrae-style'>{i18n.t('SEBRAEPLACE')}</span>
                </h5>
              )}
              {!pessoa ||
                (pessoa && Object.keys(pessoa).length === 0 && (
                  <h5 className='ant-typography register-now-text mb-0'>
                    {i18n.t('HOME_SECTION_2_CARD_5_TITLE')}{' '}
                    <span className='sebrae-style'>
                      {i18n.t('SEBRAEPLACE')}
                    </span>
                  </h5>
                ))}
            </>
          </Row>
          {pessoa && pessoa.id && (
            <Link to={'/painel'}>
              <Button
                type='primary'
                shape='round'
                className='px-2 px-md-4 create-account-button'>
                {i18n.t('GO_TO_ACCOUNT')}
              </Button>
            </Link>
          )}
          {(!pessoa || !pessoa.id) && (
            <a href={`${Config.loginUiUrl}/cadastro`}>
              <Button
                type='primary'
                shape='round'
                className='px-2 px-md-4 create-account-button'>
                {i18n.t('CREATE_YOUR_ACCOUNT')}
              </Button>
            </a>
          )}
        </Row>
      </div>
    </Container>
  );
}
