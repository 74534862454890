import { actionAlert } from '../actions/actionAlert';

export let initialStateAlert = {};

export let reducerAlert = (state, action) => {
  switch (action.type) {
    case actionAlert.ADD:
      return {
        ...action.payload,
        show: true,
        initAnimation: false,
        noSetTimeout: false
      };
    case actionAlert.ADD_NO_SETTIMEOUT:
      return {
        ...action.payload,
        show: true,
        initAnimation: false,
        noSetTimeout: true
      };
    case actionAlert.REMOVE:
      return { ...action.payload, show: false, initAnimation: false };
    case actionAlert.INITANIMATIONS:
      return { ...action.payload, initAnimation: true };
    default:
  }
};
