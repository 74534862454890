import * as jwt from 'jsonwebtoken';
import Config from '../Config';

export const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const BACK_OFFICE_ROLE = 'BACK_OFFICE';

export function getToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function setToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function getRefreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return refreshToken ? refreshToken : '';
}

export function isTokenUndefinedOrExpired() {
  const accessToken = getToken();
  if (!accessToken) {
    return true;
  }
  const decoded = jwt.decode(accessToken);
  if (!decoded || !decoded.exp || typeof decoded.exp !== 'number') {
    return true;
  }
  const expDate = new Date(decoded.exp * 1000);
  return new Date() > expDate;
}

export function isRefreshTokenUndefinedOrExpired() {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return true;
  }
  const decoded = jwt.decode(refreshToken);
  if (!decoded || !decoded.exp || typeof decoded.exp !== 'number') {
    return true;
  }
  const expDate = new Date(decoded.exp * 1000);
  return new Date() > expDate;
}

export function clearTokenAndRefreshToken() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

export function setHeaderToken() {
  const accessToken = getToken();
  return {
    Authorization: `Bearer ${accessToken}`
  };
}

export async function verificarToken() {
  if (!isTokenUndefinedOrExpired()) {
    return true;
  }
  const accessToken = await refreshAccessToken();
  if (accessToken) {
    setToken(accessToken);
    return true;
  }
  return false;
}

export async function generateNewAccessToken() {
  const refreshToken = getRefreshToken();
  const responseRefreshToken = await fetch(
    `${Config.ssoApiUrl}/auth/refresh-token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken: refreshToken })
    }
  );
  if (
    responseRefreshToken &&
    responseRefreshToken.status < 400 &&
    responseRefreshToken.status >= 200
  ) {
    const json = await responseRefreshToken.json();
    setToken(json.accessToken);
  }
}

async function refreshAccessToken() {
  const refreshToken = getRefreshToken();
  if (!refreshToken || !refreshToken.length) {
    return null;
  }
}

export function isBackOfficeUser() {
  const accessToken = getToken();
  const decoded = jwt.decode(accessToken);
  if (
    decoded &&
    decoded.roles &&
    decoded.roles.length > 0 &&
    decoded.roles.find(r => r === BACK_OFFICE_ROLE)
  ) {
    return true;
  }

  return false;
}

export function isColaborador() {
  const accessToken = getToken();
  const decoded = jwt.decode(accessToken);
  if (!accessToken || !decoded || !decoded.colaborador) {
    return false;
  }

  return decoded.colaborador;
}

export function isTerceiro() {
  const accessToken = getToken();
  const decoded = jwt.decode(accessToken);
  if (!accessToken || !decoded || !decoded.terceiro) {
    return false;
  }

  return decoded.terceiro;
}
