import dayjs from 'dayjs';
import { TipoNotificacaoEnum } from './enums/tipoNotificacaoEnum';
import { defaultObjSupportSebrae } from './suporteSebraeUtils';
import {
  CalendarOutlined,
  CheckOutlined,
  MessageOutlined,
  PlayCircleOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  UserOutlined,
  WalletOutlined
} from '@ant-design/icons';

export const notificationHandler = (notificacao, links) => {
  const item = {};
  item.id = notificacao.id;
  item.text = `${notificacao.descricao}`;
  item.dateText = notificacao.ativoEm
    ? `${dayjs(new Date(notificacao.ativoEm)).fromNow()}`
    : '';
  item.situacao = notificacao.situacao;
  item.tipo = notificacao.tipo;

  switch (notificacao.tipo) {
    case TipoNotificacaoEnum.NEGOCIACAO:
      return notificacaoNegociacao(item, links, notificacao.valor);
    case TipoNotificacaoEnum.MENSAGEM:
      return notificacaoNegociacao(item, links, notificacao.valor);
    case TipoNotificacaoEnum.CURSO_CONCLUIDO:
      return notificacaoCursoExpiracao(item, links, notificacao.valor);
    case TipoNotificacaoEnum.CURSO_VIDEO_DEMO:
      return notificacaoCurso(item, notificacao.valor, 7);
    case TipoNotificacaoEnum.CURSO_EXPIRACAO_CONCLUSAO:
      return notificacaoCursoExpiracao(item, links, notificacao.valor);
    case TipoNotificacaoEnum.CARTEIRA:
      return notificacaoCarteira(item, links);
    case TipoNotificacaoEnum.DADOS_CADASTRAIS:
      return notificacaoDadosCadastrais(item, links);
    case TipoNotificacaoEnum.CARRINHO:
      return notificacaoCarrinho(item, links);
    case TipoNotificacaoEnum.CONFIRME_EXECUCAO_NEGOCIACAO:
      return notificacaoConfirmeNegociacaoExecucao(item, links);
    case TipoNotificacaoEnum.CADASTRO_INTEGRACAO:
      return notificacaoCadastroIntegracao(item, links, notificacao.valor);
    case TipoNotificacaoEnum.RESPOSTA_PERGUNTA_AULA_CURSO:
      return notificacaoPerguntaRespondidaCursoOnline(
        item,
        links,
        notificacao.valor
      );
    case TipoNotificacaoEnum.SAAS_PERGUNTA_RESPONDIDA:
      return notificacaoPerguntaRespondida(item, links, notificacao.valor);
    case TipoNotificacaoEnum.SAAS_PERGUNTA_RECEBIDA:
      return notificacaoPerguntaRecebida(item, links, notificacao.valor);
    case TipoNotificacaoEnum.SAAS_RECLAMACAO:
      return notificacaoSaasReclamacao(item, links, notificacao.valor);
    case TipoNotificacaoEnum.SAAS_APROVADO:
      return notificacaoSaasAprovado(item, notificacao.valor);
    case TipoNotificacaoEnum.SAAS_REPROVADO:
      return notificacaoSaasReprovado(item, notificacao.valor);
    case TipoNotificacaoEnum.SEBRAETEC_VINCULO_APROVADO:
      return notificacaoSebraetecAprovado(item);
    case TipoNotificacaoEnum.SEBRAETEC_VINCULO_REPROVADO:
      return notificacaoSebraetecReprovado(item, notificacao.valor);
    case TipoNotificacaoEnum.SEBRAETEC_VINCULO_ENVIADO:
      return notificacaoSebraetecEnviado(item);
    case TipoNotificacaoEnum.SEBRAETEC_SOLICITACOES_ORCAMENTO:
      return notificacaoSebraetecSolicitacoesOrcamentos(item);
    case TipoNotificacaoEnum.SEBRAETEC_MEUS_ORCAMENTOS:
      return notificacaoSebraetecMeusOrcamentos(item);
    case TipoNotificacaoEnum.SEBRAETEC_DEVOLUCAO_SOLICITADA:
      return notificacaoSebraetecDevolucaoSolicitada(item, notificacao.valor);
    case TipoNotificacaoEnum.SEBRAETEC_DEVOLUCAO_APROVADA_CLIENTE:
      return notificacaoSebraetecDevolucaoAprovadaCliente(item);
    case TipoNotificacaoEnum.SEBRAETEC_DEVOLUCAO_CONTESTACAO_ACEITA_FORNECEDOR:
    case TipoNotificacaoEnum.SEBRAETEC_DEVOLUCAO_CONTESTACAO_REJEITADA_FORNECEDOR:
      return notificacaoSebraetecDevolucaoContestacaoFornecedor(item);
    case TipoNotificacaoEnum.SEBRAETEC_DEVOLUCAO_AJUSTES_CLIENTE:
    case TipoNotificacaoEnum.SEBRAETEC_DEVOLUCAO_AJUSTES_FORNECEDOR:
      return notificacaoSebraetecDevolucaoAjustes(item, notificacao.valor);
    case TipoNotificacaoEnum.PRODUTO:
      return notificacaoProduto(item, notificacao.valor);
    case TipoNotificacaoEnum.PRODUTO_REPROVADO:
      return notificacaoProdutoReprovado(item, notificacao.valor);
    case TipoNotificacaoEnum.FORNECEDOR:
      return notificacaoFornecedor(item, notificacao.valor);
    case TipoNotificacaoEnum.CONSULTORIA:
      return notificacaoConsultoria(item, notificacao.valor);
    case TipoNotificacaoEnum.CURSO_ENVIADO:
      return notificacaoCursoAprovado(item, links, notificacao.valor);
    case TipoNotificacaoEnum.CONSULTORIA_REPROVADA:
      return notificacaoConsultoriaReprovado(item, notificacao.valor);
    case TipoNotificacaoEnum.CURSO_APROVADO:
      return notificacaoCursoAprovado(item, links, notificacao.valor);
    case TipoNotificacaoEnum.CURSO_PUBLICACAO:
      return notificacaoCursoPost(item, links, notificacao.valor);
    case TipoNotificacaoEnum.RESPOSTA_AVALIACAO_CURSO:
      return notificacaoRespostaAvaliacaoCurso(item, links, notificacao.valor);
    case TipoNotificacaoEnum.MENSAGEM_SUPORTE:
      return notificacaoSuporteFornecedor(item, links);
    case TipoNotificacaoEnum.NOTA_FISCAL_AFILIACAO:
      return notificacaoNotaFiscal(item);
    case TipoNotificacaoEnum.ALTERACAO_NO_EVENTO:
      return notificacaoAlteracaoNoEvento(item, notificacao.valor);
    default:
      return item;
  }
};

function notificacaoProduto(item, id) {
  item.icon = TagOutlined;
  item.link = `/produto/${id}`;
  return item;
}

function notificacaoProdutoReprovado(item, id) {
  item.icon = TagOutlined;
  item.link = `/cadastro-produto/capacitacao/${id}`;
  return item;
}

function notificacaoSebraetecAprovado(item) {
  item.icon = CheckOutlined;
  item.link = '/solucoes-sebraetec/vinculados';
  return item;
}

function notificacaoSebraetecReprovado(item, id) {
  item.icon = TagOutlined;
  item.link = `/cadastro-sebraetec/${id}`;
  return item;
}

function notificacaoSebraetecEnviado(item) {
  item.icon = MessageOutlined;
  item.link = '/solucoes-sebraetec/pendentes';
  return item;
}

function notificacaoSebraetecSolicitacoesOrcamentos(item) {
  item.link = '/solucoes-sebraetec';
  return item;
}

function notificacaoSebraetecMeusOrcamentos(item) {
  item.link = '/orcamentos-sebraetec';
  return item;
}

function notificacaoSebraetecDevolucaoSolicitada(item, valor) {
  item.link = `/solucoes-sebraetec/atender/${valor}`;
  return item;
}

function notificacaoSebraetecDevolucaoAprovadaCliente(item) {
  item.link = '/minhas-compras?tab=Sebraetec';
  return item;
}

function notificacaoSebraetecDevolucaoContestacaoFornecedor(item) {
  item.link = '/solucoes-sebraetec';
  return item;
}

function notificacaoSebraetecDevolucaoAjustes(item, valor) {
  item.link = `/negociacoes?neg=${valor}`;
  return item;
}

function notificacaoConsultoria(item, id) {
  item.icon = TagOutlined;
  item.link = `/consultoria/${id}`;
  return item;
}

function notificacaoCurso(item, id, step) {
  item.icon = PlayCircleOutlined;
  item.link = `/cadastro-cursos/${id}`;
  if (step) item.link = `${item.link}/${step}`;
  return item;
}

function notificacaoCursoExpiracao(item, links, id) {
  item.icon = PlayCircleOutlined;
  item.link = `${links.cursoSalaDeAula}/${id}`;
  return item;
}

function notificacaoConsultoriaReprovado(item, id) {
  item.icon = TagOutlined;
  item.link = `/cadastro-produto/consultoria/${id}`;
  return item;
}

function notificacaoAlteracaoNoEvento(item, id) {
  item.icon = CalendarOutlined;
  item.link = `/evento/${id}`;
  return item;
}

function notificacaoFornecedor(item, id) {
  item.icon = UserOutlined;
  item.link = `/perfil/${id}`;
  return item;
}

function notificacaoNegociacao(item, links, negociacao) {
  item.icon = MessageOutlined;
  item.link = `${links.negociacao}/?neg=${negociacao}`;
  return item;
}

function notificacaoCarteira(item, links) {
  item.icon = WalletOutlined;
  item.link = links.carteira;
  return item;
}

function notificacaoDadosCadastrais(item, links) {
  item.icon = UserOutlined;
  item.link = links.dadosCadastrais;
  return item;
}

function notificacaoCarrinho(item, links) {
  item.icon = ShoppingCartOutlined;
  item.link = links.carrinho;
  return item;
}

function notificacaoConfirmeNegociacaoExecucao(item, links) {
  item.icon = CheckOutlined;
  item.link = links.painel;
  return item;
}

function notificacaoCadastroIntegracao(item, links, produto) {
  item.icon = CheckOutlined;
  item.link = `${links.cadastroIntegracao}/${produto}`;
  return item;
}

function notificacaoPerguntaRespondida(item, links, produto) {
  item.icon = CheckOutlined;
  item.link = `${links.landingPage}/${produto}?#perguntas`;
  return item;
}

function notificacaoPerguntaRespondidaCursoOnline(item, links, cursoId) {
  item.icon = MessageOutlined;
  item.link = `${links.cursosPerguntas}/${cursoId}`;
  return item;
}

function notificacaoPerguntaRecebida(item, links) {
  item.icon = CheckOutlined;
  item.link = `${links.solucaoDigitalPerguntasRespostas}`;
  return item;
}

function notificacaoSaasReclamacao(item, links, negociacao) {
  item.icon = MessageOutlined;
  item.link = `${links.negociacao}/?neg=${negociacao}`;
  return item;
}

function notificacaoSaasAprovado(item, saasProduto) {
  item.icon = CheckOutlined;
  item.link = `/solucao-inteligente/produto/${saasProduto}`;
  return item;
}

function notificacaoSaasReprovado(item, saasProduto) {
  item.icon = TagOutlined;
  item.link = `/solucao-inteligente/cadastro-produto/${saasProduto}`;
  return item;
}

function notificacaoCursoAprovado(item, links, idCurso) {
  item.icon = CheckOutlined;
  item.link = `${links.cursoOnline}/${idCurso}`;
  return item;
}

function notificacaoCursoPost(item, links, idCurso) {
  item.icon = UserOutlined;
  item.link = `${links.cursoSalaDeAula}/${idCurso}?info=posts`;
  return item;
}

function notificacaoRespostaAvaliacaoCurso(item, links, idCurso) {
  item.icon = MessageOutlined;
  item.link = `${links.cursoOnline}/${idCurso}`;
  return item;
}

function notificacaoSuporteFornecedor(item, links) {
  item.icon = MessageOutlined;
  item.link = `${links.negociacao}?neg=${defaultObjSupportSebrae.id}`;
  return item;
}

function notificacaoNotaFiscal(item) {
  item.icon = MessageOutlined;
  item.link = `/notas`;
  return item;
}
