import gql from 'graphql-tag';

export const CHANGE_SHOW_AFFILIATE_TUTORIAL = gql`
  mutation AlterarMostrarTutorialAfiliado($mostrar: Boolean!) {
    alterarMostrarTutorialAfiliado(mostrar: $mostrar) {
      afiliacao {
        id
        mostrarTutorial
      }
    }
  }
`;
