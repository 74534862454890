import React, { useState, useContext } from 'react';
import Icon from '@ant-design/icons';
import { Typography, Button, Dropdown, Menu, Row, Input } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { Container, StyledMenu, StyledMenuNotificacoes } from './styles';
import './mozilla.css';
import {
  clearTokenAndRefreshToken,
  isColaborador
} from '../../shared/util/utilToken';
import PropTypes from 'prop-types';
import { Button as ButtonLink } from '../../components/LinkComponent/LinkComponent';
import { notificationHandler } from '../../shared/util/NotificacoesHandler';
import { papelBeautify } from '../../shared/util/enums/papelEnum';
import i18n from '../../shared/util/i18n';
import { situacaoEnum } from '../../shared/util/enums/situacaoEnum';
import { ContextAlert } from '../../store/contexts/contextAlert';
import { actionAlert } from '../../store/actions/actionAlert';
import AvatarPadrao from '../../images/svgs/avatar-padrao.svg';
import LogoDev from '../../images/webps/logo-dev.webp';
import { ReactComponent as LogoSebrae } from '../../images/svgs/logo-sebrae.svg';
import { ReactComponent as LogoSebraeWhiteYellow } from '../../images/svgs/logo-sebrae-white-yellow.svg';
import { ReactComponent as Bell } from '../../images/svgs/bell.svg';
import { ReactComponent as SearchIcon } from '../../images/svgs/search.svg';
import { ReactComponent as Cart } from '../../images/svgs/cart.svg';
import { ReactComponent as Calendar } from '../../images/svgs/calendar.svg';
import { ReactComponent as HeartSolid } from '../../images/svgs/heart-solid.svg';
import { ReactComponent as MenuIcon } from '../../images/svgs/menu.svg';
import { ReactComponent as MenuListIcon } from '../../images/svgs/menu-list.svg';
import { ReactComponent as ExitIcon } from '../../images/svgs/exit-button.svg';
import { ReactComponent as Loading } from '../../images/svgs/loading.svg';
import Config from '../../shared/Config';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { getSmallestImage } from '../../shared/util/imageUtils';
const { Text } = Typography;
const { Search } = Input;

HeaderComponent.propTypes = {
  setarPessoaAtual: PropTypes.func.isRequired,
  setarPessoaAtualLoading: PropTypes.bool,
  marcarNotificacaoComoLida: PropTypes.func,
  marcarTodasNotificacoesComoLidas: PropTypes.func,
  pessoa: PropTypes.shape({}),
  showTornarSeFornecedor: PropTypes.bool,
  canRenderActions: PropTypes.bool,
  vinculos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      nome: PropTypes.string.isRequired,
      fotoUrl: PropTypes.string,
      fotoUrlResponsive: PropTypes.object,
      situacaoCadastro: PropTypes.string,
      papeis: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  okThirdSetup: PropTypes.bool,
  isPersonLoaded: PropTypes.bool,
  links: PropTypes.shape({
    painel: PropTypes.string.isRequired,
    cadastrarNovoFornecedor: PropTypes.string.isRequired,
    editarPerfilPf: PropTypes.string.isRequired,
    editarPerfilPj: PropTypes.string.isRequired,
    verPerfilPf: PropTypes.string.isRequired,
    verPerfilPj: PropTypes.string.isRequired,
    alterarSenhaPf: PropTypes.string.isRequired,
    sair: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
    cadastroFornecedor: PropTypes.string.isRequired,
    painelLicenciado: PropTypes.string.isRequired,
    cadastroPj: PropTypes.string.isRequired,
    negociacao: PropTypes.string.isRequired,
    carteira: PropTypes.string.isRequired,
    dadosCadastrais: PropTypes.string.isRequired,
    notificacoes: PropTypes.string.isRequired,
    carrinho: PropTypes.string.isRequired,
    catalogoCliente: PropTypes.string.isRequired
  }).isRequired,
  environment: PropTypes.string,
  menuList: PropTypes.bool,
  whiteYellowLogo: PropTypes.bool,
  handleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  withoutBlur: PropTypes.bool
};

export default function HeaderComponent(props) {
  const { personLoading } = useContext(ContextPerson);
  const { dispatchAlert } = useContext(ContextAlert);
  const [searchText, setSearchText] = useState('');
  const handleSearchChange = e => setSearchText(e.target.value);

  const searchCatalogo = () =>
    searchText.length > 0
      ? history.push(`/catalogo/${searchText}`) // eslint-disable-line no-restricted-syntax
      : dispatchAlert({
          type: actionAlert.ADD,
          payload: {
            message: 'WARNING',
            description: i18n.t('SEARCH_PLACEHOLDER'),
            type: 'warning'
          }
        });
  const history = useHistory();
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  let vinculos = [];
  let pessoa = null;
  let vinculoAtual = null;
  if (props && props.vinculos && props.vinculos.length) {
    vinculos = props.vinculos;
  }
  if (props && props.pessoa) {
    pessoa = props.pessoa;
    if (props.pessoa.vinculo) {
      vinculoAtual = props.pessoa.vinculo;
    }
  }

  /* eslint-disable no-restricted-syntax */
  const handleClick = (props, e) => {
    if (e.key === 'painel') {
      history.push(props.links.painel);
    } else if (e.key === 'cadastro-perfil-pf') {
      history.push(props.links.editarPerfilPf);
    } else if (e.key === 'cadastro-fornecedor') {
      history.push(props.links.cadastroFornecedor);
    } else if (e.key === 'perfil-pf') {
      history.replace(props.links.verPerfilPf);
    } else if (e.key === 'alterar-senha-pf') {
      history.replace(props.links.alterarSenhaPf);
    } else if (e.key === 'cadastro-perfil-pj') {
      history.push(props.links.editarPerfilPj);
    } else if (e.key === 'perfil-pj') {
      history.replace(props.links.verPerfilPj);
    } else if (e.key === 'home-afiliado') {
      history.replace(props.links.homeAfiliado);
    } else if (e.key === 'painel-afiliado') {
      history.replace(props.links.painelAfiliado);
    } else if (e.key === 'sair') {
      clearTokenAndRefreshToken();
      setTimeout(() => {
        // href porque é a melhor solução sim!
        window.location.href = Config.baseUrl + props.links.sair;
      }, 0);
    } else {
      let empresa = vinculos.filter(p => p.id === e.key)[0];
      if (empresa) {
        if (empresa.etapaCadastroFornecedor === situacaoEnum.EM_CADASTRAMENTO) {
          history.push(
            props.links.cadastroFornecedor +
              '/' +
              empresa.vinculoPessoaEmpresaId
          );
        } else {
          props.setarPessoaAtual(empresa);
          props.setSeeAll(false);
        }
      } else if (e.key === pessoa.id) {
        props.setarPessoaAtual(null);
      }
    }
  };
  /* eslint-enable no-restricted-syntax */

  const fotoAtual = () => {
    let fotoAtual = AvatarPadrao;
    if (vinculoAtual && vinculoAtual.empresa && vinculoAtual.empresa.fotoUrl) {
      fotoAtual = getSmallestImage(
        vinculoAtual.empresa.fotoUrlResponsive,
        vinculoAtual.empresa.fotoUrl
      );
    }
    if (!vinculoAtual && pessoa && pessoa.fotoUrl) {
      fotoAtual = getSmallestImage(pessoa.fotoUrlResponsive, pessoa.fotoUrl);
    }
    return fotoAtual;
  };
  const empresaAtualId = () => {
    if (!vinculoAtual || !vinculoAtual.empresa || !vinculoAtual.empresa.id)
      return null;
    return vinculoAtual.empresa.id;
  };
  const handleNotificationsMenuClick = (props, e, item) => {
    if (e.key === 'ver-todas') {
      props.setSeeAll(true);
      props.getNotificationsAll();
    } else {
      props.marcarNotificacaoComoLida(item);
    }
  };

  const handleImageError = e => {
    e.target.src = AvatarPadrao;
  };

  const canBecomeAffiliate = () =>
    !vinculoAtual &&
    pessoa &&
    !pessoa.afiliacao &&
    props.okThirdSetup &&
    !isColaborador();

  const isAffiliate = () => pessoa && pessoa.afiliacao && !vinculoAtual;

  const triggerModeMenu = (props, vinculos) => (
    <StyledMenu onClick={e => handleClick(props, e)}>
      <Menu.Item className='menu-item__custom'>
        <div className='container-vinculos'>
          {pessoa && (
            <div
              style={{ fontWeight: '600 !important', minHeight: '50px' }}
              key={pessoa.id}
              onClick={() => handleClick(props, { key: pessoa.id })}
              className={
                !vinculoAtual
                  ? 'dropdown-menu-item-selected'
                  : 'dropdown-menu-item'
              }>
              <img
                src={
                  pessoa.fotoUrl
                    ? getSmallestImage(pessoa.fotoUrlResponsive, pessoa.fotoUrl)
                    : AvatarPadrao
                }
                className='avatar mr-2'
                alt={i18n.t('AVATAR_ALT') + (pessoa && pessoa.nome)}
                style={{ height: '48px', width: '48px' }}
                onError={handleImageError}
              />
              <Row
                justify='space-around'
                className='ant-row-flex flex-column'
                style={{ padding: '5px auto', fontWeight: '600 !important' }}>
                <div>
                  {' '}
                  <Text
                    style={{ maxWidth: '210px' }}
                    ellipsis
                    className='nome-pessoa'>
                    {pessoa.nome}
                  </Text>
                </div>
                <div style={{ fontSize: '0.9em' }}>
                  {' '}
                  <Text
                    style={{ maxWidth: '210px' }}
                    ellipsis
                    type='secondary'
                    className='nome-pessoa'>
                    {i18n.t('CLIENT')}
                  </Text>
                </div>
              </Row>
            </div>
          )}
          {vinculos &&
            vinculos.map(vinculo => (
              <div
                style={{ fontWeight: '600 !important', minHeight: '50px' }}
                key={vinculo.empresa.id}
                onClick={() => handleClick(props, { key: vinculo.empresa.id })}
                className={
                  vinculo.empresa.id === empresaAtualId()
                    ? 'dropdown-menu-item-selected'
                    : 'dropdown-menu-item'
                }>
                <img
                  src={
                    vinculo.empresa.fotoUrl
                      ? getSmallestImage(
                          vinculo.empresa.fotoUrlResponsive,
                          vinculo.empresa.fotoUrl
                        )
                      : AvatarPadrao
                  }
                  className='avatar mr-2'
                  alt={
                    i18n.t('AVATAR_ALT') +
                    (vinculo && vinculo.empresa && vinculo.empresa.nomeFantasia)
                  }
                  style={{ height: '48px', width: '48px' }}
                  onError={handleImageError}
                />
                <Row
                  justify='space-around'
                  className='ant-row-flex flex-column'
                  style={{ padding: '5px auto', fontWeight: '600 !important' }}>
                  <div>
                    {' '}
                    <Text
                      style={{ maxWidth: '210px' }}
                      ellipsis
                      className='nome-pessoa'>
                      {pessoa.nome}
                    </Text>
                  </div>
                  <div style={{ fontSize: '0.9em' }}>
                    {vinculo.empresa && vinculo.empresa.nomeFantasia ? (
                      <Text
                        style={{ maxWidth: '130px' }}
                        ellipsis
                        type='secondary'
                        className='nome-empresa'>
                        {vinculo.empresa.nomeFantasia}
                      </Text>
                    ) : (
                      ' '
                    )}
                    {vinculo.empresa && vinculo.papel && vinculo.papel.nome ? (
                      <Text
                        style={{ maxWidth: '130px' }}
                        ellipsis
                        type='secondary'
                        className='nome-empresa'>
                        {` - ${papelBeautify(vinculo.papel.nome)}`}
                      </Text>
                    ) : (
                      ''
                    )}
                  </div>
                </Row>
              </div>
            ))}
        </div>
      </Menu.Item>

      {vinculoAtual && <Menu.Divider />}
      {vinculoAtual && (
        <Menu.Item key='cadastro-perfil-pj'>
          {i18n.t('EDIT_COMPANY_PROFILE')}
        </Menu.Item>
      )}
      {vinculoAtual && (
        <Menu.Item key='perfil-pj'>{i18n.t('VIEW_COMPANY_PROFILE')}</Menu.Item>
      )}
      <Menu.Divider />
      {pessoa && <Menu.Item key='painel'>{i18n.t('MY_DASHBOARD')}</Menu.Item>}
      {pessoa && (
        <Menu.Item key='cadastro-perfil-pf'>{i18n.t('EDIT_PROFILE')}</Menu.Item>
      )}
      {pessoa && (
        <Menu.Item key='perfil-pf'>{i18n.t('VIEW_PROFILE')}</Menu.Item>
      )}
      {pessoa && (
        <Menu.Item key='alterar-senha-pf'>{i18n.t('ALTERAR_SENHA')}</Menu.Item>
      )}
      <Menu.Divider />

      {canBecomeAffiliate() && props.allowsAffiliate && (
        <Menu.Item key='home-afiliado'>
          <Text className='spotlight-text'>
            {i18n.t('WANT_TO_BE_AFFILIATE')}
          </Text>
        </Menu.Item>
      )}

      {canBecomeAffiliate() && props.allowsAffiliate && <Menu.Divider />}
      {isAffiliate() && (
        <Menu.Item key='painel-afiliado'>
          {i18n.t('AFFILIATE_DASHBOARD')}
        </Menu.Item>
      )}

      {isAffiliate() && <Menu.Divider />}

      <Menu.Item key='sair'>
        <Text type='danger'>{i18n.t('EXIT')}</Text>
      </Menu.Item>
    </StyledMenu>
  );

  const handledNotifications = props => {
    if (props && props.notifications) {
      return props.notifications.map(item =>
        notificationHandler(item, props.links)
      );
    }
    return [];
  };

  const isPersonLoading = () => {
    return (props && props.setarPessoaAtualLoading) || personLoading;
  };

  const triggerNotifications = props => (
    <StyledMenuNotificacoes>
      <Menu.Item className='menu-item__custom'>
        <div className='container-notificacoes'>
          {handledNotifications(props).map((item, index) => (
            <div
              style={{
                fontWeight: '600 !important',
                minHeight: '50px'
              }}
              key={index}
              onClick={e => handleNotificationsMenuClick(props, e, item)}
              className={'dropdown-menu-item'}>
              <Row
                justify='space-around'
                className='ant-row-flex flex-column'
                style={{ padding: '5px auto', fontWeight: '600 !important' }}>
                <div className='d-flex align-items-center'>
                  <Button
                    shape='circle'
                    icon={item.icon ? <item.icon /> : <Icon component={Bell} />}
                    style={{
                      color: '#b0b0b0',
                      marginRight: '5px',
                      width: '34px',
                      height: '34px'
                    }}
                  />
                  <span
                    className='m-auto'
                    style={{
                      fontSize: '11px',
                      letterSpacing: '0.7px',
                      color: '#2c2c2c',
                      whiteSpace: 'break-spaces'
                    }}>
                    {`${item.text}`}
                    {item.dateText && (
                      <span className='date-text ml-3'>{`${item.dateText}`}</span>
                    )}
                  </span>
                </div>
              </Row>
            </div>
          ))}
        </div>
      </Menu.Item>

      {handledNotifications(props).length === 0 && (
        <Menu.Item>{i18n.t('NO_NEW_NOTIFICATIONS')}</Menu.Item>
      )}

      {props.qtdNotificacoes > 5 && !props.seeAll ? <Menu.Divider /> : null}
      {props.qtdNotificacoes > 5 && !props.seeAll ? (
        <Menu.Item
          key='ver-todas'
          onClick={e => handleNotificationsMenuClick(props, e)}>
          <Text type='primary'>{i18n.t('VIEW_ALL')}</Text>
        </Menu.Item>
      ) : null}
      {props.notificacoesLoading && (
        <Menu.Item>
          <Icon
            component={Loading}
            style={{
              fontSize: 24,
              color: 'var(--text-color)'
            }}
            className='icon-loading'
          />
        </Menu.Item>
      )}
    </StyledMenuNotificacoes>
  );

  const getTypeIcon = () => {
    if (props.exitButton) return;
    let className = 'menu-header';
    let component = MenuIcon;
    if (props.menuList) {
      className = 'menu-list-header';
      component = MenuListIcon;
    }
    return (
      <div className={`${className} cursor-pointer`} onClick={props.handleMenu}>
        <Icon id='menu-icon' component={component} />
      </div>
    );
  };

  const checkBotaoFornecedor = () => {
    if (!vinculos || !props.isPersonLoaded) {
      return false;
    }
    const doesNotHasSupplier = vinculos.every(
      vinculo => vinculo.papeis && !vinculo.papeis.includes('FORNECEDOR')
    );

    return doesNotHasSupplier;
  };

  const toggleMenu = e => {
    setIsOpenMenu(e);
  };

  const goToLogin = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('link')) {
      return `${props.links.login}/?requestedURL=${window.location.href}`;
    } else {
      return props.links.login;
    }
  };

  return (
    <Container
      id='header'
      showMenu={props.showMenu}
      withoutBlur={props.withoutBlur}>
      <div className='container h-100'>
        <div className='d-flex justify-content-between h-100'>
          {pessoa && getTypeIcon()}
          <div className='d-flex align-items-center'>
            {props.exitButton && (
              <div
                className='back-button-header cursor-pointer mr-3 mr-md-4'
                onClick={props.handleMenu}>
                <Icon id='menu-icon' component={ExitIcon} />
              </div>
            )}
            <Link to={'/'} className='d-flex justify-content-start'>
              <Icon
                component={
                  props.whiteYellowLogo ? LogoSebraeWhiteYellow : LogoSebrae
                }
                className='header-logo'
              />
              {props.environment === 'DEV' && (
                <img
                  src={LogoDev}
                  style={{
                    position: 'absolute',
                    margin: '10px 12px 10px 40px',
                    height: '40px',
                    width: '50px'
                  }}
                  alt=''
                  data-cy='header-logo'
                />
              )}
            </Link>
          </div>
          {props.canRenderActions === undefined ||
          props.canRenderActions === true ? (
            <div className='d-flex justify-content-end align-items-center position-relative'>
              {history &&
                history.location &&
                history.location.pathname !== '/' &&
                !history.location.pathname.includes('/catalogo') && (
                  <Link
                    to={props.links.catalogoCliente}
                    className='mr-2 mr-md-4'>
                    <Icon
                      component={SearchIcon}
                      style={{
                        fontSize: '24px',
                        color: 'var(--color-white-gray)'
                      }}
                    />
                  </Link>
                )}
              {history &&
                history.location &&
                history.location.pathname === '/' && (
                  <React.Fragment>
                    <Link
                      to={props.links.catalogoCliente}
                      className='mr-2 mr-md-4 d-block d-lg-none'>
                      <Icon
                        component={SearchIcon}
                        style={{
                          fontSize: '24px',
                          color: 'var(--color-white-gray)'
                        }}
                      />
                    </Link>
                    <Search
                      className='search-input mb-0 px-2 px-md-4 d-none d-lg-block'
                      placeholder={i18n.t('TYPE_WHAT_YOU_NEED')}
                      value={searchText}
                      enterButton={
                        <Button
                          type='primary'
                          shape='round'
                          className='btn-buscar'>
                          {i18n.t('SEARCH')}
                        </Button>
                      }
                      onChange={handleSearchChange}
                      onSearch={() => searchCatalogo()}
                    />
                  </React.Fragment>
                )}
              {props.showNotifications && (
                <React.Fragment>
                  <Dropdown
                    dropdownRender={() => triggerNotifications(props, vinculos)}
                    trigger={['click']}
                    overlayStyle={{ position: 'fixed' }}
                    placement='bottomRight'>
                    <div className='notification-bell ant-dropdown-link'>
                      {props.hasNotifications && (
                        <div className='notification-bubble' />
                      )}
                      <Icon
                        component={Bell}
                        style={{
                          fontSize: '24px',
                          color: 'var(--color-white-gray)'
                        }}
                        className='icon-menu'
                      />
                    </div>
                  </Dropdown>
                </React.Fragment>
              )}

              {props && pessoa && !vinculoAtual && checkBotaoFornecedor() && (
                <ButtonLink
                  href={props.links.cadastroFornecedorCliente}
                  type='primary'
                  shape='round'
                  className='tornar-se-fornecedor px-2 px-md-4'>
                  {i18n.t('WANT_TO_BE_SUPPLIER')}
                </ButtonLink>
              )}
              {props && !pessoa && (
                <React.Fragment>
                  <a href={goToLogin()} className='mr-2 mr-md-4'>
                    <Button shape='round' className='px-2 px-md-4' ghost>
                      {i18n.t('ENTER')}
                    </Button>
                  </a>
                  <a href={`${props.links.login}/cadastro`}>
                    <Button
                      type='primary'
                      shape='round'
                      className='px-2 px-md-4'>
                      {i18n.t('CREATE_YOUR_ACCOUNT')}
                    </Button>
                  </a>
                </React.Fragment>
              )}
              {props.allFeatures && (
                <div
                  className={`w-100 justify-content-center d-md-flex position-mobile ${
                    isOpenMenu ? 'openedMenu' : 'closedMenu'
                  }`}>
                  <Icon
                    component={Search}
                    className='mx-2'
                    style={{
                      fontSize: '24px',
                      color: 'var(--color-white-gray)'
                    }}
                  />
                  <Icon
                    component={Bell}
                    className='mx-2'
                    style={{
                      fontSize: '24px',
                      color: 'var(--color-white-gray)'
                    }}
                  />
                  <Icon
                    component={Calendar}
                    className='mx-2'
                    style={{
                      fontSize: '24px',
                      color: 'var(--color-white-gray)'
                    }}
                  />
                  <Icon
                    component={Cart}
                    className='mx-2'
                    style={{
                      fontSize: '24px',
                      color: 'var(--color-white-gray)'
                    }}
                  />
                  <Icon
                    component={HeartSolid}
                    className='mx-2'
                    style={{
                      fontSize: '24px',
                      color: 'var(--color-white-gray)'
                    }}
                  />
                </div>
              )}
              {props && pessoa && !pessoa.vinculo && (
                <Link to={props.links.carrinho} className='mr-2 mr-md-4'>
                  <Icon
                    component={Cart}
                    style={{
                      fontSize: '24px',
                      color: 'var(--color-white-gray)'
                    }}
                  />
                </Link>
              )}
              {props && pessoa && (
                <React.Fragment>
                  <Dropdown
                    onOpenChange={toggleMenu}
                    dropdownRender={() => triggerModeMenu(props, vinculos)}
                    trigger={['click']}
                    overlayStyle={{ position: 'fixed' }}
                    placement='bottomRight'
                    disabled={isPersonLoading()}>
                    <div className='ant-dropdown-link'>
                      <img
                        src={fotoAtual()}
                        className='header-avatar float-right cursor-pointer'
                        alt={i18n.t('AVATAR_ALT') + (pessoa && pessoa.nome)}
                        onError={handleImageError}
                      />
                    </div>
                  </Dropdown>
                </React.Fragment>
              )}
            </div>
          ) : (
            <img
              src={AvatarPadrao}
              className='avatar-padrao mt-2'
              alt={i18n.t('AVATAR_ALT')}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
