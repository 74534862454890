import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  margin-top: 62px;

  .Aligner {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AlignerButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-template {
    text-align: center;
    margin-bottom: 50px;
  }

  .content-mosaic {
    position: relative;
  }

  .container-mosaic {
    min-width: 575px !important;
  }

  .mosaic {
    grid-template-columns: 25% 25% 25% 25%;
    position: absolute;
    min-height: 280px;
    min-width: 100%;
  }

  .sombra {
    font-size: 422px;
    position: absolute;
    margin: 0;
    left: 68px;
    top: -263px;
    color: #dae1ed;
  }

  .luneta {
    font-size: 120px;
    position: absolute;
    margin: 0;
    left: 60px;
    top: -157px;
  }

  .grupoEstrelas1 {
    font-size: 72px;
    position: absolute;
    margin: 0;
    left: 115px;
    top: -190px;
    color: #8bb1df;
  }

  .grupoEstrelas2 {
    font-size: 51px;
    position: absolute;
    margin: 0;
    left: 330px;
    top: -192px;
    color: #8bb1df;
  }

  .texto {
    letter-spacing: 0px;
    color: #428dff;
    opacity: 1;
    font-size: 17px;
    font-family: 'Raleway';
    text-align: center;
    font-weight: 100;
    position: absolute;
    margin: 0;
    left: 201px;
    top: -56px;
  }

  .four-first {
    font-size: 149px;
    font-family: 'Raleway';
    color: #428dff;
    font-weight: 700;
    position: absolute;
    margin: 0;
    left: 188px;
    top: -244px;
  }

  .zero {
    font-size: 140px;
    font-family: 'Raleway';
    color: #428dff;
    font-weight: 800;
    position: absolute;
    margin: 0;
    top: -239px;
    left: 268px;
  }

  .four-second {
    font-size: 149px;
    font-family: 'Raleway';
    color: #428dff;
    font-weight: 700;
    position: absolute;
    margin: 0;
    top: -244px;
    left: 354px;
  }
  .button-template {
    text-align: left;
  }

  .botao {
    margin-top: 10px;
  }

  .container-mosaic {
    width: 100%;
    min-height: calc(100vh - 102px);
  }
  .content-mosaic {
    min-width: 100%;
  }

  @media (min-width: 576px) {
    .content-mosaic {
      width: 320px;
    }

    .error-template {
      text-align: center;
      margin-bottom: 50px;
    }

    .content-mosaic {
      position: relative;
    }

    .mosaic {
      grid-template-columns: 25% 25% 25% 25%;
      position: absolute;
      min-height: 330px;
      min-width: 100%;
    }

    .sombra {
      font-size: 422px;
      position: absolute;
      margin: 0;
      left: 68px;
      top: -263px;
    }

    .luneta {
      font-size: 120px;
      position: absolute;
      margin: 0;
      left: 60px;
      top: -157px;
    }

    .grupoEstrelas1 {
      font-size: 72px;
      position: absolute;
      margin: 0;
      left: 115px;
      top: -190px;
    }

    .grupoEstrelas2 {
      font-size: 51px;
      position: absolute;
      margin: 0;
      left: 330px;
      top: -192px;
    }

    .texto {
      letter-spacing: 0px;
      color: #428dff;
      opacity: 1;
      font-size: 17px;
      font-family: 'Raleway';
      text-align: center;
      font-weight: 100;
      position: absolute;
      margin: 0;
      left: 201px;
      top: -56px;
    }

    .four-first {
      font-size: 149px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 700;
      position: absolute;
      margin: 0;
      left: 188px;
      top: -244px;
    }

    .zero {
      font-size: 140px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 800;
      position: absolute;
      margin: 0;
      top: -239px;
      left: 268px;
    }

    .four-second {
      font-size: 149px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 700;
      position: absolute;
      margin: 0;
      top: -244px;
      left: 354px;
    }

    .container-mosaic {
      width: 100%;
      min-height: calc(100vh - 102px);
    }
    .content-mosaic {
      min-width: 100%;
    }
  }

  @media (min-width: 768px) {
    .content-mosaic {
      width: 576px;
    }

    .error-template {
      text-align: center;
      margin-bottom: 50px;
    }

    .content-mosaic {
      position: relative;
    }

    .mosaic {
      grid-template-columns: 25% 25% 25% 25%;
      position: absolute;
      min-height: 330px;
      min-width: 100%;
    }

    .sombra {
      font-size: 603px;
      position: absolute;
      margin: 0;
      left: 68px;
      top: -306px;
    }

    .luneta {
      font-size: 170px;
      position: absolute;
      margin: 0;
      left: 60px;
      top: -152px;
    }

    .grupoEstrelas1 {
      font-size: 103px;
      position: absolute;
      margin: 0;
      left: 136px;
      top: -201px;
    }

    .grupoEstrelas2 {
      font-size: 72px;
      position: absolute;
      margin: 0;
      left: 424px;
      top: -200px;
    }

    .texto {
      letter-spacing: 0px;
      color: #428dff;
      opacity: 1;
      font-size: 24px;
      font-family: 'Raleway';
      text-align: center;
      font-weight: 100;
      position: absolute;
      margin: 0;
      left: 247px;
      top: -10px;
    }

    .four-first {
      font-size: 213px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 700;
      position: absolute;
      margin: 0;
      left: 225px;
      top: -280px;
    }

    .zero {
      font-size: 200px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 800;
      position: absolute;
      margin: 0;
      top: -269px;
      left: 336px;
    }

    .four-second {
      font-size: 213px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 700;
      position: absolute;
      margin: 0;
      top: -280px;
      left: 452px;
    }

    .container-mosaic {
      width: 100%;
      min-height: calc(100vh - 102px);
    }
    .content-mosaic {
      min-width: 100%;
    }
  }

  @media (min-width: 992px) {
    .content-mosaic {
      width: 718px;
    }

    .error-template {
      text-align: center;
      margin-bottom: 50px;
    }

    .content-mosaic {
      position: relative;
    }

    .mosaic {
      grid-template-columns: 25% 25% 25% 25%;
      position: absolute;
      min-height: 330px;
      min-width: 100%;
    }

    .sombra {
      font-size: 684px;
      position: absolute;
      margin: 0;
      left: 134px;
      top: -287px;
    }

    .luneta {
      font-size: 170px;
      position: absolute;
      margin: 0;
      left: 148px;
      top: -97px;
    }

    .grupoEstrelas1 {
      font-size: 80px;
      position: absolute;
      margin: 0;
      left: 242px;
      top: -133px;
    }

    .grupoEstrelas2 {
      font-size: 67px;
      position: absolute;
      margin: 0;
      left: 538px;
      top: -147px;
    }

    .texto {
      letter-spacing: 0px;
      color: #428dff;
      opacity: 1;
      font-size: 30px;
      font-family: 'Raleway';
      text-align: center;
      font-weight: 100;
      position: absolute;
      margin: 0;
      left: 355px;
      top: 42px;
    }

    .four-first {
      font-size: 216px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 590;
      position: absolute;
      margin: 0;
      left: 338px;
      top: -229px;
    }

    .zero {
      font-size: 200px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 690;
      position: absolute;
      margin: 0;
      top: -215px;
      left: 450px;
    }

    .four-second {
      font-size: 216px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 590;
      position: absolute;
      margin: 0;
      top: -229px;
      left: 569px;
    }

    .container-mosaic {
      width: 100%;
      min-height: calc(100vh - 102px);
    }
    .content-mosaic {
      min-width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .content-mosaic {
      width: 992px;
    }

    .error-template {
      text-align: center;
      margin-bottom: 50px;
    }

    .content-mosaic {
      position: relative;
    }

    .mosaic {
      grid-template-columns: 25% 25% 25% 25%;
      position: absolute;
      min-height: 300px;
      min-width: 100%;
    }

    .sombra {
      font-size: 790px;
      position: absolute;
      margin: 0;
      left: 143px;
      top: -342px;
    }

    .luneta {
      font-size: 220px;
      position: absolute;
      margin: 0;
      left: 151px;
      top: -142px;
    }

    .grupoEstrelas1 {
      font-size: 91px;
      position: absolute;
      margin: 0;
      left: 279px;
      top: -172px;
    }

    .grupoEstrelas2 {
      font-size: 75px;
      position: absolute;
      margin: 0;
      left: 622px;
      top: -179px;
    }

    .texto {
      letter-spacing: 0px;
      color: #428dff;
      opacity: 1;
      font-size: 30px;
      font-family: 'Raleway';
      text-align: center;
      font-weight: 100;
      position: absolute;
      margin: 0;
      left: 400px;
      top: 48px;
    }

    .four-first {
      font-size: 262px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 700;
      position: absolute;
      margin: 0;
      left: 375px;
      top: -278px;
    }

    .zero {
      font-size: 242px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 800;
      position: absolute;
      margin: 0;
      top: -266px;
      left: 515px;
    }

    .four-second {
      font-size: 262px;
      font-family: 'Raleway';
      color: #428dff;
      font-weight: 700;
      position: absolute;
      margin: 0;
      top: -280px;
      left: 663px;
    }

    .container-mosaic {
      width: 100%;
      min-height: calc(100vh - 102px);
    }
    .content-mosaic {
      min-width: 100%;
    }
  }
`;
