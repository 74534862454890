import Config from '../Config';
import {
  getRefreshToken,
  getToken,
  isTokenUndefinedOrExpired,
  setToken
} from '../util/utilToken';

function setHeaderToken() {
  const accessToken = getToken();
  return new Headers({ Authorization: `Bearer ${accessToken}` });
}

async function verificarToken() {
  if (!isTokenUndefinedOrExpired()) {
    return true;
  }
  const accessToken = await refreshAccessToken();
  if (accessToken) {
    setToken(accessToken);
    return true;
  }
  return false;
}

async function refreshAccessToken() {
  const refreshToken = getRefreshToken();
  if (!refreshToken || !refreshToken.length) {
    return null;
  }

  const resp = await fetch(`${Config.ssoApiUrl}/auth/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refreshToken: refreshToken })
  });

  if (resp && resp.status < 400 && resp.status >= 200) {
    const json = await resp.json();
    return json.accessToken;
  }
  return null;
}

export async function getUsuarioLogado() {
  let token = getToken();
  if (token) {
    const tokenValido = await verificarToken();
    if (!tokenValido) {
      throw new Error('Sem autorização');
    }

    const resp = await fetch(`${Config.ssoApiUrl}/usuario/logado`, {
      headers: setHeaderToken()
    });

    return await resp.json();
  } else {
    return null;
  }
}

export async function getEmailByCPF(cpf) {
  let token = getToken();
  if (token) {
    const tokenValido = await verificarToken();
    if (!tokenValido) {
      throw new Error('Sem autorização');
    }

    const resp = await fetch(`${Config.ssoApiUrl}/usuario/${cpf}/obter-email`, {
      headers: setHeaderToken()
    });

    return await resp.json();
  } else {
    return null;
  }
}
