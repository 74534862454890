import styled from 'styled-components';

export const Container = styled.div`
  color: var(--color-white-gray) !important;

  .title {
    /* stylelint-disable-next-line */
    font-family: 'Raleway';
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--color-white-gray);
  }

  .subtitle {
    /* stylelint-disable-next-line */
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--color-primary);
  }

  .text-normal {
    /* stylelint-disable-next-line */
    font-family: 'Raleway';
    font-weight: 100;
    font-size: 13px;
    letter-spacing: 0.65px;
    color: var(--color-white-gray);
    line-height: 2em;
  }

  p {
    margin-bottom: 0.5em;
  }

  a {
    color: var(--color-white-gray);
  }

  .footer-top {
    background: var(--text-color) 0% 0% no-repeat padding-box;
    padding-bottom: 100px;
  }

  .footer-bottom {
    /* stylelint-disable-next-line */
    background: #000000 0% 0% no-repeat padding-box;
    height: 80px;
    margin-left: ${props => (props.menuSideBar ? '40px !important' : '0px')};
  }

  .icon-facebook-style:hover {
    /* stylelint-disable-next-line */
    color: #3a569a !important;
    transition: color 0.2s;
  }

  .icon-twitter-style:hover {
    /* stylelint-disable-next-line */
    color: #01a7e7 !important;
    transition: color 0.2s;
  }

  .icon-youtube-style:hover {
    /* stylelint-disable-next-line */
    color: #f70100 !important;
    transition: color 0.2s;
  }

  .icon-instagram-style:hover {
    /* stylelint-disable-next-line */
    color: #c70174 !important;
    transition: color 0.2s;
  }

  .icon-linkedin-style:hover {
    /* stylelint-disable-next-line */
    color: #0172ae !important;
    transition: color 0.2s;
  }

  .icon {
    max-width: 40px;
  }

  @media (max-width: 575px) {
    .footer-bottom {
      height: 201px;
      margin-left: 0px;
    }
  }
`;
