export const TipoNotificacaoEnum = {
  CARTEIRA: 'CARTEIRA',
  NEGOCIACAO: 'NEGOCIACAO',
  CURSO_ENVIADO: 'CURSO_ENVIADO',
  CURSO_APROVADO: 'CURSO_APROVADO',
  CURSO_CONCLUIDO: 'CURSO_CONCLUIDO',
  CURSO_PUBLICACAO: 'CURSO_PUBLICACAO',
  CURSO_VIDEO_DEMO: 'CURSO_VIDEO_DEMO',
  ALTERACAO_NO_EVENTO: 'ALTERACAO_NO_EVENTO',
  CURSO_EXPIRACAO_CONCLUSAO: 'CURSO_EXPIRACAO_CONCLUSAO',
  DADOS_CADASTRAIS: 'DADOS_CADASTRAIS',
  CARRINHO: 'CARRINHO',
  SEBRAETEC_VINCULO_APROVADO: 'SEBRAETEC_VINCULO_APROVADO',
  SEBRAETEC_VINCULO_REPROVADO: 'SEBRAETEC_VINCULO_REPROVADO',
  SEBRAETEC_VINCULO_ENVIADO: 'SEBRAETEC_VINCULO_ENVIADO',
  SEBRAETEC_SOLICITACOES_ORCAMENTO: 'SEBRAETEC_SOLICITACOES_ORCAMENTO',
  SEBRAETEC_MEUS_ORCAMENTOS: 'SEBRAETEC_MEUS_ORCAMENTOS',
  SEBRAETEC_DEVOLUCAO_SOLICITADA: 'SEBRAETEC_DEVOLUCAO_SOLICITADA',
  SEBRAETEC_DEVOLUCAO_APROVADA_CLIENTE: 'SEBRAETEC_DEVOLUCAO_APROVADA_CLIENTE',
  SEBRAETEC_DEVOLUCAO_CONTESTACAO_ACEITA_FORNECEDOR:
    'SEBRAETEC_DEVOLUCAO_CONTESTACAO_ACEITA_FORNECEDOR',
  SEBRAETEC_DEVOLUCAO_CONTESTACAO_REJEITADA_FORNECEDOR:
    'SEBRAETEC_DEVOLUCAO_CONTESTACAO_REJEITADA_FORNECEDOR',
  SEBRAETEC_DEVOLUCAO_AJUSTES_CLIENTE: 'SEBRAETEC_DEVOLUCAO_AJUSTES_CLIENTE',
  SEBRAETEC_DEVOLUCAO_AJUSTES_FORNECEDOR:
    'SEBRAETEC_DEVOLUCAO_AJUSTES_FORNECEDOR',
  SAAS_PERGUNTA_RESPONDIDA: 'SAAS_PERGUNTA_RESPONDIDA',
  SAAS_PERGUNTA_RECEBIDA: 'SAAS_PERGUNTA_RECEBIDA',
  SAAS_APROVADO: 'SAAS_APROVADO',
  SAAS_REPROVADO: 'SAAS_REPROVADO',
  CADASTRO_INTEGRACAO: 'CADASTRO_INTEGRACAO',
  CONFIRME_EXECUCAO_NEGOCIACAO: 'CONFIRME_EXECUCAO_NEGOCIACAO',
  MENSAGEM: 'MENSAGEM',
  SAAS_RECLAMACAO: 'SAAS_RECLAMACAO',
  FORNECEDOR: 'FORNECEDOR',
  PRODUTO: 'PRODUTO',
  PRODUTO_REPROVADO: 'PRODUTO_REPROVADO',
  CONSULTORIA: 'CONSULTORIA',
  CONSULTORIA_REPROVADA: 'CONSULTORIA_REPROVADA',
  RESPOSTA_AVALIACAO_CURSO: 'RESPOSTA_AVALIACAO_CURSO',
  RESPOSTA_PERGUNTA_AULA_CURSO: 'RESPOSTA_PERGUNTA_AULA_CURSO',
  MENSAGEM_SUPORTE: 'MENSAGEM_SUPORTE',
  NOTA_FISCAL_AFILIACAO: 'NOTA_FISCAL_AFILIACAO'
};
