import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { Row, Col, Typography, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import CarouselNew from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  GET_LISTAR_PRODUTOS_HOME,
  ALTERNAR_CONSULTORIA_FAVORITA,
  ALTERNAR_EVENTO_FAVORITO,
  GET_INDICADORES_PRODUTOS_HOME,
  ARE_FAVORITOS
} from './queries';
import Informacoes from './components/Informacoes/Informacoes.js';
import { Button } from '../../components/LinkComponent/LinkComponent';
import AffiliationTutorial from '../../components/AffiliationTutorial/AffiliationTutorial';
import SecaoSejaFornecedor from './components/SecaoSejaFornecedor/SecaoSejaFornecedor';
import FornecedoresDestaque from '../../components/FornecedoresDestaque/FornecedoresDestaque';
import ProdutoComponent from '../../components/ProdutoComponent/ProdutoComponent';
import CardProductCourse from '../../components/CardProductCourse/CardProductCourse';
import CardProductSebraetec from '../../components/CardProductSebraetec/CardProductSebraetec.js';
import SecaoSejaUmAfiliado from './components/SecaoSejaUmAfiliado/SecaoSejaUmAfiliado';
import CategoriasProdutos from './components/CategoriasProdutos/CategoriasProdutos';
import ItemsCatalogo from '../CatalogoCliente/components/ItemsCatalogo/ItemsCatalogo';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
import { ContextPerson } from '../../store/contexts/contextPerson';
import { ContextAffiliate } from '../../store/contexts/contextAffiliate';
import { formatValor } from '../../shared/util/transfoms';
import { formatDataToProductCourse } from '../../shared/util/cursosOnlineUtils';
import { modalidade } from '../../shared/util/enums/modalidadeCatalogoEnum';
import i18n from '../../shared/util/i18n';

import BgMeeting from '../../images/webps/bg-meeting-filter.webp';
import { ReactComponent as CaretLeftCircle } from '../../images/svgs/caret-left-circle.svg';
import { ReactComponent as CaretRightCircle } from '../../images/svgs/caret-right-circle.svg';

import { DivMain } from './styles';

const { Title } = Typography;

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 992 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 578 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 578, min: 0 },
    items: 1
  }
};

export default function Home() {
  const { pessoa } = useContext(ContextPerson);
  const { allowsAffiliate } = useContext(ContextAffiliate);
  const { width } = useWindowDimensions();
  const [pageSize, setPageSize] = useState(4);
  const carouselMultiRef = useRef();
  const [products, setProducts] = useState([]);
  const [productsFavoritos, setProductsFavoritos] = useState([]);
  const [searchFavoritos, setSearchFavoritos] = useState(false);
  const [indicadoresProdutos, setIndicadoresProdutos] = useState({
    possuiConsultorias: undefined,
    possuiEventos: undefined,
    possuiSolucoesDigitais: undefined,
    possuiCursosOnline: undefined
  });

  const { loading: indicadoresProdutosLoading } = useQuery(
    GET_INDICADORES_PRODUTOS_HOME,
    {
      onCompleted(data) {
        if (data && data.getIndicadoresProdutosHome) {
          setIndicadoresProdutos({ ...data.getIndicadoresProdutosHome });
        }
      }
    }
  );

  const { loading: getListProductsLoading } = useQuery(
    GET_LISTAR_PRODUTOS_HOME,
    {
      onCompleted(data) {
        if (data && data.listarProdutosHome) {
          setProducts(
            data.listarProdutosHome.map(p => {
              return { ...p };
            })
          );
        }
      },
      variables: { limit: 20 }
    }
  );

  const [areFavoritos] = useLazyQuery(ARE_FAVORITOS, {
    onCompleted(data) {
      if (data && data.areFavoritos && data.areFavoritos.length > 0) {
        setProductsFavoritos(
          data.areFavoritos.map(
            f =>
              (f.consultoria && f.consultoria.id) ||
              (f.evento && f.evento.id) ||
              (f.curso && f.curso.id)
          )
        );
        setSearchFavoritos(true);
      }
    }
  });

  const [alternarConsultoriaFavorita] = useMutation(
    ALTERNAR_CONSULTORIA_FAVORITA
  );

  const [alternarEventoFavorito] = useMutation(ALTERNAR_EVENTO_FAVORITO);

  useEffect(() => {
    if (width < 576) {
      setPageSize(1);
    } else if (width < 992) {
      setPageSize(2);
    } else if (width < 1200) {
      setPageSize(3);
    } else {
      setPageSize(4);
    }
  }, [width]);

  function produtosTransform(item) {
    return {
      id: item.id,
      nome: item.nome,
      propostaValor: item.propostaValor,
      preco: item.preco,
      fotoUrl: item.fotoUrl,
      fotoUrlResponsive: item.fotoUrlResponsive,
      pessoa: item.pessoa,
      categoria: item.categoria,
      cargaHoraria: item.cargaHoraria,
      link: '/produto/' + item.id,
      condicoes:
        item.preco && '12 x ' + formatValor(item.preco / 12) + ' sem juros',
      classificacao: item.classificacao,
      mediaAvaliacao: item.mediaAvaliacao
    };
  }

  const formatDataToProductSaas = data => {
    return {
      ...data,
      ...data.saasProduto
    };
  };

  const formatDataToProductEvent = data => {
    return {
      ...data,
      ...data.evento
    };
  };

  const handleNextProduct = () => {
    if (carouselMultiRef.current) {
      carouselMultiRef.current.next();
    }
  };

  const handlePreviousProduct = () => {
    if (carouselMultiRef.current) {
      carouselMultiRef.current.previous();
    }
  };

  useEffect(() => {
    if (pessoa && pessoa.id && !searchFavoritos && products.length) {
      // Verifica quais produtos são favoritos
      areFavoritos({
        variables: {
          entidadesId: products.map(p => p.id)
        }
      });
    }
    // eslint-disable-next-line
  }, [pessoa]);

  return (
    <DivMain>
      {pessoa && pessoa.afiliacao && pessoa.afiliacao.mostrarTutorial && (
        <AffiliationTutorial />
      )}

      <div className='overflow-hidden containerHome'>
        <div className='image-div'>
          <img
            src={BgMeeting}
            alt={i18n.t('HOME_BANNER_ALT')}
            className='w-100 img-header'></img>
        </div>
        <div className='container'>
          <Row>
            <Col className='col-texts'>
              <h1 className='title'>
                {i18n.t('SEBRAEPLACE_HOME')}
                <br className='break-line-title' />
                {i18n.t('HOME_TITLE_1')}
                <span className='title-highlight'>
                  {i18n.t('HOME_TITLE_2')}
                </span>
                {i18n.t('HOME_TITLE_3')}
              </h1>
              <h3 className='sub-title'>{i18n.t('HOME_SUBTITLE')}</h3>
            </Col>
          </Row>
          <div className='card-options'>
            <CategoriasProdutos
              loading={indicadoresProdutosLoading}
              indicadoresProdutos={indicadoresProdutos}
            />
          </div>
        </div>

        {/* VOCE PODE GOSTAR */}

        <div className='container pt-5'>
          <div className='d-flex justify-content-between mb-4 align-items-center'>
            <div className='d-flex flex-column flex-sm-row align-items-end'>
              <Title level={2} className='mb-0 title-home'>
                {i18n.t('SUGGESTIONS_TO_YOU')}
              </Title>
            </div>

            {products.length > pageSize && (
              <div>
                <Icon
                  component={CaretLeftCircle}
                  onClick={() => handlePreviousProduct()}
                  style={{
                    fontSize: 32,
                    color: 'var(--color-primary)'
                  }}
                />
                <Icon
                  component={CaretRightCircle}
                  onClick={() => handleNextProduct()}
                  style={{
                    marginLeft: '15px',
                    color: 'var(--color-primary)',
                    fontSize: 32
                  }}
                />
              </div>
            )}
          </div>

          <Skeleton loading={getListProductsLoading} active>
            <CarouselNew
              ref={carouselMultiRef}
              autoPlay={true}
              autoPlaySpeed={5000}
              infinite={true}
              centerMode={false}
              partialVisible
              className=''
              containerClass='container-carousel'
              dotListClass=''
              draggable
              focusOnSelect={false}
              itemClass='carousel-item'
              keyBoardControl
              minimumTouchDrag={80}
              arrows={false}
              renderDotsOutside={false}
              responsive={responsive}
              showDots={false}
              additionalTransfrom={0}
              sliderClass=''
              slidesToSlide={1}
              swipeable
              customButtonGroup={<></>}>
              {products &&
                Array.isArray(products) &&
                products.map((product, index) => (
                  <React.Fragment key={product.id + index}>
                    {product.tipo === 'PRODUTO' && (
                      <ProdutoComponent
                        key={product.id + index}
                        data={produtosTransform(product)}
                        isHome={true}
                      />
                    )}
                    {product.tipo === modalidade.CONSULTORIA && (
                      <ItemsCatalogo
                        key={product.id + index}
                        item={product}
                        isFavorite={
                          !!productsFavoritos.find(pf => pf === product.id)
                        }
                        isFull={true}
                        alternarConsultoriaFavorita={
                          alternarConsultoriaFavorita
                        }
                      />
                    )}
                    {product.tipo === modalidade.SAAS_PRODUTO && (
                      <ItemsCatalogo
                        key={product.id + index}
                        item={formatDataToProductSaas(product)}
                        isFull={true}
                      />
                    )}

                    {product.tipo === modalidade.EVENTO && (
                      <ItemsCatalogo
                        key={product.id + index}
                        item={formatDataToProductEvent(product)}
                        isFavorite={
                          !!productsFavoritos.find(pf => pf === product.id)
                        }
                        alternarEventoFavorito={alternarEventoFavorito}
                        isFull={true}
                      />
                    )}

                    {product.tipo === modalidade.CURSO && (
                      <CardProductCourse
                        key={product.id + index}
                        {...formatDataToProductCourse(product.curso)}
                        isFavorito={
                          !!productsFavoritos.find(pf => pf === product.id)
                        }
                        isFull={true}
                        isHome={true}
                      />
                    )}

                    {product.tipo === modalidade.SEBRAETEC_FICHA_TECNICA && (
                      <CardProductSebraetec
                        key={product.id + index}
                        {...product.sebraetecFichaTecnica}
                        isFull={true}
                        isHome={true}
                      />
                    )}
                  </React.Fragment>
                ))}
            </CarouselNew>
          </Skeleton>
          <div className='text-center mt-3'>
            <Button
              type='primary'
              shape='round'
              className='wide'
              href='/catalogo'>
              {i18n.t('SEE_ALL')}
            </Button>
          </div>
        </div>

        <Informacoes />

        {/* PROFISSIONAIS RECENTES */}
        <FornecedoresDestaque pageSize={pageSize} />
        <div className='text-center mt-3'>
          <Button
            type='primary'
            shape='round'
            className='wide'
            href='/catalogo?tipo=consultoria'>
            {i18n.t('SEE_THE_CONSULTANCIES')}
          </Button>
        </div>

        {/* PRONTO PARA FAZER PARTE */}
        <SecaoSejaFornecedor />
        {pessoa && !pessoa.vinculo && allowsAffiliate && (
          <SecaoSejaUmAfiliado />
        )}
      </div>
    </DivMain>
  );
}
