import dayjs from 'dayjs';
import themeDark from '../../styles/themes/themeDark';
import themeLight from '../../styles/themes/themeLight';
import i18n from './i18n';
import { TipoAulaEnum } from './enums/TipoAulaEnum';
import { themesEnum } from './enums/themesEnum';
import { getCroppedImage, transformSecondsToHHOrMM } from './transfoms';

export const listaPassos = [
  {
    passo: 'titulo',
    title: i18n.t('ABOUT_YOUR_COURSE')
  },
  {
    passo: 0,
    title: i18n.t('DEFINE_TITLE_COURSE')
  },
  {
    passo: 1,
    title: i18n.t('DEFINE_COURSE_SEGMENT_CATEGORY')
  },
  {
    passo: 'divisao'
  },
  {
    passo: 'titulo',
    title: i18n.t('PLAN_YOUR_COURSE')
  },
  {
    passo: 2,
    title: i18n.t('DEFINE_PERSONAS')
  },
  {
    passo: 3,
    title: i18n.t('DEFINE_TARGET_STUDENTS')
  },
  {
    passo: 4,
    title: i18n.t('COURSE_STRUCTURE')
  },
  {
    passo: 5,
    title: i18n.t('CONFIGURATION_VIDEO_COURSE')
  },
  {
    passo: 'divisao'
  },
  {
    passo: 'titulo',
    title: i18n.t('CREATE_YOUR_CONTENT')
  },
  {
    passo: 6,
    title: i18n.t('FILMING_AND_EDITING')
  },
  {
    passo: 7,
    title: i18n.t('VIDEO_DEMO_COURSE')
  },
  {
    passo: 8,
    title: i18n.t('COURSE_CONTENT')
  },
  {
    passo: 'divisao'
  },
  {
    passo: 'titulo',
    title: i18n.t('PUBLISH_YOUR_COURSE')
  },
  {
    passo: 9,
    title: i18n.t('INITIAL_PAGE_COURSE')
  },
  {
    passo: 10,
    title: i18n.t('PRICE_COURSE')
  },
  {
    passo: 11,
    title: i18n.t('SEBRAEPLACE_SERVICES')
  },
  {
    passo: 12,
    title: i18n.t('COURSE_MESSAGES')
  },
  {
    passo: 'divisao'
  }
];

export const getProgressoCurso = (
  course,
  minAula,
  minValor,
  minValorParcela,
  minDuracao,
  precoIndefinido
) => {
  if (!course) return { percentual: 0, passosConcluidos: [] };
  let steps = [];
  let total = 0;
  let valido = 0;

  if (getValidacaoTitulo(course.nome)) {
    total = total + 1;
    valido = valido + 1;
    steps.push(0);
  }

  if (getValidacaoCategoriaSegmentos(course)) {
    total = total + 1;
    valido = valido + 1;
    steps.push(1);
  }

  if (course.personas !== null && course.personas !== '') {
    total = total + 1;
    valido = valido + 1;
    steps.push(2);
  }

  if (getValidacaoAlunos(course)) {
    total = total + 1;
    valido = valido + 1;
    steps.push(3);
  }

  if (course.cienciaEstruturaCurso) {
    total = total + 1;
    valido = valido + 1;
    steps.push(4);
  }

  if (course.cienciaConfiguracaoVideo) {
    total = total + 1;
    valido = valido + 1;
    steps.push(5);
  }

  if (course.cienciaFilmagemEdicao) {
    total = total + 1;
    valido = valido + 1;
    steps.push(6);
  }

  if (getValidacaoVideoDemo(course)) {
    total = total + 1;
    valido = valido + 1;
    steps.push(7);
  }

  if (getValidacaoConteudo(course, minAula, minDuracao)) {
    total = total + 1;
    valido = valido + 1;
    steps.push(8);
  }

  if (getValidacaoPaginaInicial(course)) {
    total = total + 1;
    valido = valido + 1;
    steps.push(9);
  }

  if (
    getValidacaoPreco(course, minValor, minValorParcela) &&
    !precoIndefinido
  ) {
    total = total + 1;
    valido = valido + 1;
    steps.push(10);
  }

  if (
    course.permiteCupomDescontoSebrae !== null &&
    course.permiteMarketingAfiliado !== null
  ) {
    total = total + 1;
    valido = valido + 1;
    steps.push(11);
  }

  if (getValidacaoMensagens(course)) {
    total = total + 1;
    steps.push(12);
  }

  return {
    percentual: ((total / 13) * 100).toFixed(0),
    passosConcluidos: steps,
    cadastroValido: valido === 12
  };
};

export const getValidacaoTitulo = titulo => {
  if (
    titulo !== null &&
    titulo !== '' &&
    titulo.replace(/ /g, '').length >= 3
  ) {
    return true;
  } else {
    return false;
  }
};

export const getValidacaoCategoriaSegmentos = course => {
  if (
    course.categoria &&
    course.subcategoria &&
    course.subcategoria.categoria &&
    course.subcategoria.categoria.id === course.categoria.id &&
    course.segmentos &&
    course.segmentos.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const getValidacaoAlunos = course => {
  if (
    course.aprendizados &&
    course.aprendizados.length !== 0 &&
    course.aprendizados[0] !== null &&
    course.aprendizados[0] !== '' &&
    course.requisitos &&
    course.requisitos.length !== 0 &&
    course.requisitos[0] !== null &&
    course.requisitos[0] !== '' &&
    course.publicoAlvo &&
    course.publicoAlvo.length !== 0 &&
    course.publicoAlvo[0] !== null &&
    course.publicoAlvo[0] !== ''
  ) {
    return true;
  } else {
    return false;
  }
};

export const getValidacaoVideoDemo = course => {
  if (
    course.cienciaVideoDemo === true ||
    (course.videoDemo &&
      course.videoDemo.videoUrl &&
      course.videoDemo.dataInclusao)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getValidacaoConteudo = (course, minAula, minDuracao) => {
  if (!course.secoes || course.secoes.length === 0) return false;
  let qtdAula = 0;

  let valido = true;
  for (let secao of course.secoes) {
    if (
      !secao.nome ||
      (secao.nome && secao.nome.replace(/ /g, '').length < 3)
    ) {
      valido = false;
    }
    if (!secao.aulas || secao.aulas.length === 0) {
      valido = false;
    } else {
      qtdAula += secao.aulas.length;
    }
    for (let aula of secao.aulas) {
      if (
        aula.tipo === null ||
        !aula.nome ||
        (aula.nome && aula.nome.replace(/ /g, '').length < 3) ||
        (aula.tipo === TipoAulaEnum.ESCRITA && !aula.descricao) ||
        (aula.tipo === TipoAulaEnum.VIDEO && !aula.videoStorageUrl) ||
        (aula.tipo === TipoAulaEnum.QUESTIONARIO &&
          !aula.descricao &&
          (!aula.questionarios || aula.questionarios.length === 0))
      ) {
        valido = false;
      }
    }
  }

  if (qtdAula < minAula) {
    valido = false;
  }

  if (course.quantidadeSegundos < minDuracao) {
    valido = false;
  }

  return valido;
};

export const getValidacaoPaginaInicial = course => {
  if (
    getValidacaoTitulo(course.nome) &&
    course.categoria &&
    course.subcategoria &&
    course.subcategoria.categoria &&
    course.subcategoria.categoria.id === course.categoria.id &&
    course.videoDivulgacaoUrl &&
    course.videoDivulgacaoUrl !== null &&
    course.imagemCapa &&
    course.imagemCapa.imagemOriginal &&
    course.imagemCapa.imagemOriginal !== null &&
    course.nivel &&
    course.nivel.id &&
    course.descricao &&
    course.descricao !== null &&
    course.pessoa &&
    course.pessoa.nome !== null &&
    course.pessoa.ocupacao !== null &&
    course.pessoa.bio &&
    course.pessoa.bio.descricao !== null &&
    course.pessoa.fotoUrl !== null
  ) {
    return true;
  } else {
    return false;
  }
};

export const getValidacaoPreco = (course, minValor, minValorParcela) => {
  let valido = false;

  if (course.isGratuito) valido = true;

  if (
    !course.isGratuito &&
    course.limiteParcelamento >= 1 &&
    course.valorMercado > course.valor &&
    course.valor >= minValor &&
    course.valor / course.limiteParcelamento >= minValorParcela
  )
    valido = true;

  return valido;
};

export const getValidacaoMensagens = course => {
  if (
    (course.mensagemBoasVindas === '' && course.mensagemConclusao === '') ||
    (course.mensagemBoasVindas &&
      course.mensagemConclusao &&
      course.mensagemBoasVindas.length >= 3 &&
      course.mensagemConclusao.length >= 3)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getDefaultTheme = () => {
  const storageValue = localStorage.getItem('theme');
  if (storageValue) {
    return storageValue === themesEnum.DARK ? themeDark : themeLight;
  } else {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? themeDark
      : themeLight;
  }
};

export const setDefaultTheme = theme => {
  localStorage.setItem('theme', theme);
};

export const getDefaultAutoplay = () => {
  const storageValue = localStorage.getItem('autoplay');
  return storageValue === 'true';
};

export const setDefaultAutoplay = autoplay => {
  localStorage.setItem('autoplay', autoplay);
};

export const getDescription = data => {
  if (data && data.categoria && data.categoria.nome) {
    return data.categoria.nome;
  }

  if (data && data.subcategoria && data.subcategoria.nome) {
    return data.subcategoria.nome;
  }

  return '';
};

export const formatDataToProductCourse = course => {
  return {
    id: course && course.id ? course.id : undefined,
    slug: course && course.slug ? course.slug : undefined,
    nome: course && course.nome ? course.nome : undefined,
    categoria: getDescription(course),
    cover:
      course && course.imagemCapa
        ? getCroppedImage(course.imagemCapa)
        : undefined,
    imagemResponsive:
      course && course.imagemCapa && course.imagemCapa.imagemResponsive,
    idPerfil:
      course && course.pessoa && course.pessoa.id
        ? course.pessoa.id
        : undefined,
    fotoUrl:
      course && course.pessoa && course.pessoa.fotoUrl
        ? course.pessoa.fotoUrl
        : undefined,
    fotoUrlResponsive:
      course && course.pessoa && course.pessoa.fotoUrlResponsive,
    mediaAvaliacao:
      course && course.mediaAvaliacao ? course.mediaAvaliacao : undefined,
    valor: course && course.valor ? course.valor : undefined,
    valorFinal:
      course && course.valorFinal && course.valorFinal > 0
        ? course.valorFinal
        : undefined,
    valorMercado:
      course && course.valorMercado ? course.valorMercado : undefined,
    duracao:
      course && course.quantidadeSegundos
        ? transformSecondsToHHOrMM(course.quantidadeSegundos)
        : undefined,
    isGratuito: course.isGratuito,
    pessoa: course.pessoa,
    percentualDesconto:
      course &&
      course.campanhaDesconto &&
      course.campanhaDesconto.percentualDesconto,
    isFavorito: course && course.isFavorito
  };
};

export const getLastSevenDays = () => {
  let datas = [];
  for (let i = 0; i < 7; i++) {
    datas.unshift(
      dayjs()
        .add(-i, 'days')
        .format()
    );
  }
  return datas;
};
