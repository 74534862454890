export function getGraphQLErrors({
  graphQLErrors,
  networkError,
  operation,
  message
}) {
  if (
    graphQLErrors &&
    Array.isArray(graphQLErrors) &&
    graphQLErrors.length > 0
  ) {
    let errors = '';
    graphQLErrors.forEach(({ message }) => {
      errors += `${typeof message === 'string' ? message : message.message} `;
    });
    return errors;
  }

  if (networkError && operation) {
    return `Problemas na rede: ${operation.operationName}: ${networkError.message}`;
  }

  return message;
}

export function isGraphQLErrors404({ graphQLErrors }) {
  let is404 = false;
  if (
    graphQLErrors &&
    Array.isArray(graphQLErrors) &&
    graphQLErrors.length > 0
  ) {
    graphQLErrors.forEach(({ extensions, message }) => {
      if (message.statusCode === 404 || extensions.status === 404) {
        is404 = true;
      }
    });
  }

  return is404;
}
